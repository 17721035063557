import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Header, Body, Footer, Form, Button, Text, FileList2, DynamicList } from 'crack-ux';
import { Amount } from 'crack-functions';

// Context
import { useSession } from '../../../../contexts/general/Session';
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useItems } from '../../../../contexts/collections/storage/Items';
import { useProducts } from '../../../../contexts/collections/commerce/Products';
import { useLists } from '../../../../contexts/collections/home/Lists';

// Components
import { FeaturesForm } from '../../../../components/FeaturesForm/FeaturesForm';
import { BarcodeQRScanner } from '../../../../components/BarcodeQRScanner';
import { ItemForm } from '../../home/Lists/ItemForm';

// Styles
import './ProductForm.css';

const categoriesInternalName = 'product-categories';

const ProductForm = (props) =>
{
	const { product = {}, categories = [] } = props;

	const [productForm, setProductForm] = useState(product._id ?
	{
		...product,
		purchase: product.price?.purchase || 0,
		sale: product.price?.sale || 0,
		minQuantity: product.quantity?.min || 0,
		maxQuantity: product.quantity?.max || 0,
	} : product);
	const [messages, setMessages] = useState({});
	const [productCategories, setProductCategories] = useState(product.categories || categories);
	const [productUnit, setProductUnit] = useState(product.unit || {});
	const [pictures, setPictures] = useState([]);
	const [showFeatures, setShowFeatures] = useState(false);
	const [features, setFeatures] = useState(product.features || []);
	const [showConditionalPrices, setShowConditionalPrices] = useState(product.price?.conditional?.length > 0);
	const [conditionalPrices, setConditionalPrices] = useState(product.price?.conditional || []);

	const { user } = useSession();
	const { AddModal, CloseModal } = useModal();
	const { Translate } = useTraductor();
	const { itemsById } = useItems();
	const { code, CreateProduct, UpdateProduct } = useProducts();
	const { listsByName } = useLists();

	const categoriesList = useMemo(() => listsByName[categoriesInternalName] || [], [listsByName]);
	const unitsList = useMemo(() => listsByName.units || [], [listsByName]);

	const HandleSubmit = useCallback(async () =>
	{
		const messagesData = {};

		if(!productForm.name) messagesData.name = Translate('field-required');
		if(!productForm.purchase) messagesData.purchase = Translate('field-required');
		if(!productForm.sale) messagesData.sale = Translate('field-required');

		setMessages(messagesData);

		if(Object.keys(messagesData).length === 0)
		{
			const data =
			{
				...productForm,
				price:
				{
					purchase: productForm.purchase,
					sale: productForm.sale
				},
				quantity:
				{
					...product.quantity,
					min: productForm.minQuantity || 0,
					max: productForm.maxQuantity || 0
				},
				features,
				categories: productCategories,
				available: product._id ? product.available : true,
				pictures: pictures.filter(picture => picture._id).map(picture => picture._id),
				_files:
				{
					product: pictures.filter(picture => !picture._id).map(picture => picture.file),
				}
			};
			
			if(product._id) data._id = product._id;
			if(productUnit.value) data.unit = productUnit.value;
			if(conditionalPrices.length > 0) data.price.conditional = conditionalPrices.map(price => ({quantity: price.quantity, price: price.price, type: price.type}));
			
			const result = product._id ? await UpdateProduct(data) : await CreateProduct(data);
	
			if(result.status === 200)
			{
				CloseModal();
			}
		}
	}, [productForm, pictures, features, product, CreateProduct, UpdateProduct, CloseModal, Translate, productCategories, productUnit, conditionalPrices]);

	useEffect(() =>
	{
		const picturesData = product.pictures ? product.pictures.map(picture => itemsById[picture]) : [];

		setPictures(picturesData);
	}, [product.pictures, itemsById]);

	return (
		<Card class='product-productForm'>
			<Header>
				<Text class='bold'>{product._id ? Translate('Edit Product') : Translate('New Product')}</Text>
			</Header>
			<Body class='vertical-scroll'>
				<Form
					inputs =
					{
						[
							{
								name: 'name',
								text: Translate('product-name'),
								class: 'desktop9',
								value: productForm.name,
								message: messages.name
							},
							{
								name: 'code',
								text: Translate('code'),
								class: 'desktop3',
								disabled: true,
								value: product.codeText || `PROD-${code + 1}`,
								message: messages.code
							},
							{
								name: 'description',
								text: Translate('description'),
								type: 'textarea',
								value: productForm.description,
								message: messages.description
							},
							{
								text: Translate('pictures'),
								type: 'content',
								content: (
									<FileList2
										fileList = {product.pictures?.map(picture =>
										{
											return {
												_id: picture,
												file: itemsById[picture]?.file,
												src: itemsById[picture]?.publicURL || '/assets/box.png'
											};
										})}
										accept = 'image/*'
										Change = {(response) =>
										{
											const picturesData = response.files.map(file =>
											{
												return {
													_id: file.localFile ? null : file._id,
													file: file.localFile || file.file,
												};
											});

											setPictures(picturesData);
										}}
										language = {user.preferences?.language || 'es'}
									/>
								)
							},
							{
								type: 'content',
								content: <Button class='blue full-width left' text={showFeatures ? Translate('hide-features') : Translate('show-features')} Click={() => setShowFeatures(!showFeatures)}/>,
								removeText: true,
								removeMessage: true
							},
							...(showFeatures) ?
							[
								{
									text: Translate('features'),
									type: 'content',
									content: <FeaturesForm features={features} setFeatures={setFeatures}/>
								}
							] : [],
							{
								name: 'purchase',
								text: Translate('purchase-price'),
								class: 'mobile6',
								type: 'number',
								value: productForm.purchase,
								message: messages.purchase
							},
							{
								name: 'sale',
								text: Translate('sales-price'),
								class: 'mobile6',
								type: 'number',
								value: productForm.sale,
								message: messages.sale
							},
							{
								type: 'content',
								content: <Button class='blue full-width left' text={showConditionalPrices ? Translate('hide-conditional-prices') : Translate('show-conditional-prices')} Click={() => setShowConditionalPrices(!showConditionalPrices)}/>,
								removeText: true,
								removeMessage: true
							},
							...(showConditionalPrices) ?
							[
								{
									name: 'conditionalPrices',
									text: Translate('conditional-prices'),
									type: 'content',
									content: <DynamicList
												items = {conditionalPrices}
												setItems = {setConditionalPrices}
												fields =
												{[
													{
														name: 'type',
														text: Translate('movement-type'),
														class: 'desktop6',
														type: 'select',
														options: listsByName?.['stock-movement-types']?.selectOptions,
													},
													{
														name: 'quantity',
														text: Translate('quantity'),
														class: 'desktop2',
														type: 'number',
													},
													{
														name: 'price',
														text: Translate('price'),
														class: 'desktop2',
														type: 'number',
													},
												]}
												itemListTitle = {Translate('conditional-prices')}
												RenderItem = {({ quantity, price, type }) =>
												{
													const movementType = listsByName?.['stock-movement-types']?.itemsById?.[type];

													return <Text>{Translate('movement')} {movementType.text}: {Translate('quantity')} {quantity} - {Translate('unit-price')} {Amount.CurrencyFormat(price)}</Text>
												}}
											/>
								}
							] : [],
							{
								name: 'minQuantity',
								text: Translate('min-quantity'),
								class: 'mobile6',
								type: 'number',
								value: productForm.minQuantity,
								message: messages.minQuantity
							},
							{
								name: 'maxQuantity',
								text: Translate('max-quantity'),
								class: 'mobile6',
								type: 'number',
								value: productForm.maxQuantity,
								message: messages.maxQuantity
							},
							{
								name: 'unit',
								text: Translate('unit'),
								class: 'desktop8 mobile7',
								type: 'autocomplete',
								options: unitsList.selectOptions,
								value: productUnit.text,
								Change: (unit) => setProductUnit(unit),
								message: messages.unit
							},
							{
								class: 'desktop4 mobile5',
								type: 'content',
								content: <Button class='full-width bg-blue white' text={Translate('new-unit')} Click={() => AddModal(<ItemForm internalName='units' />)}/>
							},
							{
								name: 'categories',
								text: Translate('categories'),
								class: 'desktop8 mobile7',
								type: 'autocomplete',
								options: categoriesList.selectOptions,
								multiple: true,
								value: productCategories,
								Change: (categories) => setProductCategories(categories),
								message: messages.categories
							},
							{
								class: 'desktop4 mobile5',
								type: 'content',
								content: <Button class='full-width bg-blue white' text={Translate('new-category')} Click={() => AddModal(<ItemForm internalName={categoriesInternalName} />)}/>
							},
							{
								name: 'barcode',
								text: Translate('barcode'),
								class: 'desktop8 mobile7',
								value: productForm.barcode,
								message: messages.barcode
							},
							{
								class: 'desktop4 mobile5',
								type: 'content',
								content: <Button class='full-width bg-blue white' text={Translate('scann')} Click={() => AddModal(<BarcodeQRScanner ScanSuccess={(barcode) => setProductForm({...productForm, barcode})} scope='barcode'/>)}/>
							},
						]
					}
					form = {productForm}
					setForm = {setProductForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('save')}
					class = 'bg-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {ProductForm};