import React from 'react';
import { Content, Body, Panel, Header, Text, List, Card } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useTraductor } from '../../../../contexts/general/Traductor';

// Tables
import { ItemsTable } from './ItemsTable';

// Static Data
const filesModules = require('../../../../static-data/FilesModules.json');

const Files = () =>
{
	const { screen } = useGeneral();	
	const { Translate } = useTraductor();

	const navigate = useNavigate();
	const params = useParams();

	const { module } = params;
	const  isNotMobile = screen.device !== 'mobile';

	return (
		<Content>
			<Panel class='no-padding' style={{height: 'calc(100vh - var(--header) - 10px)'}}>
				{(isNotMobile || !module) && 
				<Panel class='full-height desktop3'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('folders')}</Text>
						</Header>
						<Body class='no-padding'>
							<List
								class = 'hover'
								currentClass = 'bg-blue white'
								current = {module}
								options = {filesModules.filter(item => !item.hidden).map(item =>
								{
									return {
										identifier: item.value,
										text: Translate(item.text),
										Click: () => navigate(`${module ? '../' : './'}${item.value}`)
									}
								})}
							/>
						</Body>
					</Card>
				</Panel>}
				{(isNotMobile || module) && 
				<Panel class='full-height desktop9'>
					<Card>
						<ItemsTable module={module}/>
					</Card>
				</Panel>}
			</Panel>
		</Content>
	);
}

export { Files };