import { useEffect, useMemo, useState } from "react";
import { Card, Header, Body, Footer, Text, Form, Button } from 'crack-ux';
import { Dates } from 'crack-functions';
import moment from "moment";

// Context
import { useTraductor } from "../../../../../contexts/general/Traductor";
import { useLists } from "../../../../../contexts/collections/home/Lists";

const Filter = (props) =>
{
	const {filter, setFilter, defaultFilter, periodOptions} = props;

	const [localFilter, setLocalFilter] = useState({...filter});

	const { Translate } = useTraductor();
	const { listsByName } = useLists();

	const categories = useMemo(() => listsByName?.['product-categories'] || {selectOptions: []}, [listsByName]);

	useEffect(() =>
	{
		setLocalFilter({...filter});
	}, [filter]);

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('filter')}</Text>
			</Header>
			<Body style={{height: 'calc(100% - var(--header) - var(--footer))'}}>
				<Form
					inputs =
					{[
						{
							name: 'period',
							text: Translate('period'),
							type: 'select',
							options: periodOptions,
							value: localFilter.period,
							Change: (response) =>
							{
								let startDate = '';
								let endDate = '';

								if(response.value === 'current-month')
								{
									startDate = moment().startOf('month');
									endDate = moment().endOf('month');
								}

								if(response.value === 'current-quarter')
								{
									startDate = moment().startOf('quarter');
									endDate = moment().endOf('quarter');
								}
								
								if(response.value === 'current-year')
								{
									startDate = moment().startOf('year');
									endDate = moment().endOf('year');
								}

								setLocalFilter(
								{
									...localFilter,
									period: response.value,
									startDate,
									endDate
								});
							}
						},
						{
							name: 'startDate',
							text: Translate('start-date'),
							type: 'date',
							value: Dates.GetDateForForm(localFilter.startDate),
							Change: (date) => setLocalFilter(current => ({...current, period: 'custom', startDate: moment(date)}))
						},
						{
							name: 'endDate',
							text: Translate('end-date'),
							type: 'date',
							value: Dates.GetDateForForm(localFilter.endDate),
							Change: (date) => setLocalFilter(current => ({...current, period: 'custom', endDate: moment(date)}))
						},
						{
							name: 'categories',
							text: Translate('categories'),
							type: 'select',
							options: [{text: Translate('all-categories'), value: 'all-categories'}, ...categories.selectOptions],
							value: localFilter.categories,
							multiple: true,
							Change: (response) => setLocalFilter(current => ({...current, categories: response.map(item => item.value)}))
						},
						{
							name: 'products',
							text: Translate('products-amount'),
							type: 'select',
							options: [{text: 5, value: 5}, {text: 10, value: 10}, {text: 20, value: 20}],
							value: localFilter.products,
							Change: (response) => setLocalFilter(current => ({...current, products: response.value}))
						}
					]}
				/>
			</Body>
			<Footer class='float-right'>
				<Button class='bg-blue white' text={Translate('apply')} Click={() => setFilter(localFilter)}/>
				<Button
					text = {Translate('clear-all')}
					Click = {() =>
					{
						setLocalFilter({...defaultFilter});
						setFilter({...defaultFilter});
					}}/>
			</Footer>
		</Card>
	)
}

export { Filter };