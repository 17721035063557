import { useEffect, useState } from 'react';
import { Card, Header, Body, Footer, Button, Text, Form } from 'crack-ux';
import moment from 'moment';

// Context
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useContacts } from '../../../../contexts/collections/crm/Contacts';
import { useCompanies } from '../../../../contexts/collections/crm/Companies';
import { useLists } from '../../../../contexts/collections/home/Lists';
import { useCampaigns } from '../../../../contexts/collections/crm/Campaigns';

// Components
import { CompanyForm } from '../Companies/CompanyForm';
import { PhonesForm } from '../../../../components/PhonesForm';
import { EmailsForm } from '../../../../components/EmailsForm';

const ContactForm = ({ contact = {}, from, company, stageId }) =>
{
    const [contactForm, setContactForm] = useState(contact);
	const [phones, setPhones] = useState([]);
	const [emails, setEmails] = useState([]);
	const [messages, setMessages] = useState({});
	const [search, setSearch] = useState('');

    const { AddModal, CloseModal } = useModal();
    const { Translate } = useTraductor();
	const { listsByName } = useLists();
	const { contacts, CreateContact, UpdateContact } = useContacts();
	const { companies } = useCompanies();
	const { currentCampaign } = useCampaigns();

	const isForLink = contactForm._type === 'link';

	const HandleSubmit = async () =>
	{
		const newMessages = {};
	
		if (!contactForm.name) newMessages.name = Translate('field-required');
		if (phones.length === 0) newMessages.phones = Translate('required-at-least-one-phone');
	
		setMessages(newMessages);
	
		if (Object.keys(newMessages).length > 0) return;
	
		const contactData =
		{
			...contactForm,
			phones,
			emails
		}
	
		if (from === 'companies')
		{
			contactData.companies = contactForm.companies ? [...contactForm.companies] : [];
	
			if (!contactData.companies.includes(company._id))
			{
				contactData.companies.push(company._id);
			}
		}
	
		const result = contactForm._id ? await UpdateContact(contactData) : await CreateContact(contactData);
	
		if (result.status === 200) CloseModal();
	}
		
	useEffect(() =>
	{
		const updates = {};
	
		if (!contact.salesStage && listsByName['sales-stage']?.items?.length > 0)
		{
			updates.salesStage = stageId || listsByName['sales-stage'].items[0]._id;
		}

		if( stageId )
		{
			updates.tags = currentCampaign.tags;
		}
	
		if (Object.keys(updates).length > 0)
		{
			setContactForm(prevForm => ({ ...prevForm, ...updates }));
		}
	
		if (contact.phones)
		{
			setPhones(contact.phones);
		}
	
		if (contact.emails)
		{
			setEmails(contact.emails);
		}
	}, [listsByName, stageId, currentCampaign.tags, contact.salesStage, contact.phones, contact.emails]);

	return (
		<Card>
			<Header>
				<Text class='title'>{contact._id ? Translate('edit-contact') : Translate('new-contact')}{company?.name && `: ${company.name}`}</Text>
			</Header>
			<Body class='vertical-scroll'>
				<Form
					inputs =
					{[
						...(from === 'companies' && !contact._id) ? 
						[
							{
								text: Translate('search-contact'),
								type: 'autocomplete',
								value: search,
								options: contacts.filter(contact => !contact.companies.includes(company._id)).map(contact => ({text: `${contact.name} ${contact.lastName || ''}`, data: contact})),
								Change: (option) =>
								{
									setSearch(option.text);
									setPhones(option.data.phones || []);
									setEmails(option.data.emails || []);
									setContactForm(
									{
										...option.data,
										_type: 'link',
									} || {});
								},
							}
						] : [],
						{
							name: 'name',
							text: Translate('name'),
							class: 'tablet6',
							value: contactForm.name,
							message: messages.name,
						},
						{
							name: 'lastName',
							text: Translate('last-name'),
							class: 'tablet6',
							value: contactForm.lastName,
							message: messages.lastName,
						},
						{
							name: 'gender',
							text: Translate('gender'),
							class: 'tablet6',
							value: contactForm.gender,
							type: 'select',
							options: listsByName.gender.selectOptions,
							message: messages.gender,
						},
						{
							name: 'dob',
							text: Translate('date-of-birth'),
							class: 'tablet6',
							value: contactForm.dob ? moment(contactForm.dob).format('YYYY-MM-DD') : '',
							type: 'date',
							message: messages.dob,
						},
						{
							text: Translate('phones'),
							type: 'content',
							message: messages.phones,
							content: <PhonesForm phones={phones} setPhones={setPhones} />
						},
						{
							text: Translate('emails'),
							type: 'content',
							content: <EmailsForm emails={emails} setEmails={setEmails} />
						},
						{
							name: 'salesStage',
							text: Translate('sales-stage'),
							type: 'select',
							options: listsByName['sales-stage'].selectOptions,
							value: contactForm.salesStage,
						},
						{
							name: 'tags',
							text: Translate('tags'),
							type: 'tags',
							value: contactForm.tags,
						},
						...(from !== 'companies') ?
						[
							{
								name: 'companies',
								text: Translate('companies'),
								class: 'mobile9',
								type: 'autocomplete',
								options: companies.map(company => ({text: company.name, value: company._id})),
								value: contactForm.companies,
								multiple: true,
							},
							{
								class: 'mobile3',
								type: 'content',
								content: <Button class='bg-blue white full-width' text={Translate('add')} Click={() => AddModal(<CompanyForm/>)}/>
							}
						] : []
					]}
					form = {contactForm}
					setForm = {setContactForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate(isForLink ? 'update-and-link' : 'save')}
					class = {`${isForLink ? 'bg-green' : 'bg-blue'} white`}
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {ContactForm};