import { Route, Routes } from 'react-router-dom';
import { Providers } from './Providers';

const ProvidersRouter = () =>
{
	return (
		<Routes>
			<Route path='/:providerName/:providerId' element={<Providers/>}/>
			<Route path='/' element={<Providers/>}/>
		</Routes>
	)
}

export { ProvidersRouter as Providers };