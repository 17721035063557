import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import moment from 'moment';

// Context
import { useSocket } from '../../general/Socket';
import { useTraductor } from '../../general/Traductor';
import { useBusiness } from '../../layouts/Business';
import { useProducts } from './Products';

// Reducers
import { MovementsReducer } from '../../../reducers/collections/commerce/Movements';

const GetSalesByDay = (sales, productsObj) =>
{
	const productsData = {};
	const datesData = {};

	sales.forEach(sale =>
	{
		const saleDate = moment(sale.date).format('DD/MM/YYYY');

		if(datesData[saleDate])
		{
			datesData[saleDate].total += sale.total;
		}
		else
		{
			datesData[saleDate] =
			{
				date: saleDate,
				total: sale.total
			}
		}
	
		sale.details.forEach(detail =>
		{
			if(productsData[detail.product])
			{
				productsData[detail.product].quantity += detail.quantity;
				productsData[detail.product].minPrice = (productsData[detail.product].minPrice < detail.price) ? productsData[detail.product].minPrice : detail.price;
				productsData[detail.product].maxPrice = (productsData[detail.product].maxPrice > detail.price) ? productsData[detail.product].maxPrice : detail.price;
				productsData[detail.product].total += detail.total;
			}
			else
			{
				productsData[detail.product] =
				{
					codeText: productsObj[detail.product]?.codeText,
					name: productsObj[detail.product]?.name,
					quantity: detail.quantity,
					minPrice: detail.price,
					maxPrice: detail.price,
					total: detail.total
				}
			}
		})
	});

	return {productsData, datesData};
}

const MovementsContext = createContext({});

const MovementsProvider = (props) =>
{
	const [state, dispatch] = useReducer(MovementsReducer, {movements: [], code: 0});
	const [currentMovement, setCurrentMovement] = useState({});
	const [movementForm, setMovementForm] = useState({});
	const [loading, setLoading] = useState(true);

	const Socket = useSocket();
	const { Translate } = useTraductor();
	const { BusinessFetch, business, SomethingWentWrong } = useBusiness();
	const { products, UpdateProduct } = useProducts();

	const ReadMovements = useCallback(async () =>
	{
		try
		{
			setLoading(true);

			const result = await BusinessFetch.Get('/commerce/movements');

			if(result.status === 200)
			{
				dispatch({type: 'LOAD_MOVEMENTS', movements: result.data});
			}
		}
		catch(error)
		{
			SomethingWentWrong('ReadMovements');
		}
		finally
		{
			setLoading(false);
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const CreateMovement = async (movement) =>
	{
		try
		{
			movement.code = state.code + 1;
	
			const result = await BusinessFetch.Post('/commerce/movement', movement);
			
			if(result.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('movement-created')
				})
			}

			return result;
		}
		catch(error)
		{
			SomethingWentWrong('CreateMovement');
		}
	}

	const UpdateMovement = async (movement) =>
	{
		delete movement.status;
	
		return await BusinessFetch.Post('/commerce/movement/update', movement);
	}

	const AnnulledMovement = async (movement) =>
	{
		if(!movement.annulled)
		{
			movement.annulled = true;
	
			delete movement.status;
	
			const result = await BusinessFetch.Post('/commerce/movement/update', movement);

			const productPromises = [];

			if(result.status === 200)
			{
				for(let i = 0; i < movement.details.length; i++)
				{
					const product = products.find(product => product._id === movement.details[i].product);

					if(product)
					{
						const productUpdated = UpdateProduct(
						{
							...product,
							quantity:
							{
								...product.quantity,
								current: movement.type === 1 ? product.quantity.current + movement.details[i].quantity : product.quantity.current - movement.details[i].quantity
							}
						});

						productPromises.push(productUpdated);
					}
				}
			}

			await Promise.all(productPromises);

			return result;
		}
	}

	const ReadMovementsByShift = useCallback(async (shiftId) =>
	{
		try
		{
			const data = 
			{
				where:
				{
					crShiftId: shiftId
				}
			}

			const result = await BusinessFetch.Get('/commerce/movements', data);

			return result;
		}
		catch(error)
		{
			SomethingWentWrong('ReadMovementsByShift');
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const AddPayment = async (payment) =>
	{
		try
		{
			const result = await BusinessFetch.Post('/commerce/movement/payment', payment);

			if(result.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('payment-added')
				})
			}

			return result;
		}
		catch(error)
		{
			SomethingWentWrong('AddPayment');
		}
	}

	useEffect(() =>
	{
		BusinessFetch.Get('/commerce/movements/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_MOVEMENT_CODE', code: response.data});
			}
		});
	}, [BusinessFetch]);

	useEffect(() =>
	{
		const events =
		[
			{
				name: 'COMMERCE_MOVEMENT_CREATE',
				Function: (movement) => dispatch({type: 'CREATE_MOVEMENT', movement})
			},
			{
				name: 'COMMERCE_MOVEMENT_UPDATE',
				Function: (movement) => dispatch({type: 'UPDATE_MOVEMENT', movement})
			},
		];

		Socket.ConnectEvents(business._id, events);
	}, [Socket, business._id]);

	const value =
	{
		loading,
		code: state.code,
		movements: state.movements,
		currentMovement,
		movementForm,
		setCurrentMovement,
		setMovementForm,
		ReadMovements,
		CreateMovement,
		UpdateMovement,
		AnnulledMovement,
		ReadMovementsByShift,
		AddPayment
	}

	return (
		<MovementsContext.Provider value={value}>
			{props.children}
		</MovementsContext.Provider>
	)
}

const useMovements = () => useContext(MovementsContext);

export {MovementsProvider, useMovements, GetSalesByDay};