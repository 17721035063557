import { useEffect, useState } from 'react';
import { Card, Header, Body, Footer, Form, Text, Button, Avatar } from 'crack-ux';
import { Text as TextF } from 'crack-functions';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useModal } from '../../../../contexts/general/Modal';
import { useBusiness } from '../../../../contexts/layouts/Business';
import { useBusiness as useMainBusiness} from '../../../../contexts/main/Business';

// Components
import { Location } from '../../../../components/Location';
import { PhonesForm } from '../../../../components/PhonesForm';
import { EmailsForm } from '../../../../components/EmailsForm';

const BusinessProfile = () =>
{
	const [businessForm, setBusinessForm] = useState({});
	const [messages, setMessages] = useState({});
	const [phones, setPhones] = useState([]);
	const [emails, setEmails] = useState([]);
	const [picture, setPicture] = useState(null);
	const [location, setLocation] = useState({});
	const [disabled, setDisabled] = useState(true);

	const { Translate } = useTraductor();
	const { AddModal } = useModal();
	const { business } = useBusiness();
	const { UpdateBusiness } = useMainBusiness();

	const SaveChanges = async () =>
	{
		const messagesData = {};

		if(!businessForm.name) messagesData.name = Translate('filed-required');
		if(!businessForm.uniqueName) messagesData.uniqueName = Translate('filed-required');

		setMessages(messagesData);

		if(Object.keys(messagesData).length === 0)
		{
			const data =
			{
				...businessForm,
				phones,
				emails,
				location,
				picture
			}
	
			const response = await UpdateBusiness(data);

			if(response.status === 200)
			{
				setDisabled(true);
			}
		}
	}

	useEffect(() =>
	{
		if(business)
		{
			setBusinessForm(business || {});
			setPhones(business.phones || []);
			setEmails(business.emails || []);
			setLocation(business.location || {});
			setPicture(business.picture || null);
		}
	}, [business]);

	return (
		<Card>
			<Header>
				<Text class='title'>{Translate('business-profile')}</Text>
			</Header>
			<Body style={{height: 'calc(100% - var(--header) - var(--footer))'}}>
					<Form
						inputs =
						{
							[
								{
									type: 'content',
									class: 'center',
									content: <Avatar
												class = 'bg-blue white round'
												size = '218px'
												fontSize = '128px'
												src = {business?.pictureURL || TextF.Initials(business?.name || '')}
												Change = {disabled ? null : (file) => 
												{
													console.log(file)
												}}
											/>
								},
								{
									name: 'name',
									text: Translate('business-name'),
									class: 'desktop6',
									value: businessForm.name,
									message: messages.name,
									disabled
								},
								{
									name: 'uniqueName',
									text: Translate("business-unique-name"),
									class: 'desktop6',
									value: businessForm.uniqueName,
									Change: () => setMessages({...messages, uniqueName: null}),
									message: messages.uniqueName,
									disabled
								},
								{
									text: Translate('phones'),
									type: 'content',
									message: messages.phones,
									content: <PhonesForm phones={phones} setPhones={setPhones} disabled={disabled}/>
								},
								{
									text: Translate('emails'),
									type: 'content',
									content: <EmailsForm emails={emails} setEmails={setEmails} disabled={disabled}/>
								},
								{
									name: 'locationText',
									text: Translate("Location"),
									value: location.address,
									icon:
									{
										class: `icon-location bg-blue white pointer ${disabled ? 'disabled' : ''}`,
										Click: () => AddModal(<Location location={location} setLocation={setLocation}/>)
									},
									disabled
								}
							]
						}
					/>
			</Body>
			<Footer class='right'>
				<Button
					text = {disabled ? Translate('edit') : Translate('save')}
					class = 'bg-blue white'
					Click = {() => disabled ? setDisabled(false) : SaveChanges()}
				/>
				{!disabled && 
				<Button
					text = {Translate('cancel')}
					Click = {() =>
					{
						setDisabled(true);
						setBusinessForm(business || {});
						setPhones(business.phones || []);
						setEmails(business.emails || []);
						setLocation(business.location || {});
						setPicture(business.picture || null);
					}}
				/>}
			</Footer>
		</Card>
	)
}

export { BusinessProfile };