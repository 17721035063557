import { createContext, useContext, useState } from "react";
import { Generic } from 'crack-functions';

// Contexts
import { useGeneral } from "../general/General";
import { useTraductor } from "../general/Traductor";
import { useSession } from '../general/Session';

const Users = createContext();

const UsersProvider = (props) =>
{
	const [currentUser, setCurrentUser] = useState({});
	const [userForm, setUserForm] = useState({});
	const [userMessages, setUserMessages] = useState({});

	const { Fetch } = useGeneral();
	const { language, Translate } = useTraductor();
	const { Session, Login } = useSession();

	const CreateUser = async (encryptedData = null) =>
	{
		let messages = {};

		if(!userForm.username) messages.username = Translate('field-required');
		if(!userForm.name) messages.name = Translate('field-required');
		if(!userForm.lastName) messages.lastName = Translate('field-required');
		if(!userForm.phoneCountry) messages.phoneCountry = Translate('field-required');
		if(!userForm.phoneNumber) messages.phoneNumber = Translate('field-required');
		if(!userForm.email) messages.email = Translate('field-required');
		if(!userForm.password) messages.password = Translate('field-required');
		if(!userForm.confirmPassword) messages.confirmPassword = Translate('field-required');
		if(userForm.username?.includes(' ')) messages.username = Translate('spaces-not-allowed');
		if(userForm.password !== userForm.confirmPassword) messages.confirmPassword = Translate('passwords-mismatch');
		if(!userForm.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) messages.email = Translate('invalid-email');

		setUserMessages(messages);

		if(Object.keys(messages).length === 0)
		{
			try
			{
				const user =
				{
					...userForm,
					phone:
					{
						country: userForm.phoneCountry,
						number: userForm.phoneNumber
					},
					preferences:
					{
						language
					},
					encryptedData
				}
			
				const response = await Fetch.Post('/users', user);

				if(response.status === 200)
				{
					window.CrackUX.Toasts.AddToast(
					{
						class: 'success',
						message: Translate('user-created'),
					});

					return Login({username: user.username, password: user.password});
				}
				else if(response.status === 202)
				{
					window.CrackUX.Toasts.AddToast(
					{
						class: 'info',
						message: Translate('username-email-already-exists'),
					});
				}
			}
			catch(error)
			{
				console.error(error);
			}
		}
	}
	
	const UpdateUser = async (user) =>
	{	
		return await Generic.Fetch('/user/update', user).then(response =>
		{
			if(user._id === Session?.session?.user?._id)
			{
				Session.UpdateSession();
			}

			return response;
		});
	}

	const SearchUser = async (emailOrUser) =>
	{
		return await Generic.Fetch('/users/read', {_where: {$or: [{email: emailOrUser}, {user: emailOrUser}]}, _limit: 1});
	}

	const SavePassword = async (passwords) =>
	{
		return Generic.Fetch('/user/change-password', passwords);
	}

	const value =
	{
		currentUser,
		userForm,
		userMessages,
		CreateUser,
		UpdateUser,
		SavePassword,
		SearchUser,
		setCurrentUser,
		setUserForm
	}

	return <Users.Provider value={value} {...props}/>;
}

const useUsers = () => useContext(Users);

export {UsersProvider, useUsers};