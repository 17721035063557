import { useEffect } from 'react';
import { Content, Panel, Text } from 'crack-ux';
import { useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useContacts } from '../../../../contexts/collections/crm/Contacts';
import { useMessages } from '../../../../contexts/collections/crm/Messages';

// Components
import { ChatList } from './ChatList';
import { Chat } from './Chat';
import { ContactInfo } from '../Contacts/ContactInfo';

// Styles
import './Chats.css';

const Chats = (props) =>
{
	const { context } = props;

	const { screen } = useGeneral();
	const { Translate } = useTraductor();
	const { contacts, currentContact, setCurrentContact } = useContacts();
	const { contactsMessages, ReadContactMessages, loading } = useMessages();

	const params = useParams();

	const { contactId } = params;

	useEffect(() =>
	{
		if(contactId && contacts.length > 0)
		{
			const contact = contacts.find(contact => contact._id === contactId);

			if(contact)
			{
				setCurrentContact(contact);
			}
		}
	}, [contactId, contacts, setCurrentContact]);
	
	useEffect(() =>
	{
		if(currentContact._id && !loading)
		{
			ReadContactMessages(currentContact._id);
		}
	}, [currentContact._id, ReadContactMessages, loading]);

	return (
		<Content class='crm-chats' style={{height: 'calc(100vh - var(--header))'}}>
			{(context === 'list' || !(screen.device === 'mobile') ) &&
			<Panel class='desktop3 laptop3 tablet4'>
				<ChatList/>
			</Panel>}
			{(context === 'chat' || !(screen.device === 'mobile')) &&
			<Panel class='desktop6 laptop6 tablet4 full-height'>
				{currentContact._id ?
				<Chat contact={currentContact} messages={contactsMessages[currentContact._id]}/> :
				<Text class='full-center'>{Translate('select-contact')}</Text>}
			</Panel>}
			{(context === 'info' || !(screen.device === 'mobile')) &&
			<Panel class='desktop3 laptop3 tablet4 full-height'>
				<ContactInfo contact={currentContact}/>
			</Panel>}
		</Content>
	);
}

export { Chats };