import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';

// Languages
import English from '../../static-data/languages/English.json';
import Spanish from '../../static-data/languages/Spanish.json';

const Traductor = createContext({});

const TraductorProvider = (props) =>
{
	const [language, setLanguage] = useState('es');

	const Translate = useCallback((text = '', lang) =>
	{
		lang = lang || language;

		switch (lang)
		{
			case 'es':
				return Spanish[text] || text;
			default:
				return English[text] || text;
		}
	}, [language]);

	const SetLanguage = async (newLanguage) =>
	{
		window.localStorage.setItem('language', newLanguage);
		setLanguage(newLanguage);

		try
		{
			if(newLanguage === 'es')
			{
				await import('moment/locale/es-mx');
			}

			if(newLanguage === 'en')
			{
				await import('moment/locale/en-in');
			}

			moment.locale(newLanguage);
		}
		catch (error)
		{
			console.error(`Error to load the language: ${newLanguage}: ${error}`);
		}
	}

	useEffect(() =>
	{
		const storedLanguage = window.localStorage.getItem('language');

		if (storedLanguage && storedLanguage !== "null")
		{
			SetLanguage(storedLanguage);
		}
		else
		{
			SetLanguage('es');
		}
	}, []);

	const value =
	{
		language,
		Translate,
		SetLanguage
	}

	return <Traductor.Provider value={value} {...props}/>
}

const useTraductor = () => useContext(Traductor);

export { TraductorProvider, useTraductor };