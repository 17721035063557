import { useEffect, useState } from 'react';
import { Content, Header, SidePanel, Icon, Text } from 'crack-ux';
import { Routes, Route, Navigate } from 'react-router-dom';

// Contexts
import { BusinessPageProvider, useBusinessPage } from '../../../contexts/pages/public/BusinessPage';

// Components
import { ShoppingCart } from './components/ShoppingCart';

// Pages
import { Home } from './Home';
import { Products } from './Products';
import { Services } from './Services';
import { Product } from './Product';
import { Service } from './Service';
import { NotFound } from './NotFound';

// Styles
import './BusinessPage.css';

const BusinessPage = (props) => 
{
	const { containerRef = null, data } = props;

	const [device, setDevice] = useState('desktop');

	const { business, showShoppingCart, setShowShoppingCart } = useBusinessPage();

	const { page = {} } = business;
	const { general = {} } = data || page;

	useEffect(() => 
	{
		let observer;
		let currentContainer = containerRef?.current;

		const HandleResize = () => 
		{
			const width = currentContainer 
				? currentContainer.offsetWidth 
				: window.innerWidth;

			if (width >= 1280) 
			{
				setDevice('desktop');
			} 
			else if (width >= 1024) 
			{
				setDevice('laptop');
			} 
			else if (width >= 768) 
			{
				setDevice('tablet');
			} 
			else 
			{
				setDevice('mobile');
			}
		};

		if (currentContainer) 
		{
			observer = new ResizeObserver(() => HandleResize());
			observer.observe(currentContainer);
		} 
		else 
		{
			window.addEventListener('resize', HandleResize);
			HandleResize();
		}

		// Cleanup
		return () => 
		{
			if (observer && currentContainer) 
			{
				observer.unobserve(currentContainer);
				observer.disconnect();
			} 
			else 
			{
				window.removeEventListener('resize', HandleResize);
			}
		};
	}, [containerRef]);

	return (
		<Content reference={containerRef} class={`business-page page-${device} ${containerRef ? 'internal' : 'public'} no-padding relative`} style={{ height: 'auto' }}>
			<Content class={`${device} no-padding`}>
				<Header class='fixed no-border' style={{width: `var(--${device})`}}>
					<Text>{general.businessName}</Text>
					<Icon
						class = 'icon-basket shopping-cart-icon'
						style =
						{{
							background: showShoppingCart ? general.primaryColor : 'var(--button-bg)',
							color: showShoppingCart ? 'white' : 'black'
						}}
						Click = {() => setShowShoppingCart(!showShoppingCart)}
					/>
				</Header>
			</Content>
			<Routes>
				<Route path='/product/:_id' element={<Product data={data}/>} />
				<Route path='/service/:_id' element={<Service data={data}/>} />
				<Route path='/products' element={<Products data={data}/>} />
				<Route path='/services' element={<Services data={data}/>} />
				<Route path='/home' element={<Home data={data}/>} />
				<Route path='/not-found' element={<NotFound />} />
				<Route path='/' element={<Home data={data}/>} />
				<Route path='*' element={<Navigate to='../not-found' />} />
			</Routes>
			<SidePanel class='side-panel-shopping-cart' show={showShoppingCart}>
				<ShoppingCart />
			</SidePanel>
		</Content>
	);
};

const BusinessWithProviders = (props) => 
{
	return (
		<BusinessPageProvider>
			<BusinessPage {...props}/>
		</BusinessPageProvider>
	);
};

export { BusinessWithProviders as BusinessPage };