import { Button } from 'crack-ux';

// Styles
import './BackButton.css';

const BackButton = (props) =>
{
	const { Click, style = {} } = props;

	return (
		<Button
			class={`back-arrow ${props.class || ''}`}
			style={style}
			text=''
			icon=
			{{
				class: `icon-angle-left`,
			}}
			Click={Click}
		/>
	);
}

export { BackButton };