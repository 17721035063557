import { Section, Content, Text, Panel, Box, Image, Card } from 'crack-ux';

const WhatIsSice = () =>
{
	return (
		<Section class='what-is-sice'>
			<Content class='desktop center'>
				<Text class='title'>No es solo un software.<br/>Es TU sistema.</Text>
				<Text class='subtitle'>
					Porque tu negocio no debería adaptarse al software.<br/>
					El software debería adaptarse a tu negocio.
				</Text>
				<Panel class='benefits-grid left'>
					<Panel class='tablet6 no-padding'>
						<Panel>
							<Card class='benefit'>
								<Box class='icon'>
									<Image src='/sice-page/happy-computer.png' alt='Control total'/>
								</Box>
								<Text class='benefit-title'>Con SICE, llevas el control total de tu negocio</Text>
								<Text class='benefit-description'>
									Monitorea ventas, compras, movimientos y cobros desde un solo lugar, sin complicaciones.
								</Text>
							</Card>
						</Panel>
						<Panel>
							<Card class='benefit'>
								<Box class='icon'>
									<Image src='/sice-page/happy-computer.png' alt='Facturación'/>
								</Box>
								<Text class='benefit-title'>Facturación electrónica, cuentas por cobrar y reportes inteligentes.</Text>
								<Text class='benefit-description'>
									Automatiza tareas contables, genera reportes útiles y olvídate del papeleo.
								</Text>
							</Card>
						</Panel>
					</Panel>
					<Panel class='tablet6 no-padding'>
						<Panel>
							<Card class='benefit'>
								<Box class='icon'>
									<Image src='/sice-page/happy-computer.png' alt='Reglas y descuentos'/>
								</Box>
								<Text class='benefit-title'>Adaptado a tus reglas, precios y descuentos</Text>
								<Text class='benefit-description'>
									Cada negocio es distinto; en SICE puedes definir condiciones, reglas especiales y promociones únicas.
								</Text>
							</Card>
						</Panel>
						<Panel>
							<Card class='benefit'>
								<Box class='icon'>
									<Image src='/sice-page/happy-computer.png' alt='Configuración'/>
								</Box>
								<Text class='benefit-title'>Configuración única para cada negocio</Text>
								<Text class='benefit-description'>
									SICE se adapta a tu forma de trabajar, no al revés. Nuestra arquitectura flexible permite que cada empresa lo haga suyo.
								</Text>
							</Card>
						</Panel>
					</Panel>
				</Panel>
			</Content>
		</Section>
	)
}

export { WhatIsSice };