import React from 'react';
import {Content, Panel, Header, Body, Card, Text, List} from 'crack-ux';

// Components
import {Loading} from '../../../../components/views/Loading';
import {BirthdayTabs} from './BirthdayTabs';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {ScheduledMessagesProviderPage, useScheduledMessagesPage} from '../../../../contexts/pages/business/crm/ScheduledMessages';

// Tables
import {ScheduledMessagesTable} from '../../../../components/tables/modules/crm/ScheduledMessages/ScheduledMessages';

// Static Data
import channels from '../../../../static-data/Channels.json';
import scheduledMessagesTypes from '../../../../static-data/ScheduledMessagesTypes.json';

const ScheduledMessages = (props) =>
{
	const [loading] = React.useState(false);

	const {Translate} = useTraductor();

	const {channel, SetChannel, scheduledMessageType, setScheduledMessageType} = useScheduledMessagesPage();

	const leftContent = React.useMemo(() =>
	{
		let result;

		switch(scheduledMessageType.value)
		{
			case 'birthday':
				result = <BirthdayTabs/>
			break;

			default:
				result = (
					<Card>
						<ScheduledMessagesTable/>
					</Card>
				);
			break;
		}

		return result;
	}, [scheduledMessageType.value]);

	if(loading)
	{
		return <Loading/>
	}
	else
	{
		return (
			<Content class='no-padding'>
				<Panel class='no-padding full-height' swipe='horizontal'>
					<Panel class='full-height desktop3 no-padding'>
						<Panel style={{height: '35%'}}>
							<Card>
								<Header>
									<Text class='bold'>{Translate('Channels')}</Text>
								</Header>
								<Body class='no-padding'>
									<List
										class = 'hover'
										currentClass = 'bg-blue white'
										current = {channel.value}
										options = {channels.filter(item => !item.hidden).map(item =>
										{
											return {
												identifier: item.value,
												text: Translate(item.text),
												Click: () => SetChannel(item)
											}
										})}
									/>
								</Body>
							</Card>
						</Panel>
						<Panel style={{height: '65%'}}>
							<Card>
								<Header>
									<Text class='bold'>{Translate('Messages')}</Text>
								</Header>
								<Body class='no-padding'>
									<List
										class = 'hover'
										currentClass = 'bg-blue white'
										current = {scheduledMessageType.value}
										options = {scheduledMessagesTypes.map(item =>
										{
											return {
												identifier: item.value,
												text: Translate(item.text),
												Click: () => setScheduledMessageType(item)
											}
										})}
									/>
								</Body>
							</Card>
						</Panel>
					</Panel>
					<Panel class='full-height desktop9'>
						{leftContent}
					</Panel>
				</Panel>
			</Content>
		);
	}
}

const ScheduledMessagesithProviders = (props) =>
{
	return (
		<ScheduledMessagesProviderPage>
			<ScheduledMessages {...props}/>
		</ScheduledMessagesProviderPage>
	);
}

export {ScheduledMessagesithProviders as ScheduledMessages}