import { useState } from 'react';
import { Card, Header, Body, Footer, Form, Button, Text } from 'crack-ux';

// Context
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useClients } from '../../../../contexts/collections/commerce/Clients';

// Components
import { PhonesForm } from '../../../../components/PhonesForm/PhonesForm';
import { EmailsForm } from '../../../../components/EmailsForm/EmailsForm';

const ClientForm = (props) =>
{
	const { client = {} } = props;

	const [clientForm, setClientForm] = useState(client);
	const [phones, setPhones] = useState(client.phones || []);
	const [emails, setEmails] = useState(client.emails || []);
	const [messages, setMessages] = useState({});

	const { CloseModal } = useModal();
	const { Translate } = useTraductor();
	const { CreateClient, UpdateClient } = useClients();

	const HandleSubmit = async () =>
	{
		const messagesData = {};

		if(!clientForm.name) messagesData.name = Translate('field-required');

		setMessages(messagesData);

		if(Object.keys(messagesData).length === 0)
		{
			const data =
			{
				...client,
				...clientForm,
				phones,
				emails
			};
	
			const result = client._id ? await UpdateClient(data) : await CreateClient(data);
	
			if(result.status === 200)
			{
				CloseModal();
			}
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{client._id ? Translate('edit-client') : Translate('new-client')}</Text>
			</Header>
			<Body>
				<Form
					inputs =
					{
						[
							{
								name: 'name',
								text: Translate('client-name'),
								value: clientForm.name,
								message: messages.name
							},
							{
								name: 'tin',
								class: 'mobile6',
								text: Translate('tin'),
								value: clientForm.tin,
								message: messages.tin
							},
							{
								name: 'discount',
								text: `${Translate('discount')} (0-100)%`,
								class: 'mobile6',
								type: 'number',
								value: clientForm.discount,
								message: messages.discount,
							},
							{
								text: Translate('phones'),
								type: 'content',
								message: messages.phones,
								content: <PhonesForm phones={phones} setPhones={setPhones} />
							},
							{
								text: Translate('emails'),
								type: 'content',
								content: <EmailsForm emails={emails} setEmails={setEmails} />
							},
						]
					}
					form = {clientForm}
					setForm = {setClientForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('save')}
					class = 'bg-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export { ClientForm };