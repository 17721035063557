import { Page } from 'crack-ux';

// Sections
import { Init } from './Init';
import { AboutUs } from './AboutUs';
import { Features } from './Features';
import { Products } from './Products';
import { Services } from './Services';
import { Reviews } from './Reviews';
import { FAQs } from './FAQs';
import { ContactUs } from './ContactUs';

// Styles
import './Home.css';

const Home = (props) => 
{
	const { data } = props;

	return (
		<Page class='home no-scroll'>
			<Init data={data} />
			<AboutUs data={data} />
			<Features data={data} />
			<Products data={data} />
			<Services data={data} />
			<Reviews data={data} />
			<FAQs data={data} />
			<ContactUs data={data} />
		</Page>
	);
};

export { Home };