import { Route, Routes } from "react-router-dom";

// Pages
import { Lists } from "./Lists";

const ListsRouter = () =>
{
	return (
		<Routes>
			<Route path="/:internalName" element={<Lists/>} />
			<Route path="/" element={<Lists/>} />
		</Routes>
	);
}

export { ListsRouter as Lists };