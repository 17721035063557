import React from 'react';
import {Section, Text} from 'crack-ux';
import {Utilities, Amount} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useLists} from '../../../../contexts/collections/home/Lists';

// Reducers
import { defaultListColor } from '../../../../reducers/collections/Lists';
import { useNavigate, useParams } from 'react-router-dom';

const ExclusionPoint = ({ contacts }) =>
{
	const {Translate} = useTraductor();
	const {listsByName} = useLists();

	const navigate = useNavigate();
	const params = useParams();

	const {campaignName, campaignId, stageId} = params;

	const sideAwayList = React.useMemo(() =>
	{
		const stages = [...listsByName['sales-stage'].workingItems.filter(item => item.data?.exclusionPoint)].map(item =>
		{
			const stageContacts = contacts.filter(contact => contact.salesStage === item._id);

			return {...item, stageContacts};
		});

		const baseContactsSize = contacts.length;

		return stages.map((item) =>
		{
			const stageContactsSize = item.stageContacts.length;
			const baseContactsPercentage = `${Amount.Round(baseContactsSize > 0 ? stageContactsSize * 100 / baseContactsSize : 0)}%`;

			const backgroundColor = item.color || defaultListColor;
			const color = Utilities.HexIsLight(backgroundColor) ? 'var(--black)' : 'var(--white)';

			return (
				<Section 
					key = {item._id}
					class = 'stage center pointer'
					style =	{{backgroundColor}}
					Click={() => navigate(`${stageId ? `../${campaignName}/${campaignId}/` : './' }${item.text}/${item._id}`)}
				>
					<Text class='block' size={1.5} style={{color}}>{item.text}</Text>
					<Text class='block' type='p' style={{color}}>{Translate('contacts')}: {stageContactsSize} ({baseContactsPercentage})</Text>
				</Section>
			);
		})
	}, [listsByName, contacts, Translate, navigate, campaignName, campaignId, stageId]);

	return (
		<Section class='exclusion-point'>
			{sideAwayList}
		</Section>
	);
}

ExclusionPoint.defaultProps =
{
	contacts: [],
};

export {ExclusionPoint};