import { Card, Form, Button, Section, Text, Icon } from 'crack-ux';

// Contexts
import { useTraductor } from '../../contexts/general/Traductor';
import { useState } from 'react';

// Static Data
import phoneCodes from '../../static-data/PhoneCodes.json';

const defaultPhone =
{
	country: 591
}

const PhonesForm = (props) =>
{
	const { phones, setPhones, disabled } = props;

	const [phone, setPhone] = useState(defaultPhone);
	const [messages, setMessages] = useState({});

	const { Translate } = useTraductor();

	const AddPhone = () =>
	{
		const messagesData = {};

		if(!phone.number) messagesData.number = Translate('field-required');

		setMessages(messagesData);

		if(Object.keys(messagesData).length === 0)
		{
			const clonePhones = [...phones];
	
			clonePhones.push(phone);
	
			setPhones(clonePhones);
			setPhone(prevPhone => ({...prevPhone, number: ''}));
		}
	}

	const RemovePhone = (index) =>
	{
		const clonePhones = [...phones];

		clonePhones.splice(index, 1);

		setPhones(clonePhones);
	}

	return (
		<Card>
			<Form
				inputs =
				{[
					{
						name: 'code',
						text: Translate('code'),
						class: 'mobile4',
						type: 'select',
						options: phoneCodes,
						value: phone.country,
						disabled,
					},
					{
						name: 'number',
						text: Translate('number'),
						class: 'mobile6',
						type: 'number',
						value: phone.number,
						message: messages.number,
						disabled,
					},
					{
						class: 'mobile2',
						type: 'content',
						content: <Button
									class = 'bg-blue white full-width'
									disabled = {disabled}
									Click = {AddPhone}
								>
									<Icon class='icon-plus no-margin'/>
								</Button>
					},
					...phones.length > 0 ?
					[
						{
							text: Translate('phones'),
							type: 'content',
							content:
							<Card class='no-shadow bg-light-gray'>
								{phones.map((phone, index) =>
								{
									return (
									<Section key={index} style={{padding: 5}}>
										<Text>(+{phone.country}) {phone.number}</Text>
										<Icon
											class='icon-minus red pointer float-right'
											Click={() => RemovePhone(index)}
										/>
									</Section>)
								})}
							</Card>
						}
					] : []
				]}
				form = {phone}
				setForm = {setPhone}
			/>
		</Card>
	)	
}

export { PhonesForm }