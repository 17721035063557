import React from 'react';
import { Table } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useModal } from '../../../../contexts/general/Modal';
import { useInvoices } from '../../../../contexts/collections/vouchers/Invoices';

// Components
import { InvoiceForm } from './InvoiceForm';

const InvoicesTable = (props) =>
{
	const { Translate } = useTraductor();
	const { AddModal } = useModal();
	const { invoices } = useInvoices();

	return (
		<Table
			header =
			{[
				{
					name: 'number',
					text: Translate('Code')
				},
				{
					name: 'clientName',
					text: Translate('Name')
				},
				{
					name: 'clientNit',
					text: Translate('Nit')
				},
				{
					name: 'dateTable',
					text: Translate('Date')
				},
				{
					name: 'total',
					text: Translate('Total')
				},
				{
					name: 'state',
					text: Translate('State')
				}
			]}
			body = {invoices}
			actions =
			{[
				{
					text: Translate('New Invoice'),
					class: 'bg-blue white',
					section: 'grouped',
					Click: () => AddModal(<InvoiceForm/>)
				},
				{
					text: Translate('Edit Invoice'),
					icon:
					{
						class: 'icon-pencil'
					},
					section: 'context',
					//Click: (invoice) => ShowInvoiceForm(invoice)
				},
				{
					text: Translate('Delete Invoice'),
					class: 'red',
					icon:
					{
						class: 'icon-trash'
					},
					section: 'context',
					//Click: (invoice) => ShowDeleteInvoiceWarning(invoice)
				}
			]}
		/>
	)
}

export { InvoicesTable };