import { useNavigate } from 'react-router-dom';
import { Content, Panel, Card, Header, Body, Text } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';

const reports =
[
	{
		identifier: 'sales',
		text: 'sales',
		picture: 'sales.png',
		route: 'sales'
	},
	{
		identifier: 'top-products',
		text: 'top-products',
		picture: 'sales.png',
		route: 'top-products'
	}
];

const ReportCard = (report) =>
{
	const { Translate } = useTraductor();
	
	const navigate = useNavigate();

	return (
		<Panel class='mobile6 tablet3 desktop2' style={{height: 'auto'}}>
			<Card class='pointer' style={{height: 'auto'}} Click={() => navigate(`./${report.route}`)}>
				<Header class='center no-padding no-border'>
					<img src={`/modules/commerce/${report.picture}`} width={80} height={100} alt={report.picture}/>
				</Header>
				<Body class='center'>
					<Text class='bold'>{Translate(report.text)}</Text>
				</Body>
			</Card>
		</Panel>
	)
}

const ReportsList = () =>
{
	return (
		<Content>
			{reports.map((report, index) => <ReportCard key={index} {...report}/>)}
		</Content>
	)
}

export { ReportsList }