import { useMemo } from 'react';
import { Content, Card, Panel, Text } from 'crack-ux';
import { useParams } from 'react-router-dom';

// Components
import { useGeneral } from '../../../../contexts/general/General';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useMovements } from '../../../../contexts/collections/commerce/Movements';

// Components
import { ClientsTable } from './ClientsTable';
import { MovementsTable } from '../Movements/MovementsTable';

const Clients = () =>
{
	const { screen } = useGeneral();
	const { Translate } = useTraductor();
	const { movements } = useMovements();

	const { clientId } = useParams();

	const clientMovements = useMemo(() => movements.filter(movement => movement.contact === clientId), [movements, clientId]);

	return (
		<Content>
			{(screen.device !== 'mobile' || !clientId) && 
			<Panel class='desktop6'>
				<Card>
					<ClientsTable/>
				</Card>
			</Panel>}
			{(screen.device !== 'mobile' || clientId) &&
			<Panel class='desktop6'>
				<Card class='relative'>
					{clientId ? <MovementsTable movements={clientMovements} from='clients'/> : <Text class='full-center'>{Translate('select-client')}</Text>}
				</Card>
			</Panel>}
		</Content>
	)
}

export {Clients};