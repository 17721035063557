import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Dates } from 'crack-functions';

// Context
import { useSocket } from '../../general/Socket';
import { useTraductor } from '../../general/Traductor';
import { useBusiness } from '../../layouts/Business';
import { useLists } from '../home/Lists';

// Reducers
import { ContactsReducer } from '../../../reducers/collections/crm/Contacts';

const GetWSPhoneText = (phone) =>
{
	const parts = ['country', 'area', 'number'];
	const phoneParts = [];

	parts.forEach(part =>
	{
		if(phone[part] !== undefined && phone[part] !== null)
		{
			phoneParts.push(phone[part]);
		}
	});

	return phoneParts.join('');
}

const Contacts = createContext({});

const ContactsProvider = (props) =>
{
	const [state, dispatch] = useReducer(ContactsReducer, {contacts: [], code: 0});
	const [currentContact, setCurrentContact] = useState({});
	const [loading, setLoading] = useState(true);

	const Socket = useSocket();
	const { Translate } = useTraductor();
	const { BusinessFetch, business, SomethingWentWrong } = useBusiness();

	const { GetListFieldsValuesForForm } = useLists();

	const contactsTags = useMemo(() =>
	{
		const allTags = state.contacts.reduce((acc, curr) =>
		{
			return Array.isArray(curr.tags) ? acc.concat(curr.tags) : acc;
		}, []);

		return [...new Set(allTags)];
	}, [state.contacts]);

	const contactsTagsOptions = useMemo(() =>
	(
		contactsTags.map(tag =>
		({
			text: tag,
			value: tag
		}))
	), [contactsTags]);

	const AddContacts = useCallback((contacts = [], from = 0) =>
	{
		dispatch({type: 'ADD_CONTACTS', contacts, from});
	}, []);

	const ParseForm = async (form) =>
	{
		const listFieldsValues = await GetListFieldsValuesForForm(form, ['gender']);
		const contactData = {...form, ...listFieldsValues};

		contactData.dob = Dates.GetDateFromForm(contactData.dob);

		if(Array.isArray(contactData.phones))
		{
			contactData.phones = contactData.phones.filter(phone => phone.country && phone.number).map(phone => ({...phone, phone: GetWSPhoneText(phone)}));
		}

		delete contactData.salesStageText;

		return contactData;
	}

	const GetMaxContactsCode = useCallback(async () =>
	{
		try
		{
			const response = await BusinessFetch.Get('/crm/contacts/code');

			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_CONTACT_CODE', code: response.data});
			}
		}
		catch (error)
		{
			SomethingWentWrong('GetMaxContactsCode');
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const ReadContacts = useCallback(async () =>
	{
		try
		{
			setLoading(true);

			const response = await BusinessFetch.Get('/crm/contacts');

			if(response.status === 200)
			{
				dispatch({type: 'LOAD_CONTACTS', contacts: response.data});
			}
		}
		catch (error)
		{
			SomethingWentWrong('ReadContacts');
		}
		finally
		{
			setLoading(false);
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const CreateContact = async (contact) =>
	{
		try
		{
			const contactData = await ParseForm(contact);
			contactData.code = state.code + 1;

			const response = await BusinessFetch.Post('/crm/contact', contactData);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					calss: 'success',
					message: Translate('contact-created'),
				})

				return response;
			}
		}
		catch (error)
		{
			SomethingWentWrong('CreateContact');
		}
	}

	const UpdateContact = async (contact) =>
	{
		try
		{
			const contactData = await ParseForm(contact);

			const response = await BusinessFetch.Put('/crm/contact', contactData);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					calss: 'success',
					message: Translate('contact-updated'),
				})

				return response;
			}
		}
		catch (error)
		{
			SomethingWentWrong('UpdateContact');
		}
	}

	const SimpleUpdate = async (contact) =>
	{
		return await BusinessFetch.Post('/crm/contact/update', contact);
	}

	const DeleteContact = async (contact) =>
	{
		try
		{
			const contactData =
			{
				_id: contact._id,
			}

			const response = await BusinessFetch.Delete('/crm/contact', contactData);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					calss: 'success',
					message: Translate('contact-deleted'),
				})

				return response;
			}
		}
		catch (error)
		{
			SomethingWentWrong('DeleteContact');
		}
	}

	useEffect(() =>
	{
		GetMaxContactsCode()
	}, [GetMaxContactsCode]);

	useEffect(() =>
	{
		const events =
		[
			{
				name: 'CRM_CONTACT_CREATE',
				Function: (contact) => dispatch({type: 'CREATE_CONTACT', contact})
			},
			{
				name: 'CRM_CONTACT_UPDATE',
				Function: (contact) => dispatch({type: 'UPDATE_CONTACT', contact})
			},
			{
				name: 'CRM_CONTACT_DELETE',
				Function: (contact) => dispatch({type: 'DELETE_CONTACT', contact})
			}
		];

		Socket.ConnectEvents(business._id, events);
	}, [Socket, business, state.code]);

	const value =
	{
		loading,
		contacts: state.contacts,
		currentContact,
		contactsTags,
		contactsTagsOptions,
		setCurrentContact,
		AddContacts,
		ReadContacts,
		CreateContact,
		UpdateContact,
		DeleteContact,
		SimpleUpdate,
	}

	return (
		<Contacts.Provider value={value}>
			{props.children}
		</Contacts.Provider>
	)
}

const useContacts = () => useContext(Contacts);

ContactsProvider.defaultProps =
{
	syssec: 'crm'
}

export {ContactsProvider, useContacts};