import React from "react";
import {Section, Box, Button, Icon, Text, Card} from 'crack-ux';
import {Generic} from 'crack-functions';

// Contexts
import {useTraductor} from "../../../../../contexts/general/Traductor";
import {useCRM} from "../../../../../contexts/modules/CRM";
import {useContacts} from "../../../../../contexts/collections/crm/Contacts";
import {useMessages} from "../../../../../contexts/collections/crm/Messages";

// Components
import {ContactInfo} from "../../../../../pages/business/crm/Contacts/ContactInfo";

// Css
import './ContactWindows.css';

const ContactWindows = () =>
{
	const {Translate} = useTraductor();

	const {contactIds, RemoveContactTab, currentContactId, setCurrentContactId} = useCRM();

	const {contacts} = useContacts();
	const {GetChatStatus} = useMessages();

	const scrollableBar = React.createRef(null);

	const ApplyScrollableBar = React.useCallback(() =>
	{
		if(scrollableBar?.current)
		{
			const slider = scrollableBar.current;

			let isDown = false;
			let startX;
			let scrollLeft;

			slider.addEventListener('mousedown', (e) =>
			{
				isDown = true;
				slider.classList.add('active');
				startX = e.pageX - slider.offsetLeft;
				scrollLeft = slider.scrollLeft;
			});

			slider.addEventListener('mouseleave', () =>
			{
				isDown = false;
				slider.classList.remove('active');
			});

			slider.addEventListener('mouseup', () =>
			{
				isDown = false;
				slider.classList.remove('active');
			});

			slider.addEventListener('mousemove', (e) =>
			{
				if(!isDown) return;

				e.preventDefault();

				const x = e.pageX - slider.offsetLeft;
				const walk = (x - startX) * 1; //scroll-fast

				slider.scrollLeft = scrollLeft - walk;
			});
		}
	}, [scrollableBar]);

	React.useEffect(() =>
	{
		ApplyScrollableBar();
	}, [ApplyScrollableBar]);

	const contactsById = React.useMemo(() => Generic.ArrayToObjectByIdentifier(contacts), [contacts]);

	const bar = React.useMemo(() =>
	{
		if(contactIds.length > 0)
		{
			const contactItems = contactIds.map(contactId =>
			{
				const contactData = contactsById[contactId] || {};
				const {statusColor, title} = GetChatStatus(contactsById[contactId]);

				return (
					<Button
						key = {contactId}
						class = {`bar-option ${currentContactId === contactId ? 'current' : ''}`}
						Click = {() => setCurrentContactId(currentContactId !== contactId ? contactId : null)}
					>
						<Text>{contactData.fullName}</Text>
						<Box class={`crm-chat-status mini bg-${statusColor}`} title={Translate(title)}/>
						<Icon
							class = 'icon-cancel close-tabs'
							Click={event =>
							{
								event.stopPropagation();
								RemoveContactTab(contactId);
							}}
						/>
					</Button>
				);
			});

			return (
				<Section class='contacts-bar' reference={scrollableBar}>
					{contactItems}
				</Section>
			);
		}
	}, [contactIds, contactsById, currentContactId, setCurrentContactId, RemoveContactTab, GetChatStatus, Translate, scrollableBar]);

	const contents = React.useMemo(() =>
	{
		return contactIds.map(contactId =>
		{
			const contactData = contactsById[contactId] || {};

			return (
				<Section key={`content-${contactId}`} class={`contact-content ${contactId === currentContactId ? 'current' : 'none'}`}>
					<Section class='right' style={{paddingTop: 3, paddingRight: 3}}>
						<Icon class='window-icon icon-minus' Click={() => setCurrentContactId(null)}/>
						<Icon class='window-icon icon-cancel' Click={() => RemoveContactTab(contactId)}/>
					</Section>
					<Card style={{height: 'calc(100% - 20px)'}}>
						<ContactInfo contact={contactData}/>
					</Card>
				</Section>
			);
		})
	}, [contactIds, contactsById, currentContactId, RemoveContactTab, setCurrentContactId]);

	const containerHeight = React.useMemo(() => contactIds.length > 0 ? currentContactId ? '100%' : '36px' : undefined, [contactIds, currentContactId]);

	return (
		<Section class={`contact-windows ${currentContactId ? 'active' : ''}`} style={{height: containerHeight}}>
			{contents}
			{bar}
		</Section>
	);
}

export {ContactWindows};