import { useNavigate } from 'react-router-dom';
import { Header, Text, Box, Button } from 'crack-ux';

// Contexts
import { useSession } from '../../../contexts/general/Session';
import { useTraductor } from '../../../contexts/general/Traductor';

const Menu = () =>
{
	const { Translate } = useTraductor();	
	const { user } = useSession();

	const navigate = useNavigate();

	return (
		<Header class='menu'>
			<Box class='float-left'>
				<Text class='logo masque white'>Sice</Text>
			</Box>
			<Box class='float-right'>
				<Button
					class = 'login-button'
					text = {user._id ? Translate('my-profile') : Translate('login')}
					Click = {() => user._id ? navigate('/p') : navigate('/login')}
				/>
			</Box>
		</Header>
	)
}

export { Menu };