const SetModule = (module) =>
{
	return module;
}

const ModulesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_MODULES':
			return {
				...state,
				modules: action.modules.map(module => SetModule(module)),
				modulesByName: action.modules.reduce((acc, module) => ({...acc, [module.route]: SetModule(module)}), {})
			}

		case 'CREATE_MODULE':
			return {
				...state,
				modules: [...state.modules, SetModule(action.module)],
				modulesByName: {...state.modulesByName, [action.module.route]: SetModule(action.module)}
			}

		case 'UPDATE_MODULE':
			return {
				...state,
				modules: state.modules.map(module => module._id === action.module._id ? SetModule(action.module) : module),
				modulesByName: {...state.modulesByName, [action.module.route]: SetModule(action.module)}
			}

		case 'DELETE_MODULE':
		{
			return {
				...state,
				modules: state.modules.filter(module => module._id !== action.module._id),
				modulesByName: Object.keys(state.modulesByName).reduce((acc, route) => route !== action.module.route ? {...acc, [route]: state.modulesByName[route]} : acc, {})
			}
		}

		default:
			return state;
	}
}

export {ModulesReducer};