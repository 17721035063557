import { Route, Routes } from 'react-router-dom';
import { Contacts } from './Contacts';

const ContactsRouter = () =>
{
	return (
		<Routes>
			<Route path='/:contactName/:contactId' element={<Contacts/>}/>
			<Route path='/' element={<Contacts/>}/>
		</Routes>
	)
}

export { ContactsRouter as Contacts };