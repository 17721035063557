import { useMemo } from 'react';
import { Header, Body, Text, List, Button } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useModal } from '../../../../contexts/general/Modal';
import { useLists } from '../../../../contexts/collections/home/Lists';
import { ItemForm } from '../../home/Lists/ItemForm';

// Components

const categoriesInterlName = 'product-categories';

const Categories = () =>
{
	const { Translate } = useTraductor();
	const { AddModal } = useModal();
	const { listsByName } = useLists();

	const navigate = useNavigate();
	const { categoryId } = useParams();

	const categoriesList = useMemo(() => listsByName[categoriesInterlName] || [], [listsByName]);
	const categories = useMemo(() =>
	{
		return [
			{
				_id: 'all-categories',
				text: Translate('all-categories'),
				value: 'all-categories',
			},
			...categoriesList.workingItems || []
		]
	}, [categoriesList, Translate]);

	return (
		<>
			<Header>
				<Text class='title'>{Translate('categories')}</Text>
				<Button
					class='bg-blue white float-right'
					style={{marginTop: -8}}
					text={Translate('new-category')}
					Click={() => AddModal(<ItemForm internalName={categoriesInterlName}/>)}
				/>
			</Header>
			<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
				<List
					class = 'hover'
					currentClass = 'bg-blue white'
					current = {categoryId}
					options = {categories.map(category =>
					{
						return {
							identifier: category._id,
							text: category.text,
							Click: () => navigate(`../${category.text}/${category._id}`),
						}
					})}
					autoSelect = {false}
				/>
			</Body>
		</>
	)
}

export { Categories };