import {useParams} from 'react-router-dom';
import {Section} from 'crack-ux';

// Contexts
import {useBusinessPage} from '../../../../contexts/pages/public/BusinessPage';

// Components
import {ServiceDetail} from '../components/ServiceDetail';

const Service = () =>
{
	const params = useParams();
	const {services} = useBusinessPage();

	const {_id} = params;

	const service = services.find(service => service._id === _id);

	return (
		<Section>
			<ServiceDetail {...service}/>
		</Section>
	)
}

export {Service}