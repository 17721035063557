import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

// Context
import { useSocket } from '../../general/Socket';
import { useTraductor } from '../../general/Traductor';
import { useBusiness } from '../../layouts/Business';

// Reducers
import { CampaignsReducer } from '../../../reducers/collections/crm/Campaigns';

const CampaignsContext = createContext();

const CampaignsProvider = (props) =>
{
	const [state, dispatch] = useReducer(CampaignsReducer, {campaigns: [], code: 0});
	const [currentCampaign, setCurrentCampaign] = useState({});
	const [loading, setLoading] = useState(true);

	const Socket = useSocket();
	const { Translate } = useTraductor();
	const { BusinessFetch, business, SomethingWentWrong } = useBusiness();

	const GetMaxCampaignsCode = useCallback(async () =>
	{
		try
		{
			const response = await BusinessFetch.Get('/crm/campaigns/code');

			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_CAMPAIGN_CODE', code: response.data});
			}
		}
		catch (error)
		{
			SomethingWentWrong('GetMaxCampaignsCode');
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const ReadCampaigns = useCallback(async () =>
	{
		try
		{
			setLoading(true);

			const response = await BusinessFetch.Get('/crm/campaigns');

			if(response.status === 200)
			{
				dispatch({type: 'LOAD_CAMPAIGNS', campaigns: response.data});
			}
		}
		catch (error)
		{
			SomethingWentWrong('ReadCampaigns');
		}
		finally
		{
			setLoading(false);
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const CreateCampaign = async (campaign) =>
	{
		try
		{
			campaign.code = state.code + 1;

			const response = await BusinessFetch.Post('/crm/campaign', campaign);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('campaign-created'),
				});
			}

			return response;
		}
		catch (error)
		{
			SomethingWentWrong('CreateCampaign');
		}
	}

	const UpdateCampaign = async (campaign) =>
	{
		try
		{
			const response = await BusinessFetch.Put('/crm/campaign', campaign);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('campaign-updated'),
				});
			}

			return response;
		}
		catch (error)
		{
			SomethingWentWrong('UpdateCampaign');
		}
	}

	const DeleteCampaign = async (campaign) =>
	{
		try
		{
			const response = await BusinessFetch.Delete('/crm/campaign', campaign);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('campaign-deleted'),
				});
			}

			return response;
		}
		catch (error)
		{
			SomethingWentWrong('DeleteCampaign');
		}
	}

	useEffect(() =>
	{
		GetMaxCampaignsCode();
	}, [GetMaxCampaignsCode]);

	useEffect(() =>
	{
		const events =
		[
			{
				name: `CRM_CAMPAIGN_CREATE`,
				Function: (campaign) => dispatch({type: 'CREATE_CAMPAIGN', campaign})
			},
			{
				name: `CRM_CAMPAIGN_UPDATE`,
				Function: (campaign) => dispatch({type: 'UPDATE_CAMPAIGN', campaign})
			},
			{
				name: `CRM_CAMPAIGN_DELETE`,
				Function: (campaign) => dispatch({type: 'DELETE_CAMPAIGN', campaign})
			}
		];

		Socket.ConnectEvents(business._id, events);
	}, [Socket, business._id]);

	const value =
	{
		loading,
		code: state.code,
		campaigns: state.campaigns,
		currentCampaign,
		setCurrentCampaign,
		ReadCampaigns,
		CreateCampaign,
		UpdateCampaign,
		DeleteCampaign,
	}

	return <CampaignsContext.Provider value={value} {...props}/>
}

const useCampaigns = () => useContext(CampaignsContext);

export {CampaignsProvider, useCampaigns};