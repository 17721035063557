import { useEffect, useMemo, useState } from 'react';
import { Section, Panel, Card, Header, Body, Footer, Form, Button, Text, Confirm } from 'crack-ux';
import { Amount } from 'crack-functions';
import moment from 'moment';

// Context
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useLists } from '../../../../contexts/collections/home/Lists';
import { useCRShifts } from '../../../../contexts/collections/commerce/CRShifts';
import { useMovements } from '../../../../contexts/collections/commerce/Movements';

const CloseCashRegisterForm = (props) =>
{
	const { currentShift, FetchMemberShifts, setShiftForm } = props;

	const [declaredAmounts, setDeclaredAmounts] = useState({note: currentShift.note || ''});
	const [expectedAmounts, setExpectedAmounts] = useState({});
	const [messages, setMessages] = useState({});
	const [showConfirm, setShowConfirm] = useState(false);

	const { CloseModal } = useModal();
	const { Translate } = useTraductor();
	const { listsByName } = useLists();
	const { ReadMovementsByShift } = useMovements();
	const { CloseCRShift } = useCRShifts();

	const paymentMethods = useMemo(() => listsByName['payment-methods'].workingItems || [], [listsByName]);

	const CloseShift = async () =>
	{
		const note = declaredAmounts.note || '';

		const declaredAmountsClone = {...declaredAmounts};
		delete declaredAmountsClone.note;

		const shift =
		{
			_id: currentShift._id,
			cashRegisterId: currentShift.cashRegisterId,
			closingSummary: Object.keys(declaredAmountsClone).map(method =>
			({
				method,
				expectedAmount: expectedAmounts[method] || 0,
				declaredAmount: declaredAmounts[method] || 0
			})),
			note
		}

		const response = await CloseCRShift(shift);

		if(response.status === 200)
		{
			FetchMemberShifts();
			setShiftForm({});
			CloseModal();
			CloseModal();
		}
	}

	const inputs = useMemo(() =>
	{
		const inputsArray = [];

		paymentMethods.forEach(paymentMethod =>
		{
			const expected = expectedAmounts[paymentMethod._id] || 0;

			inputsArray.push(
			{
				name: paymentMethod._id,
				text: `${paymentMethod.text} - ${Amount.CurrencyFormat(expected)}`,
				type: 'number',
				value: declaredAmounts[paymentMethod._id] || '0',
				message: messages[paymentMethod._id],
				class: 'mobile6'
			});
		});

		inputsArray.push(
		{
			name: 'note',
			text: Translate('note'),
			type: 'textarea',
			value: declaredAmounts.note,
			message: messages.note
		});

		return inputsArray;

	}, [paymentMethods, declaredAmounts, messages, Translate, expectedAmounts]);

	useEffect(() =>
	{
		ReadMovementsByShift(currentShift._id).then(response =>
		{
			if(response.status === 200)
			{
				const movements = response.data;
				const paymentTotals = {};

				movements.forEach(movement =>
				{
					if(!movement.annulled && Array.isArray(movement.payments))
					{
						movement.payments.forEach(payment =>
						{
							if(!paymentTotals[payment.method]) paymentTotals[payment.method] = 0;

							paymentTotals[payment.method] += parseFloat(payment.amount);
						});
					}
				});

				const cashMethod = paymentMethods.find(method => method.internalName.toLowerCase() === 'cash');

				if(cashMethod)
				{
					paymentTotals[cashMethod._id] = (paymentTotals[cashMethod._id] || 0) + parseFloat(currentShift.openingCash || 0);
				}

				setDeclaredAmounts(paymentTotals);
				setExpectedAmounts(paymentTotals);
			}
		});
	}, [currentShift._id, ReadMovementsByShift, paymentMethods, currentShift.openingCash]);

	return (
		<>
			<Card>
				<Header>
					<Text class='bold'>{Translate('close-cash-register')}</Text>
				</Header>
				<Body>
					<Section style={{marginBottom: 20}}>
						<Panel class='mobile4'>
							<Text class='bold'>{Translate('cash-register')}:</Text>
						</Panel>
						<Panel class='mobile8'>
							{/* <Text>{currentCashRegister.name}</Text> */}
						</Panel>
						<Panel class='mobile4'>
							<Text class='bold'>{Translate('closing-date')}:</Text>
						</Panel>
						<Panel class='mobile8'>
							<Text>{moment().format('DD/MM/YYYY HH:mm')}</Text>
						</Panel>
						<Panel class='mobile4'>
							<Text class='bold'>{Translate('opening-cash')}:</Text>
						</Panel>
						<Panel class='mobile8'>
							<Text>{Amount.CurrencyFormat(currentShift.openingCash)}</Text>
						</Panel>
					</Section>
					<Form
						inputs = {inputs}
						form = {declaredAmounts}
						setForm = {setDeclaredAmounts}
						response = {<Text class='green'>{messages.form}</Text>}
					/>
				</Body>
				<Footer class='right'>
					<Button
						text = {Translate('save')}
						class = 'bg-blue white'
						Click = {() =>
						{
							let someChanged = false;
							const messagesData = {}
					
							paymentMethods.forEach(paymentMethod =>
							{
								if(declaredAmounts[paymentMethod._id] < 0)
								{
									messagesData[paymentMethod._id] = Translate('amount-must-be-greater-than-zero');
								}
					
								const declared = parseFloat(declaredAmounts[paymentMethod._id]);
								const expected = parseFloat(expectedAmounts[paymentMethod._id]);

								if(!isNaN(declared) && !isNaN(expected) && declared !== expected)
								{
									someChanged = true;
								}
							})
					
							if(someChanged && !declaredAmounts.note) messagesData.note = Translate('field-required');

							setMessages(messagesData);
					
							if(Object.keys(messagesData).length === 0)
							{
								setShowConfirm(true)
							}
						}}
					/>
					<Button
						text = {Translate('cancel')}
						Click = {CloseModal}
					/>
				</Footer>
			</Card>
			<Confirm
				show = {showConfirm}
				header = {Translate('close-cash-register')}
				body = {Translate('confirm-close-cash-register')}
				confirm = {{Click: CloseShift}}
				cancel = {{Click: () => setShowConfirm(false)}}
			/>
		</>
	)
}

export { CloseCashRegisterForm };