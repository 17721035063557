import {useNavigate} from 'react-router-dom';
import {Content, Header, Body, Footer, Text, Button} from 'crack-ux';
import {Utilities} from 'crack-functions';

// Contexts
import {useBusinessPage} from '../../../../contexts/pages/public/BusinessPage';

// Components
import {Product} from '../components/Product';

const Products = () =>
{
	const navigate = useNavigate();
	const {business, products} = useBusinessPage();

	const {page = {}} = business;
	const {products: productsConf = {}} = page;

	return (
		<Content class='products desktop relative'>
			<Header class='center no-border'>
				<Text class='title'>{productsConf.title}</Text>
				<Text class='caption'>{productsConf.caption}</Text>
			</Header>
			<Body class='products no-padding'>
				{products.map(product => <Product key={product._id} {...product}/>)}
			</Body>
			<Footer class='center no-border'>
				<Button
					class = 'main-button'
					text = {productsConf.moreProductsButton?.text}
					Click = {() => navigate('./products')}
					style =
					{{
						background: productsConf.moreProductsButton?.background,
						color: Utilities.HexIsLight(productsConf.moreProductsButton?.background) ? 'black' : 'white'
					}}
				/>
			</Footer>
		</Content>
	)
}

export {Products};