import { Section, Content, Text, Box, Header, Body, Form, Button } from 'crack-ux';

const ContactUs = () =>
{
	return (
		<Section class='contact-us'>
			<Content class='desktop'>
				<Box class='form-side'>
					<Header>
						<Text class='title'>Haz crecer tu negocio con un software hecho a tu medida</Text>
						<Text class='subtitle'>
							Nuestro equipo te guiará paso a paso para digitalizar tu negocio con una solución adaptada a ti.
						</Text>
					</Header>
					<Body>
						<Form
							inputs =
							{[
								{
									placeholder: 'Nombre del negocio'
								},
								{
									placeholder: 'Tu nombre'
								},
								{
									placeholder: 'Correo electrónico o Whatsapp'
								},
								{
									placeholder: '¿Qué necesitas resolver?',
									options:
									[
										{ text: 'Ventas e inventario', value: 'ventas' },
										{ text: 'Clientes y seguimiento', value: 'crm' },
										{ text: 'Contabilidad y reportes', value: 'contabilidad' },
									]
								},
							]}
						/>
						<Button class='cta' text='Quiero que me contacten'/>
						<Text class='footer-note'>Sin compromiso – 100% personalizado</Text>
					</Body>
				</Box>
			</Content>
		</Section>
	)
}

export { ContactUs };