import { createContext, useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Contexts
import { useGeneral } from "../../general/General";
import { ModalProvider } from "../../general/Modal";

const BusinessPageContext = createContext();

const products =
[
	{
		_id: '1',
		code: 1,
		name: 'Vino Tinto',
		description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc.',
		pictures: [],
		quantity: {current: 10, min: 0, max: 100},
		price: {purchase: 100, sale: 150},
		features: [
			{
				title: 'Feature 1',
				description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc.'
			},
			{
				title: 'Feature 2',
				description: 'Description 2'
			},
			{
				title: 'Feature 3',
				description: 'Description 3'
			}
		],
		available: true,
		tags: ['vino', 'tinto'],
		categories: ['vino']
	},
	{
		_id: '2',
		code: 2,
		name: 'Vino Blanco',
		description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc.',
		pictures: [],
		quantity: {current: 10, min: 0, max: 100},
		price: {purchase: 100, sale: 150},
		features: [],
		available: true,
		tags: ['vino', 'blanco'],
		categories: ['vino']
	},
	{
		_id: '3',
		code: 3,
		name: 'Vino Rosado',
		description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc.',
		pictures: [],
		quantity: {current: 10, min: 0, max: 100},
		price: {purchase: 100, sale: 150},
		features: [],
		available: true,
		tags: ['vino', 'rosado'],
		categories: ['vino']
	},
	{
		_id: '4',
		code: 4,
		name: 'Vino Espumante',
		description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc.',
		pictures: [],
		quantity: {current: 10, min: 0, max: 100},
		price: {purchase: 100, sale: 150},
		features: [],
		available: true,
		tags: ['vino', 'espumante'],
		categories: ['vino']
	}
];

const services =
[
	{
		_id: '1',
		code: 1,
		name: 'Vino Tinto',
		description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc.',
		period: 'Por Servicio',
		pictures: [],
		price: {purchase: 100, sale: 150},
		features: [
			{
				title: 'Feature 1',
				description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc.',
				checked: true
			},
			{
				title: 'Feature 2',
				description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
				checked: true
			},
			{
				title: 'Feature 3',
				description: 'Description 3',
				checked: false
			}
		],
		available: true,
		tags: ['vino', 'tinto'],
		categories: ['vino']
	},
	{
		_id: '2',
		code: 2,
		name: 'Vino Blanco',
		description: 'Vino blanco de la mejor calidad',
		period: 'Por Mes',	
		pictures: [],
		price: {purchase: 100, sale: 150},
		features: [
			{
				title: 'Feature 1',
				description: 'Description 1',
				checked: true
			},
			{
				title: 'Feature 2',
				description: 'Description 2',
				checked: true
			},
			{
				title: 'Feature 3',
				description: 'Description 3',
				checked: false
			}
		],
		available: true,
		tags: ['vino', 'blanco'],
		categories: ['vino']
	},
	{
		_id: '3',
		code: 3,
		name: 'Vino Rosado',
		description: 'Vino rosado de la mejor calidad',
		period: 'Mensual',
		pictures: [],
		price: {purchase: 100, sale: 150},
		features: [
			{
				title: 'Feature 1',
				description: 'Description 1',
				checked: true
			},
			{
				title: 'Feature 2',
				description: 'Description 2',
				checked: true
			},
			{
				title: 'Feature 3',
				description: 'Description 3',
				checked: true
			}
		],
		available: true,
		tags: ['vino', 'rosado'],
		categories: ['vino']
	},
	{
		_id: '4',
		code: 4,
		name: 'Vino Espumante',
		description: 'Vino espumante de la mejor calidad',
		period: 'Semanal',
		pictures: [],
		price: {purchase: 100, sale: 150},
		features: [
			{
				title: 'Feature 1',
				description: 'Description 1',
				checked: true
			},
			{
				title: 'Feature 2',
				description: 'Description 2',
				checked: false
			},
			{
				title: 'Feature 3',
				description: 'Description 3',
				checked: false
			}
		],
		available: true,
		tags: ['vino', 'espumante'],
		categories: ['vino']
	}
];

const reviews =
[
	{
		_id: 1,
		name: 'John Doe',
		picture: '/assets/profile.png',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc.',
		qualification: 4.5
	},
	{
		_id: 2,
		name: 'John Doe',
		picture: '/assets/profile.png',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc.',
		qualification: 5
	},
	{
		_id: 3,
		name: 'John Doe',
		picture: '/assets/profile.png',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc.',
		qualification: 4
	},
	{
		_id: 4,
		name: 'John Doe',
		picture: '/assets/profile.png',
		description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc.',
		qualification: 3.5
	}
];

const productsCategories =
[
	{
		_id: 1,
		text: 'Vino',
		value: 'vino'
	},
	{
		_id: 2,
		text: 'Cerveza',
		value: 'cerveza'
	},
	{
		_id: 3,
		text: 'Whisky',
		value: 'whisky'
	},
	{
		_id: 4,
		text: 'Ron',
		value: 'ron'
	},
	{
		_id: 5,
		text: 'Tequila',
		value: 'tequila'
	},
];

const servicesCategories =
[
	{
		_id: 6,
		text: 'Ginebra',
		value: 'ginebra'
	},
	{
		_id: 7,
		text: 'Vodka',
		value: 'vodka'
	},
	{
		_id: 8,
		text: 'Brandy',
		value: 'brandy'
	},
	{
		_id: 9,
		text: 'Pisco',
		value: 'pisco'
	},
	{
		_id: 10,
		text: 'Otros',
		value: 'otros'
	}
];

const page =
{
	general:
	{
		websiteVisibility: true,
		businessName: 'Wine Explorers',
		logo: '/assets/logo.png',
		primaryColor: '#2196F3',
		secondaryColor: '#33B140',
	},
	init:
	{
		coverPage: '/public-page/cover-page.png',
	},
	aboutUs:
	{
		active: true,
		title: 'Bienvenido a nuestra página web de turismo enológico en Bolivia',
		caption: 'Who are in extremely love with eco friendly system.',
		description: 'Aquí podrá descubrir las maravillas de la industria del vino en nuestro país, desde los viñedos más impresionantes hasta los vinos más exclusivos',
		productsButton:
		{
			text: 'Ver Productos',
			background: '#2196F3',
		},
		servicesButton:
		{
			text: 'Ver Servicios',
			background: '#33B140',
		}
	},
	features:
	{
		title: 'Some Features that Made us Unique',
		caption: 'Who are in extremely love with eco friendly system.',
		card:
		{
			background: '#2196F3',
		},
		features:
		[
			{
				_id: 1,
				title: "Feature 1",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				icon: "icon-building"
			},
			{
				_id: 2,
				title: "Feature 2",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				icon: "icon-lock"
			},
			{
				_id: 3,
				title: "Feature 1",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				icon: "icon-users"
			},
			{
				_id: 4,
				title: "Feature 2",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				icon: "icon-cc-paypal"
			},
		]
	},
	products:
	{
		title: 'Some Features that Made us Unique',
		caption: 'Who are in extremely love with eco friendly system.',
		card:
		{
			background: '#2196F3',
		},
		price:
		{
			color: '#333333',
		},
		detailButton:
		{
			text: 'Ver Detalle',
			background: '#333333',
		},
		addProductButton:
		{
			text: 'Añadir al Carrito',
			background: '#333333',
		},
		moreProductsButton:
		{
			text: 'Ver Catalogo de Productos',
			background: '#2196F3',
		}
	},
	services:
	{
		title: 'Some Features that Made us Unique',
		caption: 'Who are in extremely love with eco friendly system.',
		card:
		{
			background: '#33B140',
		},
		detailButton:
		{
			text: 'Ver Detalle',
			background: '#333333',
		},
		addServiceButton:
		{
			text: 'Seleccionar Servicio',
			background: '#33B140',
		},
		removeServiceButton:
		{
			text: 'Cancelar Servicio',
			background: '#FE381F',
		},
		moreServicesButton:
		{
			text: 'Ver Catalogo de Servicios',
			background: '#33B140',
		}
	},
	reviews:
	{
		title: 'Some Reviews that Made us Unique',
		caption: 'Who are in extremely love with eco friendly system.',
		card:
		{
			background: '#333333',
		},
	},
	faqs:
	{
		title: 'Some FAQs that Made us Unique',
		caption: 'Who are in extremely love with eco friendly system.',
		card:
		{
			background: '#2196F3',
		},
		faqs:
		[
			{
				_id: 1,
				question: "Question 1",
				answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc."
			},
			{
				_id: 2,
				question: "Question 2",
				answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc."
			},
			{
				_id: 3,
				question: "Question 3",
				answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc."
			},
			{
				_id: 4,
				question: "Question 4",
				answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc."
			},
			{
				_id: 5,
				question: "Question 5",
				answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc."
			},
			{
				_id: 6,
				question: "Question 6",
				answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec ultricies ultricies, nunc nunc."
			},
		]
	},
	contactUs:
	{
		title: 'Contact Us',
		caption: 'Who are in extremely love with eco friendly system.',
		card:
		{
			background: '#ffffff',
		},
		phones:
		{
			background: '#333333',
		}
	}
};

const BusinessPageProvider = (props) =>
{
	const {children} = props;

	const [business, setBusiness] = useState({});
	// const [products, setProducts] = useState([]);
	// const [services, setServices] = useState([]);
	// const [reviews, setReviews] = useState([]);
	// const [productsCategories, setProductsCategories] = useState([]);
	// const [servicesCategories, setServicesCategories] = useState([]);
	const [shoppingCart, setShoppingCart] = useState(
	{
		products: localStorage.getItem('shoppingCart') ? JSON.parse(localStorage.getItem('shoppingCart')).products : {},
		services: localStorage.getItem('shoppingCart') ? JSON.parse(localStorage.getItem('shoppingCart')).services : {}
	});
	const [showShoppingCart, setShowShoppingCart] = useState(false);
	
	const { Fetch } = useGeneral();

	const params = useParams();
	const navigate = useNavigate();
	
	const SetProductInShoppingCart = (product, quantity) =>
	{
		quantity = quantity < 0 ? 0 : quantity;
		
		const shoppingCartProducts = {...shoppingCart.products};

		if(shoppingCartProducts[product._id])
		{
			shoppingCartProducts[product._id].quantity = quantity;
		}
		else
		{
			shoppingCartProducts[product._id] =
			{
				product,
				quantity
			};
		}

		setShoppingCart({...shoppingCart, products: shoppingCartProducts});
	}

	const AddProductToShoppingCart = (product) =>
	{
		const quantity = shoppingCart.products[product._id] ? parseFloat(shoppingCart.products[product._id].quantity) + 1 : 1;

		SetProductInShoppingCart(product, quantity);
	}

	const RemoveProductFromShoppingCart = (product) =>
	{
		if(shoppingCart.products[product._id] && shoppingCart.products[product._id].quantity - 1 < 0)
		{
			SetProductInShoppingCart(product, 0);
		}
		else
		{
			const quantity = shoppingCart.products[product._id] ? shoppingCart.products[product._id].quantity - 1 : 0;

			SetProductInShoppingCart(product, quantity);
		}
	}

	const SetServiceInShoppingCart = (service, quantity) =>
	{
		quantity = quantity < 0 ? 0 : quantity;
		
		const shoppingCartServices = {...shoppingCart.services};

		if(shoppingCartServices[service._id])
		{
			shoppingCartServices[service._id].quantity = quantity;
		}
		else
		{
			shoppingCartServices[service._id] =
			{
				service,
				quantity
			};
		}

		setShoppingCart({...shoppingCart, services: shoppingCartServices});
	}

	const AddServiceToShoppingCart = (service) =>
	{
		SetServiceInShoppingCart(service, 1);
	}

	const RemoveServiceFromShoppingCart = (service) =>
	{
		SetServiceInShoppingCart(service, 0);
	}

	const ParsePage = (page) =>
	{
		const parsedPage = {...page};

		if(page.aboutUs)
		{
			parsedPage.aboutUs =
			{
				...page.aboutUs,
				productsButtonText: page.aboutUs.productsButton?.text,
				productsButtonColor: page.aboutUs.productsButton?.background,
				servicesButtonText: page.aboutUs.servicesButton?.text,
				servicesButtonColor: page.aboutUs.servicesButton?.background
			}
		}

		return parsedPage;
	}

	useEffect(() =>
	{
		const query =
		{
			business: params.business
		}

		Fetch.Get('/business/public-info', query).then(response =>
		{
			if(true)
			// if(response.status === 200)
			{
				const parsedPage = ParsePage(page);

				setBusiness({page: parsedPage});

				// setBusiness(response.data.business);
				// setProducts(response.data.products);
				// setProducts(response.data.services);
				// setProducts(response.data.reviews);
				// setProducts(response.data.productsCategories);
				// setProducts(response.data.servicesCategories);
			}
			else
			{
				setBusiness({});
				navigate('./not-found');
			}
		});

	}, [params.business, navigate, Fetch]);

	useEffect(() =>
	{
		localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
	}, [shoppingCart]);

	const value =
	{
		business,
		products,
		services,
		reviews,
		productsCategories,
		servicesCategories,
		shoppingCart,
		showShoppingCart,
		SetProductInShoppingCart,
		AddProductToShoppingCart,
		RemoveProductFromShoppingCart,
		SetServiceInShoppingCart,
		AddServiceToShoppingCart,
		RemoveServiceFromShoppingCart,
		setShowShoppingCart
	}

	return (
		<BusinessPageContext.Provider value={value}>
			<ModalProvider>
				{children}
			</ModalProvider>
		</BusinessPageContext.Provider>
	)
}

const useBusinessPage = () => useContext(BusinessPageContext);

export {BusinessPageProvider, useBusinessPage}