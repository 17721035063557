import { useEffect, useState } from 'react';
import { Card, Header, Body, Footer, Form, Text, Button } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useBusiness } from '../../../../contexts/layouts/Business';
import { useBusiness as useBusinessMain } from '../../../../contexts/main/Business';

// Static Data
import RoundingMethods from '../../../../static-data/RoundingMethods.json';

const CommercePreferences = () =>
{
	const [preferncesForm, setPreferencesForm] = useState({});
	const [disabled, setDisabled] = useState(true);

	const { Translate } = useTraductor();
	const { UpdatePreferences } = useBusinessMain();
	const { business } = useBusiness();
	
	const SavePreferences = async () =>
	{
		const preferences = 
		{
			module: 'commerce',
			discountRounding:
			{
				...preferncesForm,
			}
		}

		const response = await UpdatePreferences(preferences);

		if(response.status === 200)
		{
			setDisabled(true);
		}
	}

	useEffect(() =>
	{
		if (business?.preferences?.commerce?.discountRounding)
		{
			setPreferencesForm(business.preferences.commerce.discountRounding);
		}
	}, [business]);

	return (
		<Card>
			<Header>
				<Text class='title'>{Translate('commerce-preferences')}</Text>
			</Header>
			<Body style={{height: 'calc(100% - var(--header) - var(--footer))'}}>
				<Form
					inputs =
					{[
						{
							name: 'method',
							text: Translate('discount-rounding-method'),
							class: 'desktop6',
							type: 'select',
							options: RoundingMethods.map((method) => ({text: Translate(method.text), value: method.value})),
							value: preferncesForm.method,
							disabled: disabled,
						},
						{
							name: 'decimals',
							text: Translate('discount-rounding-decimals'),
							class: 'desktop6',
							type: 'number',
							value: preferncesForm.decimals,
							disabled: disabled,
						},
						{
							name: 'halfSteps',
							text: Translate('discount-rounding-half-steps'),
							class: 'desktop6',
							type: 'switch',
							value: preferncesForm.halfSteps,
							message: <Text class='green' type='p' style={{marginTop: 10}}>{Translate('discount-rounding-half-steps-message')}</Text>,
							disabled: disabled,
						}
					]}
					form = {preferncesForm}
					setForm = {setPreferencesForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {disabled ? Translate('edit') : Translate('save')}
					class = 'bg-blue white'
					Click = {() => disabled ? setDisabled(false) : SavePreferences()}
				/>
				{!disabled && 
				<Button
					text = {Translate('cancel')}
					Click = {() =>
					{
						setDisabled(true);
					}}
				/>}
			</Footer>
		</Card>
	)
}

export { CommercePreferences };