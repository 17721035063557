import { Section, Box, Text, Avatar } from 'crack-ux';
import { Text as TextF } from 'crack-functions';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

const Contact = (contact) =>
{
	const { name, lastName, picture, lastMessage, unread } = contact;

	const navigate = useNavigate();
	const params = useParams();

	const { contactId } = params;

	const isCurrentContact = contactId === contact._id;

	const fullName = TextF.FullName(name, lastName);

	const HandleClickContact = () =>
	{
		navigate(`../${fullName}/${contact._id}/chat`);
	}

	const formattedDate = lastMessage?.date 
		? moment().diff(moment(lastMessage.date), 'days') < 7
			? moment(lastMessage.date).fromNow()
			: moment(lastMessage.date).format('DD/MM/YYYY')
		: '';

	return (
		<Section class={`contact ${isCurrentContact && 'bg-blue'}`} Click={HandleClickContact}>
			<Box class='picture'>
				<Avatar class={`round ${isCurrentContact ? 'bg-gray' : 'bg-blue'} white`} src={picture || TextF.Initials(fullName)}/>
			</Box>
			<Box>
				<Text class={`bold ${isCurrentContact ? 'white' : 'black'}`} type='p'>{fullName}</Text>
				<Text class={isCurrentContact ? 'white' : 'black'} type='p'>{lastMessage?.[0]?.message}</Text>
			</Box>
			<Box class='float-right right'>
				<Text type='p'>{formattedDate}</Text>
				{(unread || unread > 0) && <Text class='unread bg-green white'>{unread}</Text>}
			</Box>
		</Section>
	);
}

export { Contact };