import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

// Context
import { useSocket } from '../../general/Socket';
import { useTraductor } from '../../general/Traductor';
import { useBusiness } from '../../layouts/Business';

// Reducers
import { ClientsReducer } from '../../../reducers/collections/commerce/Clients';

const Clients = createContext({});

const ClientsProvider = (props) =>
{
	const [state, dispatch] = useReducer(ClientsReducer, {clients: [], code: 0});
	const [currentClient, setCurrentClient] = useState({});
	const [loading, setLoading] = useState(true);

	const Socket = useSocket();
	const { Translate } = useTraductor();
	const { BusinessFetch, business, SomethingWentWrong } = useBusiness();

	const ReadClients = useCallback(async () =>
	{
		try
		{
			setLoading(true);

			const response = await BusinessFetch.Get('/commerce/clients');

			if(response.status === 200)
			{
				dispatch({type: 'LOAD_CLIENTS', clients: response.data});
			}
		}
		catch(error)
		{
			SomethingWentWrong('ReadClients');
		}
		finally
		{
			setLoading(false);
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const CreateClient = async (client) =>
	{
		try
		{
			client.code = state.code + 1;

			const response = await BusinessFetch.Post('/commerce/client', client);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('client-created')
				});
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('CreateClient');
		}
	}

	const UpdateClient = async (client) =>
	{
		try
		{
			const response = await BusinessFetch.Put('/commerce/client', client);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('client-updated')
				});
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('UpdateClient');
		}
	}

	const DeleteClient = async (client) =>
	{
		try
		{
			const data =
			{
				_id: client._id,
			}
	
			const response = await BusinessFetch.Delete(`/commerce/client`, data);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('client-deleted')
				});
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('DeleteClient');
		}
	}

	useEffect(() =>
	{
		BusinessFetch.Get('/commerce/clients/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_CLIENT_CODE', code: response.data});
			}
		});
	}, [BusinessFetch]);

	useEffect(() =>
	{
		const events =
		[
			{
				name: 'COMMERCE_CLIENT_CREATE',
				Function: (client) => dispatch({type: 'CREATE_CLIENT', client})
			},
			{
				name: 'COMMERCE_CLIENT_UPDATE',
				Function: (client) => dispatch({type: 'UPDATE_CLIENT', client})
			},
			{
				name: 'COMMERCE_CLIENT_DELETE',
				Function: (client) => dispatch({type: 'DELETE_CLIENT', client})
			}
		];

		Socket.ConnectEvents(business._id, events);
	}, [Socket, business._id]);

	const value =
	{
		loading,
		code: state.code,
		clients: state.clients,
		currentClient,
		setCurrentClient,
		ReadClients,
		CreateClient,
		UpdateClient,
		DeleteClient
	}

	return (
		<Clients.Provider value={value}>
			{props.children}
		</Clients.Provider>
	)
}

const useClients = () => useContext(Clients);

export {ClientsProvider, useClients};