import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

// Context
import { useSocket } from '../../general/Socket';
import { useTraductor } from '../../general/Traductor';
import { useBusiness } from '../../layouts/Business';

// Reducers
import { CRShiftsReducer } from '../../../reducers/collections/commerce/CRShifts';

const CRShifts = createContext({});

const CRShiftsProvider = (props) =>
{
	const [state, dispatch] = useReducer(CRShiftsReducer, {crShifts: [], code: 0});
	const [currentCRShift, setCurrentCRShift] = useState({});
	const [loading, setLoading] = useState(true);

	const Socket = useSocket();
	const { Translate } = useTraductor();
	const { BusinessFetch, business, SomethingWentWrong } = useBusiness();

	const ReadShifts = useCallback(async () =>
	{
		try
		{
			setLoading(true);

			const response = await BusinessFetch.Get('/commerce/cash-register-shifts');

			if(response.status === 200)
			{
				dispatch({type: 'LOAD_CR_SHIFTS', crShifts: response.data});
			}
		}
		catch(error)
		{
			SomethingWentWrong('ReadShifts');
		}
		finally
		{
			setLoading(false);
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const ReadMemberShifts = useCallback(async (memberId) =>
	{
		try
		{
			const response = await BusinessFetch.Get(`/commerce/cash-register-shifts`, {where: {memberId}});

			if(response.status === 200)
			{
				return response.data;
			}
			else
			{
				return [];
			}
		}
		catch(error)
		{
			SomethingWentWrong('ReadMemberShifts');
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const OpenCRShift = async (crShift) =>
	{
		try
		{
			crShift.code = state.code + 1;

			const response = await BusinessFetch.Post('/commerce/cash-register-shift/open', crShift);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('cash-register-shift-opened')
				});
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('OpenCRShift');
		}
	}

	const CloseCRShift = async (crShift) =>
	{
		try
		{
			const response = await BusinessFetch.Put('/commerce/cash-register-shift/close', crShift);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('cash-register-shift-closed')
				});
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('CloseCRShift');
		}
	}

	useEffect(() =>
	{
		BusinessFetch.Get('/commerce/cash-register-shifts/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_CR_SHIFT_CODE', code: response.data});
			}
		});
	}, [BusinessFetch]);

	useEffect(() =>
	{
		const events =
		[
			{
				name: 'COMMERCE_CR_SHIFT_CREATE',
				Function: (crShift) => dispatch({type: 'CREATE_CR_SHIFT', crShift})
			},
			{
				name: 'COMMERCE_CR_SHIFT_UPDATE',
				Function: (crShift) => dispatch({type: 'UPDATE_CR_SHIFT', crShift})
			},
			{
				name: 'COMMERCE_CR_SHIFT_DELETE',
				Function: (crShift) => dispatch({type: 'DELETE_CR_SHIFT', crShift})
			}
		];

		Socket.ConnectEvents(business._id, events);
	}, [Socket, business._id]);

	const value =
	{
		loading,
		code: state.code,
		crShifts: state.crShifts,
		currentCRShift,
		setCurrentCRShift,
		ReadShifts,
		OpenCRShift,
		CloseCRShift,
		ReadMemberShifts
	}

	return (
		<CRShifts.Provider value={value}>
			{props.children}
		</CRShifts.Provider>
	)
}

const useCRShifts = () => useContext(CRShifts);

export {CRShiftsProvider, useCRShifts};