import { useCallback, useMemo, useState } from 'react';
import { Table, Text, Confirm } from 'crack-ux';
import { Text as TextF } from 'crack-functions';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useSession } from '../../../../contexts/general/Session';
import { useGeneral } from '../../../../contexts/general/General';
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useBusiness } from '../../../../contexts/layouts/Business';
import { useContacts } from '../../../../contexts/collections/crm/Contacts';
import { useMessages } from '../../../../contexts/collections/crm/Messages';
import { useLists } from '../../../../contexts/collections/home/Lists';
import { useCompanies } from '../../../../contexts/collections/crm/Companies';
import { useCRM } from '../../../../contexts/modules/CRM';

// Components
import { ContactForm } from './ContactForm';

const defaultContactChatStatus =
{
	chatStatusNoChat: true,
	chatStatusAnswered: true,
	chatStatusNotAnswered: true,
	chatStatusChatBot: true,
	chatStatusScheduledMessage: true,
};

const ContactsList = ({contacts = [], from, selected, setSelected}) =>
{
	const [contact, setContact] = useState({});
	const [showConfirm, setShowConfirm] = useState(false);
	const [filters, setFilters] = useState({showColumns: null, inputValues: {...defaultContactChatStatus, salesFlows: false}});

	const { user } = useSession();
	const { screen } = useGeneral();
	const { Translate } = useTraductor();
	const { AddModal } = useModal();
	const { Can } = useBusiness();
	const { DeleteContact } = useContacts();
	const { companies } = useCompanies();

	const navigate = useNavigate();
	const params = useParams();
	
	const { companyId, companyName, campaignName, campaignId, stageId } = params;

	const {ShowContactForm, ShowDeleteContactWarning, ShowUnlinkContactWarning, ShowCompanyContact, AddContactTab, RemoveContactTab} = useCRM();
	
	const {listsByName} = useLists();
	// const {GetChatStatus} = useMessages();

	const canCreateContact = Can('create-contact');

	const ChangeFilters = useCallback((newFilters) =>
	{
		setFilters(newFilters);
	}, []);

	const header = useMemo(() =>
	{
		const companyExclude = ['chatStatus', 'companiesText'];
		const salesFLowExclude = ['chatStatus', 'companiesText', 'phoneText', 'genderText', 'age'];
		const header =
		[
			{
				name: 'chatStatus',
				text: Translate('chat'),
				width: 70
			},
			{
				name: 'codeText',
				text: Translate('code'),
				sortable:
				{
					type: 'number',
					field: 'code',
				}
			},
			{
				name: 'fullName',
				text: Translate('full-name'),
			},
			{
				name: 'genderText',
				text: Translate('gender'),
				sortable: 'string',
			},
			{
				name: 'age',
				text: Translate('age'),
				sortable:
				{
					type: 'number',
					field: 'ageNumber',
				}
			},
			{
				name: 'salesStageText',
				text: Translate('sales-stage'),
			},
			{
				name: 'tagsText',
				text: Translate('tags'),
				sortable: 'string'
			},
			{
				name: 'companiesText',
				text: Translate('companies')
			},
		];

		if(['scheduled-message'].includes(from))
		{
			header.push
			({
				name: 'sentText',
				text: Translate('Sent'),
			});
		}

		if(['company', 'scheduled-messages', 'scheduled-message'].includes(from))
		{
			return header.filter(column => companyExclude.indexOf(column.name) === -1);
		}

		if(['sales-stage'].includes(from))
		{
			header.push
			({
				name: 'previousSaleStageText',
				text: Translate('Previous Sale Stage'),
			},);

			return header.filter(column => salesFLowExclude.indexOf(column.name) === -1);
		}

		return header;

	}, [from, Translate]);

	const body = useMemo(() =>
	{
		return contacts.map(contact =>
		{
			contact.genderText = listsByName.gender?.items.find(item => item._id === contact.gender)?.text || '';
			contact.salesStageText = listsByName['sales-stage']?.items.find(item => item._id === contact.salesStage)?.text || '';
			
			contact.companiesText = companies.filter(company => contact.companies?.includes(company._id)).map(company => company.name).join(', ');

			return contact;
		});
	}, [contacts, companies, listsByName]);

	const actions = useMemo(() =>
	{
		let result = [];

		if(canCreateContact)
		{
			result.push(
			{
				text: Translate('new-contact'),
				class: 'bg-blue white',
				section: 'grouped',
				Click: () => AddModal(<ContactForm from={from} company={{_id: companyId, name: companyName}} stageId={stageId}/>)
			});
		}

		result.push
		({
			text: Translate('edit-contact'),
			icon:
			{
				class: 'icon-pencil'
			},
			section: 'context',
			Click: (contact) => AddModal(<ContactForm contact={contact} from={from} company={{_id: companyId, name: companyName}}/>)
		});

		result.push
		({
			text: Translate('delete-contact'),
			class: 'red',
			icon:
			{
				class: 'icon-trash'
			},
			section: 'context',
			Click: (contact) =>
			{
				setContact(contact);
				setShowConfirm(true);
			}
		});

		// if(from !== 'company')
		// {
		// 	result.push
		// 	({
		// 		text: Translate('Check Contact'),
		// 		icon:
		// 		{
		// 			class: 'icon-vcard'
		// 		},
		// 		section: 'context',
		// 		Click: (contact) => AddContactTab(contact._id)
		// 	});
		// }

		// if(from === 'company')
		// {
		// 	result.push
		// 	({
		// 		text: Translate('Unlink Contact'),
		// 		class: 'red',
		// 		icon:
		// 		{
		// 			class: 'icon-trash'
		// 		},
		// 		section: 'context',
		// 		Click: (contact) => ShowUnlinkContactWarning(contact)
		// 	});
		// }

		return result;
	}, [AddModal, Translate, from, companyId, companyName, stageId, canCreateContact]);

	const inputFilters = useMemo(() =>
	{
		const result = from === 'contacts' ?
		[
			{
				title: Translate('Contact Chat Status'),
				type: 'title'
			},
			{
				name: 'chatStatusNoChat',
				text: Translate('No Chat'),
				type: 'checkbox',
				checked: true,
			},
			{
				name: 'chatStatusAnswered',
				text: Translate('Awaiting for Reply'),
				type: 'checkbox',
				checked: true,
			},
			{
				name: 'chatStatusNotAnswered',
				text: Translate('Already Replied'),
				type: 'checkbox',
				checked: true,
			},
			{
				name: 'chatStatusChatBot',
				text: Translate('Chat Bot Messages'),
				type: 'checkbox',
				checked: true,
			},
			{
				name: 'chatStatusScheduledMessage',
				text: Translate('Scheduled Messages'),
				type: 'checkbox',
				checked: true,
			},
		] : [];

		// if(['contacts', 'scheduled-messages', 'sales-flow'].includes(from))
		// {
		// 	result.push
		// 	(
		// 		{
		// 			title: Translate('Sales Flow'),
		// 			type: 'title'
		// 		},
		// 		{
		// 			name: 'salesFlows',
		// 			type: 'autocomplete',
		// 			options: [{value: 'no-assigned', text: Translate('No Assigned')}, ...(listsByName.salesFlow?.selectOptions || [])],
		// 			multiple: true,
		// 			selected: ['no-assigned', ...Object.keys(salesFlowItemsById)]
		// 		},
		// 	);
		// }

		return result;
	}, [from, Translate]);

	const BackButton = useMemo(() =>
	{
		if(screen.device === 'mobile' && from === 'companies')
		{
			return {
				Click: () => navigate('../')
			}
		}
		else if(screen.device === 'mobile' && from === 'sales-flow')
		{
			return {
				Click: () => navigate(`../${campaignName}/${campaignId}`)
			}
		}
		else
		{
			return null;
		}
	
	}, [from, navigate, screen.device, campaignName, campaignId]);

	return (
		<>
			<Table
				header = {header}
				body = {body}
				actions = {actions}
				searcher =
				{
					{text: Translate('Search')}
				}
				filters =
				{{
					columns:
					{
						editable: true,
						show: filters.showColumns
					},
					inputs: inputFilters,
					Apply:
					{
						text: Translate('Apply'),
						Click: ChangeFilters
					},
					Reset:
					{
						text: Translate('Reset Filters'),
						Click: ChangeFilters
					}
				}}
				DoubleClickRow = {(contact) => [].indexOf(from) !== -1 ? () => {} : AddContactTab(contact._id)}
				selected = {from === 'scheduled-messages' ? selected : false}
				ClickSelected = {setSelected}
				BackButton = {BackButton}
				language = {user.preferences?.language || 'es'}
			/>
			<Confirm
				show={showConfirm}
				header={<Text class='bold'>{Translate(`delete-contact`)}</Text>}
				body=
				{
					<>
						<Text type='p'>{Translate(`confirm-delete-contact`)}</Text>
						<Text type='p'><span className='bold'>{Translate('contact')}: </span>{TextF.FullName(contact.name, contact.lastName)}</Text>
					</>
				}
				confirm=
				{{
					text: Translate('delete'),
					class: 'bg-red white',
					Click: () =>
					{
						DeleteContact(contact);
						setShowConfirm(false);
					}
				}}
				cancel=
				{{
					text: Translate('cancel'),
					Click: () => setShowConfirm(false)
				}}
			/>
		</>
	)
}

export {ContactsList};