import { useMemo } from 'react';
import { Section, Text } from 'crack-ux';
import { Utilities, Amount } from 'crack-functions';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useLists } from '../../../../contexts/collections/home/Lists';

// Reducers
import { defaultListColor } from '../../../../reducers/collections/Lists';

const funnelWidthFactor = 15;

const Funnel = ({ contacts, funnelType }) =>
{
	const { Translate } = useTraductor();
	const { listsByName } = useLists();

	const navigate = useNavigate();
	const params = useParams();

	const { campaignName, campaignId, stageId } = params;

	const salesFlowList = useMemo(() =>
	{
		const salesStages = listsByName['sales-stage'].workingItems.filter(stage => !stage.data?.exclusionPoint);

		let accumulatedContacts = [];

		const stagesWithContacts = salesStages.reverse().map(stage =>
		{
			const stageContacts = contacts.filter(contact => contact.salesStage === stage._id);
			accumulatedContacts = [...accumulatedContacts, ...stageContacts];

			return { ...stage, stageContacts, accumulatedContacts };
		});

		const totalContactsCount = contacts.length;
		let previousStageContactsCount = funnelType === 'stage' ? stagesWithContacts[stagesWithContacts.length - 1]?.accumulatedContacts.length : totalContactsCount;

		return stagesWithContacts.reverse().map((stage, index) =>
		{
			const accumulatedContactsLength = stage.accumulatedContacts.length;
			const stageContactsLength = stage.stageContacts.length;

			const conversionPercentage = `${Amount.Round(previousStageContactsCount > 0 ? accumulatedContactsLength * 100 / previousStageContactsCount : 0)}%`;
			const totalPercentage = `${Amount.Round(totalContactsCount > 0 ? stageContactsLength * 100 / totalContactsCount : 0)}%`;

			previousStageContactsCount = accumulatedContactsLength;

			const stageBackgroundColor = stage.color || defaultListColor;
			const textColor = Utilities.HexIsLight(stageBackgroundColor) ? '#333333' : '#efefef';
			
			return (
				<Section 
					key={stage._id}
					class='stage center pointer'
					style={{
						backgroundColor: stageBackgroundColor,
						margin: 'auto',
						width: `calc(100% - ${index * funnelWidthFactor}px)`,
						clipPath: `polygon(0% 0%, 100% 0%, calc(100% - ${funnelWidthFactor / 2}px) 100%, ${funnelWidthFactor / 2}px 100%)`,
						WebkitClipPath: `polygon(0% 0%, 100% 0%, calc(100% - ${funnelWidthFactor / 2}px) 100%, ${funnelWidthFactor / 2}px 100%)`,
					}}
					Click={() => navigate(`${stageId ? `../${campaignName}/${campaignId}/` : './' }${stage.text}/${stage._id}`)}
				>
					<Text class='block' size={1.5} style={{ color: textColor }}>{stage.text}</Text>
					<Text class='block' type='p' style={{ color: textColor }}>
						{Translate('contacts')}: {funnelType === 'stage' ? accumulatedContactsLength : stageContactsLength} ({funnelType === 'stage' ? conversionPercentage : totalPercentage})
					</Text>
				</Section>
			);
		});
	}, [contacts, funnelType, listsByName, Translate, navigate, stageId, campaignName, campaignId]);

	return (
		<Section class='funnel'>
			{salesFlowList}
		</Section>
	);
}

Funnel.defaultProps =
{
	contacts: []
};

export { Funnel };