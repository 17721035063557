import { ToggleContent, Form } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';

const ServicesForm = (props) =>
{
	const { servicesForm, setServicesForm, messages } = props;

	const { Translate } = useTraductor();
	
	return (
		<ToggleContent text={Translate('services')}>
			<Form
				inputs =
				{[
					{
						name: 'title',
						text: Translate('title'),
						value: servicesForm.title,
						message: messages.title,
					},
					{
						name: 'caption',
						text: Translate('caption'),
						value: servicesForm.caption,
						message: messages.caption,
					},
					{
						name: 'cardBackground',
						text: Translate('card-background'),
						type: 'color',
						value: servicesForm.cardBackground,
						message: messages.cardBackground,
					},
					{
						name: 'detailButtonText',
						text: Translate('detail-button-text'),
						value: servicesForm.detailButtonText,
						message: messages.detailButtonText,
					},
					{
						name: 'detailButtonBackground',
						text: Translate('detail-button-background'),
						type: 'color',
						value: servicesForm.detailButtonBackground,
						message: messages.detailButtonBackground,
					},
					{
						name: 'addServiceButtonText',
						text: Translate('add-service-button-text'),
						value: servicesForm.addServiceButtonText,
						message: messages.addServiceButtonText,
					},
					{
						name: 'removeServiceButtonText',
						text: Translate('remove-service-button-text'),
						value: servicesForm.removeServiceButtonText,
						message: messages.removeServiceButtonText,
					},
					{
						name: 'moreServicesButtonText',
						text: Translate('more-services-button-text'),
						value: servicesForm.moreServicesButtonText,
						message: messages.moreServicesButtonText,
					}
				]}
				form = {servicesForm}
				setForm = {setServicesForm}
			/>
		</ToggleContent>
	)
}

export { ServicesForm };