import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Content, Panel, Card, Section, Text } from 'crack-ux';
import { Generic } from 'crack-functions';
import moment from 'moment';

// Contexts
import { useTraductor } from '../../../../../contexts/general/Traductor';
import { useMovements, GetSalesByDay } from '../../../../../contexts/collections/commerce/Movements';
import { useProducts } from '../../../../../contexts/collections/commerce/Products';

// Components
import { ProductsSold } from './ProductsSold';
import { SalesFilters } from './SalesFilters';

// Charts
import { SalesChart } from './SalesChart';

const defaultFilter =
{
	period: 'current-month',
	startDate: moment().startOf('month'),
	endDate: moment().endOf('month'),
}

const Sales = () =>
{
	const [filter, setFilter] = useState({...defaultFilter});
	const [productsData, setProductsData] = useState([]);
	const [datesData, setDatesData] = useState([]);

	const { Translate } = useTraductor();
	const { movements } = useMovements();
	const { products } = useProducts();

	const productsObj = useMemo(() => Generic.ArrayToObjectByIdentifier(products), [products]);

	const sales = useMemo(() => movements.filter(movement => movement.type === 1).sort((a, b) => a.dateObj - b.dateObj), [movements]);

	const GetProductsTable = useCallback(() =>
	{
		const filteredSales = sales.filter(sale => sale.dateObj.isBetween(filter.startDate, filter.endDate));

		const { productsData, datesData } = GetSalesByDay(filteredSales, productsObj);

		setDatesData(Generic.ObjectToArray(datesData));
		setProductsData(Generic.ObjectToArray(productsData));
	}, [productsObj, filter, sales]);

	useEffect(() =>
	{
		GetProductsTable();
	}, [GetProductsTable]);

	const periodOptions = useMemo(() =>
	[
		{
			text: Translate('current-month'),
			value: 'current-month'
		},
		{
			text: Translate('current-quarter'),
			value: 'current-quarter'
		},
		{
			text: Translate('current-year'),
			value: 'current-year'
		},
		{
			text: Translate('custom-dates'),
			value: 'custom'
		}
	], [Translate]);

	const periodText = useMemo(() =>
	(
		periodOptions.find(option => option.value === filter.period).text
	), [periodOptions, filter]);

	return (
		<Content class='report-container'>
			<Panel class='report desktop9 full-height vertical-scroll'>
				<Card class='header'>
					<Text class='title'>{Translate('sales-report')}</Text>
				</Card>
				<Section>
					<Card>
						<SalesChart dates={datesData} periodText={periodText}/>
					</Card>
					<Card>
						<ProductsSold products={productsData}/>
					</Card>
				</Section>
			</Panel>
			<Panel class='filter desktop3 full-height'>
				<SalesFilters filter={filter} SetFilter={setFilter} defaultFilter={{...defaultFilter}} periodOptions={periodOptions}/>
			</Panel>
		</Content>
	)
}

export { Sales };