import React from 'react';
import {Body, Footer, Form, Button, Section} from 'crack-ux';

// Context
import {useModal} from '../../../contexts/general/Modal';
import {useTraductor} from '../../../contexts/general/Traductor';
import {useProfile} from '../../../contexts/pages/personal/Profile';
import {useUsers} from '../../../contexts/main/Users';

// Static data
import phoneCodes from '../../../static-data/PhoneCodes.json'

// Objects
import Validate from '../../../utilities/Validate';

// CSS
import './Profile.css';

const ProfileForm = (props) =>
{
	const {user, from, disabled} = props;

	const [form, setForm] = React.useState({...user, phone: user.phone?.number, country: user.phone?.country});
	const [messages, setMessages] = React.useState({});
	
	const {CloseModal} = useModal();
	const {displayProfileForm,SetDisplayProfileForm} = useProfile();
	const {UpdateUser} = useUsers();
	const {Translate} = useTraductor();

	React.useEffect(() =>
	{
		setForm({...user, phone: user.phone?.number, country: user.phone?.country});
	}, [user]);

	const HandleSubmit = async () =>
	{
		const newForm = {...form};
		const uniqueUserValidation = Validate.ValidateUniqueName(newForm.user);

		setMessages({user: uniqueUserValidation});
		
		newForm._id = user._id;

		
		newForm.user = newForm.user.toLowerCase();
		
		if(uniqueUserValidation === null)
		{
			newForm.phone = 
			{
				country: newForm.country,
				area: null,
				number: newForm.phone
			}
						
			const result = await UpdateUser(newForm);
			
			setMessages(Validate.ParseByField(result.validations));

			if(result.status === 200)
			{
				displayProfileForm ? SetDisplayProfileForm(false) : CloseModal()
			}
		}
	}

	return (
		<Section from={from}>
			<Body class='vertical-scroll' style={{width: '100%', height: 'calc(100vh - var(--header) * 2 - 67px - 36px)'}}>
				<Form
					inputs =
					{
						[
							{
								name: 'name',
								text: Translate('Name'),
								value: form.name,
								message: messages.name,
								disabled
							},
							{
								name: 'lastName',
								text: Translate('Last Name'),
								value: form.lastName,
								disabled
							},
							{
								name: 'user',
								text: Translate("User"),
								value: form.user,
								Change: () => setMessages({...messages, user: null}),
								message: messages.user,
								disabled
							},
							{
								name: 'email',
								text: Translate("Email"),
								value: form.email,
								Change: () => setMessages({...messages, email: null}),
								message: messages.email,
								disabled
							},
							{
								name: 'country',
								text: Translate("Country"),
								type: 'select',
								class: 'desktop3',
								options: phoneCodes,
								value: form.country || "591",
								disabled
							},
							{
								name: 'phone',
								text: Translate("Phone"),
								class: 'desktop9',
								value: form.phone,
								disabled
							}
						]                        
					}
					Change = {(response) => setForm(response)}
					Submit = {HandleSubmit}
				/>
			</Body>
			{
				!disabled ? 
				<Footer class='right'>
					<Button
						text = {Translate('Save')}
						class = 'bg-blue white'
						Click = {HandleSubmit}
					/>
					<Button
						text = {Translate('Cancel')}
						Click = {() =>{displayProfileForm ? SetDisplayProfileForm(false) : CloseModal()}}
					/>
				</Footer> :
				<></>
			}
		</Section>
	) 
	
}

export {ProfileForm};