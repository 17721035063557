import { useState } from 'react';
import { Card, Header, Body, Footer, Form, Button, Text } from 'crack-ux';

// Context
import { useModal } from '../../../../contexts/general/Modal';
import { useTemplates } from '../../../../contexts/collections/crm/Templates';
import { useTraductor } from '../../../../contexts/general/Traductor';

// Components
import { TextEditor } from '../../../../components/TextEditor';

const defaultNote =
{
	content: '',
}

const TemplateForm = ({ template = {}, channel }) =>
{
    const [formTemplate, setFormTemplate] = useState({...defaultNote, ...template});
	const [messages, setMessages] = useState({});

    const { CloseModal } = useModal();
	const { CreateTemplate, UpdateTemplate } = useTemplates();
    const { Translate } = useTraductor();

	const HandleSubmit = async () =>
	{
		const data = {...formTemplate};
		const messages = {};

		if(!data.name) messages.name = Translate('required-field');
		if(!data.content) messages.content = Translate('required-field');

		setMessages(messages);

		if(Object.keys(messages).length > 0) return;
			
		data.channel = channel;

		const result = template._id ? await UpdateTemplate(data) : await CreateTemplate(data);

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{template._id ? Translate(`edit-${channel}-template`) : Translate(`new-${channel}-template`)}</Text>
			</Header>
			<Body>
				<Form
					inputs =
					{
						[
							{
								name: 'name',
								text: Translate('template-name'),
								value: formTemplate.name,
								message: messages.name
							},
							{
								name: 'content',
								text: Translate('content'),
								type: 'content',
								content: (
									<>
										<TextEditor content={formTemplate.content} Change={(content) => setFormTemplate({...formTemplate, content})}/>
										<Text class='red' size={0.8} style={{marginTop: 5}}>{messages.content}</Text>
									</>
								)
							}
						]                        
					}
					form = {formTemplate}
					setForm = {setFormTemplate}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('save')}
					class = 'bg-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {TemplateForm};