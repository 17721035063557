import {useState} from 'react';
import {Section, Header, Body, Footer, Box, Text, Button, Icon, Image, Input} from 'crack-ux';
import {Amount, Utilities} from 'crack-functions';

// Contexts
import {useModal} from '../../../../../contexts/general/Modal';
import {useBusinessPage} from '../../../../../contexts/pages/public/BusinessPage';

const Features = (feature) =>
{
	const {title, description} = feature;

	return (
		<Section class='feature'>
			<Text class='name block'>{title}:</Text>
			<Text class='description block'>{description}</Text>
		</Section>
	);
}

const ProductDetail = (product) =>
{
	const {name, pictures, description, features} = product;

	const {CloseModal} = useModal();
	const {business, shoppingCart, SetProductInShoppingCart} = useBusinessPage();

	const [quantity, setQuantity] = useState(shoppingCart.products[product._id] && shoppingCart.products[product._id].quantity > 0 ? shoppingCart.products[product._id].quantity : 1);

	const {page = {}} = business;
	const {products: productsConf = {}} = page;

	const AddToCart = () =>
	{
		SetProductInShoppingCart(product, quantity);
		CloseModal();
	};

	const AddAmount = () =>
	{
		setQuantity(parseFloat(quantity) + 1);
	}

	const RemoveAmount = () =>
	{
		if(!(quantity - 1 < 0))
		{
			setQuantity(quantity - 1);
		}
	}

	return (
		<Section class='product-detail full-height'>
			<Header>
				<Text class='bold' size={1.5}>{name}</Text>
				<Icon class='icon-cancel float-right' Click={CloseModal}/>
			</Header>
			<Body class='vertical-scroll no-padding'>
				<Section
					class = 'product-header-background center'
					style =
					{{
						backgroundColor: productsConf.card?.background,
						color: Utilities.HexIsLight(productsConf.card?.background) ? 'black' : 'white'
					}}
				>
					<Text class='name block'>{name}</Text>
				</Section>
				<Header class='no-border'>
					<Section class='current-picture'>
						<Image src='/assets/box.png'/>
					</Section>
					<Section class='pictures horizontal-scroll no-break'>
						{pictures.length === 0 ? <Box><Image src='/assets/box.png'/></Box> : pictures.map((picture, index) => <Box key={index}><Image src='/assets/box.png'/></Box>)}
					</Section>
				</Header>
				<Body class='no-padding'>
					<Section class='description'>
						<Text class='bold block' type='p'>Description:</Text>
						<Text type='p'>{description}</Text>
					</Section>
					<Section class='features'>
						{features.map((feature, index) => <Features key={index} {...feature}/>)}
					</Section>
				</Body>
				<Footer class='amount no-border'>
					<Section>
						<Box class='quantity'>
							<Input text='Cantidad' type='number' value={quantity} Change={value => setQuantity(value)} placeholder='Cantidad'/>
							<Box class='arrows'>
								<Button>
									<Icon class='icon-up-dir' Click={AddAmount}/>
								</Button>
								<Button>
									<Icon class='icon-down-dir' Click={RemoveAmount}/>
								</Button>
							</Box>
						</Box>
						<Box class='unit-price float-right'>
							<Text class='label block'>Precio Unitario</Text>
							<Text class='price block'>{Amount.CurrencyFormat(product.price.sale)}</Text>
						</Box>
					</Section>
					<Section class='total'>
						<Text>Total</Text>
						<Text class='float-right'>{Amount.CurrencyFormat(product.price.sale * quantity)}</Text>
					</Section>
					<Section class='main-button center'>
						<Button
							text = {productsConf.addProductButton?.text}
							Click = {AddToCart}
							style =
							{{
								background: productsConf.addProductButton?.background,
								color: Utilities.HexIsLight(productsConf.addProductButton?.background) ? 'black' : 'white'
							}}
						/>
					</Section>
				</Footer>
			</Body>
		</Section>
	)
}

export {ProductDetail};