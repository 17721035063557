import { ToggleContent, Form } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';

const ContactUsForm = (props) =>
{
	const { contactUsForm, setContactUsForm, messages } = props;

	const { Translate } = useTraductor();

	return (
		<ToggleContent text={Translate('contact-us')}>
			<Form
				inputs =
				{[
					{
						name: 'title',
						text: Translate('title'),
						value: contactUsForm.title,
						message: messages.title,
					},
					{
						name: 'caption',
						text: Translate('caption'),
						value: contactUsForm.caption,
						message: messages.caption,
					},
					{
						name: 'cardBackground',
						text: Translate('card-background'),
						type: 'color',
						value: contactUsForm.cardBackground,
						message: messages.cardBackground,
					},
				]}
				form = {contactUsForm}
				setForm = {setContactUsForm}
			/>
		</ToggleContent>
	)
}

export { ContactUsForm };