import React from 'react';
import {Content, Panel, Header, Body, Card, Text, List} from 'crack-ux';

// Components
import {Loading} from '../../../../components/views/Loading';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {ChatBotsProvider, useChatBots as useChatBotsPage} from '../../../../contexts/pages/business/crm/ChatBots';

// Tables
import {ChatBotsTable} from '../../../../components/tables/modules/crm/ChatBots/ChatBots';

// Static Data
import channels from '../../../../static-data/Channels.json';

const ChatBots = (props) =>
{
	const [loading] = React.useState(false);

	const {Translate} = useTraductor();

	const {channel, SetChannel} = useChatBotsPage();

	if(loading)
	{
		return <Loading/>
	}
	else
	{
		return (
			<Content class='crm-contacts no-padding'>
				<Panel class='no-padding full-height' swipe='horizontal'>
					<Panel class='full-height desktop3'>
						<Card>
							<Header>
								<Text class='bold'>{Translate('Channels')}</Text>
							</Header>
							<Body class='no-padding'>
								<List
									class = 'hover'
									currentClass = 'bg-blue white'
									current = {channel.value}
									options = {channels.filter(item => !item.hidden).map(item =>
									{
										return {
											identifier: item.value,
											text: Translate(item.text),
											Click: () => SetChannel(item)
										}
									})}
								/>
							</Body>
						</Card>
					</Panel>
					<Panel class='full-height desktop9'>
						<Card>
							<ChatBotsTable/>
						</Card>
					</Panel>
				</Panel>
			</Content>
		);
	}
}

const BotsWithProviders = (props) =>
{
	return (
		<ChatBotsProvider>
			<ChatBots {...props}/>
		</ChatBotsProvider>
	);
}

export {BotsWithProviders as ChatBots}