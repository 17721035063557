import { useMemo } from 'react';
import { Section, Icon } from 'crack-ux';

// Contexts
import { useGeneral } from '../../contexts/general/General';
import { useBusiness } from '../../contexts/layouts/Business';
import { useModules } from '../../contexts/main/Modules';

// Styles
import './Modules.css';

const Modules = () =>
{
	const { screen } = useGeneral();
	const { member, showMenu, setShowMenu, SwitchMenu } = useBusiness();
	const { modules, currentModule, setCurrentModule } = useModules();

	const authorizedModules = useMemo(() =>
	{
		return member.isBusinessOwner ? modules : modules.filter(module => (member?.permissions?.[module.route]?.value || module.route === 'home'));
	}, [modules, member.isBusinessOwner, member.permissions]);

	const modulesIcons = useMemo(() =>
	{
		return authorizedModules.map(module =>
		{
			return (
				<Icon
					key = {module._id}
					class = {`module pointer ${module._id === currentModule._id && 'current'} ${module.icon}`}
					Click = {() =>
					{
						if(module._id === currentModule._id)
						{
							SwitchMenu();
						}
						else
						{
							setCurrentModule(module);
							setShowMenu(true);
						}
					}}
				/>
			)
		});
	}, [currentModule._id, setCurrentModule, setShowMenu, SwitchMenu, authorizedModules]);

	return (
		<Section class='navigation-modules'>
			<Section class={`menu-icon ${(screen.device === 'mobile' || screen.device === 'tablet') ? 'mobile-menu-icon' : ''}`}>
				<Icon class='icon-menu pointer' Click={SwitchMenu}/>
			</Section>
			<Section class={`modules ${(screen.device === 'mobile' || screen.device === 'tablet') ? showMenu ? 'mobile-modules show' : 'mobile-modules hide' : ''}`}>
				{modulesIcons}
			</Section>
		</Section>
	)
}

export {Modules};