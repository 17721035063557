
import { useState } from 'react';
import { Card, Header, Body, Footer, Form, Text, Button } from 'crack-ux';
// import {Dates} from 'crack-functions';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useModal } from '../../../../contexts/general/Modal';
import { useCampaigns } from '../../../../contexts/collections/crm/Campaigns';

const CampaignForm = ({ campaign = {} }) =>
{
	const [campaignForm, setCampaignForm] = useState(campaign);
	const [messages, setMessages] = useState({});

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();

	const {CreateCampaign, UpdateCampaign} = useCampaigns();

	const HandleSubmit = async () =>
	{
		const validationMessages = {}

		if (!campaignForm.name) validationMessages.name = Translate('field-required');
		if (!campaignForm.tags || campaignForm.tags.length === 0) validationMessages.tags = Translate('required-at-least-one-tag');

		if (Object.keys(validationMessages).length)
		{
			setMessages(validationMessages);
			return;
		}

		const result = campaign._id ? await UpdateCampaign(campaignForm) : await CreateCampaign(campaignForm);

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	return (
		<Card>
			<Header>
				<Text class='title'>{Translate(campaign._id ? 'edit-campaign' : 'new-campaign')}</Text>
			</Header>
			<Body>
				<Form
					inputs =
					{[
						{
							name: 'name',
							text: Translate('campaign-name'),
							value: campaignForm.name,
							message: messages.name,
						},
						// {
						// 	name: 'startDate',
						// 	text: Translate('Start Date'),
						// 	message: messages.startDate,
						// 	value: campaignForm.startDate,
						// 	class: 'desktop6',
						// 	type: 'date',
						// },
						// {
						// 	name: 'endDate',
						// 	text: Translate('End Date'),
						// 	message: messages.endDate,
						// 	value: campaignForm.endDate,
						// 	class: 'desktop6',
						// 	type: 'date',
						// },
						{
							name: 'tags',
							text: Translate('tags'),
							value: campaignForm.tags,
							type: 'tags',
							message: messages.tags,
						},
						
					]}
					form = {campaignForm}
					setForm = {setCampaignForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					class = 'bg-blue white'
					text = {Translate('save')}
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	);
}

export {CampaignForm}