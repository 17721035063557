import { useEffect, useState } from 'react';
import {Panel, Form, Text, Button, Header, Body, Footer, Card} from 'crack-ux';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

// Components
import {Map} from './Map';

// Contexts
import {useTraductor} from '../../contexts/general/Traductor';
import {useModal} from '../../contexts/general/Modal';

// Styles
import './Location.css';

const defaultLocation =
{
	country: '',
	state: '',
	province: '',
	city: '',
	street: '',
	postalCode: '',
}

const Location = (props) =>
{
	const {location, setLocation, readOnly} = props;

	const [currentLocation, setCurrentLocation] = useState(defaultLocation);
	const [search, setSearch] = useState('');

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	useEffect(() =>
	{
		setCurrentLocation(Object.keys(location).length > 0 ? location : defaultLocation);
	}, [location]);

	const GetLocation = (response) =>
	{
		const result = response.results[0];

		const location = {};

		result.address_components.forEach(component =>
		{
			location[component.types[0]] = component.long_name;
		})

		const street = 
		[
			location.route,
			location.street_number
		]

		if(!readOnly)
		{
			setCurrentLocation(
			{
				street: street.filter(value => value).join(', '),
				postalCode: location.postal_code,
				city: location.locality,
				province: location.administrative_area_level_2,
				state: location.administrative_area_level_1, 
				country: location.country,
				latitude: result.geometry.location.lat,
				longitude: result.geometry.location.lng,
			});
		}
	}

	return (
		<Card class='location'>
			<Header>
				<Text class='title'>{Translate('location')}</Text>
			</Header>
			<Body>
				<Panel class='map-container laptop8'>
					<Text class='caption'>{Translate('map')}</Text>
					<Map latitude={currentLocation.latitude} longitude={currentLocation.longitude} addresRequired={search} GetLocation={GetLocation}/>
				</Panel>
				<Panel class='form-container laptop4'>
					<Text class='caption'>{Translate('location')}</Text>
					<Text style={{marginLeft: 5, marginBottom: 5}}>{Translate('search-location')}</Text>
					<GooglePlacesAutocomplete
						apiKey="AIzaSyBKlbA051wqGwFbzw5jYhUSuWnMBsjs5XU"
						selectProps=
						{{
							className: 'places',
							value: search,
							onChange: (response) =>
							{
								if(!readOnly)
								{
									setSearch(response.label);
								}
							},
						}}
					/>
					<Form
						inputs =
						{[
							{
								name: 'country',
								text: Translate('Country'),
								value: currentLocation.country, 
								disabled: readOnly,
							},
							{
								name: 'state',
								text: Translate('state'),
								value: currentLocation.state, 
								disabled: readOnly,
							},
							{
								name: 'province',
								text: Translate('province'),
								value: currentLocation.province, 
								disabled: readOnly,
							},
							{
								name: 'city',
								text: Translate('city'),
								value: currentLocation.city, 
								disabled: readOnly,
							},
							{
								name: 'street',
								text: Translate('street'),
								value: currentLocation.street, 
								disabled: readOnly,
							},
							{
								name: 'zipCode',
								text: Translate('post-code'),
								value: currentLocation.postalCode, 
								disabled: readOnly,
							},
							{
								name: 'especifications',
								text: Translate('especifications'),
								type: 'textarea',
								rows: 3,
								value: currentLocation.description,
							},
							{
								name: 'latitude',
								text: Translate('latitude'),
								class: 'mobile6',
								value: currentLocation.latitude, 
								disabled: true,
							},
							{
								name: 'longitude',
								text: Translate('longitude'),
								class: 'mobile6',
								value: currentLocation.longitude, 
								disabled: true,
							}
						]}
						form = {currentLocation}
						setForm = {setCurrentLocation}
					/>
				</Panel>
			</Body>
			<Footer class='right'>
				{
					!readOnly
					&&
					<Button
						class='bg-blue white'
						text='Save'
						Click={() =>
						{
							setLocation({
								...currentLocation,
								address: Object.keys(currentLocation)
									.filter(key => key !== 'latitude' && key !== 'longitude' && currentLocation[key])
									.map(key => currentLocation[key])
									.join(', ')
							});
							
							CloseModal();
						}}
					/>
				}
				<Button text='Close' Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

Location.defaultProps =
{
	location: {},
	GetLocation: () => {},
	readOnly: false,
}

export {Location};