import React from 'react';

// Contexts
import { useSession } from '../general/Session';
import {useSocket} from '../general/Socket';
import {useBusiness} from '../layouts/Business';

// Reducers
import {NotificationsReducer} from '../../reducers/main/Notifications';

const NotificationsContext = React.createContext({});

const NotificationsProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(NotificationsReducer, {notifications: []});
	const [oldSocket, setOldSocket] = React.useState({});
	const [date, setDate] = React.useState(new Date());

	const {user} = useSession();
	const Socket = useSocket();
	const {business, BusinessFetch} = useBusiness();
	
	const room = React.useMemo(() => `notifications_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return user._id ? `NOTIFICATION_${user._id}` : 'NOTIFICATION';
	}, [user._id]);

	React.useEffect(() =>
	{
		let lastMinute = (new Date()).getMinutes();
		const timer = setInterval(() =>
		{
			const currentTime = new Date();

			if(lastMinute !== currentTime.getMinutes())
			{
				lastMinute = currentTime.getMinutes();

				setDate(currentTime);
			}
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	// React.useEffect(() =>
	// {
	// 	if(business._id && user._id)
	// 	{
	// 		BusinessFetch.Post('/notifications/read', {_where: {staff: user._id}}).then(response =>
	// 		{
	// 			if(response.status === 200)
	// 			{
	// 				dispatch({type: 'LOAD_NOTIFICATIONS', notifications: response.data || []});
	// 			}
	// 		});
	// 	}
	// }, [BusinessFetch, business._id, user._id]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (notification) =>
				{
					console.log('aaaaa create', notification);
					dispatch({type: 'CREATE_NOTIFICATION', notification});
				}
			},
			{
				name: `MULTIPLE_UPDATE_${unique}`,
				Function: (notifications) =>
				{
					console.log('aaaaa u', notifications);
					dispatch({type: 'MULTIPLE_UPDATE_NOTIFICATION', notifications})
				}
			}
		];

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket]);

	const workingNotifications = React.useMemo(() =>
	{
		return state.notifications.filter(notification => notification.sentDate <= date).sort((a, b) => b.sentDate.getTime() - a.sentDate.getTime());
	}, [state.notifications, date]);

	const notSeen = React.useMemo(() =>
	{
		return workingNotifications.reduce((cnt, notification) => (cnt + (notification.viewFlag ? 0 : 1)), 0);
	}, [workingNotifications]);

	const ViewNotifications = React.useCallback(async () =>
	{
		if(notSeen > 0)
		{
			const data = {_socket: room}
	
			return await BusinessFetch.Post('/notifications/view', data);
		}
	}, [BusinessFetch, room, notSeen]);

	// const PushSubscription = React.useCallback(() =>
	// {

	// }, []);

	const value =
	{
		code: state.code,
		notifications: workingNotifications,
		notSeen,
		ViewNotifications
	}

	return <NotificationsContext.Provider value={value} {...props}/>
}

const useNotifications = () => React.useContext(NotificationsContext);

export {NotificationsProvider, useNotifications};