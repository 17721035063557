import React, { useMemo } from "react";
import { Section } from 'crack-ux';

// Components
import { ProductCard } from "./ProductCard";

// Contexts
import { useProducts } from "../../../../../contexts/collections/commerce/Products";
import { useItems } from "../../../../../contexts/collections/storage/Items";

// Styles
import './ProductGrid.css';

const ProductsGrid = (props) =>
{
	const { filter } = props;

	const { products } = useProducts();
	const { itemsById } = useItems();

	const filteredProducts = useMemo(() =>
	{
		return products.filter(product =>
		{
			// Filter by categories
			const categoryMatch = !filter.categories.length || product.categories.some(category => filter.categories.includes(category));
	
			// Filter by search term
			const searchTerm = filter.search.toLowerCase();
			const searchMatch = !filter.search || product.name.toLowerCase().includes(searchTerm) || (product.description?.toLowerCase()?.includes(searchTerm) || false);
	
			return categoryMatch && searchMatch;
		});
	}, [products, filter]);
		
	const availableProducts = useMemo(() =>
	{
		return filteredProducts.filter(product => product.available).map(product =>
		{
			product.pictureURL = itemsById[product.pictures[0]]?.publicURL || '/assets/box.png';

			return product;
		});
	}, [filteredProducts, itemsById])

	return (
		<Section class='products-grid vertical-scroll' style={{height: 'calc(100% - var(--header))'}}>
			{availableProducts.map(product => <ProductCard key={product._id} product={product} {...props} />)}
		</Section>
	)
}

export { ProductsGrid };