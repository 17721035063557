import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import {Generic} from 'crack-functions';

// Contexts
import {useBusiness} from '../layouts/Business';

// Reducers
import {ModulesReducer} from '../../reducers/main/Modules';

const Modules = createContext({});

const ModulesProvider = (props) =>
{
	const [state, dispatch] = useReducer(ModulesReducer, {modules: []});
	const [currentModule, setCurrentModule] = useState({});

	const {business, path, BusinessFetch} = useBusiness();

	const GetModules = useCallback(async () =>
	{
		try
		{
			if(business?._id)
			{
				const response = await BusinessFetch.Get('/modules');

				if(response.status === 200)
				{
					dispatch({type: 'LOAD_MODULES', modules: response.data});
				}
			}
		}
		catch(error)
		{
			console.log('error', error);
		}

	}, [business, BusinessFetch]);

	useEffect(() =>
	{
		GetModules();
	}, [GetModules]);

	useEffect(() =>
	{
		if(!currentModule._id)
		{
			const module = state.modules.find(module => module.route === path.module);

			if(module)
			{
				setCurrentModule(module);
			}
		}
	}, [path.module, state.modules, currentModule._id]);

	const modulesByName = useMemo(() => Generic.ArrayToObjectByIdentifier(state.modules, 'route'), [state.modules]);

	const value =
	{
		modules: state.modules,
		modulesByName,
		currentModule,
		setCurrentModule,
	}

	return <Modules.Provider value={value} {...props}/>
}

const useModules = () => useContext(Modules);

export {ModulesProvider, useModules};