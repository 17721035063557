import { useCallback, useEffect, useMemo, useState } from "react";
import { Content, Panel, Header, Body, Footer, Card, Text, Button, Icon, Box, ButtonGroup, Form } from 'crack-ux';
import { Generic, Amount } from 'crack-functions';
import moment from 'moment';

// Contexts
import { useGeneral } from "../../../../contexts/general/General";
import { useTraductor } from "../../../../contexts/general/Traductor";
import { useModal } from "../../../../contexts/general/Modal";
import { useBusiness } from "../../../../contexts/layouts/Business";
import { useLists } from "../../../../contexts/collections/home/Lists";
import { useCRShifts } from "../../../../contexts/collections/commerce/CRShifts";
import { useMovements } from "../../../../contexts/collections/commerce/Movements";

// Components
import { ProductFilter } from "../components/ProductsFilter";
import { ProductsGrid } from "../components/ProductsGrid";
import { ProductsDetail } from "../components/ProductsDetail";
import { VoucherPreview } from "../components/VoucherPreview";
import { CloseCashRegisterForm } from "../CashRegisters/CloseCashRegisterForm";

// Styles
import "./SalesPoint.css";

const buttonOptions =
[
	{ icon: 'icon-sliders', view: 'filter' },
	{ icon: 'icon-list-alt', view: 'detail' },
	{ icon: 'icon-table', view: 'products' },
];

const defaultFilter =
{
	categories: [],
	search: ''
}

const defaultSale =
{
	date: moment().format('YYYY-MM-DD'),
	paymentAmount: 0,
}

const SalesPoint = () =>
{
	const [shiftForm, setShiftForm] = useState({});
	const [productsSelected, setProductsSelected] = useState({});
	const [filter, setFilter] = useState(defaultFilter);
	const [view, setView] = useState('products');
	const [memberShifts, setMemberShifts] = useState([]);
	const [messages, setMessages] = useState({});

	const { screen } = useGeneral();
	const { AddModal } = useModal();
	const { member } = useBusiness();
	const { Translate } = useTraductor();
	const { listsByName } = useLists();
	const { crShifts, ReadMemberShifts, OpenCRShift } = useCRShifts();
	const { code, movementForm, setMovementForm } = useMovements();

	const cashRegisters = useMemo(() => listsByName['cash-registers']?.workingItems || [], [listsByName]);
	const categories = useMemo(() => listsByName['product-categories']?.workingItems || [], [listsByName]);
	const currentShift = useMemo(() => memberShifts.find(shift => shift.status === 1), [memberShifts]);
	const products = useMemo(() => Generic.ObjectToArray(productsSelected), [productsSelected]);

	const cashRegistersAvailable = useMemo(() =>
	{
		const usedRegisters = crShifts.filter(shift => shift.status === 1).map(shift => shift.cashRegisterId);
	
		return cashRegisters.filter(register => !usedRegisters.includes(register._id));
	}, [crShifts, cashRegisters]);

	const paymentMethods = useMemo(() => listsByName['payment-methods'] || {}, [listsByName]);
	const movementTypes = useMemo(() => listsByName['stock-movement-types'] || {}, [listsByName]);

	const total = useMemo(() =>
	{
		let total = 0

		if(products.length > 0)
		{
			products.forEach(product =>
			{
				total = total + Amount.CalculateTotal({quantity: product.amount, price: product.salePrice, tax: 0});
			});
		}
		
		return total;
	}, [products]);

	const GetConditionalPrice = useCallback((product, quantity) =>
	{
		let specialPrice = product.price.sale;
	
		if(product.price?.conditional?.length && movementForm.type)
		{
			const validConditions = product.price.conditional.filter(condition => condition.type === movementForm.type && quantity >= condition.quantity );
	
			const bestCondition = validConditions.reduce((best, current) =>
			{
				return (!best || current.quantity > best.quantity) ? current : best;
			}, null);
	
			if(bestCondition)
			{
				specialPrice = bestCondition.price;
			}
		}
	
		return specialPrice;
	}, [movementForm.type]);

	const AddProduct = useCallback((product) =>
	{
		const cloneProductsSelected = {...productsSelected};
	
		if(product.quantity.current > 0 && (!cloneProductsSelected[product._id] || cloneProductsSelected[product._id].amount < product.quantity.current))
		{
			if(cloneProductsSelected[product._id])
			{
				cloneProductsSelected[product._id].amount++;
			}
			else
			{
				cloneProductsSelected[product._id] =
				{
					...product,
					amount: 1,
					salePrice: product.price.sale
				}
			}
	
			const newAmount = cloneProductsSelected[product._id].amount;
			cloneProductsSelected[product._id].salePrice = GetConditionalPrice(product, newAmount);
		
			setProductsSelected(cloneProductsSelected);
		}
		else
		{
			window.CrackUX.Toasts.AddToast(
			{
				class: 'info',
				message: Translate('product-not-stock'),
			});
		}
	}, [productsSelected, Translate, GetConditionalPrice]);

	const RemoveProduct = useCallback((product) =>
	{
		if(productsSelected[product._id]?.amount)
		{
			const cloneProductsSelected = {...productsSelected};
	
			if(cloneProductsSelected[product._id].amount > 1)
			{
				cloneProductsSelected[product._id].amount--;
	
				const newAmount = cloneProductsSelected[product._id].amount;
				cloneProductsSelected[product._id].salePrice = GetConditionalPrice(product, newAmount);
			}
			else
			{
				delete cloneProductsSelected[product._id];
			}
	
			setProductsSelected(cloneProductsSelected);
		}
	}, [productsSelected, GetConditionalPrice]);

	const ClearProduct = useCallback((product) =>
	{
		const cloneProductsSelected = {...productsSelected};
		delete cloneProductsSelected[product._id];

		setProductsSelected(cloneProductsSelected);
	}, [productsSelected]);

	const UpdatePrice = (product, price) =>
	{
		if(productsSelected[product._id])
		{
			const cloneProductsSelected = {...productsSelected};
			cloneProductsSelected[product._id].salePrice = price;
	
			setProductsSelected(cloneProductsSelected);
		}
	}

	const UpdateAmount = (product, amount) =>
	{
		if(productsSelected[product._id])
		{
			const cloneProductsSelected = {...productsSelected};
			
			const validAmount = amount <= cloneProductsSelected[product._id].quantity.current ? amount : cloneProductsSelected[product._id].quantity.current;
			cloneProductsSelected[product._id].amount = validAmount;
	
			cloneProductsSelected[product._id].salePrice = GetConditionalPrice(product, validAmount);
	
			setProductsSelected(cloneProductsSelected);
		}
	}

	const ClearAll = () =>
	{
		setProductsSelected({});
		setMovementForm(defaultSale);
		setView('products');
	}

	const ResetFilter = () =>
	{
		setFilter(defaultFilter);
	};

	const HandleSubmit = () =>
	{
		setMovementForm({...movementForm, paymentAmount: total});

		AddModal(<VoucherPreview
			from = 'sales'
			products = {products}
			total = {total}
			ClearAll = {ClearAll}
			currentShift = {currentShift}
		/>);
	}

	const FetchMemberShifts = useCallback(async () =>
	{
		if(member._id)
		{
			const responseMemberShifts = await ReadMemberShifts(member._id);
			setMemberShifts(responseMemberShifts);
		}
	}, [ReadMemberShifts, member._id]);

	const HandleOpenShift = async () =>
	{
		const errors = {};

		if(!shiftForm.cashRegister) errors.cashRegister = Translate('field-required');
		if(!shiftForm.openingCash) errors.openingCash = Translate('field-required');

		setMessages(errors);

		if(Object.keys(errors).length > 0) return;

		const shift =
		{
			cashRegisterId: shiftForm.cashRegister,
			memberId: member._id,
			openingCash: shiftForm.openingCash
		}

		const response = await OpenCRShift(shift);

		if(response.status === 200)
		{
			FetchMemberShifts();
		}
	}

	const HandleCloseShift = async () =>
	{
		AddModal(<CloseCashRegisterForm currentShift={currentShift} FetchMemberShifts={FetchMemberShifts} setShiftForm={setShiftForm}/>);
	}

	useEffect(() =>
	{
		FetchMemberShifts();
	}, [FetchMemberShifts]);

	useEffect(() =>
	{
		const newMovementForm = {...movementForm, ...defaultSale};
		let updatedType = false;
		let updatedPaymentMethod = false;

		if(!movementForm.type && movementTypes.items?.length > 0)
		{
			newMovementForm.type = movementTypes.items.find(type => type.internalName === 'sales')?._id;
			updatedType = true;
		}

		if(!movementForm.paymentMethodId && paymentMethods.items?.length > 0)
		{
			newMovementForm.paymentMethodId = paymentMethods.items.find(method => method.internalName === 'cash')?._id;
			updatedPaymentMethod = true;
		}

		if(updatedType || updatedPaymentMethod)
		{
			setMovementForm(newMovementForm);
		}
	}, [paymentMethods, movementForm, movementTypes, setMovementForm]);

	if(!currentShift)
	{
		return (
			<Content class='commerce-sales-point relative'>
				<Box class='full-center'>
					<Card style={{width: 300}}>
						<Header>
							<Text class='bold'>{Translate('open-shift')}</Text>
						</Header>
						<Body>
							<Form
								inputs =
								{[
									{
										name: 'cashRegister',
										text: Translate('cash-register'),
										type: 'select',
										options: cashRegistersAvailable.map(cashRegister => ({value: cashRegister._id, text: cashRegister.text})),
										message: messages.cashRegister
									},
									{
										name: 'openingCash',
										text: Translate('opening-cash'),
										type: 'number',
										message: messages.openingCash
									},
									{
										name: 'openDate',
										text: Translate('opening-date'),
										value: moment().format('DD-MM-YYYY HH:mm'),
										disabled: true
									}
								]}
								form = {shiftForm}
								setForm = {setShiftForm}
							/>
						</Body>
						<Footer>
							<Button class='bg-blue white full-width' text = {Translate('open')} Click={HandleOpenShift}/>
						</Footer>
					</Card>
				</Box>
			</Content>
		)
	}
	else
	{
		return (
			<Content class='commerce-sales-point'>
				<Panel class='auto-height'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('sales-point')}: {`MOVE-${code + 1}`}</Text>
							<Box class='float-right'>
								{screen.device === 'mobile' && buttonOptions.map(button =>
								{
									return (
										<Button key={button.view} class={`float-right ${view === button.view ? 'bg-blue white' : 'default'}`} style={{ marginTop: -7, marginLeft: 5 }}>
											<Icon class={`${button.icon} no-margin`} Click={() => setView(button.view)} />
										</Button>
									)
								})}
							</Box>
						</Header>
					</Card>
				</Panel>
				<Body class='no-padding' style={{height: `calc(100% - var(--header) - var(--footer) - 10px)`}}>
					{(view === 'filter' || screen.device !== 'mobile') &&
					<Panel class='desktop2 full-height'>
						<ProductFilter
							categories = {categories}
							filter = {filter}
							setFilter = {setFilter}
							ResetFilter = {ResetFilter}
						/>
					</Panel>}
					{(view === 'products' || screen.device !== 'mobile') &&
					<Panel class='desktop6 full-height'>
						<ProductsGrid
							productsSelected = {productsSelected}
							AddProduct = {AddProduct}
							RemoveProduct = {RemoveProduct}
							ClearProduct = {ClearProduct}
							type = 'sales'
							filter = {filter}
						/>
					</Panel>}
					{(view === 'detail' || screen.device !== 'mobile') &&
					<Panel class='desktop4 full-height'>
						<ProductsDetail
							productsSelected = {productsSelected}
							UpdatePrice = {UpdatePrice}
							UpdateAmount = {UpdateAmount}
							AddProduct = {AddProduct}
							ClearProduct = {ClearProduct}
							from = 'sales'
						/>
					</Panel>}
				</Body>
				{view !== 'filter' &&
				<Panel class='auto-height'>
					<Card>
						<Footer>
							<Text class='bold' size={1.5}>Total:</Text>
							<Text size={1.5} style={{marginLeft: 5}}>{Amount.CurrencyFormat(total)}</Text>
							<Box class='float-right' style={{marginTop: -5}}>
								{(view === 'products' && screen.device === 'mobile') &&
									<Button text={Translate('next')} Click={() => setView('detail')}/>
								}
								{view === 'detail' &&
								<ButtonGroup
									actions =
									{[
										{
											text: Translate('save'),
											class: 'bg-blue white',
											Click: HandleSubmit,
											disabled: products.length === 0
										},
										{
											text: Translate('previous'),
											Click: () => setView('products')
										},
										{
											text: Translate('clear-all'),
											Click: ClearAll
										},
										{
											text: Translate('close-shift'),
											class: 'bg-red white',
											Click: HandleCloseShift
										}
									]}
								/>}
								{screen.device !== 'mobile' &&
								<>
									<Button class='bg-blue white' text={Translate('save')} Click={HandleSubmit} disabled={products.length === 0}/>
									<Button text={Translate('clear-all')} Click={ClearAll}/>
									<Button class='bg-red white' text={Translate('close-shift')} Click={HandleCloseShift}/>
								</>}
							</Box>
						</Footer>
					</Card>
				</Panel>}
			</Content>
		)
	}
}

export { SalesPoint };