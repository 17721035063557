import { ToggleContent, Form } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';

const GeneralForm = (props) =>
{
	const { generalForm, setGeneralForm, messages } = props;

	const { Translate } = useTraductor();

	return (
		<ToggleContent text={Translate('general')}>
			<Form
				inputs =
				{[
					{
						name: 'websiteVisibility',
						text: Translate('website-visibility'),
						type: 'switch',
						value: generalForm.websiteVisibility,
						message: messages.websiteVisibility,
					},
					{
						name: 'businessName',
						text: Translate('business-name'),
						value: generalForm.businessName,
						message: messages.businessName,
					},
					{
						name: 'logo',
						text: Translate('logo'),
						type: 'file',
						value: generalForm.logo,
						message: messages.logo,
					},
					{
						name: 'primaryColor',
						text: Translate('primary-color'),
						class: 'desktop6',
						type: 'color',
						value: generalForm.primaryColor,
						message: messages.primaryColor,
					},
					{
						name: 'secondaryColor',
						text: Translate('secondary-color'),
						type: 'color',
						class: 'desktop6',
						value: generalForm.secondaryColor,
						message: messages.secondaryColor,
					},
				]}
				form = {generalForm}
				setForm = {setGeneralForm}
			/>
		</ToggleContent>
	)
}

export { GeneralForm };