import { Section, Content, Card, Panel, Header, Body, Text, Button, Box, Footer, Image } from 'crack-ux';

const WhyUs = () =>
{
	return (
		<Section class='why-us'>
			<Content class='desktop relative center'>
				<Box class='content'>
					<Text class='title'>¿Tu software actual no se adapta a tu negocio?</Text>
					<Text class='subtitle'>SICE se adapta a ti. Evoluciona contigo.</Text>
					<Panel class='tablet6'>
						<Card>
							<Header>
								<Text>ANTES</Text>
							</Header>
							<Body>
								<Image src='/sice-page/sad-computer.png' alt='Antes'/>
							</Body>
							<Footer>
								<Text>Procesos manuales</Text>
								<Text>Reportes en Excel</Text>
								<Text>Pérdida de tiempo</Text>
							</Footer>
						</Card>
					</Panel>
					<Panel class='tablet6'>
						<Card style={{ background: '#eaf7ff' }}>
							<Header>
								<Text>AHORA</Text>
							</Header>
							<Body>
								<Image src='/sice-page/happy-computer.png' alt='Ahora'/>
							</Body>
							<Footer>
								<Text>Automatización de procesos</Text>
								<Text>Reportes en tiempo real</Text>
								<Text>Control total desde cualquier lugar</Text>
							</Footer>
						</Card>
					</Panel>
					<Button class='cta' text='Quiero que mi software se adapte a mí'/>
				</Box>
			</Content>
		</Section>
	)
}

export { WhyUs };