import { Route, Routes } from 'react-router-dom';

// Pages
import { IntegrationList } from './IntegrationList';
import { Whatsapp } from './Whatsapp';

const Integrations = (props) =>
{
	return (
		<Routes>
			<Route path='/whatsapp' element={<Whatsapp/>}/>
			<Route path='/' element={<IntegrationList/>}/>
		</Routes>
	)
}

export { Integrations }