import { Amount } from 'crack-functions';
import moment from "moment";

const SetMovements = (movement) =>
{
	movement.codeText = `MOVE-${movement.code}`;
	movement.dateTimeText = moment(movement.date).format('DD/MM/YYYY hh:mm A');
	movement.dateText = moment(movement.date).format('DD/MM/YYYY');
	movement.timeText = moment(movement.date).format('hh:mm A');
	movement.dateObj = moment(movement.date);
	movement.totalText = Amount.CurrencyFormat(movement.total);
	movement.paymentsTotal = movement.payments.reduce((total, payment) => total + payment.amount, 0);
	movement.paymentsTotalText = Amount.CurrencyFormat(movement.paymentsTotal);
	movement.discountText = Amount.CurrencyFormat(movement.discount);
	movement.balance = movement.total - movement.discount - movement.paymentsTotal;
	movement.balanceText = Amount.CurrencyFormat(movement.balance);

	return movement;
}

const MovementsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_MOVEMENTS':
			return {
				...state,
				movements: action.movements.map(movement => SetMovements(movement))
			}

		case 'CREATE_MOVEMENT':
			return {
				...state,
				movements: [SetMovements(action.movement), ...state.movements],
				code: state.code + 1
			}

		case 'UPDATE_MOVEMENT':
			return {
				...state,
				movements: state.movements.map(movement => movement._id === action.movement._id ? SetMovements(action.movement) : movement)
			}

		case 'DELETE_MOVEMENT':
		{
			return {
				...state,
				movements: state.movements.filter(movement => movement._id !== action.movement._id)
			}
		}

		case 'SET_LATEST_MOVEMENT_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {MovementsReducer};