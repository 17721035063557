import moment from 'moment';
import { Amount } from 'crack-functions';

const SetCRShift = (crShift) =>
{
	crShift.statusText = crShift.status === 1 ? 'Open' : 'Closed';
	crShift.openingDateText = moment(crShift.openingDate).format('DD/MM/YYYY HH:mm:ss');
	crShift.closingDateText = crShift.closingDate ? moment(crShift.closingDate).format('DD/MM/YYYY HH:mm:ss') : '';
	crShift.openingCashText = Amount.CurrencyFormat(crShift.openingCash);

	return crShift;
}

const CRShiftsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_CR_SHIFTS':
			return {
				...state,
				crShifts: action.crShifts.map(crShift => SetCRShift(crShift))
			}

		case 'CREATE_CR_SHIFT':
			return {
				...state,
				crShifts: [SetCRShift(action.crShift), ...state.crShifts],
				code: state.code + 1
			}

		case 'UPDATE_CR_SHIFT':
			return {
				...state,
				crShifts: state.crShifts.map(crShift => crShift._id === action.crShift._id ? SetCRShift(action.crShift) : crShift)
			}

		case 'DELETE_CR_SHIFT':
		{
			return {
				...state,
				crShifts: state.crShifts.filter(crShift => crShift._id !== action.crShift._id)
			}
		}

		case 'SET_LATEST_CR_SHIFT_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export { CRShiftsReducer };