import { useMemo } from 'react';
import { Section, Header, Body, Text, Table } from 'crack-ux';
import { Amount } from 'crack-functions';
import { Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

// Contexts
import { useTraductor } from '../../../../../contexts/general/Traductor';
import { useSession } from '../../../../../contexts/general/Session';

// Static Data
import Colors from '../../../../../static-data/Colors.json';

Chart.register(...registerables);

const NTopProductsPie = (props) =>
{
	const { products, filter } = props;

	const { Translate } = useTraductor();
	const { user } = useSession();

	const labels = products.map(item => item.product?.name || Translate('unknown-product'));
	const dataValues = products.map(item => item.totalRevenue);

	const options = useMemo(() =>
	{
		const totalRevenue = dataValues.reduce((sum, value) => sum + value, 0);

		const result =
		{
			plugins:
			{
				title:
				{
					display: true,
					text: `${Translate('top')} ${filter.products} ${Translate('products')}`,
				},
				tooltip:
				{
					callbacks:
					{
						label: (tooltipItem) =>
						{
							const value = tooltipItem.raw;
							const percentage = ((value / totalRevenue) * 100).toFixed(2);
							return `Bs. ${value} (${percentage}%)`;
						}
					}
				}
			},
			responsive: true,
			maintainAspectRatio: false,
			interaction:
			{
				mode: 'index',
				intersect: false,
			}
		};

		return result;
	}, [dataValues, filter.products, Translate]);

	return (
		<Section>
			<Header>
				<Text class='title'>{Translate('top-products-by-revenue')}</Text>
			</Header>
			<Body style={{height: 700}}>
				<Pie
					data =
					{{
						labels: labels,
						datasets:
						[
							{
								label: `${Translate('revenues')} (Bs)`,
								data: dataValues,
								backgroundColor: Object.values(Colors.background).reduce((acc, color) => [...acc, color], []),
								borderColor: Object.values(Colors.border).reduce((acc, color) => [...acc, color], []),
								borderWidth: 1,
							}
						]
					}}
					options = {options}
				/>
			</Body>
			<Body class='no-padding-horizontal no-padding-bottom'>
				<Table
					header = 
					{[
						{
							name: 'name',
							text: Translate('product-name')
						},
						{
							name: 'revenueText',
							text: Translate('revenue')
						},
						{
							name: 'unitsSold',
							text: Translate('units-sold')
						},
						{
							name: 'averageText',
							text: Translate('average-price')
						}
					]}
					body = {products.map(product =>
					{
						const averagePrice = product.totalQuantity > 0 ? product.totalRevenue / product.totalQuantity : 0;
						product.name = product.product?.name || Translate('unknown-product');
						product.revenueText = Amount.CurrencyFormat(product.totalRevenue);
						product.unitsSold = product.totalQuantity;
						product.averageText = Amount.CurrencyFormat(averagePrice);

						return product;
					})}
					searcher = {false}
					filters = {false}
					pager =
					{{
						rows: 1000
					}}
					language = {user.preferences?.language || 'es'}
				/>
			</Body>
		</Section>
	)
}

export { NTopProductsPie };