import React from "react";
import {Card, Header, Body, Footer, Text, Form, Button} from 'crack-ux';
import {Dates} from 'crack-functions';
import moment from "moment";

// Context
import {useTraductor} from "../../../../../contexts/general/Traductor";

const SalesFilters = (props) =>
{
	const {filter, SetFilter, defaultFilter, periodOptions} = props;

	const [localFilter, setLocalFilter] = React.useState({...filter});

	const {Translate} = useTraductor();

	React.useEffect(() =>
	{
		setLocalFilter({...filter});
	}, [filter]);

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('filter')}</Text>
			</Header>
			<Body style={{height: 'calc(100% - var(--header) - var(--footer))'}}>
				<Form
					inputs =
					{[
						{
							name: 'period',
							text: Translate('period'),
							type: 'select',
							options: periodOptions,
							value: localFilter.period,
							Change: (response) =>
							{
								let startDate = '';
								let endDate = '';

								if(response.value === 'current-month')
								{
									startDate = moment().startOf('month');
									endDate = moment().endOf('month');
								}

								if(response.value === 'current-quarter')
								{
									startDate = moment().startOf('quarter');
									endDate = moment().endOf('quarter');
								}
								
								if(response.value === 'current-year')
								{
									startDate = moment().startOf('year');
									endDate = moment().endOf('year');
								}

								setLocalFilter(
								{
									period: response.value,
									startDate,
									endDate
								});
							}
						},
						{
							name: 'startDate',
							text: Translate('start-date'),
							type: 'date',
							value: Dates.GetDateForForm(localFilter.startDate),
							Change: (date) => setLocalFilter(current => ({...current, period: 'custom', startDate: moment(date)}))
						},
						{
							name: 'endDate',
							text: Translate('end-date'),
							type: 'date',
							value: Dates.GetDateForForm(localFilter.endDate),
							Change: (date) => setLocalFilter(current => ({...current, period: 'custom', endDate: moment(date)}))
						}
					]}
				/>
			</Body>
			<Footer class='float-right'>
				<Button class='bg-blue white' text={Translate('apply')} Click={() => SetFilter(localFilter)}/>
				<Button
					text = {Translate('clear-all')}
					Click = {() =>
					{
						setLocalFilter({...defaultFilter});
						SetFilter({...defaultFilter});
					}}/>
			</Footer>
		</Card>
	)
}

export {SalesFilters};