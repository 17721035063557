import { Section } from 'crack-ux';

// Components
import { Hero } from './Hero';

// Styles
import './Home2.css';

const Home2 = () =>
{
	return (
		<Section class='sice-home'>
			<Hero/>
		</Section>
	)
}

export { Home2 };