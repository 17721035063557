import { Route, Routes } from 'react-router-dom';
import { Products } from './Products';


const ProductsRouter = () =>
{
	return (
		<Routes>
			<Route path='/:categoryName/:categoryId' element={<Products/>}/>
			<Route path='/' element={<Products/>}/>
		</Routes>
	)
}

export { ProductsRouter as Products };