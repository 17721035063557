import { Route, Routes } from "react-router-dom";

// Pages
import { Members } from "./Members";

const MembersRouter = () =>
{
	return (
		<Routes>
			<Route path="/:memberName/:memberId" element={<Members/>} />
			<Route path="/" element={<Members/>} />
		</Routes>
	);
}

export { MembersRouter as Members };