import React from "react";
import {Content, Card, Panel, Header, Text, Button, Avatar, Footer, Section} from 'crack-ux';

// Crack-Function
import {Text as TextF} from 'crack-functions';

// Functions
import {useTraductor} from "../../../contexts/general/Traductor";

// Components
import {ProfileForm} from "../../../components/forms/Profile/Profile";

// Context
import {useSession} from "../../../contexts/general/Session";
import {useUsers} from "../../../contexts/main/Users";
import {ProfileProvider, useProfile} from "../../../contexts/pages/personal/Profile";
import {UsersProvider} from "../../../contexts/main/Users";

const ProfileBody = (props) =>
 {
    const {displayProfileForm} = useProfile();
    const {user} = useSession();
 
	if(displayProfileForm)
	{
		return <ProfileForm user={user} key='editable'/>
	}
	else
	{
        return <ProfileForm user={user} disabled={true} key='notEditable'/>
	}
}

const FooterDisplayProfileForm = () =>
{
    const {displayProfileForm, SetDisplayProfileForm} = useProfile();
    const {Translate} = useTraductor();

    if(!displayProfileForm)
    {
        return (
            <Footer class='right'>
                <Button 
                    class = 'bg-blue white' 
                    text = {displayProfileForm ? Translate('Cancel Edit') : Translate('Edit Info')}
                    Click = {()=> displayProfileForm ? SetDisplayProfileForm(false) : SetDisplayProfileForm(true)}
                />
            </Footer>
        )
    }
}

const Profile = (props) =>
{
    const {user} = useSession();
    const {ShowPasswordForm} = useProfile();
    const {UpdateUser} = useUsers();
    const {Translate} = useTraductor();

	return (
		<Content class='no-padding full-height'>
			<Panel class='full-height no-padding'>
				<Panel class='desktop4 center full-height'>
                    <Card>
                        <Avatar
							size = {218}
                            fontSize = {128}
                            style={{marginTop: 78}}
                            src= {user?.pictureURL || TextF.Initials(user?.name || '')}
                            Change= {(picture) => 
                            {
                                const data =
                                {
                                    _files:
                                    {
                                        picture: [picture]
                                    },
                                    _id: user._id
                                }

                                UpdateUser(data)
                            }}
                        ></Avatar>
                        <Text class='block bold' style={{marginTop: 35, fontSize:24}}>{user.name}</Text>
                        <Text class='block' style={{marginTop: 5, marginBottom: 10, fontSize:24}}>@{user.user}</Text>
                        <Section>
							<Button class='bg-blue white' text={Translate('Change Password')} Click={() => {ShowPasswordForm()}}/>
						</Section>
                    </Card>
                </Panel>
                <Panel class='desktop8 full-height left' >
                    <Card>
                        <Header class='bold'>
                            <Text>{Translate('Profile Info')}</Text>
                        </Header>
                        <ProfileBody/>
                        <FooterDisplayProfileForm/>
                    </Card>
                </Panel>
			</Panel>
		</Content>
	)
}

const UsersWithProviders = () =>
{
	return (
			<UsersProvider>
                <ProfileProvider>
				    <Profile/>
                </ProfileProvider>
			</UsersProvider>
	)
}

export {UsersWithProviders as Profile};