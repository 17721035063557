const SetClient = (client) =>
{
	client.codeText = `CLIE-${client.code}`;
	client.phonesText = client.phones.map(phone => `(+${phone.country}) ${phone.number}`).join(', ');
	client.emailsText = client.emails.map(email => email.address).join(', ');
	client.discountText = `${client.discount || 0}%`;

	return client;
}

const ClientsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_CLIENTS':
			return {
				...state,
				clients: action.clients.map(client => SetClient(client))
			}

		case 'CREATE_CLIENT':
			return {
				...state,
				clients: [...state.clients, SetClient(action.client)],
				code: state.code + 1
			}

		case 'UPDATE_CLIENT':
			return {
				...state,
				clients: state.clients.map(client => client._id === action.client._id ? SetClient(action.client) : client)
			}

		case 'DELETE_CLIENT':
		{
			return {
				...state,
				clients: state.clients.filter(client => client._id !== action.client._id)
			}
		}

		case 'SET_LATEST_CLIENT_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {ClientsReducer};