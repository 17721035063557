import React from 'react';
import {Card, Header, Body, Footer, Text, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useModal} from '../../../../contexts/general/Modal';
import {useContacts} from '../../../../contexts/collections/crm/Contacts';
import {useCompanies} from '../../../../contexts/collections/crm/Companies';

const UnlinkContact = (props) =>
{
	const {contact} = props;

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	const {SimpleUpdate} = useContacts();
	const {currentCompany} = useCompanies();

	const HandleUnlinkContact = () =>
	{
		if(contact?._id && currentCompany?._id && Array.isArray(contact.companies))
		{
			const index = contact.companies.findIndex(companyId => companyId === currentCompany._id);

			if(index !== -1)
			{
				const companies = [...contact.companies];

				companies.splice(index, 1);

				const data =
				{
					_id: contact._id,
					companies
				};
		
				SimpleUpdate(data).then(response =>
				{
					if(response.status === 200)
					{
						CloseModal();
					}
				})
			}
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('Unlink Contact')}</Text>
			</Header>
			<Body>
				<Text>{Translate('Are you sure you want to unlink the next contact?')}</Text>
				<Text class='block'>{contact.name || ''} {contact.lastName || ''}</Text>
			</Body>
			<Footer class='right'>
				<Button class='bg-blue white' text={Translate('Continue')} Click={HandleUnlinkContact}/>
				<Button text={Translate('Cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export {UnlinkContact};