import { useEffect } from 'react';
import { Tabs, Icon } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useMembers } from '../../../../contexts/collections/staff/Members';

// Components
import { Permissions } from './Permissions';

const MemberTabs = () =>
{
	const { screen } = useGeneral();
	const { Translate } = useTraductor();
	const { members, currentMember, setCurrentMember } = useMembers();

	const navigate = useNavigate();
	const params = useParams();

	const { memberId } = params;

	useEffect(() =>
	{
		const member = members.find(member => member._id === memberId);

		setCurrentMember(member);
	}, [members, memberId, setCurrentMember]);

	if(!currentMember) return null;

	return(
		<Tabs
			current = {1}
			tabs=
			{[
				...(screen.device === 'mobile') ?
				[{
					name: <Icon class='icon-angle-left'/>,
					Click: () => navigate('../')
				}] : [],
				{
					name: Translate('permissions'),
					content: <Permissions/>
				},
			]}
		/>
	)
}

export {MemberTabs};