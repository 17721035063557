import {Icon} from 'crack-ux';
import {Generic} from 'crack-functions';
import moment from 'moment';

const defaultImages =
{
	audio: '/assets/audio.png',
	excel: '/assets/excel.png',
	pdf: '/assets/pdf.png',
	ppt: '/assets/ppt.png',
	video: '/assets/video.png',
	word: '/assets/word.png',
	noPreview: '/assets/no-preview.jpg',
};

const GetSize = (item) =>
{
	let result = '';

	if(item.file)
	{
		let value = item.file.size || 0;
		let XB = '';
		const KB = 1024;
		const MB = KB * 1024;
		const GB = MB * 1024;

		if(value > GB)
		{
			value /= GB;
			XB = 'GB';
		}
		else if(value > MB)
		{
			value /= MB;
			XB = 'MB';
		}
		else if(value > KB)
		{
			value /= KB;
			XB = 'KB';
		}
		else
		{
			XB = 'Bytes';
		}

		result = `${value.toFixed(2)} ${XB}`;
	}
	else
	{
		result = `0 items`;
	}

	return result;
}

const GetExtension = (item) =>
{
	let extension = null;

	if(item.file)
	{
		const fileArray = item.file.filename.split('.');
		extension = fileArray[(fileArray.length - 1)];
	}

	return extension;
}

const GetProps = (item) =>
{
	const icon =
	{
		style: item.readOnly ? {color: '#999999'} : {color: '#444444'}
	};

	let defaultURL = defaultImages.noPreview;
	let extension = '';

	icon.class = 'icon-folder';

	if(item.file)
	{
		extension = GetExtension(item);

		if(extension === 'pdf')
		{
			icon.class = 'icon-file-pdf';
			defaultURL = defaultImages.pdf;
		}
		else if(extension === 'docx' || extension === 'doc')
		{
			icon.class = 'icon-file-word';
			defaultURL = defaultImages.word;
		}
		else if(extension === 'xlsx' || extension === 'xls')
		{
			icon.class = 'icon-file-excel';
			defaultURL = defaultImages.excel;
		}
		else if(extension === 'pptx' || extension === 'ppt')
		{
			icon.class = 'icon-file-powerpoint';
			defaultURL = defaultImages.ppt;
		}
		else if(["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp", "tiff", "ico", "heic"].indexOf(extension) !== -1)
		{
			icon.class = 'icon-file-image';
			defaultURL = defaultImages.noPreview;
		}
		else if(['mp3', 'ogg', 'flac', 'wma'].indexOf(extension) !== -1)
		{
			icon.class = 'icon-file-audio';
			defaultURL = defaultImages.audio;
		}
		else if(['mp4', 'avi', 'flv'].indexOf(extension) !== -1)
		{
			icon.class = 'icon-file-video';
			defaultURL = defaultImages.video;
		}
		else if(["zip", "rar", "7z", "tar", "gz"].indexOf(extension) !== -1)
		{
			icon.class = 'icon-file-archive';
			defaultURL = defaultImages.noPreview;
		}
		else if(["txt"].indexOf(extension) !== -1)
		{
			icon.class = 'icon-doc-text';
			defaultURL = defaultImages.noPreview;
		}
		else
		{
			icon.class = 'icon-attention';
		}
	}

	return {icon, defaultURL, extension};
}

const SetItem = (item) =>
{
	const {icon, defaultURL, extension} = GetProps(item);

	item.extension = extension;
	item.defaultURL = defaultURL;
	item.typeIcon = <Icon {...icon}/>;
	item.dateCreatedText = moment(new Date(item.dateCreated)).format('DD/MM/YYYY');
	item.sizeText = GetSize(item);
	item.src = item.file ? item.publicURL : null;
	item.fullName = `${item.name}${item.extension ? `.${item.extension}`: ''}`;
	item.type = item.file ? 'file' : 'folder';

	if(item.isPublic)
	{
		item.publicURLIcon = <Icon class='icon-docs white bg-blue' style={{padding: 5}} Click={() => Generic.CopyToClipboard(item.publicURL)}/>
	}
	else
	{
		item.publicURLIcon = <></>
	}

	return item;
}

const ItemsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_ITEMS':
		{
			return {
				...state,
				items: action.items.map(item => SetItem(item)),
				itemsById: action.items.reduce((acc, item) => ({...acc, [item._id]: SetItem(item)}), {}),
			}
		}

		case 'CREATE_ITEM':
			return {
				...state,
				items: [...state.items, SetItem(action.item)],
				itemsById: {...state.itemsById, [action.item._id]: SetItem(action.item)}
			}

		case 'UPDATE_ITEM':
			return {
				...state,
				items: state.items.map(item => item._id === action.item._id ? SetItem(action.item) : item),
				itemsById: {...state.itemsById, [action.item._id]: SetItem(action.item)}
			}

		case 'DELETE_ITEM':
		{
			return {
				...state,
				items: state.items.filter(item => item._id !== action.item._id),
				itemsById: Object.keys(state.itemsById).reduce((acc, key) => key !== action.item._id ? {...acc, [key]: state.itemsById[key]} : acc, {})
			}
		}

		default:
			return state;
	}
}

export {ItemsReducer};