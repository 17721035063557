import {Content, Header, Body, Text} from 'crack-ux';

// Contexts
import {useBusinessPage} from '../../../../contexts/pages/public/BusinessPage';

// Components
import {Review} from '../components/Review';

const Reviews = () =>
{
	const {business, reviews} = useBusinessPage();

	const {page = {}} = business;
	const {reviews: reviewsConf = {}} = page;

	return (
		<Content class='reviews desktop'>
			<Header class='center no-border'>
				<Text class='title' type='p'>{reviewsConf.title}</Text>
				<Text class='caption' type='p'>{reviewsConf.caption}</Text>
			</Header>
			<Body class='no-padding'>
				{reviews.map(review => <Review key={review._id} {...review} />)}
			</Body>
		</Content>
	);
}

export {Reviews}