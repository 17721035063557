import React, { useMemo, useState } from 'react';
import { Card, Header, Body, Footer, Form, Button, Text } from 'crack-ux';

// Context
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useLists } from '../../../../contexts/collections/home/Lists';

// Reducers
import { defaultListColor, listsWithColor } from '../../../../reducers/collections/Lists';

// Functions
import Validations from '../../../../utilities/Validate';

const ItemForm = ({ item, internalName }) =>
{
	const [itemForm, setItemForm] = useState(item || {color: defaultListColor});
	const [messages, setMessages] = useState({});

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();

	const {AddItem, UpdateItem} = useLists();

	const HandleSubmit = async () =>
	{
		const itemData = {...itemForm};

		if(!listsWithColor.includes(internalName))
		{
			delete itemData.color;
		}

		const result = itemForm._id ? await UpdateItem(internalName, itemData) : await AddItem(internalName, itemData);

		setMessages(Validations.ParseByField(result.validations));

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	const specificInputs = useMemo(() =>
	{
		const result = [];

		if(listsWithColor.includes(internalName))
		{
			result.push
			({
				name: 'color',
				message: messages.color,
				text: Translate('Color'),
				value: itemForm.color,
				type: 'color'
			});
		}

		switch(internalName)
		{
			case 'sales-stage':
				result.push
				({
					name: 'exclusionPoint',
					text: Translate('exclusion-point'),
					value: itemForm.data?.exclusionPoint || false,
					type: 'checkbox',
				});
			break;

			default:
			break;
		}

		return result;
	}, [internalName, itemForm, messages, Translate]);

	return (
		<Card style={{maxWidth: 400}}>
			<Header>
				<Text class='bold'>{Translate(`${itemForm._id ? 'edit' : 'new'}-${internalName}`)}</Text>
			</Header>
			<Body>
				<Form
					inputs =
					{
						[
							{
								name: 'text',
								text: Translate('item-name'),
								value: itemForm.text,
								message: messages.text
							},
							...specificInputs
						]
					}
					form = {itemForm}
					setForm = {setItemForm}
					Submit = {HandleSubmit}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('save')}
					class = 'bg-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export { ItemForm };