const SetBusiness = (business) =>
{
	business.phoneText = business?.phone?.number ? `+${business.phone.code} ${business.phone.number}` : '';
	business.locationText = business?.location?.address ? business.location.address : '';
	business.whatsapps = business.whatsapps || [];

	return business;
}

const SetWhatsapp = (whatsapp) =>
{
	whatsapp.phoneText = `${whatsapp.country ? `(${whatsapp.country}) ` : ''}${whatsapp.number || ''}`;

	return whatsapp;
}

const BusinessReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_BUSINESSES':
			return {
				...state,
				businesses: action.businesses.map(business => SetBusiness(business)),
			}

		case 'CREATE_BUSINESS':
			return {
				...state,
				businesses: [...state.business, SetBusiness(action.business)],
			}

		case 'UPDATE_BUSINESS':
			return {
				...state,
				businesses: state.business.map(business => business._id === action.business._id ? SetBusiness(action.business) : business)
			}

		case 'DELETE_BUSINESS':
			return {
				...state,
				businesses: state.business.filter(business => business._id !== action.business._id)
			}

		case 'LOAD_WHATSAPPS':
			return {
				...state,
				businesses: state.businesses.map(business => business._id === action.businessId ? {...business, whatsapps: action.whatsapps.map(whatsapp => SetWhatsapp(whatsapp))} : business)
			}

		case 'CREATE_WHATSAPP':
			return {
				...state,
				businesses: state.businesses.map(business => business._id === action.businessId ? {...business, whatsapps: [...business.whatsapps, SetWhatsapp(action.whatsapp)]} : business)
			}

		case 'UPDATE_WHATSAPP':
			return {
				...state,
				businesses: state.businesses.map(business => business._id === action.businessId ? {...business, whatsapps: business.whatsapps.map(whatsapp => whatsapp._id === action.whatsapp._id ? SetWhatsapp(action.whatsapp) : whatsapp)} : business)
			}

		case 'DELETE_WHATSAPP':
			return {
				...state,
				businesses: state.businesses.map(business => business._id === action.businessId ? {...business, whatsapps: business.whatsapps.filter(whatsapp => whatsapp._id !== action.whatsapp._id)} : business)
			}

		default:
			return state;
	}
}

export {BusinessReducer};