import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../layouts/Business';
import {useCompanies} from './Companies';
import {useContacts} from './Contacts';

// Reducers
import {ScheduledMessagesReducer} from '../../../reducers/collections/crm/ScheduledMessages';

const ScheduledMessagesContext = React.createContext({});

const ScheduledMessagesProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(ScheduledMessagesReducer, {scheduledMessages: []});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {BusinessFetch, business} = useBusiness();

	const {companies} = useCompanies();
	const {contacts} = useContacts();

	const room = React.useMemo(() => `crm_scheduled_messages_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `CRM_SCHEDULED_MESSAGE_${eventId}` : 'CRM_SCHEDULED_MESSAGE';
	}, [eventId]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (scheduledMessage) => dispatch({type: 'CREATE_SCHEDULED_MESSAGE', scheduledMessage})
			},
			{
				name: `UPDATE_${unique}`,
				Function: (scheduledMessage) => dispatch({type: 'UPDATE_SCHEDULED_MESSAGE', scheduledMessage})
			},
			{
				name: `DELETE_${unique}`,
				Function: (scheduledMessage) => dispatch({type: 'DELETE_SCHEDULED_MESSAGE', scheduledMessage})
			}
		];

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket]);

	const GetFilteredContacts = React.useCallback((filters = {}, mode = 'full') =>
	{
		const {contact = {}, company = {}} = filters;

		// testing
		// BusinessFetch.Post('/crm/filtered-contacts/read', {filters}).then(response => console.log({response}));

		let companyIds = [];
		const tags = contact?.tags || [];
		const genders = contact?.genders || [];
		const minAge = contact?.age?.min || 0;
		const maxAge = contact?.age?.max || 0;
		const emptyAge = !!contact?.emptyAge;
		const emptyGender = !!contact?.emptyGender;
		const salesFlows = contact?.salesFlows || [];
		const emptySalesFlow = !!contact?.emptySalesFlow;
		const economicActivities = company.economicActivities || [];
		const locationStates = Array.isArray(company.location?.states) ? company.location.states.map(state => new RegExp(state, 'i')) : [];
		const emptyEconomicActivities = !!company?.emptyEconomicActivities;

		// get companies ids
		const companyConditions = [];

		if(economicActivities.length > 0)
		{
			companyConditions.push(company =>
			{
				if(Array.isArray(company?.economicActivities))
				{
					if(company.economicActivities.length > 0)
					{
						return economicActivities.some(economicActivity => company.economicActivities.indexOf(economicActivity) !== -1);
					}
				}
				else
				{
					return economicActivities.indexOf(company.economicActivities) !== -1;
				}

				return false;
			});
		}

		if(locationStates.length > 0)
		{
			companyConditions.push(company => typeof company.location?.state === 'string' && locationStates.some(state => state.test(company.location.state)));
		}

		const filteredCompanies = companies.filter(company => companyConditions.every(Fn => Fn(company)));

		if(Array.isArray(filteredCompanies))
		{
			companyIds = filteredCompanies.map(filteredCompanyData => filteredCompanyData._id);
		}
		// end get companies ids

		// phone filter
		const conditions =
		[
			contact => !!contact?.phones?.[0]?.phone && !!contact?.phones?.[0]?.number
		];

		// company filter
		if(economicActivities.length > 0 || locationStates.length > 0)
		{
			conditions.push(contact =>
			{
				if(Array.isArray(contact.companies) && contact.companies.length > 0)
				{
					return contact.companies.some(company => companyIds.indexOf(company) !== -1);
				}

				return emptyEconomicActivities;
			});
		}

		// tags filter
		if(tags.length > 0)
		{
			conditions.push(contact =>
			{
				if(Array.isArray(contact.tags) && contact.tags.length > 0)
				{
					return contact.tags.some(tag => tags.indexOf(tag) !== -1);
				}

				return false;
			});
		}

		// sales flow filter
		if(salesFlows.length > 0)
		{
			conditions.push(contact =>
			{
				if(Array.isArray(contact.salesFlow) && contact.salesFlow.length > 0)
				{
					return salesFlows.includes(contact.salesFlow.slice(-1)[0]);
				}

				return emptySalesFlow;
			});
		}

		// genders filter
		if(genders.length > 0)
		{
			conditions.push(contact => contact.gender ? genders.indexOf(contact.gender) !== -1 : emptyGender);
		}

		// min age
		if(minAge > 0)
		{
			conditions.push(contact => contact.age ? contact.age >= minAge : emptyAge);
		}

		// max age
		if(maxAge > 0)
		{
			conditions.push(contact => contact.age ? contact.age <= maxAge : emptyAge);
		}

		const filtered = contacts.filter(contact => conditions.every(Fn => Fn(contact)));

		return mode === 'number' ? filtered.length : filtered;
	}, [companies, contacts]);

	const LoadScheduledMessages = React.useCallback((scheduledMessages = []) =>
	{
		dispatch({type: 'LOAD_SCHEDULED_MESSAGES', scheduledMessages});
	}, []);

	const CreateScheduledMessage = async (scheduledMessage) =>
	{
		scheduledMessage._socket = room;

		return await BusinessFetch.Post('/crm/scheduled-message/create', scheduledMessage);
	}

	const UpdateScheduledMessage = async (scheduledMessage) =>
	{
		scheduledMessage._socket = room;

		return await BusinessFetch.Post('/crm/scheduled-message/update', scheduledMessage);
	}

	const DeleteScheduledMessage = async (scheduledMessage) =>
	{
		scheduledMessage._socket = room;

		return await BusinessFetch.Post('/crm/scheduled-message/delete', scheduledMessage);
	}

	const value =
	{
		code: state.code,
		scheduledMessages: state.scheduledMessages,
		SetEventId: setEventId,
		LoadScheduledMessages,
		CreateScheduledMessage,
		UpdateScheduledMessage,
		DeleteScheduledMessage,
		GetFilteredContacts,
	}

	return <ScheduledMessagesContext.Provider value={value} {...props}/>
}

const useScheduledMessages = () => React.useContext(ScheduledMessagesContext);

export {ScheduledMessagesProvider, useScheduledMessages};