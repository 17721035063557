import {Text as TextF} from 'crack-functions';

const memberStatus = ['Inactive', 'Active'];

const SetMember = (member) =>
{
	member.nameText = member.name;
	member.lastNameText = member.lastName;
	member.fullName = TextF.FullName(member.name, member.lastName);
	member.emailText = member.email;
	member.statusText = memberStatus[member.status];
	member.invitation = member.user ? 'accepted' : 'sent';

	return member;
}

const MembersReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_MEMBERS':
			return {
				...state,
				members: action.members.map(member => SetMember(member)),
				membersObj: action.members.reduce((obj, member) => ({...obj, [member._id]: SetMember(member)}), {})
			}

		case 'CREATE_MEMBER':
			return {
				...state,
				members: [...state.members, SetMember(action.member)],
				membersObj: {...state.membersObj, [action.member._id]: SetMember(action.member)}
			}

		case 'UPDATE_MEMBER':
			return {
				...state,
				members: state.members.map(member => member._id === action.member._id ? SetMember(action.member) : member),
				membersObj: {...state.membersObj, [action.member._id]: SetMember(action.member)}
			}

		case 'DELETE_MEMBER':
			return {
				...state,
				members: state.members.filter(member => member._id !== action.member._id),
				membersObj: Object.keys(state.membersObj).reduce((obj, key) => key !== action.member._id ? {...obj, [key]: state.membersObj[key]} : obj, {})
			}

		default:
			return state;
	}
}

export {MembersReducer, SetMember};