import {Generic} from 'crack-functions';

const channels = require('../../../static-data/Channels.json');

const channelsById = Generic.ArrayToObjectByIdentifier(channels, 'id');
const whatsappId = channels.find(channel => channel.value === 'whatsapp').id;

const SetMessage = (message) =>
{
	if(message.channel === whatsappId)
	{
		message.channelSent = !!message?.whatsapp?.id;
	}

	message.channelValue = channelsById[message.channel] !== undefined ? channelsById[message.channel].value === 'landingPageAPI' ? 'web' : channelsById[message.channel].value : 'default';
	message.errorURL = '/assets/no-preview.jpg';
	message.sentDateObj = new Date(message.sendDate);
	message.finishedChat = message.chatStatus === 1; // finished chat

	return message;
}

const MessagesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_LAST_MESSAGES':
		{
			const contactsMessages = {};

			if(Array.isArray(action.messages))
			{
				action.messages.forEach(message =>
				{
					if(!contactsMessages[message.contact]) contactsMessages[message.contact] = [];

					if(contactsMessages[message.contact].length === 0) contactsMessages[message.contact].push(SetMessage(message));
				});
			}

			return {
				...state,
				contactsMessages
			}
		}

		case 'LOAD_CONTACT_MESSAGES':
		{
			return {
				...state,
				contactsMessages:
				{
					...state.contactsMessages,
					[action.contactId]: action.messages.map(message => SetMessage(message))
				},
			}
		}

		case 'CREATE_MESSAGE':
		{
			return {
				...state,
				contactsMessages:
				{
					...state.contactsMessages,
					[action.message.contact]:
					[
						...(state.contactsMessages[action.message.contact] || []),
						SetMessage(action.message)
					]
				}
			}
		}

		case 'UPDATE_MESSAGE':
		{
			return {
				...state,
				contactsMessages:
				{
					...state.contactsMessages,
					[action.message.contact]:
					[
						...(state.contactsMessages[action.message.contact] ? state.contactsMessages[action.message.contact].map(message => message._id === action.message._id ? SetMessage(action.message) : message) : [SetMessage(action.message)])
					]
				}
			}
		}

		case 'DELETE_MESSAGE':
		{
			return {
				...state,
				contactsMessages:
				{
					...state.contactsMessages,
					[action.message.contact]:
					[
						...(state.contactsMessages[action.message.contact] ? state.contactsMessages[action.message.contact].filter(message => message._id !== action.message._id) : [])
					]
				}
			}
		}

		default:
			return state;
	}
}

export {MessagesReducer};