import { useMemo, useState } from 'react';
import { Table, Confirm, Text } from 'crack-ux';

// Contexts
import { useModal } from '../../../../contexts/general/Modal';
import { useSession } from '../../../../contexts/general/Session';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useTemplates } from '../../../../contexts/collections/crm/Templates';

// Components
import { TemplateForm } from './TemplateForm';

const TemplatesTable = ({ channel }) =>
{
	const [template, setTemplate] = useState({});
	const [showConfirm, setShowConfirm] = useState(false);

	const { AddModal } = useModal();
	const { Translate } = useTraductor();
	const { user } = useSession();
	const { templates, DeleteTemplate } = useTemplates();
	
	const templatesFiltered = useMemo(() =>
	{
		return templates.filter(template => template.channel === channel);
	}, [templates, channel])

	return (
		<>
			<Table
				header =
				{[
					{
						width: 80,
						name: 'codeText',
						text: Translate('code')
					},
					{
						name: 'name',
						text: Translate('template-name'),
					},
					{
						name: 'contentText',
						text: Translate('content'),
						title: 'contentText',
					}
				]}
				body = {templatesFiltered}
				actions =
				{[
					{
						text: Translate(`new-${channel}-template`),
						class: 'bg-blue white',
						section: 'grouped',
						Click: () => AddModal(<TemplateForm channel={channel}/>)
					},
					{
						text: Translate(`edit-${channel}-template`),
						icon:
						{
							class: 'icon-pencil'
						},
						section: 'context',
						Click: (template) => AddModal(<TemplateForm template={template} channel={channel}/>)
					},
					{
						text: Translate(`delete-${channel}-template`),
						class: 'red',
						icon:
						{
							class: 'icon-trash'
						},
						section: 'context',
						Click: (template) =>
						{
							setTemplate(template);
							setShowConfirm(true);
						}
					}
				]}
				filters = 
				{{
					columns:
					{
						editable: true
					},
					Apply:
					{
						text: Translate('Apply'),
						Click: () => {}
					},
					Reset:
					{
						text: Translate('Reset'),
						Click: () => {}
					}
				}}
				language={user.preferences?.language || 'es'}
			/>
			<Confirm
				show={showConfirm}
				header={<Text class='bold'>{Translate(`delete-${channel}-template`)}</Text>}
				body=
				{
					<>
						<Text type='p'>{Translate(`confirm-delete-${channel}-template`)}</Text>
						<Text type='p'><span className='bold'>{Translate('template')}: </span>{template.name}</Text>
					</>
				}
				confirm=
				{{
					text: Translate('delete'),
					class: 'bg-red white',
					Click: () =>
					{
						DeleteTemplate(template);
						setShowConfirm(false);
					}
				}}
				cancel=
				{{
					text: Translate('cancel'),
					Click: () => setShowConfirm(false)
				}}
			/>
		</>
	)
};

export {TemplatesTable};