import { useCallback, useEffect, useMemo, useState } from 'react';
import { Section, Card, Chat as ChatUX, Header, Body, Text, Select, Avatar, Box } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useBusiness } from '../../../../contexts/main/Business';
import { useWhatsappIntegration } from '../../../../contexts/integrations/Whatsapp';
import { useMessages } from '../../../../contexts/collections/crm/Messages';

// Components
import { BackButton } from '../../../../components/BackButton';

const Chat = (props) =>
{
	const { contact = {}, messages = [] } = props;

	const [outboundOption, setOutboundOption] = useState({});
	const [inboundOption, setInboundOption] = useState({});

	const { Translate } = useTraductor();
	const { currentBusiness } = useBusiness();
	const { whatsappsIntegration } = useWhatsappIntegration();
	const { SendMessage } = useMessages();
	
	const params = useParams();
	const navigate = useNavigate();

	const { contactId } = params;

	const outboundOptions = useMemo(() =>
	{
		const whatsappChannels = currentBusiness.whatsapps.filter(whatsapp => whatsappsIntegration[whatsapp._id]?.status === 'connected').map(whatsapp =>
		{
			return {
				text: whatsapp.label,
				value: whatsapp._id,
				data:
				{
					...whatsapp,
					channel: 1
				}
			}
		});

		return [
			...whatsappChannels
		]
	}, [currentBusiness.whatsapps, whatsappsIntegration]);

	const inboundOptions = useMemo(() => 
	{
		if (outboundOption.data?.channel === 1 && contact.phones && contact.phones.length > 0) 
		{
			return contact.phones.map(phone => (
			{
				text: `(${phone.country}) ${phone.number}`, 
				value: phone._id, 
				data: phone
			}));
		}

		return [];
	}, [outboundOption, contact]);

	const HandleSendMessage = useCallback((data) =>
	{
		if(contactId && outboundOption.data.channel)
		{
			const messageData =
			{
				channel: outboundOption.data.channel,
				transmiter: true,
				sendDate: moment(),
				message: data.message,
				contact: contactId,
			}

			if(messageData.channel === 1)
			{
				messageData.whatsapp =
				{
					id: outboundOption.value
				};
				messageData.whatsappId = outboundOption.value;
				messageData.phone = inboundOption.data.phone;
			}

			SendMessage(messageData);
		}
	}, [contactId, outboundOption, inboundOption, SendMessage]);

	useEffect(() =>
	{
		if(outboundOptions.length > 0 && !outboundOption.value)
		{
			setOutboundOption(outboundOptions[0]);
		}
	}, [outboundOptions, outboundOption]);

	useEffect(() =>
	{
		if(inboundOptions.length > 0 && !inboundOption.value)
		{
			setInboundOption(inboundOptions[0]);
		}
	}, [inboundOptions, inboundOption]);

	return (
		<Card class='crm-chat'>
			<Header>
				<Section class='info'>
					<BackButton Click={() => navigate('../')}/>
					<Box class='pointer' Click={() => navigate(`../${contact.name}/${contact._id}/info`)}>
						<Avatar class='bg-blue white round' src={contact.initials}/>
						<Text class='bold'>{contact.fullName}</Text>
					</Box>
				</Section>
				<Section class='channels'>
					<Select
						class='inline-block'
						text={Translate('outbound-channel')}
						options={outboundOptions}
						selected={outboundOption.value}
					/>
					<Select
						class='inline-block'
						text={Translate('inbound-channel')}
						options={inboundOptions}
						selected={inboundOption.value}
					/>
				</Section>
			</Header>
			<Body class='no-padding'>
				<ChatUX
					messages={messages.map(message =>
					{
						return {
							position: message.transmiter ? 'right' : 'left',
							type: 'text',
							content: {message: message.message},
							date: message.sendDate,
							icons: [],
						}
					})}
					SendButton=
					{{
						Click: HandleSendMessage
					}}
					language='es'
					dateFormat='DD/MM/YYYY'
				/>
			</Body>
		</Card>
	);
}

export { Chat };