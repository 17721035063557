import React from 'react';
import {Card, Panel, Header, Body, Footer, Form, Text, Button, FileList2} from 'crack-ux';
import {Dates, Generic} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useModal} from '../../../../../contexts/general/Modal';
import {useSession} from '../../../../../contexts/general/Session';
import {useBusiness} from '../../../../../contexts/layouts/Business';
import {useSystemVariables} from '../../../../../contexts/collections/home/SystemVariables';
import {useCRM} from '../../../../../contexts/modules/CRM';
import {useLists} from '../../../../../contexts/collections/home/Lists';
import {useScheduledMessages} from '../../../../../contexts/collections/crm/ScheduledMessages';
import {useTemplates} from '../../../../../contexts/collections/crm/Templates';
import {useContacts} from '../../../../../contexts/collections/crm/Contacts';
import {useItems} from '../../../../../contexts/collections/storage/Items';

// Functions
import Validations from '../../../../../utilities/Validate';

const GetContactFilters = filters =>
{
	const result =
	{
		genders: [],
		emptyGenderFlag: false,
		minAge: 0,
		maxAge: 0,
		emptyAgeFlag: false,
		salesFlows: [],
		emptySalesFlowFlag: false,

		companyEconomicActivities: [],
		emptyEconomicActivitiesFlag: false,
		locationStates: [],
	};

	if(Array.isArray(filters?.contact?.tags)) result.tags = filters.contact.tags;
	if(Array.isArray(filters?.contact?.genders)) result.genders = filters.contact.genders;
	if(typeof filters?.contact?.emptyGender === 'boolean') result.emptyGenderFlag = filters.contact.emptyGender;
	if(filters?.contact?.age?.min > 0) result.minAge = filters.contact.age.min;
	if(filters?.contact?.age?.max > 0) result.maxAge = filters.contact.age.max;
	if(typeof filters?.contact?.emptyAge === 'boolean') result.emptyAgeFlag = filters.contact.emptyAge;
	if(Array.isArray(filters?.contact?.salesFlows)) result.salesFlows = filters.contact.salesFlows;
	if(typeof filters?.contact?.emptySalesFlow === 'boolean') result.emptySalesFlowFlag = filters.contact.emptySalesFlow;

	if(Array.isArray(filters?.company?.economicActivities)) result.companyEconomicActivities = filters.company.economicActivities;
	if(typeof filters?.company?.emptyEconomicActivities === 'boolean') result.emptyEconomicActivitiesFlag = filters.company.emptyEconomicActivities;
	if(Array.isArray(filters?.company?.location?.states)) result.locationStates = filters.company.location.states;

	return result;
}

const GetFiltersFromForm = (filters, tags) =>
{
	const result =
	{
		contact:
		{
			tags,
			genders: filters.genders,
			emptyGender: filters.emptyGenderFlag,
			age:
			{
				min: filters.minAge,
				max: filters.maxAge
			},
			emptyAge: filters.emptyAgeFlag,
			salesFlows: filters.salesFlows,
			emptySalesFlow: filters.emptySalesFlowFlag
		},
		company:
		{
			economicActivities: filters.companyEconomicActivities,
			emptyEconomicActivities: filters.emptyEconomicActivitiesFlag,
			location:
			{
				states: filters.locationStates
			}
		}
	};

	return result;
}

const noPhone = {text: 'No Selected', value: 'no-selected'};
const shippingInterval = Array.from({length: 4}, (_, i) => (i + 1) * 15);
const defaultShippingInterval = 30;
const states = ['Beni', 'Cochabamba', 'La Paz', 'Oruro', 'Pando', 'Potosí', 'Santa Cruz', 'Sucre', 'Tarija'];
const statesOptions = states.map(state => ({text: state, value: state}));

const ScheduledMessageForm = (props) =>
{
	const {scheduledMessage, channel, contact, type, base} = props;

	const [preventUpdateRecipients, setPreventUpdateRecipients] = React.useState(!!scheduledMessage._id);
	const [form, setForm] = React.useState
	({
		...scheduledMessage,
		date: Dates.GetDateForForm(scheduledMessage.date),
		time: Dates.GetTimeForForm(scheduledMessage.date),
		businessPhone: scheduledMessage.businessPhone?.[0] || 'no-selected'
	});
	const [contactFilters, setContactFilters] = React.useState(GetContactFilters(scheduledMessage.filters));
	const [companyFilters, setCompanyFilters] = React.useState(GetContactFilters(scheduledMessage.filters));
	const [files, setFiles] = React.useState([]);
	const [attachedData, setAttachedData] = React.useState([]);
	const [attachedFlag, setAttachedFlag] = React.useState(false);
	const [messages, setMessages] = React.useState({});
	const [tags, setTags] = React.useState(Array.isArray(scheduledMessage.filters?.contact?.tags) ? scheduledMessage.filters.contact.tags : []);
	const [message, setMessage] = React.useState(scheduledMessage.message || '');
	const [whatsappPhones, setWhatsappPhones] = React.useState([]);
	const [filteredContacts, setFilteredContacts] = React.useState([]);
	const [filteredRecipients, setFilteredRecipients] = React.useState(null);

	const {user} = useSession();
	const {BusinessFetch, business} = useBusiness();
	const {VariableReplacer} = useSystemVariables();

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();

	const {ShowScheduledMessageContacts} = useCRM();
	const {CreateScheduledMessage, UpdateScheduledMessage, GetFilteredContacts} = useScheduledMessages();
	const {listsByName} = useLists();
	const {templates} = useTemplates();
	const {contacts, contactsTagsOptions} = useContacts();
	const {storagesById} = useItems();

	React.useEffect(() =>
	{
		if(!attachedFlag)
		{
			if(Array.isArray(scheduledMessage.files))
			{
				setAttachedData(scheduledMessage.files.map(file => storagesById[file]).filter(file => file));
			}

			setAttachedFlag(true);
		}
	}, [storagesById, attachedFlag, scheduledMessage.files]);

	const CheckFilteredContacts = React.useCallback((filters, updateRecipients = true) =>
	{
		const filtered = GetFilteredContacts(filters);

		setFilteredContacts(filtered);

		if(updateRecipients)
		{
			setFilteredRecipients(null);
		}
	}, [GetFilteredContacts]);

	const contactsObj = React.useMemo(() => Generic.ArrayToObjectByIdentifier(contacts), [contacts]);

	React.useEffect(() =>
	{
		if(preventUpdateRecipients)
		{
			setFilteredRecipients(Array.isArray(scheduledMessage.recipients) && scheduledMessage.recipients.length > 0 ? scheduledMessage.recipients.map(item => contactsObj[item] || {_id: item}) : null)
		}
	}, [preventUpdateRecipients, scheduledMessage.recipients, contactsObj]);

	React.useEffect(() =>
	{
		BusinessFetch.Get('/whatsapp/alive-phones').then(response =>
		{
			if(response.status === 200)
			{
				setWhatsappPhones(response.data || []);

				if(!response.data.find(phone => phone._id === scheduledMessage.businessPhone?.[0]))
				{
					setForm(current => ({...current, businessPhone: 'no-selected'}));
				}
			}
		});
	}, [BusinessFetch, scheduledMessage.businessPhone]);

	React.useEffect(() =>
	{
		const joinedFilters = {...contactFilters, ...companyFilters};

		const filters = GetFiltersFromForm(joinedFilters, tags);

		CheckFilteredContacts(filters, !preventUpdateRecipients);
	}, [contactFilters, companyFilters, tags, preventUpdateRecipients, CheckFilteredContacts]);

	const HandleSubmit = async () =>
	{
		if(Array.isArray(filteredRecipients) && filteredRecipients?.length === 0) return;

		const date = base ? Dates.GetDateForForm(new Date()) : form.date;

		const scheduledMessageData =
		{
			...form,
			channel: channel.id,
			type: type.id,
			date: Dates.GetDateFromForm(date, form.time),
			message,
			files: [...files, ...attachedData].map(file =>
			{
				const cloneFile = {_id: file._id, localFile: file.localFile};
	
				if(cloneFile.localFile)
				{
					cloneFile.replaceFile = true;
				}
	
				return cloneFile;
			}),
			filters: GetFiltersFromForm({...contactFilters, ...companyFilters}, tags),
			contact
		};

		if(base && !scheduledMessage._id)
		{
			scheduledMessageData.base = base;
		}

		if(Array.isArray(filteredRecipients))
		{
			scheduledMessageData.recipients = filteredRecipients.map(item => item._id);
		}
		else
		{
			scheduledMessageData['$unset'] = {recipients: 1};
		}

		if(!scheduledMessageData.businessPhone || scheduledMessageData.businessPhone === 'no-selected')
		{
			delete scheduledMessageData.businessPhone;
		}
		else
		{
			scheduledMessageData.businessPhone = [scheduledMessageData.businessPhone];
		}

		scheduledMessageData._files =
		{
			scheduled: files.map(file => file.localFile)
		};

		const result = scheduledMessage._id ? await UpdateScheduledMessage({...scheduledMessageData, _id: scheduledMessage._id}) : await CreateScheduledMessage(scheduledMessageData);

		setMessages(Validations.ParseByField(result.validations));

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	const AddNewContactTag = React.useCallback(tag =>
	{
		if
		(
			typeof tag === 'string'
			&&
			tag.trim().length > 0
			&&
			tags.indexOf(tag) === -1
		)
		{
			setTags(current => [...current, tag]);
		}
	}, [tags]);

	const LoadTemplate = React.useCallback(response =>
	{
		const templateFound = templates.find(template => template._id === response.value);

		if(templateFound)
		{
			if(contact)
			{
				const contactFound = contacts.find(item => item._id === contact);

				setMessage(templateFound.content ? VariableReplacer(templateFound.content, {business, contact: contactFound, user: user}, 'crm-contact') : '');
			}
			else
			{
				setMessage(templateFound.content);
			}

			setAttachedData(Array.isArray(templateFound.files) ? templateFound.files.map(fileId => storagesById[fileId]).filter(file => file) : []);
		}
	}, [templates, contacts, contact, business, user, VariableReplacer, storagesById]);

	const templatesOptions = React.useMemo(() =>
	{
		return templates.filter(template => template.channel === 'whatsapp').map(template =>
		({
			text: template.name,
			value: template._id
		}));
	}, [templates]);

	const businessPhones = React.useMemo(() => [...whatsappPhones.map(phone => ({text: `+${phone.country} ${phone.number}`, value: phone._id})), noPhone], [whatsappPhones]);
	const shippingIntervalOptions = React.useMemo(() => shippingInterval.map(interval => ({text: `${Translate('Each')} ${interval} ${Translate('seconds')}`, value: interval})), [Translate]);

	const panelClass = React.useMemo(() => contact ? '' : 'desktop6', [contact]);
	const width = React.useMemo(() => contact ? 600 : 1200, [contact]);
	const inputs = React.useMemo(() =>
	{
		const result =
		[
			{
				name: 'title',
				text: Translate('Title'),
				message: messages.title,
				value: form.title,
				class: 'desktop8',
			},
			{
				name: 'businessPhone',
				text: Translate('Business Phone'),
				value: form.businessPhone,
				type: 'select',
				options: businessPhones,
				class: 'desktop4',
				message: messages.businessPhone
			},
			{
				name: 'date',
				text: Translate('Date'),
				message: messages.date,
				value: form.date,
				class: contact ? 'desktop6' : 'desktop4',
				type: 'date',
			},
			{
				name: 'time',
				text: Translate('Hour'),
				value: form.time,
				class: contact ? 'desktop6' : 'desktop4',
				type: 'time',
			},
			{
				name: 'loadTemplate',
				text: Translate('Load Template'),
				value: form.loadTemplate,
				type: 'select',
				options: templatesOptions,
				Change: LoadTemplate
			},
			{
				name: 'message',
				text: Translate('Message'),
				value: message,
				rows: 16,
				type: 'textarea',
				Change: setMessage
			},
			{
				text: Translate('Files'),
				type: 'content',
				content: (
					<FileList2
						fileList = {files}
						dataList = {attachedData}
						Change = {response =>
						{
							setFiles(response.files);
							setAttachedData(response.data);
						}}
					/>
				)
			},
		];

		if(!contact)
		{
			result.splice(4, 0, {
				name: 'shippingInterval',
				text: Translate('Shipping Interval'),
				value: form.shippingInterval || defaultShippingInterval,
				type: 'select',
				options: shippingIntervalOptions,
				class: 'desktop4',
				message: messages.shippingInterval
			});

			if(type.value === 'birthday')
			{
				result.splice(2, 1, {
					name: 'date',
					text: Translate('Date'),
					value: Translate('Every birthday'),
					class: 'desktop4',
					disabled: true,
				});
			}
		}

		return result;
	}, [contact, type, LoadTemplate, Translate, businessPhones, files, form, message, messages, shippingIntervalOptions, templatesOptions, attachedData]);

	return (
		<Card>
			<Panel class='no-padding' style={{height: 745, maxHeight: 'calc(100vh - 67px - 40px)', width, maxWidth: 'calc(100vw - 40px)'}} swipe='horizontal'>
				<Panel class={`${panelClass} full-height no-padding`}>
					<Header>
						<Text class='bold'>{Translate(`${scheduledMessage._id ? 'Edit' : 'Create'} ${type.value === 'birthday' ? 'Birthday Message' : 'Scheduled Message'}`)}</Text>
					</Header>
					<Body class='vertical-scroll' style={{height: 'calc(100% - var(--header))'}}>
						<Form
							inputs = {inputs}
							Change = {setForm}
						/>
					</Body>
				</Panel>
				{!contact ? <Panel class='desktop6 full-height no-padding'>
					<Header>
						<Text class='bold'>{Translate('Filters')}</Text>
					</Header>
					<Body class='no-padding vertical-scroll' style={{height: 'calc(100% - var(--header))'}}>
						<Header>
							<Text class='semi-bold'>{Translate('Contact')}</Text>
						</Header>
						<Body>
							<Form
								inputs =
								{[
									{
										name: 'genders',
										text: Translate('Gender'),
										value: contactFilters.genders,
										type: 'autocomplete',
										class: 'desktop6',
										multiple: true,
										options: listsByName.gender?.selectOptions,
										Change: () => setPreventUpdateRecipients(false)
									},
									{
										name: 'emptyGenderFlag',
										text: Translate('Include Empty Gender'),
										value: contactFilters.emptyGenderFlag,
										type: 'checkbox',
										class: 'desktop6',
										Change: () => setPreventUpdateRecipients(false)
									},
									{
										name: 'minAge',
										text: Translate('Min Age'),
										type: 'number',
										message: messages.minAge,
										value: contactFilters.minAge,
										class: 'desktop3',
										Change: () => setPreventUpdateRecipients(false),
									},
									{
										name: 'maxAge',
										text: Translate('Max Age'),
										type: 'number',
										message: messages.maxAge,
										value: contactFilters.maxAge,
										class: 'desktop3',
										Change: () => setPreventUpdateRecipients(false),
									},
									{
										name: 'emptyAgeFlag',
										text: Translate('Include Empty Age'),
										value: contactFilters.emptyAgeFlag,
										type: 'checkbox',
										class: 'desktop6',
										Change: () => setPreventUpdateRecipients(false),
									},
									{
										name: 'tagsInput',
										text: Translate('Type a Tag'),
										value: contactFilters.tagsInput,
										type: 'autocomplete',
										options: contactsTagsOptions,
										Change: (response) =>
										{
											if(response?.value)
											{
												AddNewContactTag(response.value);
												setPreventUpdateRecipients(false);
											}
										}
									},
									{
										name: 'tags',
										text: Translate('Tags'),
										value: tags,
										type: 'tags',
										Change: value =>
										{
											setTags(value);
											setPreventUpdateRecipients(false);
										}
									},
									{
										name: 'salesFlows',
										text: Translate('Sale Stages'),
										value: contactFilters.salesFlows,
										type: 'autocomplete',
										class: 'desktop6',
										multiple: true,
										options: listsByName.salesFlow?.selectOptions,
										Change: () => setPreventUpdateRecipients(false)
									},
									{
										name: 'emptySalesFlowFlag',
										text: Translate('Include Empty Sale Stages'),
										value: contactFilters.emptySalesFlowFlag,
										type: 'checkbox',
										class: 'desktop6',
										Change: () => setPreventUpdateRecipients(false),
									},
								]}
								Change = {setContactFilters}
							/>
						</Body>
						<Header>
							<Text class='semi-bold'>{Translate('Company')}</Text>
						</Header>
						<Body>
							<Form
								inputs =
								{[
									{
										name: 'companyEconomicActivities',
										text: Translate('Economic Activity'),
										value: companyFilters.companyEconomicActivities,
										type: 'autocomplete',
										class: 'desktop6',
										multiple: true,
										options: listsByName.economicActivities?.selectOptions,
										Change: () => setPreventUpdateRecipients(false)
									},
									{
										name: 'locationStates',
										text: Translate('States'),
										value: companyFilters.locationStates,
										type: 'autocomplete',
										class: 'desktop6',
										multiple: true,
										options: statesOptions,
										Change: () => setPreventUpdateRecipients(false)
									},
									{
										name: 'emptyEconomicActivitiesFlag', // this is empty company
										text: Translate('Include Without Business'),
										value: companyFilters.emptyEconomicActivitiesFlag,
										type: 'checkbox',
										class: 'desktop6',
										Change: () => setPreventUpdateRecipients(false)
									},
								]}
								Change = {setCompanyFilters}
							/>
						</Body>
					</Body>
				</Panel>: <></>}
			</Panel>
			<Footer class='right'>
				{!contact && !base ? <Text class='float-left' style={{marginTop: 10}}>
					{filteredContacts.length} {Translate('possible contacts')}&nbsp;-&nbsp;
					<Text 
						class = 'blue pointer'
						Click = {() => ShowScheduledMessageContacts(filteredContacts, filteredRecipients === null ? filteredContacts : filteredRecipients, (selected) => {setFilteredRecipients(selected.length === filteredContacts.length ? null : selected)})}
					>
						Selected {filteredRecipients === null ? 'All' : filteredRecipients?.length}
					</Text>
				</Text> : <></>}
				<Button
					class = 'bg-blue white'
					text = {Translate('Save')}
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	);
}

export {ScheduledMessageForm}