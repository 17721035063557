import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Section } from 'crack-ux';

// Contexts
import { CRMProvider } from '../../../contexts/modules/CRM';
import { CompaniesProvider, useCompanies } from '../../../contexts/collections/crm/Companies';
import { ContactsProvider, useContacts  } from '../../../contexts/collections/crm/Contacts';
import { TemplatesProvider, useTemplates } from '../../../contexts/collections/crm/Templates';
import { ScheduledMessagesProvider } from '../../../contexts/collections/crm/ScheduledMessages';
import { ChatBotsProvider } from '../../../contexts/collections/crm/ChatBots';
import { CampaignsProvider, useCampaigns } from '../../../contexts/collections/crm/Campaigns';
import { ContactsNotesProvider } from '../../../contexts/collections/crm/ContactsNotes';
import { MessagesProvider, useMessages } from '../../../contexts/collections/crm/Messages';
import { EventsConfigProvider } from '../../../contexts/collections/scheduler/EventsConfig';
import { EventsProvider } from '../../../contexts/collections/scheduler/Events';

// Views
import { ContactWindows } from '../../../components/views/modules/crm/ContactWindows/ContactWindows';

// Module Section
import { ModalProvider } from '../../../contexts/general/Modal';
import { Contacts } from './Contacts';
import { Chats } from './Chats';
import { Companies } from './Companies';
import { Templates } from './Templates';
import { ChatBots } from './ChatBots';
import { ScheduledMessages } from './ScheduledMessages';
import { Campaigns } from './Campaigns';

const CRM = () =>
{
	const { ReadContacts, loading: loadingContacts } = useContacts();
	const { ReadCompanies, loading: loadingCompanies } = useCompanies();
	const { ReadCampaigns } = useCampaigns();
	const { ReadTemplates } = useTemplates();
	const { ReadLastMessages } = useMessages();

	// First Stage - Priority Data Load
	useEffect(() =>
	{
		ReadContacts();
		ReadCompanies();
	}, [ReadContacts, ReadCompanies]);

	// Second Stage - Secondary Data Load
	useEffect(() =>
	{
		if(!loadingContacts && !loadingCompanies)
		{
			ReadCampaigns();
			ReadTemplates();
			ReadLastMessages();
		}
	}, [loadingContacts, loadingCompanies, ReadCampaigns, ReadTemplates, ReadLastMessages]);

	return (
		<Section class='crm-module full-height relative'>
			<Routes>
				<Route path='/contacts/*' element={<Contacts/>}/>
				<Route path='/companies/*' element={<Companies/>}/>
				<Route path='/chats/*' element={<Chats/>}/>
				<Route path='/campaigns/*' element={<Campaigns/>}/>
				<Route path='/templates/*' element={<Templates/>}/>
				<Route path='/bots/*' element={<ChatBots/>}/>
				<Route path='/scheduled-messages/*' element={<ScheduledMessages/>}/>
			</Routes>
			<ContactWindows/>
		</Section>
	)
}

const CRMWithProviders = () =>
{
	return (
		<ContactsProvider>
			<CompaniesProvider>
				<TemplatesProvider>
					<ScheduledMessagesProvider>
						<ChatBotsProvider>
							<ContactsNotesProvider>
								<MessagesProvider>
									<CampaignsProvider>
										<EventsConfigProvider syssec='crm'>
											<EventsProvider syssec='crm'>
												<CRMProvider>
													<ModalProvider>
														<CRM/>
													</ModalProvider>
												</CRMProvider>
											</EventsProvider>
										</EventsConfigProvider>
									</CampaignsProvider>
								</MessagesProvider>
							</ContactsNotesProvider>
						</ChatBotsProvider>
					</ScheduledMessagesProvider>
				</TemplatesProvider>
			</CompaniesProvider>
		</ContactsProvider>
	)
}

export {CRMWithProviders as CRM};