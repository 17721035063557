import { Route, Routes } from "react-router-dom";

// Pages
import { Campaigns } from "./Campaigns";

const CampaignsRouter = () =>
{
	return (
		<Routes>
			<Route path="/:campaignName/:campaignId/:stageName/:stageId" element={<Campaigns/>} />
			<Route path="/:campaignName/:campaignId" element={<Campaigns/>} />
			<Route path="/" element={<Campaigns/>} />
		</Routes>
	);
}

export { CampaignsRouter as Campaigns };