import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Contexts
import { ModalProvider } from '../../../contexts/general/Modal';

// Page
import {Members} from './Members';

const Staff = () =>
{
	return (
		<Routes>
			<Route path='/members/*' element={<Members/>}/>
		</Routes>
	)
}

const StaffWithProviders = () =>
{
	return (
		<ModalProvider>
			<Staff/>
		</ModalProvider>
	)
}

export {StaffWithProviders as Staff};