import { Section, Content, Text, Panel, Button, Image } from 'crack-ux';

const Commerce = () =>
{
	return (
		<Section class='commerce'>
			<Content class='desktop'>
				<Panel class='info desktop6'>
					<Text class='title'>Módulo de Comercio</Text>
					<Text class='subtitle'>Gestión ágil y precisa, adaptada a tu negocio</Text>
					<Text class='description'>Gestioná ventas, compras, cajas, stock y cuentas por cobrar desde un sistema rápido, personalizable y 100% en la nube.
					El módulo de comercio está diseñado para adaptarse a las reglas y precios únicos de tu negocio, automatizando los descuentos, impuestos, reportes y mucho más.</Text>
					<Section class='features'>
						<Panel class='desktop6'>
							<li>
								<Text>Ventas y punto de venta</Text>
							</li>
							<li>
								<Text>Cajas y movimientos</Text>
							</li>
							<li>
								<Text>Precios y descuentos</Text>
							</li>
							<li>
								<Text>Gestión de usuarios y permisos</Text>
							</li>
							<li>
								<Text>Impresión de tickets y facturación electrónica</Text>
							</li>
						</Panel>
						<Panel class='desktop6'>
							<li>
								<Text>Compras y stock</Text>
							</li>
							<li>
								<Text>Cuentas por cobrar</Text>
							</li>
							<li>
								<Text>Reportes inteligentes</Text>
							</li>
							<li>
								<Text>Alertas de inventario bajo</Text>
							</li>
							<li>
								<Text>Historial completo de movimientos</Text>
							</li>
						</Panel>
					</Section>
					<Button class='available-button' text='Disponible ahora'/>
				</Panel>
				<Panel class='mockup desktop6'>
					<Image src='/sice-page/mockups/commerce-preview.png' alt='Vista módulo Commerce'/>
				</Panel>
			</Content>
		</Section>
	)
}

export { Commerce };