import { ToggleContent, Form } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';

const InitForm = (props) =>
{
	const { initForm, setInitForm, messages } = props;

	const { Translate } = useTraductor();

	return (
		<ToggleContent text={Translate('init')}>
			<Form
				inputs =
				{[
					{
						name: 'coverPage',
						text: Translate('cover-page'),
						type: 'file',
						value: initForm.coverPage,
						message: messages.coverPage,
					}
				]}
				form = {initForm}
				setForm = {setInitForm}
			/>
		</ToggleContent>
	)
}

export { InitForm };