import {Section, Header, Body, Footer, Box, Text, Button, Icon, Image} from 'crack-ux';
import {Amount, Utilities} from 'crack-functions';

// Contexts
import {useModal} from '../../../../../contexts/general/Modal';
import {useBusinessPage} from '../../../../../contexts/pages/public/BusinessPage';

const Feature = (feature) =>
{
	const {description, checked} = feature;

	const iconClass = checked ? 'icon-check-1 selected' : 'icon-cancel-1 none';

	return (
		<Section class='feature'>
			<Text>{description}</Text>
			<Icon class={`checked ${iconClass}`}/>
		</Section>
	);
}

const ServiceDetail = (service) =>
{
	const {_id, name, description, features, price, period} = service;

	const {CloseModal} = useModal();
	const {business, shoppingCart, AddServiceToShoppingCart, RemoveServiceFromShoppingCart} = useBusinessPage();

	const {page = {}} = business;
	const {services: servicesConf = {}} = page;

	const shoppingCartService = shoppingCart.services[_id];
	const existService = shoppingCartService?.quantity > 0;

	return (
		<Section class='service-detail full-height'>
			<Header class='no-border'>
				<Text class='bold' size={1.5}>{name}</Text>
				<Icon class='icon-cancel float-right' Click={CloseModal}/>
			</Header>
			<Body class='vertical-scroll no-padding'>
				<Header
					class = 'center no-border'
					style =
					{{
						backgroundColor: servicesConf.card?.background,
						color: Utilities.HexIsLight(servicesConf.card?.background) ? 'black' : 'white'
					}}
				>
					<Text class='name block'>{name}</Text>
					<Text class='price block'>{Amount.CurrencyFormat(price.sale)}</Text>
					<Text class='period block'>{period}</Text>
				</Header>
				<Box
					class = 'header-arrow'
					style =
					{{
						backgroundColor: servicesConf.card?.background,
					}}
				/>
				<Section class='picture'>
					<Image src='/assets/box.png'/>
				</Section>
				<Body>
					<Section class='description'>
						<Text class='bold block' type='p'>Description:</Text>
						<Text type='p'>{description}</Text>
					</Section>
					<Section class='features'>
						{features.map((feature, index) => <Feature key={index} {...feature}/>)}
					</Section>
				</Body>
				<Footer class='center no-border'>
					<Button
						class = 'main-button'
						text = {existService ? servicesConf.removeServiceButton?.text : servicesConf.addServiceButton?.text}
						Click = {() => existService ? RemoveServiceFromShoppingCart(service) : AddServiceToShoppingCart(service)}
						style =
						{{
							backgroundColor: existService ? servicesConf.removeServiceButton?.background : servicesConf.addServiceButton?.background,
							color: Utilities.HexIsLight(existService ? servicesConf.removeServiceButton?.background : servicesConf.addServiceButton?.background) ? 'black' : 'white'
						}}
					/>
				</Footer>
			</Body>
		</Section>
	)
}

export {ServiceDetail};