import { Section, Content, Text, Panel, Button, Icon, Card } from 'crack-ux';

const Coming = () =>
{
	return (
		<Section class='coming'>
			<Content class='desktop'>
				<Text class='title'>Lo que viene para llevar tu negocio aún más lejos</Text>
				<Text class='subtitle'>En SICE no nos detenemos. Estamos construyendo nuevos módulos que te ayudarán a fidelizar clientes, mejorar tus ventas y automatizar aún más tu gestión.</Text>
				<Section class='content'>
					<Panel class='tablet7'>
						<Card class='crm'>
							<Section>
								<Text class='tag tag-yellow'>Lazamiento Próximo</Text>
								<Text class='module'>Módulo de CRM</Text>
								<Text class='caption'>Conecta con tus clientes como nunca antes.</Text>
								<Section class='features'>
									<Section class='feature'>
										<Icon class='icon-user'/>
										<Text>Seguimiento de clientes</Text>
									</Section>
									<Section class='feature'>
										<Icon class='icon-list-bullet'/>
										<Text>Tareas y recordatorios automáticos</Text>
									</Section>
									<Section class='feature'>
										<Icon class='icon-calendar'/>
										<Text>Historial de compras</Text>
									</Section>
									<Section class='feature'>
										<Icon class='icon-heart'/>
										<Text>Segmentación de clientes</Text>
									</Section>
									<Section class='feature'>
										<Icon class='icon-bell-alt'/>
										<Text>Alertas de recontacto</Text>
									</Section>
								</Section>
							</Section>
							<Section class='actions'>
								<Text class='dates'>
									Beta: Junio 2025
								</Text>
								<Button class='beta-button' text='Anotarme a la fase beta'/>
							</Section>
						</Card>
					</Panel>
					<Panel class='tablet5'>
						<Card class='accountancy'>
							<Section>
								<Text class='tag tag-blue'>En Desarrollo</Text>
								<Text class='module'>Módulo de Contabilidad</Text>
								<Text class='caption'>Contabilidad Automatizada.</Text>
								<Text class='description'>Imaginá tener tu contabilidad trabajando en sincronía con tus ventas, tus pagos y tu día a día.
								Eso es lo que estamos creando.</Text>
							</Section>
							<Button class='reminder-button' text='Recibir un recordatorio'/>
						</Card>
					</Panel>
				</Section>
			</Content>
		</Section>
	)
}

export { Coming };