import { useMemo } from 'react';
import { Section, Header, Body, Text, Table } from 'crack-ux';
import { Amount } from 'crack-functions';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

// Contexts
import { useTraductor } from '../../../../../contexts/general/Traductor';
import { useSession } from '../../../../../contexts/general/Session';

// Static Data
import Colors from '../../../../../static-data/Colors.json';

Chart.register(...registerables);

const NTopProductsBar = (props) =>
{
	const { products, filter } = props;

	const { Translate } = useTraductor();
	const { user } = useSession();

	const labels = products.map(item => item.product?.name || Translate('unknown-product'));
	const dataValues = products.map(item => item.totalQuantity);

	const options = useMemo(() =>
	{
		const result =
		{
			plugins:
			{
				title:
				{
					display: true,
					text: `${Translate('top')} ${filter.products} ${Translate('products')}`,
				}
			},
			responsive: true,
			interaction:
			{
				mode: 'index',
				intersect: false,
			},
			stacked: false,
			scales:
			{
				y:
				{
					type: 'linear',
					display: true,
					position: 'left',
				}
			}
		};

		return result;
	}, [filter.products, Translate]);

	return (
		<Section>
			<Header>
				<Text class='title'>{Translate('top-products-by-quantity')}</Text>
			</Header>
			<Body>
				<Bar
					data =
					{{
						labels: labels,
						datasets:
						[
							{
								label: Translate('amount'),
								data: dataValues,
								backgroundColor: Object.values(Colors.background).reduce((acc, color) => [...acc, color], []),
								borderColor: Object.values(Colors.border).reduce((acc, color) => [...acc, color], []),
								borderWidth: 1,
							}
						]
					}}
					options = {options}
				/>
			</Body>
			<Body class='no-padding-horizontal no-padding-bottom'>
				<Table
					header = 
					{[
						{
							name: 'name',
							text: Translate('product-name')
						},
						{
							name: 'revenueText',
							text: Translate('revenue')
						},
						{
							name: 'unitsSold',
							text: Translate('units-sold')
						},
						{
							name: 'averageText',
							text: Translate('average-price')
						}
					]}
					body = {products.map(product =>
					{
						const averagePrice = product.totalQuantity > 0 ? product.totalRevenue / product.totalQuantity : 0;
						product.name = product.product?.name || Translate('unknown-product');
						product.revenueText = Amount.CurrencyFormat(product.totalRevenue);
						product.unitsSold = product.totalQuantity;
						product.averageText = Amount.CurrencyFormat(averagePrice);

						return product;
					})}
					searcher = {false}
					filters = {false}
					pager =
					{{
						rows: 1000
					}}
					language = {user.preferences?.language || 'es'}
				/>
			</Body>
		</Section>
	)
}

export { NTopProductsBar };