import React, { useEffect, useMemo, useState } from 'react';
import { Content, Panel, Card, Header, Body, Text, Box, Button } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useCampaigns } from '../../../../contexts/collections/crm/Campaigns';
import { useContacts } from '../../../../contexts/collections/crm/Contacts';
import { useLists } from '../../../../contexts/collections/home/Lists';

// Components
import { CampaignsTable } from './CampaignsTable';
import { ContactsList } from '../Contacts/ContactsList';
import { BackButton } from '../../../../components/BackButton/BackButton';

// Views
import { Funnel } from './Funnel';
import { ExclusionPoint } from './ExclusionPoint';

// Styles
import './Campaigns.css';

const Campaigns = () =>
{
	const [funnelType, setFunnelType] = useState('stage');
	const [stageContacts, setStageContacts] = useState([]);

	const { screen } = useGeneral();
	const { Translate } = useTraductor();
	const { currentCampaign, setCurrentCampaign, campaigns } = useCampaigns();
	const { contacts } = useContacts();
	const { listsByName } = useLists();

	const navigate = useNavigate();
	const params = useParams();

	const { campaignId, stageId } = params;

	const filteredContacts = useMemo(() =>
	{
		if(!campaignId || !currentCampaign.tags)
		{
			return [];
		}
		else if (campaignId === 'global')
		{
			return contacts;
		}
		else
		{
			if(Array.isArray(currentCampaign.tags) && currentCampaign.tags.length > 0)
			{
				return contacts.filter(contact => Array.isArray(contact.tags) && contact.tags.length > 0 && currentCampaign.tags.some(campaignTag => contact.tags.includes(campaignTag)));
			}
			else
			{
				return [];
			}
		}
	}, [campaignId, contacts, currentCampaign]);

	useEffect(() =>
	{
		if(campaignId && campaigns.length > 0)
		{
			setCurrentCampaign(campaigns.find(campaign => campaign._id === campaignId));
		}
	}, [campaignId, campaigns, currentCampaign, setCurrentCampaign]);

	useEffect(() =>
	{
		if (stageId && filteredContacts.length > 0 && listsByName['sales-stage'])
		{
			const isExclusionPoint = listsByName['sales-stage'].workingItems.some(item => item.data?.exclusionPoint && item._id === stageId);

			if (funnelType === 'total' || isExclusionPoint)
			{
				setStageContacts(filteredContacts.filter(contact => contact.salesStage === stageId));
			}
			else
			{
				const salesStages = listsByName['sales-stage'].workingItems.filter(stage => !stage.data?.exclusionPoint).reverse();

				let accumulatedContacts = [];

				for (let stage of salesStages)
				{
					accumulatedContacts = [...accumulatedContacts, ...filteredContacts.filter(contact => contact.salesStage === stage._id)];
					if (stage._id === stageId) break;
				}

				setStageContacts(accumulatedContacts);
			}
		}
	}, [stageId, filteredContacts, funnelType, listsByName]);

	return (
		<Content class='crm-campaigns'>
			{((screen.device === 'mobile' && !campaignId) || (screen.device === 'tablet' && !stageId) || screen.device === 'laptop' || screen.device === 'desktop') &&
			<Panel class='desktop4 tablet6 full-height'>
				<Card>
					<CampaignsTable/>
				</Card>
			</Panel>}
			{((screen.device === 'mobile' && campaignId && !stageId) || (screen.device === 'tablet' && campaignId) || screen.device === 'laptop' || screen.device === 'desktop') &&
			<Panel class='desktop4 tablet6 full-height'>
				<Card class='relative'>
					<Header>
						{(screen.device === 'mobile' || (screen.device === 'tablet' && stageId)) && <BackButton Click={() => navigate('../')}/>}
						<Text class='bold'>{Translate('sales-flow')}</Text>
						<Box class='float-right' style={{paddingBottom: 10}}>
							<Button class={funnelType === 'stage' ? 'bg-blue white' : 'default'} style={{margin: 0}} text={Translate('stage')} Click={() => setFunnelType('stage')}/>
							<Button class={funnelType === 'total' ? 'bg-blue white' : 'default'} style={{margin: 0}} text={Translate('total')} Click={() => setFunnelType('total')}/>
						</Box>
					</Header>
					<Body class='center vertical-scroll full-width' style={{height: 'calc(100% - var(--header) - 30px)'}}>
						<Funnel contacts={filteredContacts} funnelType={funnelType}/>
						<ExclusionPoint contacts={filteredContacts}/>
					</Body>
				</Card>
			</Panel>}
			{((screen.device === 'mobile' && campaignId && stageId) || (screen.device === 'tablet' && stageId) || screen.device === 'laptop' || screen.device === 'desktop') &&
			<Panel class='desktop4 tablet6 full-height'>
				<Card class='relative'>
					{stageId ? <ContactsList contacts = {stageContacts} from='sales-flow'/> : <Text class='full-center'>{Translate('select-stage')}</Text>}
				</Card>
			</Panel>}
		</Content>
	)
}

export { Campaigns };