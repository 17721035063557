import {Section} from 'crack-ux';

// Components
import {Profile} from './Profile';

// Contexts
import {useBusiness} from '../../contexts/layouts/Business';

const MultiFunctionPanel = () =>
{
	const { multiFunctionPanel } = useBusiness();

	return (
		<Section class='full-height'>
			<Section class='full-height'>
				{multiFunctionPanel === 'profile' && <Profile/>}
			</Section>
		</Section>
	);
}

export { MultiFunctionPanel };