const SetCompany = (company) =>
{
	company.codeText = `COMP-${company.code}`;
	company.locationText = company.location?.address || '';

	return company;
}

const CompaniesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_COMPANIES':
			return {
				...state,
				companies: action.companies.map(company => SetCompany(company))
			}

		case 'ADD_COMPANIES':
			return {
				...state,
				companies: [...state.companies.slice(0, action.from), ...action.companies.map(company => SetCompany(company))]
			}

		case 'CREATE_COMPANY':
			return {
				...state,
				companies: [...state.companies, SetCompany(action.company)],
				code: state.code + 1
			}

		case 'UPDATE_COMPANY':
			return {
				...state,
				companies: state.companies.map(company => company._id === action.company._id ? SetCompany(action.company) : company)
			}

		case 'DELETE_COMPANY':
		{
			return {
				...state,
				companies: state.companies.filter(company => company._id !== action.company._id)
			}
		}

		case 'SET_LATEST_COMPANY_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {CompaniesReducer};