import React, { createContext, useContext } from "react";

// Contexts
import { useModal } from "../general/Modal";

// Forms
import { BusinessForm } from "../../components/forms/Business";

const PersonalLayoutContext = createContext({});

const PersonalLayoutProvider = (props) =>
{
	const { AddModal } = useModal();

	const ShowBusinessForm = (business = {}) =>
	{
		AddModal(<BusinessForm business = {business}/>);
	}

	const value =
	{
		ShowBusinessForm
	}

	return ( 		
		<PersonalLayoutContext.Provider value={value}>
			{props.children}
		</PersonalLayoutContext.Provider>
	);
}

const usePersonalLayout = () => useContext(PersonalLayoutContext);

export {PersonalLayoutProvider, usePersonalLayout};