import { Route, Routes } from "react-router-dom";

// Pages
import { Files } from "./Files";

const FilesRouter = () =>
{
	return (
		<Routes>
			<Route path='/:module/*' element={<Files/>}/>
			<Route path='/' element={<Files/>}/>
		</Routes>
	);
}

export { FilesRouter };