import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Section, Header, Body, Text, Avatar, Card, Icon, Box } from 'crack-ux';
import { Text as TextF } from 'crack-functions';

// Contexts
import { useSession } from '../../contexts/general/Session';
import { useTraductor } from '../../contexts/general/Traductor';
import { useBusiness } from '../../contexts/layouts/Business';

const Profile = () =>
{
	const { user, Logout } = useSession();
	const { Translate } = useTraductor();
	const { SomethingWentWrong } = useBusiness();

	const navigate = useNavigate();

	const fullName = React.useMemo(() => TextF.FullName(user.name, user.lastName), [user]);

	const menuOptions =
	[
		{
			name: 'businesses',
			icon:
			{
				class: 'icon-building'
			},
			Click: () => navigate('/p/business'),
		},
		{
			name: 'profile',
			icon:
			{
				class: 'icon-vcard'
			},
			Click: () => navigate('/p/profile'),
		},
		{
			name: 'preferences',
			icon:
			{
				class: 'icon-sliders'
			},
			Click: () => navigate('/p/preferences'),
		},
		{
			name: 'logout',
			icon:
			{
				class: 'icon-logout',
			},
			Click: () =>
			{
				if(Logout())
				{
					navigate('/');
				}
				else
				{
					SomethingWentWrong();
				}
			},
		}
	];

	return (
		<Section class='account'>
			<Header>
				<Card>
					<Avatar class='bg-gray white round' src={TextF.Initials(fullName)} size={72} fontSize='2em'/>
					<Box class='info'>
						<Text class='fullname' type='p'>{fullName}</Text>
						<Text class='email' type='p'>{user.email}</Text>
						<Text class='username' type='p'>@{user.username}</Text>
					</Box>
				</Card>
			</Header>
			<Body>
				{menuOptions.map((option, index) =>
				{
					return (
						<Card key={index} class='menu-option' Click={option.Click}>
							<Icon {...option.icon}/>
							<Text>{Translate(option.name)}</Text>
							{option.name !== 'logout' && <Icon class='icon-angle-right float-right'/>}
						</Card>
					)
				})}
			</Body>
		</Section>
	)
}

export {Profile};