import { Table, Text, Header, Body, Box } from 'crack-ux';
import { Amount } from 'crack-functions';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useSession } from '../../../../contexts/general/Session';
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useMembers } from '../../../../contexts/collections/staff/Members';

// Components
import { CloseCashRegisterView } from './CloseCashRegisterView';

const CashRegisterShiftsTable = (props) =>
{
	const { crShifts } = props;

	const { screen } = useGeneral();
	const { user } = useSession();
	const { AddModal } = useModal();
	const { Translate } = useTraductor();
	const { membersObj } = useMembers();

	const navigate = useNavigate();
	const { cashRegisterName } = useParams();	

	return (
		<>
			<Header>
				<Text class='title'>{Translate('cash-registers-shifts')}</Text>
			</Header>
			<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
				<Table
					header = 
					{[
						{
							name: 'statusColorFlag',
							text: Translate('status'),
							class: 'center',
							width: 50
						},
						{
							name: 'memberFullName',
							text: Translate('employee')
						},
						{
							name: 'openingDateText',
							text: Translate('opening-date')
						},
						{
							name: 'closingDateText',
							text: Translate('closed-date')
						},
						{
							name: 'openingCashText',
							text: Translate('opening-cash')
						},
						{
							name: 'declaredAmountTotalsText',
							text: Translate('closing-expected-amount')
						},
						{
							name: 'expectedAmountTotalsText',
							text: Translate('closing-declared-amount')
						},
						{
							name: 'noteText',
							text: Translate('note')
						},
					]}
					body = {crShifts.map((cashRegisterShift) =>
					{
						const member = membersObj[cashRegisterShift.memberId] || {};

						let expectedAmountTotals = 0;
						let declaredAmountTotals = 0;

						cashRegisterShift.closingSummary.forEach(detail =>
						{
							expectedAmountTotals += detail.expectedAmount;
							declaredAmountTotals += detail.declaredAmount;
						});
						
						const expectedAmountTotalsText = <Text>{Amount.CurrencyFormat(expectedAmountTotals)}</Text>;
						const declaredAmountTotalsText = <Text class={expectedAmountTotals !== declaredAmountTotals && 'red'}>{Amount.CurrencyFormat(declaredAmountTotals)}</Text>;

						return {
							...cashRegisterShift,
							memberFullName: member.fullName,
							declaredAmountTotalsText: cashRegisterShift.status === 0 ? declaredAmountTotalsText : '',
							expectedAmountTotalsText: cashRegisterShift.status === 0 ? expectedAmountTotalsText : '',
							noteText: <Text	title={cashRegisterShift.note}>{cashRegisterShift.note}</Text>,
							statusColorFlag: <Box class={`round bg-${cashRegisterShift.status === 1 ? 'green' : 'red'}`} style={{width: 15, height: 15}} title={cashRegisterShift.status === 1 ? Translate('opened') : Translate('closed')}></Box>,
						}
					})}
					actions =
					{[
						{
							text: Translate('view-detail'),
							section: 'context',
							icon:
							{
								class: 'icon-clipboard'
							},
							Click: (crShift) => AddModal(<CloseCashRegisterView shift={{...crShift, cashRegisterName}}/>)
						}
					]}
					BackButton = {(screen.device === 'mobile') ?
					{
						Click: () => navigate('../')
					}
					: null}
					exportable = {false}
					language = {user.preferences?.language || 'es'}
					filters = 
					{{
						columns:
						{
							editable: true
						},
						Apply:
						{
							text: Translate('Apply'),
							Click: () => {}
						},
						Reset:
						{
							text: Translate('Reset'),
							Click: () => {}
						}
					}}
					firstLoad = 
					{{
						triggerClickRow: false
					}}
				/>
			</Body>
			
		</>
	)
}

export { CashRegisterShiftsTable };