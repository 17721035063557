
import { useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Page, Content, Box, Header, Text, ContextMenu, Avatar, Panel } from 'crack-ux';
import { Text as TextF } from 'crack-functions';
import config from '../../config.json';

// Contexts
import { useGeneral } from '../../contexts/general/General';
import { useSession } from '../../contexts/general/Session';
import { BusinessProvider } from '../../contexts/main/Business.js';
import { SocketProvider } from '../../contexts/general/Socket.js';
import { ModalProvider } from '../../contexts/general/Modal';

// Layouts
import {Loading} from '../../components/views/Loading';
import {PersonalLayoutProvider} from '../../contexts/layouts/Personal';

// Pages
import {Business} from '../../pages/personal/Business';
import {Preferences} from '../../pages/personal/Preferences';
import {Profile} from '../../pages/personal/Profile/Profile';

// Views
import {PageNotFound} from '../../components/PageNotFound/index.jsx';

// Components
//import {Menu} from './Menu';
import {PersonalMenu} from './PersonalMenu.jsx';

// Style
import './Personal.css';

const PersonalHeader = () =>
{
	const { user } = useSession();

	const navigate = useNavigate();

	const initials = TextF.Initials(TextF.FullName(user.name, user.lastName));

	return (
		<Header class='personal-header bg-blue no-border'>
			<Box>
				<Text class='logo masque white pointer' Click={() => navigate('/')}>Sice</Text>
				<Text class='version'>{config.app.version}</Text>
			</Box>
			<Box class='float-right'>
				<Text class='white' style={{marginRight: 10}} >{TextF.FullName(user.name, user.lastName)}</Text>
				<Avatar 
					class = 'bg-gray white round' 
					src = {user.pictureURL || initials} 
					error = {initials}
				/>
			</Box>
		</Header>
	)
}

const Personal = () =>
{
	const [showMenu, setShowMenu] = useState(false);
	
	const { loading } = useSession();
	const { screen } = useGeneral();

	const {device} = screen;

	if(loading)
	{
		return <Loading/>;
	}
	else
	{
		return (
			<Page class='personal-layout'>
				<PersonalHeader showMenu={showMenu} setShowMenu={setShowMenu}/>
				<Content class='content'>
					{device !== 'mobile' && 
					<Panel class='desktop4 tablet4 full-height'>
						<PersonalMenu/>
					</Panel>}
					<Panel class='desktop8 tablet8 no-padding full-height'>
						<Routes>
							<Route path="/business" exact={true} element={<Business/>}/>
							<Route path="/profile" exact={true} element={<Profile/>}/>
							<Route path="/preferences" exact={true} element={<Preferences/>}/>
							<Route path="/" element={<Navigate to='./business'/>}/>
							<Route path="*" element={<PageNotFound/>}/>
						</Routes>
					</Panel>
				</Content>
				{device === 'mobile' && <PersonalMenu/>}
			</Page>
		)
	}
}

const PersonalWithProviders = () =>
{
	return (
		<SocketProvider>
			<BusinessProvider>
				<ModalProvider>
					<PersonalLayoutProvider>
						<Personal/>
						<ContextMenu/>
					</PersonalLayoutProvider>
				</ModalProvider>
			</BusinessProvider>
		</SocketProvider>
	)
}

export {PersonalWithProviders as Personal};