import React, { useMemo } from 'react';
import { Card, Panel, Table, Header, Body, Footer, Avatar, Text, Icon, Button, Box } from 'crack-ux';
import { Amount } from 'crack-functions';

// Contexts
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useItems } from '../../../../contexts/collections/storage/Items';
import { useProducts } from '../../../../contexts/collections/commerce/Products';
import { useLists } from '../../../../contexts/collections/home/Lists';

// Components
import { Label } from '../../../../components/Label/Label';

const MovementsDetailsTable = (props) =>
{
	const { movement = {} } = props;

	const { CloseModal } = useModal();
	const { Translate } = useTraductor();
	const { itemsById } = useItems();
	const { products: allProducts } = useProducts();
	const { listsByName } = useLists();

	const categoriesList = useMemo(() => listsByName['product-categories'] || [], [listsByName]);

	const products = useMemo(() =>
	{
		const movementDetailProducts = [];

		allProducts.forEach(product =>
		{
			const productItem = movement.details.find(detail => detail.product === product._id);

			if(productItem)
			{
				movementDetailProducts.push(
				{
					...product,
					detailQuantity: productItem.quantity,
					detailPrice: Amount.CurrencyFormat(productItem.price),
					detailTotal: Amount.CurrencyFormat(productItem.total),
				})
			}
		});

		return movementDetailProducts;
	},  [movement, allProducts]);

	const productsPictures = useMemo(() =>
	{
		const result = {};

		products.forEach(product =>
		{
			const url = itemsById?.[product.pictures?.[0]]?.publicURL || '/assets/box.png';

			result[product._id] = <Avatar class='bg-white' src={url} error={'/assets/box.png'}/>
		});

		return result;
	}, [products, itemsById]);

	return (
		<Card style={{width: 1024}}>
			<Header>
				<Text class='title'>{Translate('movement')}</Text>
				<Icon class='icon-cancel float-right pointer' size={1.5} Click={CloseModal}/>
			</Header>
			<Header>
				<Panel class='desktop6'>
					<Label label={Translate('code')} text={movement.codeText}/>
					<Label label={movement.type ? Translate('client') : Translate('provider')} text={movement.name}/>
					{movement.tin && <Label label={Translate('tin')} text={movement.tin}/>}
				</Panel>
				<Panel class='desktop6'>
					<Label label={Translate('date')} text={movement.dateText}/>
					<Label label={Translate('time')} text={movement.timeText}/>
				</Panel>
			</Header>
			<Body class='no-padding' style={{height: 600}}>
				<Table
					title = {Translate('detail')}
					class = 'hover pointer'
					header =
					{[
						{
							name: 'pictureMiniature',
							text: Translate('picture'),
							width: 80
						},
						{
							name: 'codeText',
							text: Translate('code'),
							width: 90
						},
						{
							name: 'name',
							text: Translate('product-name'),
							width: 200
						},
						{
							name: 'categoriesText',
							text: Translate('categories')
						},
						{
							name: 'detailQuantity',
							text: Translate('quantity'),
							width: 82
						},
						{
							name: 'detailPrice',
							text: Translate('price'),
							width: 135
						},
						{
							name: 'detailTotal',
							text: Translate('total'),
							width: 135
						},
					]}
					body = {products.map(product =>
					{
						const categoriesFiltered = product?.categories ? product.categories.map(category => categoriesList.itemsById?.[category].text) : [];
						product.categoriesText = categoriesFiltered.join(', ');
		
						if(productsPictures[product._id]) product.pictureMiniature = productsPictures[product._id];
		
						return product;
					})}
					actions = {false}
					searcher = {false}
					footer = {false}
					pager =
					{{
						rows: 1000
					}}
				/>
			</Body>
			<Footer class='right'>
				<Box class='float-left' style={{paddingTop: 6}}>
					<Text class='bold' size={1.5}>Total:</Text>
					<Text size={1.5} style={{marginLeft: 5}}>{movement.totalText}</Text>
				</Box>
				<Button text={Translate('close')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
} 

export { MovementsDetailsTable };