import React from 'react';
import { Content, Header, Text, } from 'crack-ux';
import { Dates } from 'crack-functions';

// Contexts
import { useSession } from '../../../../contexts/general/Session';
import { useTraductor } from '../../../../contexts/general/Traductor';

// CSS
import './Dashboard.css';

const Dashboard = () =>
{
	const { user } = useSession();
	const { Translate } = useTraductor();

	return (
		<Content class='dashboard-page vertical-scroll'>
			<Header>
				<Text size={1.5}>{Translate(Dates.Greeting().toLowerCase().replace(' ', '-'))} {user.name} 👋</Text>
			</Header>
		</Content>
	)
}

export { Dashboard };