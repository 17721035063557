import { useMemo, useState } from 'react';
import { Table, Alert, Confirm, Text } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useModal } from '../../../../contexts/general/Modal';
import { useCampaigns } from '../../../../contexts/collections/crm/Campaigns';

// Components
import { CampaignForm } from './CampaignForm';

const CampaignsTable = (props) =>
{
	const [campaign, setCampaign] = useState({});
	const [showConfirm, setShowConfirm] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const { screen } = useGeneral();
	const { Translate } = useTraductor();
	const { AddModal } = useModal();
	const { campaigns, DeleteCampaign } = useCampaigns();

	const navigate = useNavigate();
	const params = useParams();

	const { campaignName, stageId } = params;
	
	const body = useMemo(() =>
	{
		return campaigns.map(campaign =>
		{
			return campaign;
		});
	}, [campaigns])

	return (
		<>
			<Table
				class = 'current'
				header =
				{[
					{
						name: 'codeText',
						text: Translate('code')
					},
					{
						name: 'name',
						text: Translate('campaign-name')
					},
					// {
					// 	name: 'startDateText',
					// 	text: Translate('Start Date'),
					// },
					// {
					// 	name: 'endDateText',
					// 	text: Translate('End Date'),
					// },
					{
						name: 'tagsText',
						text: Translate('tags'),
						maxWidth: 300,
						title: 'tagsText'
					}
				]}
				body = {body}
				actions =
				{[
					{
						text: Translate('new-campaign'),
						class: 'bg-blue white',
						section: 'grouped',
						Click: () => AddModal(<CampaignForm/>)
					},
					{
						text: Translate('Edit Campaign'),
						icon:
						{
							class: 'icon-pencil'
						},
						section: 'context',
						Click: (campaign) => campaign._id === 'global' ? setShowAlert(true) : AddModal(<CampaignForm campaign={campaign}/>)
					},
					{
						text: Translate('Delete Campaign'),
						class: 'red',
						icon:
						{
							class: 'icon-trash'
						},
						section: 'context',
						Click: (campaign) =>
						{
							if (campaign._id === 'global')
							{
								setShowAlert(true)
							}
							else
							{
								setCampaign(campaign);
								setShowConfirm(true);
							}
						}
					}
				]}
				DoubleClickRow = {(campaign) => campaign._id === 'global' ? setShowAlert(true) : AddModal(<CampaignForm campaign={campaign}/>)}
				ClickRow = {(campaign) => navigate(`${campaignName ? '../' : './'}${campaign.name}/${campaign._id}`)}
				firstLoad = {
				{
					triggerClickRow: screen.device !== 'mobile' && !campaignName && !stageId
				}}
				searcher =
				{
					{text: Translate('Search')}
				}
				texts =
				{
					{
						noRecords:Translate('There Are Not Records To Show'),
						records:Translate('Records'),
						fields: Translate('Fields'),
						settings: Translate('Settings'),
						export: Translate('Export')
					}
				}
				filters = 
				{{
					columns:
					{
						editable: true
					},
					Apply:
					{
						text: Translate('Apply'),
						Click: () => {}
					},
					Reset:
					{
						text: Translate('Reset'),
						Click: () => {}
					}
				}}
			/>
			<Confirm
				show={showConfirm}
				header={<Text class='bold'>{Translate(`delete-campaign`)}</Text>}
				body=
				{
					<>
						<Text type='p'>{Translate(`confirm-delete-campaign`)}</Text>
						<Text type='p'><span className='bold'>{Translate('campaign')}: </span>{campaign.name}</Text>
					</>
				}
				confirm=
				{{
					text: Translate('delete'),
					class: 'bg-red white',
					Click: () =>
					{
						DeleteCampaign(campaign);
						setShowConfirm(false);
					}
				}}
				cancel=
				{{
					text: Translate('cancel'),
					Click: () => setShowConfirm(false)
				}}
			/>
			<Alert
				show = {showAlert}
				header = {<Text class='bold'>{Translate(`warning-global-campaign`)}</Text>}
				body = {<Text type='p'>{Translate(`warning-global-campaign`)}</Text>}
				cancel =
				{{
					text: Translate('cancel'),
					Click: () => setShowAlert(false)
				}}
			/>
		</>
	)
};

export {CampaignsTable};