import React from 'react';
import { Section } from 'crack-ux';

// Components
import { Menu } from './Menu';
import { Hero } from './Hero';
import { WhyUs } from './WhyUs';
import { WhatIsSice } from './WhatIsSice';
import { Modules } from './Modules';
import { Commerce } from './Commerce';
import { Coming } from './Coming';
import { Testimonials } from './Testimonials';
import { ContactUs } from './ContactUs';
import { End } from './End';

// Style
import './Home.css';

const Home = () =>
{
	return (
		<Section class='sice-home relative'>
			<Menu/>
			<Hero/>
			<WhatIsSice/>
			<WhyUs/>
			<Modules/>
			<Commerce/>
			<Coming/>
			<Testimonials/>
			<ContactUs/>
			<End/>
		</Section>
	)
}

export {Home};