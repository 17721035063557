import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../layouts/Business';

// Reducers
import {ContactsNotesReducer} from '../../../reducers/collections/crm/ContactsNotes';

const ContactsNotesContext = React.createContext({});

const ContactsNotesProvider = (props) =>
{
	const [state, dispatch] = React.useReducer(ContactsNotesReducer, {notes: [], contactsNotes: {}, code: 0});
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {BusinessFetch, business} = useBusiness();

	const room = React.useMemo(() => `crm_contact_notes_${business._id}`, [business._id]);
	const unique = 'CRM_CONTACT_NOTE';

	React.useEffect(() =>
	{
		BusinessFetch.Post('/crm/contact-notes/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_NOTE_CODE', code: response.data});
			}
		});
	}, [BusinessFetch]);

	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (note) =>
				{
					dispatch({type: 'CREATE_NOTE', note});
					dispatch({type: 'SET_LATEST_NOTE_CODE', code: state.code + 1});
				}
			},
			{
				name: `UPDATE_${unique}`,
				Function: (note) => dispatch({type: 'UPDATE_NOTE', note})
			},
			{
				name: `DELETE_${unique}`,
				Function: (note) => dispatch({type: 'DELETE_NOTE', note})
			}
		];

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code]);

	const LoadContactNotes = React.useCallback((contact =>
	{
		if(contact)
		{
			BusinessFetch.Post('/crm/contact-notes/read', {_where: {contact}}).then(response =>
			{
				if(response.status === 200)
				{
					dispatch({type: 'LOAD_CONTACT_NOTES', notes: response.data || [], contact});
				}
			});
		}
	}), [BusinessFetch]);

	const LoadNotes = React.useCallback(notes =>
	{
		dispatch({type: 'LOAD_NOTES', notes});
	}, []);

	const CreateNote = async (note) =>
	{
		note._socket = room;

		return await BusinessFetch.Post('/crm/contact-note/create', note);
	}

	const UpdateNote = async (note) =>
	{
		note._socket = room;

		return await BusinessFetch.Post('/crm/contact-note/update', note);
	}

	const DeleteNote = async (note) =>
	{
		const data =
		{
			_socket: room,
			_id: note._id
		};

		return await BusinessFetch.Post('/crm/contact-note/delete', data);
	}

	const value =
	{
		code: state.code,
		contactsNotes: state.contactsNotes,
		LoadNotes,
		LoadContactNotes,
		CreateNote,
		UpdateNote,
		DeleteNote
	}

	return <ContactsNotesContext.Provider value={value} {...props}/>
}

const useContactsNotes = () => React.useContext(ContactsNotesContext);

export {ContactsNotesProvider, useContactsNotes};