import React, { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

// Context
import { useSocket } from '../../general/Socket';
import { useTraductor } from '../../general/Traductor';
import { useBusiness } from '../../layouts/Business';

// Reducers
import { TemplatesReducer } from '../../../reducers/collections/crm/Templates';

const Templates = createContext({});

const TemplatesProvider = (props) =>
{
	const [state, dispatch] = useReducer(TemplatesReducer, {templates: [], code: 0});
	const [currentTemplate, setCurrentTemplate] = useState({});

	const Socket = useSocket();
	const { Translate } = useTraductor();
	const { BusinessFetch, business, SomethingWentWrong } = useBusiness();

	const GetMaxTemplatesCode = useCallback(async () =>
	{
		try
		{
			const response = await BusinessFetch.Get('/crm/templates/code');

			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_TEMPLATE_CODE', code: response.data});
			}
		}
		catch (error)
		{
			SomethingWentWrong('GetMaxTemplatesCode');
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const ReadTemplates = useCallback(async () =>
	{
		try
		{
			const response = await BusinessFetch.Get('/crm/templates');

			if(response.status === 200)
			{
				dispatch({type: 'LOAD_TEMPLATES', templates: response.data});
			}
		}
		catch (error)
		{
			SomethingWentWrong('ReadTemplates');
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const CreateTemplate = async (template) =>
	{
		try
		{
			template.code = state.code + 1;

			const response = await BusinessFetch.Post('/crm/template', template);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					calss: 'success',
					message: Translate('template-created'),
				})

				return response;
			}
		}
		catch (error)
		{
			SomethingWentWrong('CreateTemplate');
		}
	}

	const UpdateTemplate = async (template) =>
	{
		try
		{
			const response = await BusinessFetch.Put('/crm/template', template);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					calss: 'success',
					message: Translate('template-updated'),
				})

				return response;
			}
		}
		catch (error)
		{
			SomethingWentWrong('UpdateTemplate');
		}
	}

	const DeleteTemplate = async (template) =>
	{
		try
		{
			const response = await BusinessFetch.Delete('/crm/template', template);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					calss: 'success',
					message: Translate('template-deleted'),
				})

				return response;
			}
		}
		catch (error)
		{
			SomethingWentWrong('DeleteTemplate');
		}
	}

	useEffect(() =>
	{
		GetMaxTemplatesCode();
	}, [GetMaxTemplatesCode]);
	
	useEffect(() =>
	{
		const events =
		[
			{
				name: `CRM_TEMPLATE_CREATE`,
				Function: (template) => dispatch({type: 'CREATE_TEMPLATE', template})
			},
			{
				name: `CRM_TEMPLATE_UPDATE`,
				Function: (template) => dispatch({type: 'UPDATE_TEMPLATE', template})
			},
			{
				name: `CRM_TEMPLATE_DELETE`,
				Function: (template) => dispatch({type: 'DELETE_TEMPLATE', template})
			}
		];

		Socket.ConnectEvents(business._id, events);
	}, [Socket, business]);

	const value =
	{
		code: state.code,
		templates: state.templates,
		currentTemplate,
		setCurrentTemplate,
		ReadTemplates,
		CreateTemplate,
		UpdateTemplate,
		DeleteTemplate
	}

	return (
		<Templates.Provider value={value}>
			{props.children}
		</Templates.Provider>
	)
}

const useTemplates = () => useContext(Templates);

export {TemplatesProvider, useTemplates};