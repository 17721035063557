import { useMemo } from 'react';
import { Content, Card, Panel, Header, Body, Text, List, Box } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useLists } from '../../../../contexts/collections/home/Lists';
import { useCRShifts } from '../../../../contexts/collections/commerce/CRShifts';

// Components
import { CashRegisterShiftsTable } from './CashRegisterShiftsTable';

const CashRegisters = () =>
{
	const { screen } = useGeneral();
	const { Translate } = useTraductor();
	const { listsByName } = useLists();
	const { crShifts } = useCRShifts();

	const navigate = useNavigate();
	const { cashRegisterId } = useParams();

	const cashRegisters = useMemo(() => listsByName['cash-registers'] || {}, [listsByName]);

	const filteredCRShifts = useMemo(() => crShifts.filter((crShift) => crShift.cashRegisterId === cashRegisterId), [crShifts, cashRegisterId]);

	return (
		<Content>
			{(screen.device !== 'mobile' || !cashRegisterId) && 
			<Panel class='desktop2'>
				<Card>
					<Header>
						<Text class='title'>{Translate('cash-registers')}</Text>
					</Header>
					<Body class='no-padding'>
						<List
							current = {cashRegisterId}
							currentClass = 'bg-light-gray'
							options = {cashRegisters.items?.map((cashRegister) =>
							{
								const someActiveShift = crShifts.some((crShift) => crShift.cashRegisterId === cashRegister._id && crShift.status === 1);

								return {
									identifier: cashRegister._id,
									content: <>
												<Box class={`bg-${someActiveShift ? 'green' : 'red'} round`} style={{width: 15, height: 15, marginRight: 10}}/>
												<Text>{cashRegister.text}</Text>
											</>,
									Click: () => navigate(`${cashRegisterId ? '..' : '.'}/${cashRegister.text}/${cashRegister._id}`) 
								}
							})}
						/>
					</Body>
				</Card>
			</Panel>}
			{(screen.device !== 'mobile' || cashRegisterId) &&
			<Panel class='desktop10'>
				<Card class='relative'>
					{cashRegisterId ? <CashRegisterShiftsTable crShifts={filteredCRShifts}/> : <Text class='full-center'>{Translate('select-cash-register')}</Text>}
				</Card>
			</Panel>}
		</Content>
	)
}

export {CashRegisters};