import { useState } from 'react';
import { Card, Header, Body, Footer, Form, Button, Text } from 'crack-ux';

// Context
import { useModal } from '../../../contexts/general/Modal';
import { useBusiness } from '../../../contexts/main/Business';
import { useTraductor } from '../../../contexts/general/Traductor';

const BusinessForm = () =>
{
	const [business, setBusiness] = useState({});
	const [messages, setMessages] = useState({});
	
	const { Translate } = useTraductor();
	const { CloseModal } = useModal();
	const { CreateBusiness } = useBusiness();

	const HandleSubmit = async () =>
	{
		const messages = {};
		
		if (!business.name) messages.name = Translate('field-required');
		if (!business.uniqueName) messages.uniqueName = Translate('field-required');
		if (business.uniqueName && business.uniqueName.includes(' ')) messages.uniqueName = Translate('spaces-not-allowed');

		if(Object.keys(messages).length === 0)
		{
			const newBusiness = {...business};

			newBusiness.uniqueName = newBusiness.uniqueName?.toLowerCase();

			const result = await CreateBusiness(newBusiness);

			if(result.status === 200)
			{
				CloseModal();

				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('business-created'),
				});
			}
			else if(result.status === 202)
			{
				messages.uniqueName = Translate(result.validations[0].content);
			}
		}

		setMessages(messages);
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('New Business')}</Text>
			</Header>
			<Body class='vertical-scroll'>
				<Form
					inputs =
					{[
						{
							name: 'name',
							text: Translate('business-name'),
							placeholder: Translate('business-name'),
						},
						{
							name: 'uniqueName',
							text: Translate("business-unique-name"),
							placeholder: Translate('business-unique-name'),
						}
					]}
					form = {business}
					setForm = {setBusiness}
					messages = {messages}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('save')}
					class = 'bg-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {BusinessForm};