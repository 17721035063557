import {useState} from 'react';
import {Section, Header, Body, Text, Animation} from 'crack-ux';
import {Utilities} from 'crack-functions';

// Contexts
import {useBusinessPage} from '../../../../../contexts/pages/public/BusinessPage';

const FAQ = (faq) =>
{
	const {question, answer} = faq;

	const [showAnswer, setShowAnswer] = useState(false);

	const {business} = useBusinessPage();
	
	const {page = {}} = business;
	const {faqs: faqsConf = {}} = page;

	return (
		<Section class='faq'>
			<Header
				Click = {() => setShowAnswer(!showAnswer)}
				style =
				{{
					backgroundColor: faqsConf.card?.background,
					color: Utilities.HexIsLight(faqsConf.card?.background) ? 'black' : 'white'
				}}
			>
				<Text>{question}</Text>
			</Header>
			<Animation animation='slide-height' show={showAnswer}>
				<Body class='bg-white'>
					<Text type='p'>{answer}</Text>
				</Body>
			</Animation>
		</Section>
	);
}

export {FAQ};