import { useNavigate } from 'react-router-dom';
import { Section, Card, Panel, Header, Box, Text, Icon, Avatar } from 'crack-ux';
import { Text as TextF } from 'crack-functions';

// Components
import { Loading } from '../../../../src/components/views/Loading';

// Contexts
import { useSession } from '../../../contexts/general/Session';
import { usePersonalLayout } from '../../../contexts/layouts/Personal';
import { useTraductor } from '../../../contexts/general/Traductor';
import { maxUnpaidInvoices } from '../../../contexts/collections/home/Invoices';

import './Business.css';

const Business = () =>
{
	const navigate = useNavigate();

	const {Translate} = useTraductor();
	const {user, businesses, loading} = useSession();
	const {ShowBusinessForm} = usePersonalLayout();

	const isBusinessLimitReached = user.business?.length >= user.businessLimit;

	if(loading)
	{
		return (
			<Loading/>
		)
	}
	else
	{
		return (
			<Section class='business-page'>
				<Panel>
					<Card>
						<Header class='no-border'>
							<Text class='bold'>{Translate('businesses')}</Text>
						</Header>
					</Card>
				</Panel>
				<Panel class='center'>
					<Text type='p'>{Translate('current-business-limit-message')} {user.businessLimit}.</Text>
					<Text type='p'>{Translate('more-business-limit-message')}</Text>
				</Panel>
				{businesses.map((business, index) =>
				{
					const disabled = Array.isArray(business.unpaidInvoices) && business.unpaidInvoices.length > maxUnpaidInvoices;
					const classList = disabled ? 'pointer' : 'pointer';
					// const unpaidIndicator = disabled && <Text size={10} class='red unpaid-indicator'>{business.unpaidInvoices.length} {Translate(business.unpaidInvoices.length > 1 ? 'Unpaid Invoices' : 'Unpaid Invoice')}</Text>
					const defaultHomePage = disabled ? 'billings' : 'dashboard';

					return (
						<Panel key={index} class='desktop4 tablet6'>
							<Card class={`desktop4 tablet6 business-option ${classList}`} Click={() => navigate(`/b/${business.uniqueName}/home/${defaultHomePage}`)}>
								<Panel class='relative'>
									<Box class='desktop3 mobile2 center' style={{verticalAlign: 'middle', padding: 5}}>
										<Avatar 
											src = {business.pictureURL || '/assets/business.png'}
											alt ='business-logo'
											size = {50}
											error={TextF.Initials(business.name)}
										/>
									</Box>
									<Box class='desktop9 mobile10 left center gray' style={{verticalAlign: 'middle', padding: 5}}>
										<Text>{business.name}</Text>
									</Box>
									{/* {unpaidIndicator} */}
								</Panel>
							</Card>
						</Panel>
					)
				})}
				<Panel class='desktop4 tablet6'>
					<Card
						class = {`desktop4 tablet6 business-option new-business ${!isBusinessLimitReached ? 'active' : 'disabled'}`}
						Click = {() => 
						{
							if(!isBusinessLimitReached)
							{
								ShowBusinessForm()
							}
							else
							{
								window.CrackUX.Toasts.AddToast(
								{
									class: 'info',
									message: Translate('business-limit-reached'),
								});
							}
						}}
					>
						<Panel>
							<Box class='desktop3 mobile2'>
								<Icon class='icon-plus'/>
							</Box>
							<Box class='desktop9 mobile10'>
								<Text>{Translate('create-business')}</Text>
							</Box>
						</Panel>
					</Card>
				</Panel>
			</Section>
		);
	}
}

export {Business};