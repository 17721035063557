import {Content, Header, Footer, Text} from 'crack-ux';

// Contexts
import {useBusinessPage} from '../../../../contexts/pages/public/BusinessPage';

// Components
import {FAQ} from '../components/FAQ';

const FAQs = () =>
{
	const {business} = useBusinessPage();

	const {page = {}} = business;
	const {faqs: faqsConf = {}} = page;
	const {faqs = []} = faqsConf;

	return (
		<Content class='faqs desktop'>
			<Header class='center no-border'>
				<Text class='title' type='p'>{faqsConf.title}</Text>
				<Text class='caption' type='p'>{faqsConf.caption}</Text>
			</Header>
			<Footer>
				{faqs.map(faq => <FAQ key={faq._id} {...faq} />)}
			</Footer>
		</Content>
	);
}

export {FAQs}