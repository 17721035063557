import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

// Context
import { useSocket } from '../../general/Socket';
import { useTraductor } from '../../general/Traductor';
import { useBusiness } from '../../layouts/Business';

// Reducers
import { ItemsReducer } from '../../../reducers/collections/storage/Items';

const ItemsContext = createContext({});

const ItemsProvider = (props) =>
{
	const [state, dispatch] = useReducer(ItemsReducer, {items: [], itemsById: {}});
	const [currentItem, setCurrentItem] = useState({});
	const [loading, setLoading] = useState(true);

	const Socket = useSocket();
	const { Translate } = useTraductor();
	const { business, BusinessFetch, SomethingWentWrong } = useBusiness();

	const ReadItems = useCallback(async (folder = null) =>
	{
		try
		{
			setLoading(true);

			const query = {folder};

			const response = await BusinessFetch.Get('/storage/items', query);

			if(response.status === 200)
			{
				dispatch({type: 'LOAD_ITEMS', items: response.data});
			}
		}
		catch(error)
		{
			SomethingWentWrong('ReadItems');
		}
		finally
		{
			setLoading(false);
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const CreateItem = async (item) =>
	{
		try
		{
			const response = await BusinessFetch.Post('/storage/item', item);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate(`${item.type}-created`),
				});

				return response;
			}
		}
		catch(error)
		{
			SomethingWentWrong('CreateItem');
		}
	}

	const UpdateItem = async (item) =>
	{
		try
		{
			const itemData =
			{
				_id: item._id,
				name: item.name,
				isPublic: item.isPublic
			}

			const response = await BusinessFetch.Put('/storage/item', itemData);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate(`${item.type}-updated`),
				});

				return response;
			}
		}
		catch(error)
		{
			SomethingWentWrong('UpdateItem');
		}
	}

	const UploadDocuments = async (data) =>
	{
		try
		{
			window.CrackUX.Toasts.AddToast(
			{
				class: 'info',
				title: Translate('uploading-files'),
				message: Translate('uploading-files-message'),
			});

			const response = await BusinessFetch.Post('/storage/upload', data);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('files-uploaded'),
				});
			}

			return response;
		}
		catch(error)
		{
			console.log('error', error);
			SomethingWentWrong('UploadDocuments');
		}
	}

	const DeleteItem = async (item) =>
	{
		try
		{
			const response = await BusinessFetch.Delete('/storage/item', {_id: item._id});

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate(`${item.type}-deleted`),
				});

				return response;
			}
		}
		catch(error)
		{
			SomethingWentWrong('DeleteItem');
		}
	}

	const PublicPrivate = async (item) =>
	{
		const data =
		{
			...item,
			isPublic: !item.isPublic,
		};

		return await UpdateItem(data);
	}

	useEffect(() =>
	{
		const events =
		[
			{
				name: `STORAGE_ITEM_CREATE`,
				Function: (item) => dispatch({type: 'CREATE_ITEM', item})
			},
			{
				name: `STORAGE_ITEM_UPDATE`,
				Function: (item) => dispatch({type: 'UPDATE_ITEM', item})
			},
			{
				name: `STORAGE_ITEM_DELETE`,
				Function: (item) => dispatch({type: 'DELETE_ITEM', item})
			}
		];

		Socket.ConnectEvents(business._id, events);
	}, [Socket, business._id]);

	const value =
	{
		loading,
		items: state.items,
		itemsById: state.itemsById,
		currentItem,
		setCurrentItem,
		ReadItems,
		CreateItem,
		UpdateItem,
		DeleteItem,
		UploadDocuments,
		PublicPrivate,
	}

	return (
		<ItemsContext.Provider value={value}>
			{props.children}
		</ItemsContext.Provider>
	)
}

const useItems = () => useContext(ItemsContext);

export {ItemsProvider, useItems};