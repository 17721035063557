import { useCallback, useState } from 'react';
import {Section, Card, Header, Body, Footer, Form, Text, Button} from 'crack-ux';

// Context
import {useModal} from '../../../../contexts/general/Modal';
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useMembers} from '../../../../contexts/collections/staff/Members';

const MemberForm = ({ user = {} }) =>
{
	const [memberForm, setMemberForm] = useState({});
	const [validations, setValidations] = useState({});
	const [disabled, setDisabled] = useState(true);
	const [allowInvite, setAllowInvite] = useState(false);

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	const {SearchUserByEmail, InviteUserToStaff} = useMembers();

	const VerifyEmail = useCallback(async () =>
	{
		const response = await SearchUserByEmail(memberForm);

		if(response.status === 200)
		{
			if(response.message === 'user-not-found')
			{
				setValidations(
				{
					...validations,
					email: 
					<Section>
						<Text class='green' type='p'>{Translate('user-not-found')}</Text>
						<Text class='green' type='p'>{Translate('proceed-register-user')}</Text>
					</Section>
				});

				setAllowInvite(true);
				setDisabled(false);
			}
			else if(response.message === 'member-already-invited-to-staff')
			{
				setValidations(
				{
					...validations,
					email: <Text class='green' type='p'>{Translate('user-already-invited')}</Text>
				});

				setAllowInvite(false);
				setDisabled(true);
			}
			else if(response.message === 'member-not-found-in-staff')
			{
				setValidations(
				{
					...validations,
					email: 
					<Section>
						<Text class='green' type='p'>{Translate('user-already-registered')}</Text>
						<Text class='green' type='p'>{Translate('proceed-invite-user')}</Text>
					</Section>
				});

				setAllowInvite(true);
				setDisabled(true);
			}
			else if(response.message === 'invalid-email-format')
			{
				setValidations({...validations, email: <Text class='red'>{`${Translate('invalid-email-format')}`}</Text>});

				setAllowInvite(false);
				setDisabled(true);
			}

			setMemberForm(
			{
				email: memberForm.email,
				name: response.data?.name,
				lastName: response.data?.lastName,
			});
		}
	}, [SearchUserByEmail, Translate, memberForm, validations]);

	const HandleSubmit = useCallback(async () =>
	{
		if(allowInvite)
		{
			const response = await InviteUserToStaff(memberForm);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('user-invited-successfully'),
				});

				CloseModal();
			}
		}
	}, [InviteUserToStaff, allowInvite, memberForm, Translate, CloseModal]);

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('invite-new-member')}</Text>
			</Header>
			<Body>
				<Form
					inputs =
					{[
						{
							name: 'email',
							text: 'Email',
							value: memberForm.email,
							message: validations.email,
							icon:
							{
								class: 'icon-search bg-blue white pointer',
								Click: VerifyEmail,
							}
						},
						{
							name: 'name',
							text: 'Name',
							value: memberForm.name,
							disabled,
						},
						{
							name: 'lastName',
							text: 'Last Name',
							value: memberForm.lastName,
							disabled,
						},
					]}
					form = {memberForm}
					setForm = {setMemberForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					class='bg-blue white'
					text={Translate('invite-user')}
					disabled={!allowInvite}
					Click={HandleSubmit}/>
				<Button text={Translate('Cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export { MemberForm };