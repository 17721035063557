import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Contexts
import { ModalProvider } from '../../../contexts/general/Modal';
import { ContactsProvider } from '../../../contexts/collections/vouchers/Contacts';

// Module Section
import { Contacts } from './Contacts';
import { Invoices } from './Invoices';
import { Settings } from './Settings';

const Vouchers = () =>
{
	return (
		<Routes>
			<Route path='contacts/*' element={<Contacts/>}/>
			<Route path='invoices/*' element={<Invoices/>}/>
			<Route path='settings/*' element={<Settings/>}/>
		</Routes>
	)
}

const VouchersWithProviders = () =>
{
	return (
		<ContactsProvider>
			<ModalProvider>
				<Vouchers/>
			</ModalProvider>
		</ContactsProvider>
	)
}

export {VouchersWithProviders as Vouchers};