import {Box, Section, Icon} from 'crack-ux';

const Loading = () =>
{
	return (
		<Section class='relative full-width full-height'>
			<Box class='full-center center'>
				<Icon class='icon-spin6 animate-spin blue' size={3}></Icon>
			</Box>
		</Section>
	)
}

export {Loading};