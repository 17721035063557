import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { useParams, useNavigate } from 'react-router-dom';
import { Select } from 'crack-ux';

// Contexts
import { useSession } from '../../contexts/general/Session';
import { useBusiness } from '../../contexts/layouts/Business';

// Styles
import './BusinessSelector.css';

const BusinessSelector = (props) =>
{
	const params = useParams();
	const navigate = useNavigate();
	
	const {businesses} = useSession();
	const {business} = useBusiness();

	const HandleChange = (response) =>
	{
		const path = generatePath(`/b/:business/${params['*']}`, {business: response.value});

		navigate(path);
	}

	const businessOptions = useMemo(() =>
	{
		return businesses.map(business =>
		({
			text: business.name,
			value: business.uniqueName
		}));
	}, [businesses]);

	return (
		<Select
			class = 'business-selector inline-block pointer'
			options = {businessOptions}
			selected = {business?.uniqueName}
			Change = {HandleChange}
		/>
	);
};

export {BusinessSelector};