import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

// Context
import { useSocket } from '../../general/Socket';
import { useTraductor } from '../../general/Traductor';
import { useBusiness } from '../../layouts/Business';

// Reducers
import { ProductsReducer } from '../../../reducers/collections/commerce/Products';

const Products = createContext({});

const ProductsProvider = (props) =>
{
	const [state, dispatch] = useReducer(ProductsReducer, {products: [], code: 0});
	const [currentProduct, setCurrentProduct] = useState({});
	const [loading, setLoading] = useState(true);

	const Socket = useSocket();
	const { Translate } = useTraductor();
	const { BusinessFetch, business, SomethingWentWrong } = useBusiness();

	const ReadProducts = useCallback(async () =>
	{
		try
		{
			setLoading(true);

			const response = await BusinessFetch.Get('/commerce/products');

			if(response.status === 200)
			{
				dispatch({type: 'LOAD_PRODUCTS', products: response.data});
			}
		}
		catch(error)
		{
			SomethingWentWrong('ReadProducts');
		}
		finally
		{
			setLoading(false);
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const CreateProduct = async (product) =>
	{
		try
		{
			product.code = state.code + 1;

			delete product.status;
			delete product.pictureMiniature;

			const response = await BusinessFetch.Post('/commerce/product', product);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('product-created'),
				});
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('CreateProduct');
		}
	}

	const UpdateProduct = async (product) =>
	{
		try
		{
			delete product.status;
			delete product.pictureMiniature;

			const response = await BusinessFetch.Put('/commerce/product', product);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('product-updated'),
				});
			}

			return response;
		}
		catch(error)
		{
			console.log(error);
			SomethingWentWrong('UpdateProduct');
		}
	}

	const DeleteProduct = async (product) =>
	{
		try
		{
			const productDate =
			{
				_id: product._id,
			}

			const response = await BusinessFetch.Delete('/commerce/product', productDate);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('product-deleted'),
				});
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('DeleteProduct');
		}
	}

	useEffect(() =>
	{
		BusinessFetch.Get('/commerce/products/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_PRODUCT_CODE', code: response.data});
			}
		});
	}, [BusinessFetch]);

	useEffect(() =>
	{
		const events =
		[
			{
				name: 'COMMERCE_PRODUCT_CREATE',
				Function: (product) => dispatch({type: 'CREATE_PRODUCT', product})
			},
			{
				name: 'COMMERCE_PRODUCT_UPDATE',
				Function: (product) => dispatch({type: 'UPDATE_PRODUCT', product})
			},
			{
				name: 'COMMERCE_PRODUCT_DELETE',
				Function: (product) => dispatch({type: 'DELETE_PRODUCT', product})
			}
		];

		Socket.ConnectEvents(business._id, events);
	}, [Socket, business]);

	const value =
	{
		loading,
		code: state.code,
		products: state.products,
		currentProduct,
		setCurrentProduct,
		ReadProducts,
		CreateProduct,
		UpdateProduct,
		DeleteProduct
	}

	return (
		<Products.Provider value={value}>
			{props.children}
		</Products.Provider>
	)
}

const useProducts = () => useContext(Products);

export {ProductsProvider, useProducts};