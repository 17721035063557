import { Card, Form, Button, Section, Text, Icon } from 'crack-ux';

// Contexts
import { useTraductor } from '../../contexts/general/Traductor';
import { useState } from 'react';

// Utilities
import Validate from '../../utilities/Validate';

const EmailsForm = (props) =>
{
	const { emails, setEmails, disabled } = props;

	const [email, setEmail] = useState({});
	const [messages, setMessages] = useState({});

	const { Translate } = useTraductor();

	const AddEmail = () =>
	{
		const messagesData = {};

		if(!email.address) messagesData.email = Translate('field-required');

		if(!Validate.IsEmail(email.address)) messagesData.email = Translate('invalid-email');
		
		setMessages(messagesData);

		if(Object.keys(messagesData).length === 0)
		{
			const cloneEmails = [...emails];

			cloneEmails.push(email);

			setEmails(cloneEmails);
			setEmail({});
			setMessages({...messages, email: ''});
		}
	}

	const RemoveEmail = (index) =>
	{
		const cloneEmails = [...emails];

		cloneEmails.splice(index, 1);

		setEmails(cloneEmails);
	}

	return (
		<Card>
			<Form
				inputs =
				{[
					{
						name: 'address',
						text: Translate('email'),
						class: 'mobile10',
						value: email.address,
						message: messages.email,
						disabled
					},
					{
						class: 'mobile2',
						type: 'content',
						content: <Button
									class = 'bg-blue white full-width'
									Click = {AddEmail}
									disabled = {disabled}
								>
									<Icon class='icon-plus no-margin'/>
								</Button>
					},
					...emails.length > 0 ?
					[
						{
							text: Translate('emails'),
							type: 'content',
							content:
							<Card class='no-shadow bg-light-gray'>
								{emails.map((email, index) =>
								{
									return (
									<Section key={index} style={{padding: 5}}>
										<Text>{email.address}</Text>
										<Icon
											class='icon-minus red pointer float-right'
											Click={() => RemoveEmail(index)}
										/>
									</Section>)
								})}
							</Card>
						}
					] : []
				]}
				form = {email}
				setForm = {setEmail}
			/>
		</Card>
	)	
}

export { EmailsForm }