import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../layouts/Business';

// Reducers
import {EventsReducer} from '../../../reducers/collections/scheduler/Events';
import { useSession } from '../../general/Session';

const Events = React.createContext({});

const EventsProvider = (props) =>
{	
	const [state, dispatch] = React.useReducer(EventsReducer, {events: []});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {user} = useSession();
	const {BusinessFetch, business} = useBusiness();

	const room = React.useMemo(() => `events_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `EVENT_${eventId}` : 'EVENT';
	}, [eventId]);
	
	React.useEffect(() =>
	{
		const events =
		[
			{
				name: `CREATE_${unique}`,
				Function: (data) => dispatch({type: 'CREATE_EVENT', events: data.filter(event => Array.isArray(event.staff) && event.staff.indexOf(user._id) !== -1)})
			},
			{
				name: `UPDATE_${unique}`,
				Function: (data) => dispatch({type: 'UPDATE_EVENT', events: data})
			},
			{
				name: `DELETE_${unique}`,
				Function: (data) => dispatch({type: 'DELETE_EVENT', eventsIds: data.map(event => event._id)})
			}
		];

		Socket.ConnectEvents(room, events, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, events, unique});
		}
	}, [Socket, room, unique, oldSocket, user]);

    const LoadEvents = React.useCallback((events = []) =>
    {
        dispatch({type: 'LOAD_EVENTS', events});
    }, []);

	const CreateEvent = async (data) =>
	{
		const url = '/event/create';

		data._socket = room;

		return await BusinessFetch.Post(url, data);
	}

	const UpdateEvent = async (data) =>
	{
		const url = '/event/update';

		data._socket = room;

		return await BusinessFetch.Put(url, data);
	}

	const DeleteEvent = async (event) =>
	{
		const data =
		{
			_id: event._id,
			actionType: event.actionType,
			_socket: room
		}

		return await BusinessFetch.Delete('/event/delete', data);
	}

	const value =
	{
		events: state.events,
		setEventId,
        LoadEvents,
		CreateEvent,
		UpdateEvent,
		DeleteEvent
	}

	return <Events.Provider value={value} {...props}/>
}

const useEvents = () => React.useContext(Events);

export {EventsProvider, useEvents};