import { useMemo, useState } from 'react';
import { Card, Header, Body, Button, Text } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useContacts } from '../../../../contexts/collections/crm/Contacts';
import { useMessages } from '../../../../contexts/collections/crm/Messages';

// Components
import { BackButton } from '../../../../components/BackButton';
import { Contact } from './Contact';

const ChatList = () =>
{
	const [isNewChat, setIsNewChat] = useState(false);

	const { Translate } = useTraductor();
	const { contacts } = useContacts();
	const { contactsMessages } = useMessages();

	const filteredContacts = useMemo(() =>
	{
		if(isNewChat)
		{
			return contacts.filter(contact => !contactsMessages[contact._id]);
		}
		else
		{
			return contacts.filter(contact => contactsMessages[contact._id]);
		}
	}, [contacts, contactsMessages, isNewChat]);

	return (
		<Card>
			<Header class={`${isNewChat ? 'left' : 'right'}`}>
				{isNewChat && <BackButton Click={() => setIsNewChat(false)}/>}
				<Text class={`title ${!isNewChat && 'float-left'}`} style={{marginTop: isNewChat ? 0 : 10}}>{Translate('chats')}</Text>
				{!isNewChat && <Button class='bg-blue white' text={Translate('new-chat')} Click={() => setIsNewChat(true)}/>}
			</Header>
			<Body class='no-padding'>
				{filteredContacts.map((contact, index) =>
				{
					return <Contact key={index} {...contact} lastMessage={contactsMessages[contact._id]}/>;
				})}
			</Body>
		</Card>
	);
}

export { ChatList };