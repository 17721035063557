import React from "react";
import {Form, Body, Card, Text, Button, Header, Footer} from 'crack-ux';

// Functions
import {useTraductor} from "../../../contexts/general/Traductor";
import Validations from "../../../utilities/Validate";

// Contexts
import {useModal} from "../../../contexts/general/Modal";
import {useUsers} from "../../../contexts/main/Users";

import './ChangePassword.css';

const ChangePassword = (props) =>
{
	const [messagePassword, setMessagePassword] = React.useState('');
	const [messagesPassword, setMessagesPassword] = React.useState({});
	const [passwordForm, setPasswordForm] = React.useState(props.password ? props.password : {});

	const {Translate} = useTraductor();

	const {SavePassword} = useUsers();
	const {CloseModal} = useModal();

	const HandleSavePassword = (response) =>
	{
		const passwordError = Validations.ValidatePasswords(response.password, response.confirmPassword);

		if(passwordError)
		{
			setMessagePassword(passwordError);
		}
		else
		{
			setMessagePassword(null);

			SavePassword(response).then(response =>
			{
				setMessagesPassword(Validations.ParseByField(response.validations));

				if(response.status === 200)
				{
					CloseModal();
				}
			});
		}
	}

	return <Card class='change-password-container'>
				<Header>
					<Text class='bold'>{Translate('Change Password')}</Text>
				</Header>
				<Body class='vertical-scroll'>
					<Form
						inputs = 
						{[
							{
								name: 'currentPassword',
								text: Translate('Current Password'),
								type: 'password',
								value: passwordForm.currentPassword,
								message: messagesPassword.currentPassword,
								required: true
							},
							{
								name: 'password',
								text: Translate('New Password'),
								type: 'password',
								value: passwordForm.password,
								message: messagesPassword.password,
								required: true
							},
							{
								name: 'confirmPassword',
								text: Translate('Confirm Password'),
								type: 'password',
								value: passwordForm.confirmPassword,
								message: messagesPassword.confirmPassword,
								required: true
							},
						]}
						Change = {(response) => setPasswordForm({...passwordForm, ...response})}
						Submit = {(response) => HandleSavePassword(response)}
						response = {messagePassword}
					/>
				</Body>
				<Footer class='right'>
					<Button
						text = {Translate('Change Password')}
						class = 'bg-blue white'
						Click = {() => HandleSavePassword(passwordForm)}
					/>
					<Button
						text = {Translate('Cancel')}
						Click = {CloseModal}
					/>
				</Footer>
			</Card>
}

export {ChangePassword}