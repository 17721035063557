import { useState } from 'react';
import { Card, Header, Body, Footer, Form, Button, Text } from 'crack-ux';

// Context
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useContacts } from '../../../../contexts/collections/vouchers/Contacts';

// Components
import { PhonesForm } from '../../../../components/PhonesForm/PhonesForm';
import { EmailsForm } from '../../../../components/EmailsForm/EmailsForm';

const ContactForm = (props) =>
{
	const { contact = {} } = props;

	const [contactForm, setContactForm] = useState(contact);
	const [phones, setPhones] = useState(contact.phones || []);
	const [emails, setEmails] = useState(contact.emails || []);
	const [messages, setMessages] = useState({});

	const { CloseModal } = useModal();
	const { Translate } = useTraductor();
	const { CreateContact, UpdateContact } = useContacts();

	const HandleSubmit = async () =>
	{
		const messagesData = {};

		if(!contactForm.name) messagesData.name = Translate('field-required');

		setMessages(messagesData);

		if(Object.keys(messagesData).length === 0)
		{
			const data =
			{
				...contact,
				...contactForm,
				phones,
				emails
			};
	
			const result = contact._id ? await UpdateContact(data) : await CreateContact(data);
	
			if(result.status === 200)
			{
				CloseModal();
			}
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{contact._id ? Translate('edit-contact') : Translate('new-contact')}</Text>
			</Header>
			<Body>
				<Form
					inputs =
					{
						[
							{
								name: 'name',
								text: Translate('contact-name'),
								value: contactForm.name,
								message: messages.name
							},
							{
								name: 'tin',
								text: Translate('tin'),
								value: contactForm.tin,
								message: messages.tin
							},
							{
								text: Translate('phones'),
								type: 'content',
								message: messages.phones,
								content: <PhonesForm phones={phones} setPhones={setPhones} />
							},
							{
								text: Translate('emails'),
								type: 'content',
								content: <EmailsForm emails={emails} setEmails={setEmails} />
							},
						]
					}
					form = {contactForm}
					setForm = {setContactForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('save')}
					class = 'bg-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export { ContactForm };