import React from 'react';
import {Content, Section, Avatar, Text} from 'crack-ux';
import {Text as TextF, Utilities} from 'crack-functions';

// Contexts
import {useBusinessPage} from '../../../../contexts/pages/public/BusinessPage';

const Init = (props) =>
{
	const { data } = props;

	const { business } = useBusinessPage();
	
	const { page = {} } = business;
	const { general = {}, init = {} } = data || page;

	const logo = React.useMemo(() =>
	{
		return false ? `${business.picture}` : TextF.Initials(general.businessName);
	}, [business.picture, general.businessName]);

	return (
		<Content class='init desktop no-padding relative'>
			<img className='cover-page' src={init.coverPage || '/public-page/cover-page.png'} alt='cover-page'/>
			<Section class='business-info'>
				<Avatar
					class = 'logo'
					src = {logo}
					size = {200}
					style =
					{{
						background: general.primaryColor,
						color: Utilities.HexIsLight(general.primaryColor) ? 'black' : 'white'
					}}
				/>
				<Text class='business-name'>{general.businessName}</Text>
			</Section>
		</Content>
	)
}

export {Init};