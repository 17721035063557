import { Section, Content, Text, Panel, Card } from 'crack-ux';

const Testimonials = () =>
{
	return (
		<Section class='testimonials'>
			<Content class='desktop center'>
				<Text class='title'>Historias reales.<br/>Resultados reales.</Text>
				<Text class='subtitle'>
					Cada negocio es diferente, pero todos comparten algo en común: SICE los ayuda a crecer.
				</Text>
				<Panel class='tablet6 desktop4'>
					<Card class='testimonial'>
						<Text class='quote'>
							“Desde que usamos SICE, el control de nuestras ventas a stock mejoró completamente.
							Ya no perdemos tiempo con Excel.”
						</Text>
						<Text class='name'>María Gutiérrez</Text>
						<Text class='role'>Dueña · Comercial La Paz</Text>
					</Card>
				</Panel>
				<Panel class='tablet6 desktop4'>
					<Card class='testimonial'>
						<Text class='quote'>
							“Lo que más me gusta es que adaptaron el sistema a nuestras reglas de precios. 
							Sentí que entendieron nuestro negocio.”
						</Text>
						<Text class='name'>Luís Romero</Text>
						<Text class='role'>Administrador · Ferretería El Progreso</Text>
					</Card>
				</Panel>
				<Panel class='tablet6 desktop4'>
					<Card class='testimonial'>
						<Text class='quote'>
							“Los reportes automatizan todo y me ayudan a tomar decisiones sin esperar al contador. 
							¡Es como tener un asistente más!”
						</Text>
						<Text class='name'>Andrea Suárez</Text>
						<Text class='role'>Gerente · Boutique Inspira</Text>
					</Card>
				</Panel>
			</Content>
		</Section>
	)
}

export { Testimonials };