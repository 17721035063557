import {useCallback} from 'react';
import {Section, Panel, Card, Header, Body, Footer, Box, Text, Button, Icon} from 'crack-ux';
import {Amount, Utilities} from 'crack-functions';

// Contexts
import {useModal} from '../../../../../contexts/general/Modal';
import {useBusinessPage} from '../../../../../contexts/pages/public/BusinessPage';

// Components
import {ServiceDetail} from '../ServiceDetail';

const Feature = (feature) =>
{
	const {description, checked} = feature;

	const iconClass = checked ? 'icon-check-1 selected' : 'icon-cancel-1 none';

	return (
		<Section class='feature'>
			<Text>{description}</Text>
			<Icon class={`checked ${iconClass}`}/>
		</Section>
	);
}

const Service = (service = {}) =>
{
	const {_id, name, price, period, features} = service;

	const {AddModal} = useModal();
	const {business, shoppingCart, AddServiceToShoppingCart, RemoveServiceFromShoppingCart} = useBusinessPage();

	const {page = {}} = business;
	const {services: servicesConf = {}} = page;

	const shoppingCartService = shoppingCart.services[_id];
	const existService = shoppingCartService?.quantity > 0;

	const ShowServiceDetail = useCallback(() =>
	{
		AddModal(
			<Card class='service-detail-modal'>
				<ServiceDetail {...service}/>
			</Card>
		);
	}, [AddModal, service]);

	return (
		<Panel class='service desktop3'>
			<Card>
				<Header
					class = 'center no-border'
					style =
					{{
						backgroundColor: servicesConf.card?.background,
						color: Utilities.HexIsLight(servicesConf.card?.background) ? 'black' : 'white'
					}}
				>
					<Text class='name block'>{name}</Text>
					<Text class='price block'>{Amount.CurrencyFormat(price.sale)}</Text>
					<Text class='period block'>{period}</Text>
				</Header>
				<Box
					class = 'header-arrow'
					style =
					{{
						backgroundColor: servicesConf.card?.background,
					}}
				/>
				<Body>
					{features.map((feature, index) => <Feature key={index} {...feature}/>)}
				</Body>
				<Footer class='center no-border'>
					<Button
						class = 'action-button'
						text = {servicesConf.detailButton?.text}
						Click = {ShowServiceDetail}
						style =
						{{
							backgroundColor: servicesConf.detailButton?.background,
							color: Utilities.HexIsLight(servicesConf.detailButton?.background) ? 'black' : 'white'
						}}
					/>
					<Button
						class = 'action-button'
						text = {existService ? servicesConf.removeServiceButton?.text : servicesConf.addServiceButton?.text}
						Click = {() => existService ? RemoveServiceFromShoppingCart(service) : AddServiceToShoppingCart(service)}
						style =
						{{
							backgroundColor: existService ? servicesConf.removeServiceButton?.background : servicesConf.addServiceButton?.background,
							color: Utilities.HexIsLight(existService ? servicesConf.removeServiceButton?.background : servicesConf.addServiceButton?.background) ? 'black' : 'white'
						}}
					/>
				</Footer>
			</Card>
		</Panel>
	);
}

export {Service};