// import { useMemo } from 'react';
import { Content, Card, Panel } from 'crack-ux';
import { useParams } from 'react-router-dom';

// Components
import { useGeneral } from '../../../../contexts/general/General';
import { ContactsTable } from './ContactsTable';
// import { MovementsTable } from '../Movements/MovementsTable';
// import { useMovements } from '../../../../contexts/collections/commerce/Movements';

const Contacts = () =>
{
	const { screen } = useGeneral();
	// const { movements } = useMovements();

	const { clientId } = useParams();

	// const clientMovements = useMemo(() => movements.filter(movement => (movement.contact === clientId && movement.type === 1)), [movements, clientId]);

	return (
		<Content>
			{(screen.device !== 'mobile' || !clientId) && 
			<Panel class='desktop6'>
				<Card>
					<ContactsTable/>
				</Card>
			</Panel>}
			{/* {(screen.device !== 'mobile' || clientId) &&
			<Panel class='desktop6'>
				<Card>
					<MovementsTable movements={clientMovements} from='clients'/>
				</Card>
			</Panel>} */}
		</Content>
	)
}

export {Contacts};