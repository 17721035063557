import { useNavigate, useParams } from 'react-router-dom';
import { Content, Section, Box, Header, Body, Footer, Text, Form, Button } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../contexts/general/Traductor';
import { useUsers } from '../../../contexts/main/Users';

// Static Data
import PhoneCodes from '../../../static-data/PhoneCodes.json';

// Styles
import './CreateAccount.css';
import { useEffect } from 'react';

const CreateAccount = () =>
{
	const navigate = useNavigate();

	const { Translate } = useTraductor();
	const { userForm, setUserForm, userMessages, CreateUser } = useUsers();
	const params = useParams();

	const { encryptedData } = params;

	useEffect(() =>
	{
		setUserForm({phoneCountry: 591});
	}, [setUserForm]);

	return (
		<Content class='create-account tablet relative'>
			<Box class='content'>
				<Header class='center'>
					<Text class='logo block' size={5}>Sice</Text>
					<Text class='block' size={2}>{Translate('create-account')}</Text>
				</Header>
				<Body>
					<Form
						inputs=
						{[
							{
								name: 'username',
								text: Translate('username'),
								placeholder: Translate('username'),
								message: userMessages.username,
							},
							{
								name: 'name',
								text: Translate('name'),
								placeholder: Translate('name'),
								class: 'tablet6',
								message: userMessages.name,
							},
							{
								name: 'lastName',
								text: Translate('last-name'),
								placeholder: Translate('last-name'),
								class: 'tablet6',
								message: userMessages.lastName,
							},
							{
								name: 'phoneCountry',
								text: Translate('cellphone'),
								class: 'mobile5',
								type: 'select',
								options: PhoneCodes,
								value: userForm.phoneCountry,
							},
							{
								name: 'phoneNumber',
								placeholder: Translate('number'),
								class: 'mobile7',
								type: 'number',
								message: userMessages.phoneNumber,
							},
							{
								name: 'email',
								text: Translate('email'),
								placeholder: Translate('email'),
								message: userMessages.email
							},
							{
								name: 'password',
								text: Translate('password'),
								type: 'password',
								placeholder: Translate('password'),
								class: 'tablet6',
								message: userMessages.password
							},
							{
								name: 'confirmPassword',
								text: Translate('confirm-password'),
								type: 'password',
								placeholder: Translate('confirm-password'),
								class: 'tablet6',
								message: userMessages.confirmPassword
							},
						]}
						form={userForm}
						setForm={setUserForm}
						messages={userMessages}
					/>
				</Body>
				<Footer>
					<Button
						class='full-width bg-blue white'
						text={Translate('create-account')}
						Click={async () =>
						{
							const response = await CreateUser(encryptedData);
							
							if(response && response.type === 'success')
							{
								navigate('/p');
							}
						}}
					/>
					<Section class='center' style={{marginTop: 15}}>
						<Text>{Translate('have-account')}</Text>
						<Text class='blue pointer' Click={() => navigate('/login')} style={{marginLeft: 5}}>{Translate('login-here')}</Text>
					</Section>
				</Footer>
			</Box>
		</Content>
	);
}

export { CreateAccount }