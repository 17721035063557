import React from 'react';

import {Card, Form, Text, Header, Body, Footer, Button} from 'crack-ux';

// Contexts
import {useModal} from '../../../../../contexts/general/Modal';
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useCRM} from '../../../../../contexts/modules/CRM';
import {useContacts} from '../../../../../contexts/collections/crm/Contacts';
import {useCompanies} from '../../../../../contexts/collections/crm/Companies';

const CompanyContact = () =>
{
	const [form, setForm] = React.useState({});
	const [message, setMessage] = React.useState(null);

	const {CloseModal} = useModal();
	const {Translate} = useTraductor();

	const {ShowContactForm} = useCRM();

	const {contacts, SimpleUpdate} = useContacts();
	const {currentCompany} = useCompanies();

	const Submit = () =>
	{
		if(form.contact && currentCompany?._id)
		{
			const contact = contacts.find(contact => contact._id === form.contact) || contacts.find(contact => contact.fullName === form.contact);

			if(contact)
			{
				if(!Array.isArray(contact.companies) || contact.companies.indexOf(currentCompany._id) === -1)
				{
					const data =
					{
						_id: contact._id,
						companies: Array.isArray(contact.companies) ? [...contact.companies, currentCompany._id] : [currentCompany._id]
					};

					SimpleUpdate(data).then(response =>
					{
						if(response.status === 200)
						{
							CloseModal();
						}
					});
				}
				else
				{
					setMessage(<Text class='red'>{Translate('Contact already linked')}</Text>);
				}
			}
			else
			{
				setMessage(<Text class='red'>{Translate('Contact not found')}</Text>);
			}
		}
	}

	return (
		<Card style={{width: 480, maxWidth: 'calc(100vw - 20px)'}}>
			<Header>
				<Text class='bold'>{Translate('Add Contact')}</Text>
			</Header>
			<Body>
				<Form
					inputs =
					{[
						{
							name: 'contact',
							text: Translate('Contact'),
							type: 'autocomplete',
							options: contacts.filter(contact => !Array.isArray(contact.companies) || contact.companies.indexOf(currentCompany._id) === -1).map(contact => ({text: contact.fullName, value: contact._id})),
							value: form.contact,
						},
					]}
					response = {message}
					Change = {setForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Link Contact')}
					class = 'bg-green white'
					Click = {Submit}
				/>
				<Button
					text = {Translate('New Contact')}
					class = 'bg-blue white'
					Click = {() =>
					{
						if(currentCompany?._id)
						{
							CloseModal();
							setTimeout(() =>
							{
								ShowContactForm({name: form.contact || '', companies: [currentCompany._id]});
							}, 0);
						}
					}}
				/>
				<Button
					text = {Translate('Close')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {CompanyContact}