import { createContext, useCallback, useContext, useEffect, useReducer, useState } from "react";

// Contexts
import { useSession } from "../general/Session";
import { useSocket } from "../general/Socket";
import { useTraductor } from "../general/Traductor";
import { useBusiness as useBusinessLayout } from "../layouts/Business";

// Reducers
import { BusinessReducer } from "../../reducers/main/Business";

const defaultBusiness =
{
	whatsapps: []
}

const BusinessContext = createContext({});

const BusinessProvider = (props) =>
{
	const [state, dispatch] = useReducer(BusinessReducer, { businesses: [] });
	const [currentBusiness, setCurrentBusiness] = useState(defaultBusiness);

	const { businesses, SessionFetch, UpdateSession } = useSession();
	const { ConnectEvents } = useSocket();
	const { Translate } = useTraductor();
	const { path, BusinessFetch, SomethingWentWrong } = useBusinessLayout();

	const CreateBusiness = async (business) =>
	{
		const response = SessionFetch.Post('/business/create', business);

		if(response.status === 200)
		{
			dispatch({type: 'CREATE_BUSINESS', business: response.data.business});

			UpdateSession();
		}
		
		return response;
	}

	const UpdatePreferences = async (preferences) =>
	{
		try
		{
			const response = await BusinessFetch.Put('/business/preferences', preferences);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate(`${preferences.module}-preferences-updated`),	
				});
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('UpdatePreferences');
		}
	}

	const ReadWhatsappNumbers = useCallback(async () =>
	{
		try
		{
			if(currentBusiness._id)
			{
				const response = await BusinessFetch.Get('/business/whatsapp/number');

				if(response.status === 200)
				{
					dispatch({type: 'LOAD_WHATSAPPS', businessId: currentBusiness._id, whatsapps: response.data});
				}
			}
		}
		catch(error)
		{
			console.log('ReadWhatsappNumbers', error);
		}
	}, [BusinessFetch, currentBusiness._id]);

	const CreateWhatsappNumber = async (whatsapp) =>
	{
		try
		{
			const whatsappData = {...whatsapp};

			whatsappData.country = String(whatsappData.country);
			whatsappData.number = String(whatsappData.number);

			const response = await BusinessFetch.Post('/business/whatsapp/number', whatsappData);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('whatsapp-created'),
				});

				return {
					status: 200,	
					messages: {}
				}
			}
			else
			{
				const messages = {};

				for(const key in response.validations)
				{
					messages[key] = response.validations[key].validations[0].content;
				}

				return {
					status: 202,
					messages
				};
			}
		}
		catch(error)
		{
			window.CrackUX.Toasts.AddToast(
			{
				class: 'error',
				title: Translate('something-went-wrong'),
				message: Translate('refresh-try-again-contact-support'),
			});
			
			console.log('CreateWhatsappNumber', error);
		}
	}

	const UpdateWhatsappNumber = async (whatsapp) =>
	{
		try
		{
			const whatsappData = {...whatsapp};

			whatsappData.country = String(whatsappData.country);
			whatsappData.number = String(whatsappData.number);
			
			delete whatsappData.status;

			const response = await BusinessFetch.Put('/business/whatsapp/number', whatsappData);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('whatsapp-updated'),
				});

				return {
					status: 200,	
					messages: {}
				}
			}
			else
			{
				const messages = {};

				for(const key in response.validations)
				{
					messages[key] = response.validations[key].validations[0].content;
				}

				return {
					status: 202,
					messages
				};
			}
		}
		catch(error)
		{
			window.CrackUX.Toasts.AddToast(
			{
				class: 'error',
				title: Translate('something-went-wrong'),
				message: Translate('refresh-try-again-contact-support'),
			});
			
			console.log('UpdateWhatsappNumber', error);
		}
	}

	const DeleteWhatsappNumber = async (whatsapp) =>
	{
		try
		{
			const query =
			{
				_id: whatsapp._id
			}

			const response = await BusinessFetch.Delete('/business/whatsapp/number', query);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('whatsapp-deleted'),
				});
			}
		}
		catch(error)
		{
			window.CrackUX.Toasts.AddToast(
			{
				class: 'error',
				title: Translate('something-went-wrong'),
				message: Translate('refresh-try-again-contact-support'),
			});
			
			console.log('DeleteWhatsappNumber', error);
		}
	}

	useEffect(() =>
	{
		dispatch({type: 'LOAD_BUSINESSES', businesses});
	}, [businesses]);

	useEffect(() =>
	{
		if(state.businesses.length > 0 && path?.uniqueName)
		{
			const businessFound = state.businesses.find(business => business.uniqueName === path.uniqueName);

			if(businessFound)
			{
				setCurrentBusiness(businessFound);
			}
		}
	}, [state.businesses, path]);

	useEffect(() =>
	{
		if(currentBusiness._id)
		{
			const events =
			[
				{
					name: `WHATSAPP_NUMBER_CREATE`,
					Function: (whatsapp) => dispatch({type: 'CREATE_WHATSAPP', businessId: currentBusiness._id, whatsapp})
				},
				{
					name: `WHATSAPP_NUMBER_UPDATE`,
					Function: (whatsapp) => dispatch({type: 'UPDATE_WHATSAPP', businessId: currentBusiness._id, whatsapp})
				},
				{
					name: `WHATSAPP_NUMBER_DELETE`,
					Function: (whatsapp) => dispatch({type: 'DELETE_WHATSAPP', businessId: currentBusiness._id, whatsapp})
				}
			];
	
			ConnectEvents(currentBusiness._id, events);
		}
	
	}, [currentBusiness._id, ConnectEvents]);

	const value =
	{
		businesses: state.businesses,
		currentBusiness,
		setCurrentBusiness,
		CreateBusiness,
		UpdatePreferences,
		CreateWhatsappNumber,
		ReadWhatsappNumbers,
		UpdateWhatsappNumber,
		DeleteWhatsappNumber,
	}

	return (
		<BusinessContext.Provider value={value}>
			{props.children}
		</BusinessContext.Provider>
	);
}

const useBusiness = () => useContext(BusinessContext);

export {BusinessProvider, useBusiness};