import {Section, Header, Body, Text, List} from 'crack-ux';
import {Link} from 'react-router-dom';

// Contexts
import {useGeneral} from '../../contexts/general/General';
import {useBusiness} from '../../contexts/layouts/Business';
import {useTraductor} from '../../contexts/general/Traductor';
import {useModules} from '../../contexts/main/Modules';

// Styles
import './Menu.css'
import { useMemo } from 'react';

const Menu = () =>
{
	const { screen } = useGeneral();
	const { member, showMenu, path } = useBusiness();
	const { Translate } = useTraductor();
	const { currentModule } = useModules();

	const filteredMenu = useMemo(() =>
	{
		if(Object.keys(member).length === 0) return [];

		return Array.isArray(currentModule.menu) ? currentModule.menu.filter(option =>
		{
			if (member.isBusinessOwner) return true;

			const modulePermissions = member.permissions[currentModule.route];
			if (!modulePermissions || !modulePermissions.value) return false;

			const optionPermissions = modulePermissions.permissions ? modulePermissions.permissions[option.route]?.value : null;
			if (!optionPermissions) return false;

			return true;
		}) : [];
	}, [currentModule, member]);

	return (
		<Section class={`navigation-menu ${showMenu ? 'show' : 'hide'} ${(screen.device === 'mobile' || screen.device === 'tablet')? 'mobile-menu' : ''}`}>
			<Header class='module'>
				<Text>{Translate(currentModule.name)}</Text>
			</Header>
			<Body class='container-menu no-padding'>
				<List
					class = 'menu'
					currentClass = 'bg-blue white'
					current = {`./${path.module}/${path.menu}`}
					autoSelect = {false}
					options = {filteredMenu.map(option =>
					{
						return {
							identifier: `./${currentModule.route}/${option.route}`,
							content: <Link to={`./${currentModule.route}/${option.route}`}><Text class='block'>{Translate(option.name)}</Text></Link>
						}
					})}
				/>
			</Body>
		</Section>
	)
}

export {Menu};