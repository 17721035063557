import { Section, Content, Text, Panel, Card, Image, Button } from 'crack-ux';

const Modules = () =>
{
	return (
		<Section class='modules'>
			<Content class='desktop center'>
				<Text class='title'>El sistema que crece con tu negocio</Text>
				<Text class='subtitle'>
					Comienza con lo que necesitas hoy. Activa nuevos módulos cuando estés listo.
				</Text>
				<Panel class='tablet6 desktop3'>
					<Card class='module'>
						<Section class='image'>
							<Image src='/sice-page/happy-computer.png' alt='Commerce'/>
						</Section>
						<Text class='module-title'>Comercio</Text>
						<Text class='module-description'>
							Gestiona ventas, compras e inventario de manejo eficaz desde un solo lugar.
						</Text>
						<Button class='tag tag-green' text='Disponible ahora'/>
					</Card>
				</Panel>
				<Panel class='tablet6 desktop3'>
					<Card class='module'>
						<Section class='image'>
							<Image src='/sice-page/happy-computer.png' alt='CRM'/>
						</Section>
						<Text class='module-title'>CRM</Text>
						<Text class='module-description'>
							Administra clientes, aumentá las ventas y fideliza a tus carteras con flexibilidad.
						</Text>
						<Button class='tag tag-yellow' text='Lanzamiento próximo'/>
					</Card>
				</Panel>
				<Panel class='tablet6 desktop3'>
					<Card class='module'>
						<Section class='image'>
							<Image src='/sice-page/happy-computer.png' alt='Accountancy'/>
						</Section>
						<Text class='module-title'>Contabilidad</Text>
						<Text class='module-description'>
							Controla contabilidad y finanzas desde el ingreso hasta las obligaciones fiscales.
						</Text>
						<Button class='tag tag-blue' text='En desarrollo'/>
					</Card>
				</Panel>
			</Content>
		</Section>
	)
}

export { Modules };