import { ToggleContent, Form } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';

const ReviewsForm = (props) =>
{
	const { reviewsForm, setReviewsForm, messages } = props;

	const { Translate } = useTraductor();

	return (
		<ToggleContent text={Translate('reviews')}>
			<Form
				inputs =
				{[
					{
						name: 'title',
						text: Translate('title'),
						value: reviewsForm.title,
						message: messages.title,
					},
					{
						name: 'caption',
						text: Translate('caption'),
						value: reviewsForm.caption,
						message: messages.caption,
					},
					{
						name: 'cardBackground',
						text: Translate('card-background'),
						type: 'color',
						value: reviewsForm.cardBackground,
						message: messages.cardBackground,
					},
				]}
				form = {reviewsForm}
				setForm = {setReviewsForm}
			/>
		</ToggleContent>
	)
}

export { ReviewsForm };