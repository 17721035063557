import { useCallback, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Page, Section, Header, Body, ContextMenu, Box, SidePanel, Text, Avatar } from 'crack-ux';
import { Text as TextF } from 'crack-functions';

// Views
import { Loading } from '../../components/views/Loading';

// Components
import { Modules } from './Modules';
import { Menu } from './Menu';
import { MultiFunctionPanel } from './MultiFunctionPanel.jsx';
import { BusinessSelector } from './BusinessSelector';

// Pages
import { CRM } from '../../pages/business/crm';
import { Home } from '../../pages/business/home';
import { Commerce } from '../../pages/business/commerce';
import { Vouchers } from '../../pages/business/vouchers';
import { Storage } from '../../pages/business/storage';
import { Staff } from '../../pages/business/staff';
import { Scheduler } from '../../pages/business/scheduler';

// Contexts
import { useGeneral } from '../../contexts/general/General';
import { useTraductor } from '../../contexts/general/Traductor.js';
import { useSession } from '../../contexts/general/Session.js';
import { SocketProvider } from '../../contexts/general/Socket.js';
import { BusinessProvider, useBusiness } from '../../contexts/layouts/Business.js';
import { BusinessProvider as BusinessProviderMain, useBusiness as useBusinessMain } from '../../contexts/main/Business.js';
import { MembersProvider } from '../../contexts/collections/staff/Members.js';
import { WhatsappIntegrationProvider } from '../../contexts/integrations/Whatsapp.js';
import { ItemsProvider } from '../../contexts/collections/storage/Items.js';
import { ModulesProvider } from '../../contexts/main/Modules';
import { SystemVariablesProvider } from '../../contexts/collections/home/SystemVariables';
import { ListsProvider } from '../../contexts/collections/home/Lists';
import { NotificationsProvider } from '../../contexts/main/Notifications';

// Styles
import './Business.css';

const Business = () =>
{
	const { screen } = useGeneral();
	const { Translate } = useTraductor();
	const { user, loading } = useSession();
	const { business, showMenu, showAccount, SetShowAccount, SwitchAccount, multiFunctionPanel, setMultiFunctionPanel, path } = useBusiness();
	const { ReadWhatsappNumbers } = useBusinessMain();
	// const {notSeen} = useNotifications();

	const navigate = useNavigate();

	const sections = path.relative.split('/');

	const HandleShowAccount = useCallback((identifier) =>
	{
		if(multiFunctionPanel === identifier)
		{
			SwitchAccount();
		}
		else
		{
			setMultiFunctionPanel(identifier);

			if(!showAccount)
			{
				SetShowAccount(identifier)
			}
		}
	}, [setMultiFunctionPanel, SetShowAccount, SwitchAccount, multiFunctionPanel, showAccount]);

	// const badge = useMemo(() => notSeen > 0 ? <Text size={0.6} class='bg-red white center' style={{paddingTop: 2.5, paddingLeft: 2}}>{notSeen > 9 ? '9+' : notSeen}</Text> : <></>, [notSeen]);

	const GetDisplayText = (text, Translate) => 
	{
		if (/%[0-9A-Fa-f]{2}/.test(text))
		{
			return decodeURIComponent(text);
		}

		return Translate(text);
	};

	useEffect(() =>
	{
		if(business._id)
		{
			ReadWhatsappNumbers();
		}
	}, [business._id, ReadWhatsappNumbers]);

	if(loading)
	{
		return <Loading/>;
	}
	else
	{
		const fullName = TextF.FullName(user.name, user.lastName);

		return (
			<Page class='business-layout relative'>
				<Section class='navigation'>
					<Modules/>
					<Menu/>
				</Section>
				<Section class={`content ${(screen.device !== 'mobile' && screen.device !== 'tablet') ? showMenu ? 'contracted' : 'expanded' : ''}`}>
					<Header class='bg-blue no-border'>
						<Box class='work-route'>
							<Box class='business-dropdown-box'>
								<BusinessSelector/>
							</Box>
							<Box class='breadcrumbs' style={{marginLeft: 10}}>
								{
									screen.device === 'mobile'
									? <Box class='relative'>
										<Text class='white'>
											{GetDisplayText(path.view || path.menu, Translate)}
										</Text>
										{/* <Text
											class='white'
											Click={() => navigate(`/b/${business.uniqueName}/${path.module}/${path.menu}`)}
										>
											{GetDisplayText(path.menu, Translate)}
										</Text>
										{path.view && <Text class='light-gray' style={{marginLeft: 10}}>/ {GetDisplayText(path.view, Translate)}</Text>} */}
									</Box>
									: (() => {
										const pathMenuIndex = sections.findIndex(section => section === path.menu);
										const maxIndex = pathMenuIndex + 1; // Permite hasta un elemento después de path.menu
										const limitedSections = sections.slice(0, maxIndex + 1); // Incluye un elemento más después del match

										return limitedSections.map((section, index) => 
										{
											const pathToSection = `/b/${business.uniqueName}/${sections.slice(0, index + 1).join('/')}`;
											const lastRoute = index === limitedSections.length - 1; // Cambia a comparar con el límite ajustado

											return (
												<Text
													key={index}
													class={lastRoute ? 'light-gray' : 'pointer white'}
													Click={lastRoute ? () => {} : () => navigate(pathToSection)}
												>
													{index > 0 && <span style={{margin: '0px 10px'}}>/</span>}
													{GetDisplayText(section, Translate)}
												</Text>
											);
										});
									})()
								}
							</Box>
						</Box>
						<Box class='float-right'>
							{/* <Box class='messages'>
								<Icon
									class = 'icon-chat white pointer'
									Click = {() => HandleShowAccount('messages')}
								/>
							</Box> */}
							{/* <Box class='notifications'>
								<Badge
									class = 'pointer'
									content = {badge}
									Click = {() =>
									{
										HandleShowAccount('notifications');
									}}
									vertical = 'bottom'
									horizontal = 'right'
									style = {{marginTop: 0}}
								>
									<Icon
										class = 'icon-globe white pointer'
									/>
								</Badge>
							</Box> */}
							<Box
								class = 'profile-account pointer'
								Click = {() => HandleShowAccount('profile')}
							>
								{screen.device !== 'mobile' ? <Text class='white'>{fullName}</Text> : <></>}
								<Avatar
									class = 'bg-gray white round' 
									src = {user.pictureURL || TextF.Initials(fullName)} 
									style =
									{{
										marginTop: -9.5,
										marginLeft: 10
									}}
									error = {TextF.Initials(fullName)}
								/>
							</Box>
						</Box>
					</Header>
					<Body class='no-padding'>
						<Routes>
							<Route path={'/home/*'} element={<Home/>}/>
							<Route path={'/crm/*'} element={<CRM/>}/>
							<Route path={'/commerce/*'} element={<Commerce/>}/>
							<Route path={'/vouchers/*'} element={<Vouchers/>}/>
							<Route path={'/scheduler/*'} element={<Scheduler/>}/>
							<Route path={'/storage/*'} element={<Storage/>}/>
							<Route path={'/staff/*'} element={<Staff/>}/>
						</Routes>
					</Body>
				</Section>
				<SidePanel class='multi-function-panel no-scroll' show={showAccount} style={{backgroundColor: '#ffffff'}}>
					<MultiFunctionPanel/>
				</SidePanel>
			</Page>
		)
	}
}

const BusinessWithProviders = (props) =>
{
	return (
		<SocketProvider>
			<BusinessProvider>
				<BusinessProviderMain>
					<MembersProvider>
						<ItemsProvider>
							<WhatsappIntegrationProvider>
								<NotificationsProvider>
									<ListsProvider>
										<SystemVariablesProvider>
											<ModulesProvider>
												<Business {...props}/>
												<ContextMenu/>
											</ModulesProvider>
										</SystemVariablesProvider>
									</ListsProvider>
								</NotificationsProvider>
							</WhatsappIntegrationProvider>
						</ItemsProvider>
					</MembersProvider>
				</BusinessProviderMain>
			</BusinessProvider>
		</SocketProvider>
	)
}

export {BusinessWithProviders as Business}