import { Content, Section } from 'crack-ux';

const Hero = () =>
{
	return (
		<Section class='hero'>
			<Content class='desktop'>
				<h1>Hero</h1>
			</Content>
		</Section>
	)
}

export { Hero };