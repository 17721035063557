import { ToggleContent, Form } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';

const ProductsForm = (props) =>
{
	const { productsForm, setProductsFrom, messages } = props;

	const { Translate } = useTraductor();

	return (
		<ToggleContent text={Translate('products')}>
			<Form
				inputs =
				{[
					{
						name: 'title',
						text: Translate('title'),
						value: productsForm.title,
						message: messages.title,
					},
					{
						name: 'caption',
						text: Translate('caption'),
						value: productsForm.caption,
						message: messages.caption,
					},
					{
						name: 'cardBackground',
						text: Translate('card-background'),
						type: 'color',
						value: productsForm.cardBackground,
						message: messages.cardBackground,
					},
					{
						name: 'priceColor',
						text: Translate('price-color'),
						type: 'color',
						value: productsForm.priceColor,
						message: messages.priceColor,
					},
					{
						name: 'detailButtonText',
						text: Translate('detail-button-text'),
						value: productsForm.detailButtonText,
						message: messages.detailButtonText,
					},
					{
						name: 'detailButtonBackground',
						text: Translate('detail-button-background'),
						type: 'color',
						value: productsForm.detailButtonBackground,
						message: messages.detailButtonBackground,
					},
					{
						name: 'addProductButtonText',
						text: Translate('add-product-button-text'),
						value: productsForm.addProductButtonText,
						message: messages.addProductButtonText,
					},
					{
						name: 'addProductButtonBackground',
						text: Translate('add-product-button-background'),
						type: 'color',
						value: productsForm.addProductButtonBackground,
						message: messages.addProductButtonBackground,
					},
					{
						name: 'moreProductsButtonText',
						text: Translate('more-products-button-text'),
						value: productsForm.moreProductsButtonText,
						message: messages.moreProductsButtonText,
					},
					{
						name: 'moreProductsButtonBackground',
						text: Translate('more-products-button-background'),
						type: 'color',
						value: productsForm.moreProductsButtonBackground,
						message: messages.moreProductsButtonBackground,
					}
				]}
				form = {productsForm}
				setForm = {setProductsFrom}
			/>
		</ToggleContent>
	)
}

export { ProductsForm };