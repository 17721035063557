import { Route, Routes } from "react-router-dom";

// Pages
import { Templates } from "./Templates";

const TemplatesRouter = () =>
{
	return (
		<Routes>
			<Route path="/whatsapp" element={<Templates channel='whatsapp'/>} />
			<Route path="/notes" element={<Templates channel='notes'/>} />
			<Route path="/" element={<Templates/>} />
		</Routes>
	);
}

export { TemplatesRouter as Templates };