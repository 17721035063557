import React from "react";

// Contexts
import {useModal} from "../general/Modal";
import {EventsConfigProvider} from "../collections/scheduler/EventsConfig";
import {EventsProvider} from "../collections/scheduler/Events";

// Forms
import {EventConfigForm} from "../../components/forms/modules/scheduler/EventConfig/EventConfig";
import {EventForm} from "../../components/forms/modules/scheduler/Event/Event";

// Warnings
import {ConfirmEventRecurrence} from "../../components/warnings/scheduler/ConfirmEventRecurrence/ConfirmEventRecurrence";

const SchedulerModuleContext = React.createContext({});

const SchedulerProviderModule = (props) =>
{
	const {AddModal} = useModal();

	const ShowEventConfigForm = (eventConfig = {}) =>
	{
		AddModal(<EventConfigForm eventConfig = {eventConfig}/>);
	}

	const ShowEventForm = (event = {}) =>
	{
		AddModal(<EventForm event = {event}/>);
	}

	const ShowConfirmEventRecurrence = (event = {}, params = {}) =>
	{
		const
		{
			action
		} = params;

		AddModal(<ConfirmEventRecurrence event = {event} action = {action}/>);
	}

	const value =
	{
		ShowEventConfigForm,
		ShowEventForm,
		ShowConfirmEventRecurrence,
	};

	return (
		<EventsConfigProvider>
			<EventsProvider>
				<SchedulerModuleContext.Provider value={value}>
					{props.children}
				</SchedulerModuleContext.Provider>
			</EventsProvider>
		</EventsConfigProvider>
	);
}

const useSchedulerModule = () => React.useContext(SchedulerModuleContext);

export {SchedulerProviderModule, useSchedulerModule};