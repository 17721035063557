import { useMemo, useState } from 'react';
import { Table, Confirm, Text, Avatar, Header, Body } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useSession } from '../../../../contexts/general/Session';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useModal } from '../../../../contexts/general/Modal';
import { useItems } from '../../../../contexts/collections/storage/Items';
import { useProducts } from '../../../../contexts/collections/commerce/Products';
import { useLists } from '../../../../contexts/collections/home/Lists';

// Components
import { QRGenerator } from '../../../../components/QRGenerator';
import { ProductForm } from './ProductForm';

const ProductsTable = () =>
{
	const [showConfirm, setShowConfirm] = useState(false);

	const { screen } = useGeneral();
	const { user } = useSession();
	const { Translate } = useTraductor();
	const { AddModal } = useModal();
	const { itemsById } = useItems();
	const { products, currentProduct, setCurrentProduct, UpdateProduct, DeleteProduct } = useProducts();
	const { listsByName } = useLists();

	const navigate = useNavigate();
	const { categoryId } = useParams();

	const categoriesList = useMemo(() => listsByName['product-categories'] || [], [listsByName]);
	const categories = useMemo(() => ((categoryId && categoryId !== 'all-categories') ? [categoryId] : []), [categoryId]);
	const unitsList = useMemo(() => listsByName.units || [], [listsByName]);

	const productsPictures = useMemo(() =>
	{
		const result = {};

		products.forEach(product =>
		{
			const url = itemsById[product.pictures[0]]?.publicURL || '/assets/box.png';

			result[product._id] = <Avatar src={url} error={'/assets/box.png'} size={32} round={false}/>
		});

		return result;
	}, [products, itemsById]);

	const filteredProducts = useMemo(() =>
	{
		if(categoryId === 'all-categories') return products;

		return products.filter(product => product.categories ? product.categories.includes(categoryId) : false);
	}, [categoryId, products]);

	return (
		<>
			<Header>
				<Text class='title'>{Translate('products')}</Text>
			</Header>
			<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
				<Table
					class = 'hover pointer'
					header = 
					{[
						{
							name: 'status',
							text: Translate('status'),
							class: 'center',
							width: 75
						},
						{
							name: 'pictureMiniature',
							text: Translate('picture'),
							class: 'center',
							width: 80
						},
						{
							name: 'codeText',
							text: Translate('code'),
							width: 90
						},
						{
							name: 'name',
							text: Translate('product-name'),
							width: 200
						},
						{
							name: 'categoriesText',
							text: Translate('categories')
						},
						{
							name: 'currentQuantity',
							text: Translate('quantity'),
							width: 82
						},
						{
							name: 'unitText',
							text: Translate('unit'),
						},
						{
							name: 'purchaseText',
							text: Translate('purchase-price'),
							width: 135
						},
						{
							name: 'salesText',
							text: Translate('sales-price'),
							width: 120
						},
						// {
						// 	name: 'grossProfit',
						// 	text: Translate('Gross Profit'),
						// 	width: 120
						// },
						// {
						// 	name: 'grossMargin',
						// 	text: Translate('Gross Margin'),
						// 	width: 120
						// },
					]}
					body = {filteredProducts.map(product =>
					{
						const categoriesFiltered = product?.categories ? product.categories.map(category => categoriesList.itemsById?.[category].text) : [];
						product.categoriesText = categoriesFiltered.join(', ');
						product.unitText = unitsList?.itemsById?.[product.unit]?.text || '';

						if(productsPictures[product._id]) product.pictureMiniature = productsPictures[product._id];

						return product;
					})}
					actions =
					{[
						{
							text: Translate('new-product'),
							class: 'bg-blue white',
							section: 'grouped',
							Click: () => AddModal(<ProductForm categories={categories}/>)
						},
						{
							text: Translate('stock-qr'),
							section: 'context',
							icon:
							{
								class: 'icon-qrcode'
							},
							Click: (product) => AddModal(<QRGenerator title={Translate('stock-qr')} value={product._id}/>)	
						},
						{
							text: Translate('edit-product'),
							section: 'context',
							icon:
							{
								class: 'icon-pencil'
							},
							Click: (product) => AddModal(<ProductForm product={product} categories={categories}/>)
						},
						{
							text: Translate('enable'),
							section: 'context',
							icon:
							{
								class: 'icon-dot-circled'
							},
							Condition: (product) => !product.available,
							Click: (product) => UpdateProduct({...product, available: true})
						},
						{
							text: Translate('disable'),
							section: 'context',
							icon:
							{
								class: 'icon-circle-empty'
							},
							Condition: (product) => product.available,
							Click: (product) => UpdateProduct({...product, available: false})
						},
						{
							text: Translate('delete-product'),
							class: 'red',
							section: 'context',
							icon:
							{
								class: 'icon-trash'
							},
							Click: (product) =>
							{
								setCurrentProduct(product);
								setShowConfirm(true);
							}
						}
					]}
					DoubleClickRow = {(product) => AddModal(<ProductForm product={product} categories={categories}/>)}
					language = {user.preferences?.language || 'es'}
					filters = 
					{{
						columns:
						{
							editable: true
						},
						Apply:
						{
							text: Translate('Apply'),
							Click: () => {}
						},
						Reset:
						{
							text: Translate('Reset'),
							Click: () => {}
						}
					}}
					BackButton = {screen.device === 'mobile' ?
					{
						Click: () => navigate('../')
					} : null}
				/>
			</Body>
			<Confirm
				show={showConfirm}
				header={<Text class='bold'>{Translate(`delete-product`)}</Text>}
				body=
				{
					<>
						<Text type='p'>{Translate(`confirm-delete-product`)}</Text>
						<Text type='p'><span className='bold'>{Translate('product-name')}: </span>{currentProduct.name}</Text>
					</>
				}
				confirm=
				{{
					text: Translate('delete'),
					class: 'bg-red white',
					Click: () =>
					{
						DeleteProduct(currentProduct);
						setShowConfirm(false);
					}
				}}
				cancel=
				{{
					text: Translate('cancel'),
					Click: () => setShowConfirm(false)
				}}
			/>
		</>
	)
}

export {ProductsTable};