import React from "react";
import {Content, Card, Panel, Header, Body, Text, Footer, Button} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';

const Settings = (props) =>
{
    const {Translate} = useTraductor();

	return (
		<Content class='no-padding desktop7'>
			<Panel class='full-height'>
				<Card>
					<Header>
						<Text class='bold'>{Translate('Invoicing Settings')}</Text>
						
					</Header>
					<Body style={{height: 'calc(100% - var(--header) - 67px)'}}>
						<Text class='bold' type='p'>{Translate('NIT')}</Text>
						<Text class='block'>aqui ira el nit</Text>
						<Text class='bold' type='p' style={{marginTop: 10}}>{Translate('Authorization Code')}</Text>
						<Text class='block'>aqui ira el codigo de autorizacion</Text>
					</Body>
					<Footer class='left'>
						<Button
							text = {Translate('Edit')}
							class = 'bg-blue white'
						/>
					</Footer>
				</Card>
			</Panel>
		</Content>
	)
}

export {Settings};