import { useMemo } from 'react';
import { Card, Header, Body, Footer, Text, Button, Image } from 'crack-ux';
import { Generic } from 'crack-functions';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';

const image = new RegExp('image/*');
const video = new RegExp('video/*');
const audio = new RegExp('audio/*');
const pdf = new RegExp('application/pdf');

const FileViewer = ({document}) =>
{
	const { screen } = useGeneral();
	const { Translate } = useTraductor();
	const { CloseModal } = useModal();

	const HandleDownload = () =>
	{
		Generic.DownloadURI(`${document.publicURL}?action=download`, document.name);
	}

	const content = useMemo(() =>
	{
		if(image.test(document.file.mimetype))
		{
			return (
				<Image src={document.publicURL}/>
			);
		}
		else if(video.test(document.file.mimetype))
		{
			return (
				<video
					className='full-center'
					controls={true}
					autoPlay={true}
					style =
					{{
						maxWidth: '100%',
						maxHeight: '100%'
					}}
				>
					<source src={document.publicURL} type={document.file.mimetype}></source>
				</video>
			);
		}
		else if(audio.test(document.file.mimetype))
		{
			return (
				<audio
					className='full-center'
					controls={true}
					autoPlay={true}
					style =
					{{
						maxWidth: '100%',
						maxHeight: '100%'
					}}
				>
					<source src={document.publicURL} type={document.file.mimetype}></source>
				</audio>
			);
		}
		else if(pdf.test(document.file.mimetype))
		{
			return (
				<object
					data={document.publicURL}
					type="application/pdf"
					width="100%"
					height="100%"
				>
					<iframe
						src={document.publicURL}
						width="100%"
						height="100%"
						style={{border: "none"}}
						title="abc"
					>
						<p>
							{Translate('Your browser does not support PDFs.')}.
						</p>
					</iframe>
				</object>
			);
		}
		else
		{
			return (
				<Image src='/assets/no-preview.jpg'/>
			);
		}
	}, [document, Translate]);

	const specificClass = useMemo(() =>
	{
		let classList = '';

		if(document.file?.mimetype === 'application/pdf')
		{
			classList = 'no-padding';
		}

		return classList;
	}, [document]);

	return (
		<Card style={{width: screen.device === 'mobile' ? '100vw' : 'calc(100vw - 40px)'}}>
			<Header>
				<Text class='bold'>{document.fullName}</Text>
			</Header>
			<Body class={`relative ${specificClass}`} style={{height: 'calc(100vh - var(--header) - var(--footer) - 40px)', width: '100%'}}>
				{content}
			</Body>
			<Footer class='right'>
				<Button
					class = 'bg-blue white'
					text = {Translate('Download')}
					Click = {HandleDownload}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	);
};

export {FileViewer};