import { createContext, useCallback, useContext, useEffect, useState } from "react";
import config from "../../config";

// Contexts
import { useGeneral } from "../general/General";
import { useSocket } from "../general/Socket";
import { useBusiness as useBusinessMain } from "../main/Business";
import { useBusiness } from "../layouts/Business";

const WhatsappIntegration = createContext();

const WhatsappIntegrationProvider = (props) =>
{
	const { children } = props;

	const [whatsappsIntegration, setWhatsappsIntegration] = useState({});

	const { Fetch } = useGeneral();
	const { ConnectEvents } = useSocket();
	const { business } = useBusiness();
	const { currentBusiness } = useBusinessMain();
	
	const ConnectWhatsapp = useCallback(async (whatsapp) =>
	{
		try
		{
			const data = 
			{
				room: business._id,
				url: `${config.server.fullDomain}/whatsapp`,
				whatsappId: whatsapp._id
			}

			await Fetch.Post(`${config.whatsapp.fullDomain}/whatsapp/connect`, data);
		}
		catch(error)
		{
			console.log('ConnectWhatsapp', error);
		}
	}, [business._id, Fetch]);

	const DisconnectWhatsapp = async (whatsapp) =>
	{
		try
		{
			const whatsappId = whatsapp._id

			await Fetch.Delete(`${config.whatsapp.fullDomain}/whatsapp/disconnect/${whatsappId}`);
		}
		catch(error)
		{
			console.log('DisconnectWhatsapp', error);
		}
	}

	const ConnectWhatsapps = useCallback(async () =>
	{
		try
		{
			for(const whatsapp of currentBusiness.whatsapps)
			{
				await ConnectWhatsapp(whatsapp);
			}
		}
		catch(error)
		{
			console.log('ConnectWhatsapps', error);
		}
	}, [currentBusiness.whatsapps, ConnectWhatsapp]);

	const CheckStatus = useCallback(async (whatsapp) =>
	{
		try
		{
			const whatsappId = whatsapp._id;

			const response = await Fetch.Get(`${config.whatsapp.fullDomain}/whatsapp/status/${whatsappId}`);

			if(response.status === 200)
			{
				const { status } = response.data;

				setWhatsappsIntegration(prevState =>
				{
					return {
						...prevState,
						[whatsapp._id]: 
						{
							...prevState[whatsapp._id],
							status
						}
					}
				});
			}
		}
		catch(error)
		{
			console.log('CheckStatus', error);
		}
	}, [Fetch]);

	useEffect(() =>
	{
		const room = `${business._id}`;

		const events =
		[
			{
				name: `QR-UPDATED`,
				Function: (data) =>
				{
					const { whatsappId, qr } = data;

					setWhatsappsIntegration(prevState =>
					{
						return {
							...prevState,
							[whatsappId]: 
							{
								...prevState[whatsappId],
								qr
							}
						}
					});
				}
			},
			{
				name: `STATUS`,
				Function: (data) =>
				{
					setWhatsappsIntegration(prevState =>
					{
						const { whatsappId, status } = data;

						return {
							...prevState,
							[whatsappId]: 
							{
								...prevState[whatsappId],
								status
							}
						}
					});						
				}
			}
		];

		ConnectEvents(room, events);
	}, [business._id, ConnectEvents]);

	useEffect(() =>
	{
		ConnectWhatsapps();
	}, [ConnectWhatsapps]);

	const value =
	{
		whatsappsIntegration,
		DisconnectWhatsapp,
		CheckStatus
	}

	return (
		<WhatsappIntegration.Provider value={value}>
			{children}
		</WhatsappIntegration.Provider>
	)
}

const useWhatsappIntegration = () => useContext(WhatsappIntegration);

export { WhatsappIntegrationProvider, useWhatsappIntegration };