import React, { useState, useEffect } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { setDefaults, fromAddress, fromLatLng } from "react-geocode";

const Map = (props) =>
{
	const { addresRequired, GetLocation, latitude: propLatitude, longitude: propLongitude } = props;

	const defaultLatitude = -17.7833102;
	const defaultLongitude = -63.1821403;

	const [latitude, setLatitude] = useState(propLatitude || defaultLatitude);
	const [longitude, setLongitude] = useState(propLongitude || defaultLongitude);
	const [zoom] = useState(14);
	const [markerPosition, setMarkerPosition] = useState(propLatitude && propLongitude ? { lat: propLatitude, lng: propLongitude } : null);
	const [marker, setMarker] = useState(null);

	useEffect(() =>
	{
		// Configura la clave API y otras opciones predeterminadas
		setDefaults({
			key: "AIzaSyBKlbA051wqGwFbzw5jYhUSuWnMBsjs5XU",
			language: "en",
			region: "es"
		});

		if (addresRequired && addresRequired !== '')
		{
			ChangeLocationAddress(addresRequired);
		}
		else
		{
			setLatitude(propLatitude || defaultLatitude);
			setLongitude(propLongitude || defaultLongitude);
			if (propLatitude && propLongitude) {
				setMarkerPosition({ lat: propLatitude, lng: propLongitude });
			} else {
				setMarkerPosition(null);
			}
		}
	}, [propLatitude, propLongitude, addresRequired]);

	useEffect(() =>
	{
		if (addresRequired)
		{
			if (addresRequired !== '')
			{
				ChangeLocationAddress(addresRequired);
			}
			else
			{
				ChangeLocationAddress('Santa Cruz Bolivia');
			}
		}
		else
		{
			setLatitude(propLatitude || defaultLatitude);
			setLongitude(propLongitude || defaultLongitude);
			if (propLatitude && propLongitude) {
				setMarkerPosition({ lat: propLatitude, lng: propLongitude });
			} else {
				setMarkerPosition(null);
			}
		}
	}, [addresRequired, propLatitude, propLongitude]);

	const ChangeLocationAddress = (address) =>
	{
		fromAddress(address).then(
			(response) =>
			{
				const lat = response.results[0].geometry.location.lat;
				const lng = response.results[0].geometry.location.lng;
				ShowAddress(lat, lng);
			},
			(error) =>
			{
				console.error(error);
			}
		);
	};

	const ShowAddress = (lat, lng) =>
	{
		setLatitude(lat);
		setLongitude(lng);
		setMarkerPosition({ lat, lng });
		fromLatLng(lat, lng).then(
			(response) =>
			{
				GetLocation(response);
			},
			(error) =>
			{
				console.error(error);
			}
		);
	};

	const MapClick = (event) =>
	{
		const lat = event.latLng.lat();
		const lng = event.latLng.lng();
		ShowAddress(lat, lng);
	};

	const mapStyle =
	{
		width: '100%',
		height: '100%',
		maxHeight: 'calc(100vh - var(--header) - 125px)'
	};

	const center =
	{
		lat: latitude,
		lng: longitude
	};

	const mapOptions =
	{
		scrollwheel: true,
		zoomControlOptions:
		{
			style: 'SMALL'
		},
		draggable: true,
		rotateControl: false,
		scaleControl: false,
		streetViewControl: false,
		panControl: false,
		mapTypeControl: false
	};

	const googleMapProps =
	{
		mapContainerStyle: mapStyle,
		center: center,
		zoom: zoom,
		onClick: MapClick,
		options: mapOptions
	};

	useEffect(() =>
	{
		if (window.google && window.google.maps && window.googleMapInstance)
		{
			if (marker)
			{
				marker.setMap(null);
			}
			if (markerPosition) {
				const newMarker = new window.google.maps.Marker({
					position: markerPosition,
					map: window.googleMapInstance
				});
				setMarker(newMarker);
			}
		}
	}, [markerPosition]);

	// This code below is for the AdvancedMarkerElement that avoids the deprecated warning of the marker but need to be correctly configurated to work, in index.html we need to add marker in libreries (&libraries=places,marker)

	// useEffect(() =>
	// {
	// 	if (window.google && window.google.maps && window.google.maps.marker && window.googleMapInstance)
	// 	{
	// 		if (marker)
	// 		{
	// 			marker.map = null; // Remove previous marker if any
	// 		}
	// 		if (markerPosition) {
	// 			const newMarker = new window.google.maps.marker.AdvancedMarkerElement({
	// 				position: markerPosition,
	// 				map: window.googleMapInstance,
	// 			});
	// 			setMarker(newMarker);
	// 		}
	// 	}
	// }, [markerPosition]);

	return (
		<GoogleMap
			{...googleMapProps}
			onLoad={(map) => {
				window.googleMapInstance = map;
			}}
		>
		</GoogleMap>
	);
};

export { Map };