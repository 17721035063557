import { useMemo, useState } from 'react';
import { Content, Panel, Tabs } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useSystemVariables } from '../../../../contexts/collections/home/SystemVariables';

// Components
import { SystemVariablesTable } from './SystemVariablesTable';

const SystemVariables = () =>
{
	const [currentTab, setCurrentTab] = useState('system');

	const {systemVariables} = useSystemVariables();

	const {Translate} = useTraductor();

	const tabs = useMemo(() =>
	{
		const tabs = [];

		for(let i in systemVariables)
		{
			tabs.push(
			{
				identifier: i,
				name: Translate(i),
				content: <SystemVariablesTable variables = {systemVariables[i].variables}/>
			});
		}

		return tabs;
	}, [systemVariables, Translate]);

	return (
		<Content>
			<Panel class='full-height'>
				<Tabs
					tabs = {tabs}
					current = {currentTab}
					Change = {(currentTab) =>
					{
						setCurrentTab(currentTab.identifier);
					}}
				/>
			</Panel>
		</Content>
	);
}

export {SystemVariables};