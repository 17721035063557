import React from 'react';
import {Content, Panel, Header, Body, Card, Text, List} from 'crack-ux';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useModules} from '../../../../contexts/collections/home/Modules';
import {ModulesProviderPage, useModulesPage} from '../../../../contexts/pages/business/home/Modules';

// Components
import {Info} from './Info';

// Styles
import './Modules.css';

const Modules = (props) =>
{
	const {Translate} = useTraductor();
	const {currentModule} = useModulesPage();
	const {modules} = useModules();
	
	return (
		<Content class='modules no-padding'>
			<Panel class='no-padding full-height' swipe='horizontal'>
				<Panel class='full-height desktop3'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('Modules')}</Text>
						</Header>
						<Body class='no-padding'>
							<List
								class = 'hover'
								currentClass = 'bg-blue white'
								current = {currentModule}
								options = {modules.map(item =>
								{
									return {
										identifier: item.syssec,
										text: Translate(item.name),
										// Click: () => setCurrentModule(item.syssec)
									}
								})}
							/>
						</Body>
					</Card>
				</Panel>
				<Panel class='full-height desktop9'>
					<Card>
						<Info/>
					</Card>
				</Panel>
			</Panel>
		</Content>
	);
}

const ModulesWithProviders = (props) =>
{
	return (
		<ModulesProviderPage>
			<Modules {...props}/>
		</ModulesProviderPage>
	);
}

export {ModulesWithProviders as Modules}