import { Content, Panel, List, Card, Text } from 'crack-ux';
import { useNavigate } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useTraductor } from '../../../../contexts/general/Traductor';

// Components
import { TemplatesTable } from './TemplatesTable';

// Styles
import './Templates.css';

// Static Data
const templatesChannels = require('../../../../static-data/TemplatesChannels.json');

const Templates = (props) =>
{
	const { channel } = props;
	
	const { screen } = useGeneral();
	const { Translate } = useTraductor();

	const navigate = useNavigate();

	return (
		<Content>
			{(screen.device !== 'mobile' || !channel) &&
			<Panel class='full-height tablet3'>
				<Card>
					<List
						class = 'hover'
						currentClass = 'bg-blue white'
						current = {channel}
						options = {templatesChannels.filter(item => !item.hidden).map(item =>
						{
							return {
								identifier: item.value,
								text: Translate(item.text),
								Click: () => navigate(`${channel ? '../' : './'}${item.value}`),
							}
						})}
					/>
				</Card>
			</Panel>}
			{(screen.device !== 'mobile' || channel) &&
			<Panel class='full-height tablet9'>
				<Card class='relative'>
					{channel ? <TemplatesTable channel={channel}/> : <Text class='full-center'>{Translate('select-template')}</Text>}
				</Card>
			</Panel>}
		</Content>
	);
}

export {Templates};