import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Content, Panel, Card, Section, Text, Body } from 'crack-ux';
import moment from 'moment';

// Contexts
import { useTraductor } from '../../../../../contexts/general/Traductor';
import { useMovements } from '../../../../../contexts/collections/commerce/Movements';
import { useProducts } from '../../../../../contexts/collections/commerce/Products';

// Components
import { Filter } from './Filter';

// Charts
import { NTopProductsBar } from './NTopProductsBar';
import { NTopProductsPie } from './NTopProductsPie';

const defaultFilter =
{
	period: 'current-month',
	startDate: moment().startOf('month'),
	endDate: moment().endOf('month'),
	categories: ['all-categories'],
	products: 10
}

const TopProducts = () =>
{
	const [filter, setFilter] = useState({...defaultFilter});
	const [productsFilteredByRevenue, setProductsFilteredByRevenue] = useState([]);
	const [productsFilteredByQuantity, setProductsFilteredByQuantity] = useState([]);

	const { Translate } = useTraductor();
	const { movements } = useMovements();
	const { products } = useProducts();

	const sales = useMemo(() => movements.filter(movement => movement.type === 1).sort((a, b) => a.dateObj - b.dateObj), [movements]);
	const salesWithinPeriod = useMemo(() => sales.filter((sale) => sale.dateObj >= filter.startDate && sale.dateObj <= filter.endDate), [sales, filter.startDate, filter.endDate]);

	const periodOptions = useMemo(() =>
	[
		{
			text: Translate('current-month'),
			value: 'current-month'
		},
		{
			text: Translate('current-quarter'),
			value: 'current-quarter'
		},
		{
			text: Translate('current-year'),
			value: 'current-year'
		},
		{
			text: Translate('custom-dates'),
			value: 'custom'
		}
	], [Translate]);

	const BuildReport = useCallback(() =>
	{
		const allProducts = products || [];
		const isAllCategories = filter.categories.includes('all-categories');

		const allowedProductIds = isAllCategories
			? allProducts.map(product => product._id)
			: allProducts.filter(product => product.categories.some(category => filter.categories.includes(category))).map(product => product._id);

		const productMap = {};

		salesWithinPeriod.forEach((sale) =>
		{
			sale.details.forEach((detail) =>
			{
				const productId = detail.product;

				if (allowedProductIds.includes(productId))
				{
					if (!productMap[productId])
					{
						const productInfo = allProducts.find(product => product._id === productId);

						productMap[productId] =
						{
							product: productInfo,
							totalQuantity: 0,
							totalRevenue: 0
						}
					}

					productMap[productId].totalQuantity += detail.quantity;
					productMap[productId].totalRevenue += detail.total;
				}
			});
		});

		const productArrayByRevenue = Object.values(productMap).sort((a, b) => b.totalRevenue - a.totalRevenue).slice(0, filter.products);
		const productArrayByQuantity = Object.values(productMap).sort((a, b) => b.totalQuantity - a.totalQuantity).slice(0, filter.products);

		setProductsFilteredByRevenue(productArrayByRevenue);
		setProductsFilteredByQuantity(productArrayByQuantity);
	}, [salesWithinPeriod, filter, products]);
	
	useEffect(() =>
	{
		BuildReport();
	}, [BuildReport]);

	return (
		<Content class='report-container'>
			<Panel class='report desktop9 full-height vertical-scroll'>
				<Card class='header'>
					<Text class='title'>{Translate('top-selling-products')}</Text>
				</Card>
				<Body>
					<Text type='p'>{Translate('top-selling-products-description')}</Text>
				</Body>
				<Section>
					<Card>
						<NTopProductsPie products={productsFilteredByRevenue} filter={filter}/>
					</Card>
					<Card>
						<NTopProductsBar products={productsFilteredByQuantity} filter={filter}/>
					</Card>
				</Section>
			</Panel>
			<Panel class='filter desktop3 full-height'>
				<Filter filter={filter} setFilter={setFilter} defaultFilter={{...defaultFilter}} periodOptions={periodOptions}/>
			</Panel>
		</Content>
	)
}

export { TopProducts };