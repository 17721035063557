import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';
// import {Dates} from 'crack-functions';

// Context
import { useSocket } from '../../general/Socket';
import { useBusiness } from '../../layouts/Business';

// Reducers
import { MessagesReducer } from '../../../reducers/collections/crm/Messages';

const MessagesContext = createContext({});

const MessagesProvider = (props) =>
{
	const [state, dispatch] = useReducer(MessagesReducer, {messages: [], contactsMessages: {}, code: 0});
	const [loading, setLoading] = useState(true);

	const Socket = useSocket();
	const {BusinessFetch, business, SomethingWentWrong} = useBusiness();

	const ReadContactMessages = useCallback(async (contactId) =>
	{
		try
		{
			if(contactId)
			{
				const query =
				{
					contactId
				}

				const response = await BusinessFetch.Get('/crm/messages', query);
				
				if(response.status === 200)
				{
					dispatch({type: 'LOAD_CONTACT_MESSAGES', messages: response.data || [], contactId});
				}
			}
		}
		catch(error)
		{
			SomethingWentWrong('ReadContactMessages');
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const ReadLastMessages = useCallback(async () =>
	{
		try
		{
			setLoading(true);

			const response = await BusinessFetch.Get('/crm/messages/last-messages');

			if(response.status === 200)
			{
				dispatch({type: 'LOAD_LAST_MESSAGES', messages: response.data || []});
			}
		}
		catch(error)
		{
			SomethingWentWrong('ReadLastMessages');
		}
		finally
		{
			setLoading(false);
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const SendMessage = async (message) =>
	{
		try
		{
			// message.storages = message.attached.data.map(item => item._id);
			// message._files =
			// {
			// 	chat: message.attached.files.map(file => file.localFile)
			// };
	
			// delete message.attached;
	
			await BusinessFetch.Post('/crm/messages/send-message', message);
		}
		catch(error)
		{
			SomethingWentWrong('SendMessage');
		}
	}

	const GetChatStatus = useCallback((contact) =>
	{
		// const date24 = Dates.Add(new Date(), -24, 'hours');

		const contactMessages = state.contactsMessages[contact?._id] || []; // TODO: Check: A contact without _id ?
		const lastMessage = contactMessages.slice(-1)[0];

		let statusColor = '';
		let title = 'No Chat';
		let type = '';

		if(!lastMessage || (lastMessage.chatStatus === 1 /*1.- finishChat*/))
		{
			statusColor = 'light-gray';
			type = 'chatStatusNoChat';
		}
		else if(lastMessage.chatStatus === 2 /*2.- requestOperator*/)
		{
			statusColor = 'green';
			title = 'Awaiting for Reply';
			type = 'chatStatusAnswered';
		}
		else if(lastMessage.fromSection === 1) // Chat Bot
		{
			statusColor = 'blue';
			title = 'Chat Bot Message';
			type = 'chatStatusChatBot';
		}
		else if(lastMessage.fromSection === 2) // Scheduled Messages
		{
			statusColor = 'purple';
			title = 'Scheduled Message';
			type = 'chatStatusScheduledMessage';
		}
		else if(lastMessage.transmiter)
		{
			statusColor = 'red';
			title = 'Already Replied';
			type = 'chatStatusNotAnswered';
		}
		else
		{
			statusColor = 'green';
			title = 'Awaiting for Reply';
			type = 'chatStatusAnswered';
		}

		return {statusColor, title, type};
	}, [state.contactsMessages])

	useEffect(() =>
	{
		const events =
		[
			{
				name: `CRM_MESSAGE_CREATE`,
				Function: (message) => dispatch({type: 'CREATE_MESSAGE', message, contact: message?.contact})
			}
		];

		Socket.ConnectEvents(business._id, events);

	}, [Socket, business._id]);

	const value =
	{
		loading,
		code: state.code,
		messages: state.messages,
		contactsMessages: state.contactsMessages,
		ReadContactMessages,
		ReadLastMessages,
		SendMessage,
		GetChatStatus
	}

	return <MessagesContext.Provider value={value} {...props}/>
}

const useMessages = () => useContext(MessagesContext);

export {MessagesProvider, useMessages};