import { Route, Routes } from "react-router-dom";

// Pages
import { Chats } from "./Chats";

const ChatsRouter = () =>
{
	return (
		<Routes>
			<Route path="/:contactName/:contactId/info" element={<Chats context='info'/>} />
			<Route path="/:contactName/:contactId/chat" element={<Chats context='chat'/>} />
			<Route path="/" element={<Chats context='list'/>} />
		</Routes>
	);
}

export { ChatsRouter as Chats };