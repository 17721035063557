import React from 'react'
import {Link, useParams} from 'react-router-dom';
import {Content, Box, Card, Section, Text, Footer, Button} from 'crack-ux';

// Components
import {EmailForm} from '../../../components/forms/Email';
import {PasswordRecoverForm} from '../../../components/forms/PasswordRecover/PasswordRecover';

// Styles
import './PasswordRecover.css';

const PasswordRecover = () =>
{
	const {token} = useParams();

	return (
		<Section class='passwordrecovery'>
			<Content class='init relative no-padding full-height-v'>
				<Box class='full-center'>
					<Card style={{width: 300}}>
						{token ? <PasswordRecoverForm/> : <EmailForm/>}
						<Footer>
							<Section class='center'>
								<Section>
									<Text>Do you have an account?</Text>
									<Link to="/"> <Button class='link blue create_account' text='Back to Login'/> </Link>
								</Section>
							</Section>
						</Footer>
					</Card>
				</Box>
			</Content>
		</Section>
	)
}

export {PasswordRecover};
