import { Content, Panel, Card } from 'crack-ux';

// Contexts
import { useMovements } from '../../../../contexts/collections/commerce/Movements';

// Components
import { MovementsTable } from './MovementsTable';

const Movements = () =>
{
	const { movements } = useMovements();

	return (
		<Content>
			<Panel>
				<Card>
					<MovementsTable movements={movements}/>
				</Card>
			</Panel>
		</Content>
	)
}

export { Movements };