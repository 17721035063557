import {Content, Section, Card, Header, Body, Text, Icon, Button} from 'crack-ux';
import {Utilities} from 'crack-functions';

// Contexts
import {useBusinessPage} from '../../../../contexts/pages/public/BusinessPage';

const Phone = (phone) =>
{
	const {country, number, icon = 'icon-whatsapp', title = 'Whatsapp'} = phone;

	const {business} = useBusinessPage();

	const {page = {}} = business;
	const {contactUs: contactUsConf = {}} = page;

	return (
		<Button
			class = 'phone'
			type = 'a'
			href = {`https://wa.me/${country}${number}`}
			target = '_blank'
			style =
			{{
				background: contactUsConf.phones?.background,
				color: Utilities.HexIsLight(contactUsConf.phones?.background) ? 'black' : 'white'
			}}
		>
			<Text class='block'>{title}</Text>
			<Icon class={icon}/>
			<Text>+{country} {number}</Text>
		</Button>
	);
}

const ContactUs = () =>
{
	const {business} = useBusinessPage();

	const {page = {}} = business;
	const {contactUs: contactUsConf = {}} = page;

	return (
		<Content class='contact-us desktop'>
			<Header class='center'>
				<Text class='title' type='p'>{contactUsConf.title}</Text>
				<Text class='caption' type='p'>{contactUsConf.caption}</Text>
			</Header>
			<Body>
				<Section class='address'>
					<Card>
						<Header class='no-border'>
							<Text>Dirección</Text>
						</Header>
						<Body>
							<Text type='p'>{business.location?.address}</Text>
						</Body>
					</Card>
				</Section>
				<Section class='phones center'>
					{business.phones?.map(phone => <Phone key={phone._id} {...phone}/>)}
				</Section>
			</Body>
		</Content>
	);
};

export {ContactUs};