import { useEffect, useState } from 'react';
import { Card, Header, Body, Footer, Button, Text, Form } from 'crack-ux';

// Context
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useLists } from '../../../../contexts/collections/home/Lists';
import { useCompanies } from '../../../../contexts/collections/crm/Companies';

// Components
import { Location } from '../../../../components/Location';
import { ItemForm } from '../../home/Lists/ItemForm';

// Static Date
import CompanyType from '../../../../static-data/CompanyType.json';

const CompanyForm = ({ company = {} }) =>
{
    const [companyForm, setCompanyForm] = useState(company);

	const [location, setLocation] = useState(company.location || {});
	const [messages, setMessages] = useState({});

    const { AddModal, CloseModal } = useModal();
    const { Translate } = useTraductor();
	const { CreateCompany , UpdateCompany} = useCompanies();
	const { listsByName } = useLists();
	
	const HandleSubmit = async () =>
	{
		const validationMessages = {}

		if (!companyForm.name) validationMessages.name = Translate('field-required');

		if (Object.keys(validationMessages).length)
		{
			setMessages(validationMessages);
			return;
		}

		const result = company._id ? await UpdateCompany(companyForm) : await CreateCompany(companyForm);

		if(result.status === 200)
		{
			CloseModal();
		}
	}

	useEffect(() =>
	{
		setCompanyForm(prevState => ({...prevState, location}));
	}, [location]);

	return (
		<Card>
			<Header>
				<Text class='title'>{company._id ? Translate('Edit Company') : Translate('New Company')}</Text>
			</Header>
			<Body class='vertical-scroll'>
				<Form
					inputs =
					{[
						{
							name: 'name',
							text: Translate('company-name'),
							class: 'tablet8',
							value: companyForm.name,
							message: messages.name
						},
						{
							name: 'tin',
							text: Translate('tin'),
							class: 'tablet4',
							value: companyForm.tin,
							message: messages.tin
						},
						{
							name: 'type',
							text: Translate('company-type'),
							class: 'tablet6',
							type: 'select',
							options:
							[
								{
									text: Translate('no-selected'),
									value: null
								},
								...CompanyType.map(type => ({text: Translate(type.text), value: type.value}))
							],
							value: companyForm.type,
							message: messages.type
						},
						{
							name: 'employees',
							text: Translate('employees-amount'),
							class: 'tablet6',
							type: 'number',
							value: companyForm.employees,
							message: messages.employees
						},
						{
							name: 'description',
							text: Translate('description'),
							type: 'textarea',
							value: companyForm.description,
							message: messages.description
						},
						{
							name: 'location',
							text: Translate("location"),
							value: location.address,
							icon:
							{
								class: `icon-location bg-blue white pointer`,
								Click: () => AddModal(<Location location={location} setLocation={setLocation}/>)
							},
							readOnly: true
						},
						{
							name: 'economicActivities',
							text: Translate('economic-activities'),
							class: 'mobile9',
							type: 'autocomplete',
							options: listsByName['economic-activity']?.selectOptions || [],
							multiple: true,
							value: companyForm.economicActivities,
							message: messages.companies
						},
						{
							class: 'mobile3',
							type: 'content',
							content: <Button
								class='full-width bg-blue white'
								text={Translate('add')}
								Click={() => AddModal(<ItemForm internalName='economic-activity'/>)}
							/>
						},
					]}
					form = {companyForm}
					setForm = {setCompanyForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('Save')}
					class = 'bg-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {CompanyForm};