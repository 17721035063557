import { useState } from 'react';
import { Table, Confirm, Text, Header, Body } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useSession } from '../../../../contexts/general/Session';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useModal } from '../../../../contexts/general/Modal';
import { useContacts } from '../../../../contexts/collections/vouchers/Contacts';

// Components
import { ContactForm } from './ContactForm';

const ContactsTable = () =>
{
	const [showConfirm, setShowConfirm] = useState(false);

	const { user } = useSession();
	const { Translate } = useTraductor();
	const { AddModal } = useModal();
	const { contacts, currentContact, setCurrentContact, DeleteContact } = useContacts();

	const navigate = useNavigate();
	const { contactId } = useParams();

	return (
		<>
			<Header>
				<Text class='title'>{Translate('contacts')}</Text>
			</Header>
			<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
				<Table
					class = 'current hover pointer'
					header = 
					{[
						{
							name: 'codeText',
							text: Translate('code')
						},
						{
							name: 'name',
							text: Translate('contact-name')
						},
						{
							name: 'phonesText',
							text: Translate('phones')
						},
						{
							name: 'emailsText',
							text: Translate('emails')
						},
						{
							name: 'tin',
							text: Translate('tin')
						},
					]}
					body = {contacts.map(contact =>
					{
						if(contact.code === 0) contact.name = Translate(contact.name)

						return contact;
					})}
					ClickRow = {(contact) => navigate(`${contactId ? '../' : './'}${contact.name}/${contact._id}`)}
					actions =
					{[
						{
							text: Translate('new-contact'),
							class: 'bg-blue white',
							section: 'grouped',
							Click: () => AddModal(<ContactForm/>),
							Condition: (contact) => contact.code !== 0
						},
						{
							text: Translate('edit-contact'),
							section: 'context',
							icon:
							{
								class: 'icon-pencil'
							},
							Click: (contact) => AddModal(<ContactForm contact={contact}/>),
							Condition: (contact) => contact.code !== 0
						},
						{
							text: Translate('delete-contact'),
							class: 'red',
							section: 'context',
							icon:
							{
								class: 'icon-trash'
							},
							Click: (contact) =>
							{
								setCurrentContact(contact);
								setShowConfirm(true);
							},
							Condition: (contact) => contact.code !== 0
						}
					]}
					exportable = {false}
					language = {user.preferences?.language || 'es'}
					filters = 
					{{
						columns:
						{
							editable: true
						},
						Apply:
						{
							text: Translate('Apply'),
							Click: () => {}
						},
						Reset:
						{
							text: Translate('Reset'),
							Click: () => {}
						}
					}}
					firstLoad = 
					{{
						triggerClickRow: false
					}}
				/>
			</Body>
			<Confirm
				show={showConfirm}
				header={<Text class='bold'>{Translate(`delete-contact`)}</Text>}
				body=
				{
					<>
						<Text type='p'>{Translate(`confirm-delete-contact`)}</Text>
						<Text type='p'><span className='bold'>{Translate('contact-name')}: </span>{currentContact.name}</Text>
					</>
				}
				confirm=
				{{
					text: Translate('delete'),
					class: 'bg-red white',
					Click: () =>
					{
						DeleteContact(currentContact);
						setShowConfirm(false);
					}
				}}
				cancel=
				{{
					text: Translate('cancel'),
					Click: () => setShowConfirm(false)
				}}
			/>
		</>
	)
}

export { ContactsTable };