import React from 'react';

// Context
import {useSocket} from '../../general/Socket';
import {useBusiness} from '../../layouts/Business';

// Reducers
import {EventsConfigReducer} from '../../../reducers/collections/scheduler/EventsConfig';

const EventsConfigContext = React.createContext({});

const EventsConfigProvider = (props) =>
{
	// const {syssec} = props;

	const [state, dispatch] = React.useReducer(EventsConfigReducer, {eventsConfig: []});
	const [eventId, setEventId] = React.useState('');
	const [oldSocket, setOldSocket] = React.useState({});

	const Socket = useSocket();
	const {BusinessFetch, business} = useBusiness();

	const room = React.useMemo(() => `events_config_${business._id}`, [business._id]);
	const unique = React.useMemo(() =>
	{
		return eventId ? `EVENT_CONFIG_${eventId}` : 'EVENT_CONFIG';
	}, [eventId]);
	
	React.useEffect(() =>
	{
		const eventsConfig =
		[
			{
				name: `CREATE_${unique}`,
				Function: (eventConfig) => dispatch({type: 'CREATE_EVENT_CONFIG', eventConfig})
			},
			{
				name: `UPDATE_${unique}`,
				Function: (eventConfig) => dispatch({type: 'UPDATE_EVENT_CONFIG', eventConfig})
			},
			{
				name: `DELETE_${unique}`,
				Function: (eventConfig) => dispatch({type: 'DELETE_EVENT_CONFIG', eventConfig})
			}
		];

		Socket.ConnectEvents(room, eventsConfig, oldSocket);

		if(oldSocket.unique !== unique)
		{
			setOldSocket({room, eventsConfig, unique});
		}
	}, [Socket, room, unique, oldSocket, state.code]);

    const LoadEventsConfig = React.useCallback((eventsConfig = []) =>
    {
        dispatch({type: 'LOAD_EVENTS_CONFIG', eventsConfig});
    }, []);

	const CreateEventConfig = async (data) =>
	{
		const url = '/event-config/create';

		data._socket = room;
		// data.syssec = syssec;

		return await BusinessFetch.Post(url, data);
	}

	const UpdateEventConfig = async (data) =>
	{
		const url = '/event-config/update';

		data._socket = room;

		return await BusinessFetch.Post(url, data);
	}

	const DeleteEventConfig = async (event) =>
	{
		const data =
		{
			_id: event._id,
			_socket: room
		}

		return await BusinessFetch.Post('/event-config/delete', data);
	}

	const value =
	{
		eventsConfig: state.eventsConfig,
		setEventId,
        LoadEventsConfig,
		CreateEventConfig,
		UpdateEventConfig,
		DeleteEventConfig
	}

	return <EventsConfigContext.Provider value={value} {...props}/>
}

const useEventsConfig = () => React.useContext(EventsConfigContext);

EventsConfigProvider.defaultProps =
{
	syssec: 'scheduler'
}

export {EventsConfigProvider, useEventsConfig};