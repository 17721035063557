import {useNavigate} from 'react-router-dom';
import {Content, Header, Body, Footer, Text, Button} from 'crack-ux';
import {Utilities} from 'crack-functions';

// Contexts
import {useBusinessPage} from '../../../../contexts/pages/public/BusinessPage';

// Components
import {Service} from '../components/Service';

const Services = () =>
{
	const navigate = useNavigate();
	const {business, services} = useBusinessPage();

	const {page = {}} = business;
	const {services: servicesConf = {}} = page;

	return (
		<Content class='services desktop relative'>
			<Header class='center no-border'>
				<Text class='title' type='p'>{servicesConf.title}</Text>
				<Text class='caption' type='p'>{servicesConf.caption}</Text>
			</Header>
			<Body class='services no-padding'>
				{services.map(service => <Service key={service._id} {...service}/>)}
			</Body>
			<Footer class='center no-border'>
				<Button
					class='main-button'
					text = {servicesConf.moreServicesButton?.text}
					Click = {() => navigate('./services')}
					style =
					{{
						backgroundColor: servicesConf.moreServicesButton?.background,
						color: Utilities.HexIsLight(servicesConf.moreServicesButton?.background) ? 'black' : 'white'
					}}
				/>
			</Footer>
		</Content>
	)
}

export {Services};