import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Layouts
import { Loading } from '../../../components/views/Loading';

// Context
import { useGeneral } from '../../../contexts/general/General';
import { useSession } from '../../../contexts/general/Session';

const AcceptedInvitation = () =>
{
	const params = useParams();
	const navigate = useNavigate();
	const { Fetch } = useGeneral();
	const { user } = useSession();

	const { userId, encryptedData } = params;

	useEffect(() =>
	{
		if(userId === 'new-user')
		{
			navigate(`/create-account/${encryptedData}`);
		}
		else
		{
			const query =
			{
				userId,
				encryptedData
			}

			Fetch.Get('/staff/members/accept-invitation', query).then(response =>
			{
				if(response.status === 200)
				{
					if(user._id)
					{
						navigate('/p/business');
					}
					else
					{
						navigate('/login');
					}
				}
			});
		}
	}, [user._id, userId, encryptedData, Fetch, navigate]);

	return <Loading/>
}

export {AcceptedInvitation};