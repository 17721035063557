import { createContext, useCallback, useContext, useEffect, useState } from "react";
import socketIOClient from "socket.io-client";

// Config
import config from '../../config.json';

const Socket = createContext({});

let socket = null;

const SocketProvider = (props) =>
{
	const [status, setStatus] = useState('disconnected');

	const TrySocketDisconnection = useCallback(() =>
	{
		if(socket && socket.disconnect)
		{
			socket.disconnect();
			socket = null;

			if(status === 'connected')
			{
				console.log(`Socket Platform Layout Disconnected`);
			}
			
			setStatus('disconnected');
		}
	}, [status]);

	const TrySocketConnection = useCallback(() =>
	{
		TrySocketDisconnection();

		socket = socketIOClient(config.sockets.fullDomain);

		socket.on('connect', () =>
		{
			setStatus('connected');
			console.log(`Socket Platform Layout Connected`);
		})
	}, [TrySocketDisconnection]);

	const ConnectEvents = useCallback((room, events = [], remove = {}) =>
	{
		if(socket && room && room.trim().length > 0)
		{
			socket.emit('join',
			{
				room
			})

			if(remove.events)
			{
				remove.events.forEach(event => socket.removeAllListeners(event.name));
				remove.events.forEach(event => socket.off(event.name));
			}

			events.forEach(event =>
			{
				socket.removeAllListeners(event.name);
				socket.off(event.name);

				socket.on(event.name, (response) =>
				{
					event.Function(response);
				})
			})
		}
	}, []);

	useEffect(() =>
	{
		if(status === 'disconnected')
		{
			TrySocketConnection();
		}

		return () =>
		{
			if(status === 'connected')
			{
				TrySocketDisconnection();
			}
		}
	}, [status, TrySocketConnection, TrySocketDisconnection]);

	const value =
	{
		status,
		ConnectEvents
	};

	return <Socket.Provider value={value} {...props}/>;
}

const useSocket = () => useContext(Socket);

export { SocketProvider, useSocket };