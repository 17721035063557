import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Contexts
import { useSocket } from '../general/Socket';
import { useGeneral } from '../general/General';
import { useSession } from '../general/Session';
import { useTraductor } from '../general/Traductor';

const Business = createContext({});

const BusinessProvider = (props) =>
{
	const [business, setBusiness] = useState({});
	const [member, setMember] = useState({});
	const [showMenu, setShowMenu] = useState(false);
	const [showAccount, setShowAccount] = useState(false);
	const [multiFunctionPanel, setMultiFunctionPanel] = useState('profile');

	const Socket = useSocket();
	const { businesses } = useSession();
	const { Fetch } = useGeneral();
	const { Translate } = useTraductor();

	const location = useLocation();
	const params = useParams();

	const path = useMemo(() => 
	{
		const pathSplitted = location.pathname.split('/');

		const relativePath = pathSplitted.slice(3);

		return {
			full: location.pathname,
			relative: relativePath.join('/'),
			uniqueName: pathSplitted[2],
			module: pathSplitted[3],
			menu: pathSplitted[4],
			view: pathSplitted[5],
		}
	}, [location.pathname]);

	const SwitchMenu = useCallback(() => setShowMenu(!showMenu), [showMenu]);
	const SwitchAccount = useCallback(() => setShowAccount(!showAccount), [showAccount]);

	const BusinessFetch = useCallback(async (url, data = {}, method = 'GET') =>
	{
		if(business._id)
		{
			const options =
			{
				credentials: 'include',
				headers:
				{
					'bid': business._id,
				},
			};

			const response = await Fetch.Core(url, data, method, options);
	
			return response;
		}
		else
		{
			// console.warn("BusinessFetch Warning: business._id is missing. Ensure that business id is available before making requests.");
			
			return {
				status: 400,
				message: 'business._id is missing. Please ensure that business id is set before making this request.',
				data: null,
			};
		}
	}, [Fetch, business._id]);

	const BusinessFetchObject = useMemo(() =>
	{
		return {
			Get: (url, data) => BusinessFetch(url, data),
			Post: (url, data) => BusinessFetch(url, data, 'POST'),
			Put: (url, data) => BusinessFetch(url, data, 'PUT'),
			Delete: (url, data) => BusinessFetch(url, data, 'DELETE'),
		};
	}, [BusinessFetch]);

	const SaveLocalStorage = useCallback((key, value) =>
	{
		const keyName = `${business._id}_${key}`;

		localStorage.setItem(keyName, JSON.stringify(value));
	}, [business._id]);

	const GetLocalStorage = useCallback((key) =>
	{
		const keyName = `${business._id}_${key}`;

		return JSON.parse(localStorage.getItem(keyName));
	}, [business._id]);

	const SomethingWentWrong = useCallback((location) =>
	{
		window.CrackUX.Toasts.AddToast(
		{
			class: 'error',
			title: Translate('something-went-wrong'),
			message: Translate('refresh-try-again-contact-support'),
		});

		console.error('Something went wrong: ', location);
	}, [Translate]);

	const FetchUserPermissions = useCallback(async () =>
	{
		try
		{
			const response = await BusinessFetch('/staff/members/current');

			if(response.status === 200)
			{
				setMember(response.data);
			}
		}
		catch(error)
		{
			SomethingWentWrong('FetchUserPermissions');
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const Can = useCallback((permission) =>
	{
		if(member.isBusinessOwner) return true;

		if(member.permissions
			&& member.permissions[path.module]
			&& member.permissions[path.module].permissions
			&& member.permissions[path.module].permissions[path.menu]
			&& member.permissions[path.module].permissions[path.menu].permissions
			&& member.permissions[path.module].permissions[path.menu].permissions[permission]
		)
		{
			return member.permissions[path.module].permissions[path.menu].permissions[permission].value;
		}

		return false;
	}, [member.isBusinessOwner, member.permissions, path.module, path.menu]);

	useEffect(() =>
	{
		if(businesses.length > 0)
		{
			const businessFound = businesses.find(business => business.uniqueName === params.business);

			if(businessFound && businessFound._id !== business._id)
			{
				setBusiness(businessFound);
			}
		}
	}, [business, businesses, params.business]);

	useEffect(() =>
	{
		setShowMenu(false);
	}, [path]);

	useEffect(() =>
	{
		if(business._id)
		{
			FetchUserPermissions();
		}
	}, [business._id, FetchUserPermissions]);

	useEffect(() =>
	{
		if(member._id)
		{
			const events =
			[
				{
					name: `CURRENT_MEMBER_${member._id}`,
					Function: (member) => setMember(member),
				},
			];
	
			Socket.ConnectEvents(business._id, events);
		}
	}, [Socket, business._id, member._id]);

	const value =
	{
		business,
		member,
		BusinessFetch: BusinessFetchObject,
		path,
		showMenu,
		showAccount,
		multiFunctionPanel,
		setShowMenu,
		setShowAccount,
		setMultiFunctionPanel,
		SwitchMenu,
		SwitchAccount,
		SaveLocalStorage,
		GetLocalStorage,
		SomethingWentWrong,
		Can,
	}

	return (
		<Business.Provider value={value} {...props}/>
	);
}

const useBusiness = () => useContext(Business);

export {BusinessProvider, useBusiness};