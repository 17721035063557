import React from "react";
import { useLocation } from "react-router-dom";

// Contexts
import { useModal } from "../general/Modal";
import { useContactsNotes } from "../collections/crm/ContactsNotes";
import { useMessages } from "../collections/crm/Messages";

// Forms
import { ChatBotOptionForm } from "../../components/forms/modules/crm/ChatBot/ChatBotOption";
import { CompanyContact } from "../../components/forms/modules/crm/CompanyContact/CompanyContact";
import { ScheduledMessageForm } from "../../components/forms/modules/crm/ScheduledMessage/ScheduledMessage";
import { ScheduledMessageContactsForm } from "../../components/forms/modules/crm/ScheduleMessageContacts/ScheduledMessageContacts";
import { ContactNoteForm } from "../../components/forms/modules/crm/ContactNote/ContactNote";
import { EventConfigForm } from "../../components/forms/modules/scheduler/EventConfig/EventConfig";
import { EventForm } from "../../components/forms/modules/scheduler/Event/Event";
import { ContactsListForm } from "../../components/forms/modules/crm/ContactsList/ContactsList";

// Warnings
import { DeleteChatBotOption } from "../../components/warnings/crm/DeleteChatBotOption";
import { UnlinkContact } from "../../components/warnings/crm/UnlinkContact/UnlinkContact";
import { DeleteScheduledMessage } from "../../components/warnings/crm/ScheduledMessage/ScheduledMessaje";
import { DeleteNote } from "../../components/warnings/crm/DeleteContactNote/DeleteContactNote";
import { ConfirmEventRecurrence } from "../../components/warnings/scheduler/ConfirmEventRecurrence/ConfirmEventRecurrence";

const CRMContext = React.createContext({});

const CRMProvider = (props) =>
{
	const [contactIds, setContactIds] = React.useState([]);
	const [currentContactId, setCurrentContactId] = React.useState(null);

	const { pathname } = useLocation();
	const { AddModal } = useModal();
	const { LoadContactMessages } = useMessages();
	const { LoadContactNotes } = useContactsNotes();

	React.useEffect(() => setCurrentContactId(null), [pathname]);

	React.useEffect(() =>
	{
		setContactIds([]);
	}, []);

	const AddContactTab = (contactId) =>
	{
		const foundContactId = contactIds.find(item => item === contactId);

		if(!foundContactId)
		{
			setContactIds(current =>
			{
				const newContactIds = [...current, contactId];

				return newContactIds;
			});

			LoadContactMessages(contactId);
			LoadContactNotes(contactId);
		}
	}

	const RemoveContactTab = (contactId) =>
	{
		const foundContactId = contactIds.find(item => item === contactId);

		if(foundContactId)
		{
			const newContactIds = contactIds.filter(item => item !== contactId);

			setContactIds(newContactIds);
			setCurrentContactId(null);
		}
	}

	const ShowChatBotOptionForm = (params = {}) =>
	{
		const
		{
			chatBot = {},
			option = {},
			Submit = () => {},
			channel = {}
		} = params;

		AddModal(<ChatBotOptionForm chatBot = {chatBot} option = {option} Submit = {Submit} channel = {channel}/>);
	}

	const ShowDeleteChatBotOption = (params = {}) =>
	{
		const
		{
			option = {},
			Delete = () => {}
		} = params;

		AddModal(<DeleteChatBotOption option = {option} Delete = {Delete}/>);
	}

	const ShowCompanyContact = () =>
	{
		AddModal(<CompanyContact/>);
	}

	const ShowUnlinkContactWarning = (contact = {}) =>
	{
		AddModal(<UnlinkContact contact = {contact}/>);
	}

	const ShowScheduledMessageForm = (scheduledMessage = {}, params = {}) =>
	{
		const
		{
			channel = null,
			contact = null,
			type = null,
			base = false
		} = params;

		AddModal(<ScheduledMessageForm scheduledMessage = {scheduledMessage} channel = {channel} contact = {contact} type = {type} base = {base}/>);
	}

	const ShowDeleteScheduledMessage = (scheduledMessage = {}) =>
	{
		AddModal(<DeleteScheduledMessage scheduledMessage = {scheduledMessage}/>);
	}

	const ShowScheduledMessageContacts = (contacts, filteredContacts, setFilteredContacts) =>
	{
		AddModal(<ScheduledMessageContactsForm contacts={contacts} filteredContacts={filteredContacts} setFilteredContacts={setFilteredContacts}/>);
	}

	const ShowContactNoteForm = (note, params = {}) =>
	{
		const
		{
			contact = {}
		} = params;

		AddModal(<ContactNoteForm note = {note} contact = {contact}/>);
	}
	const ShowDeleteNoteWarning = (note = {}) =>
	{
		AddModal(<DeleteNote note = {note}/>);
	}

	const ShowEventConfigForm = (eventConfig = {}) =>
	{
		AddModal(<EventConfigForm eventConfig = {eventConfig}/>);
	}

	const ShowEventForm = (event = {}) =>
	{
		AddModal(<EventForm event = {event}/>);
	}

	const ShowConfirmEventRecurrence = (event = {}, params = {}) =>
	{
		const
		{
			action
		} = params;

		AddModal(<ConfirmEventRecurrence event = {event} action = {action}/>);
	}

	const ShowContactsList = (contacts = []) =>
	{
		AddModal(<ContactsListForm contacts = {contacts}/>);
	}

	const value =
	{
		contactIds,
		currentContactId,
		setCurrentContactId,
		AddContactTab,
		RemoveContactTab,
		ShowChatBotOptionForm,
		ShowDeleteChatBotOption,
		ShowCompanyContact,
		ShowUnlinkContactWarning,
		ShowScheduledMessageForm,
		ShowDeleteScheduledMessage,
		ShowScheduledMessageContacts,
		ShowContactNoteForm,
		ShowDeleteNoteWarning,
		ShowEventConfigForm,
		ShowEventForm,
		ShowConfirmEventRecurrence,
		ShowContactsList,
	}

	return (
		<CRMContext.Provider value={value}>
			{props.children}
		</CRMContext.Provider>
	)
}

const useCRM = () => React.useContext(CRMContext);

export {CRMProvider, useCRM}