import { Section, Panel, Card, Header, Body, Text, Icon } from 'crack-ux';
import { useNavigate } from 'react-router-dom';

// Contexts
import { useTraductor } from '../../../../../contexts/general/Traductor';

// Static Data
import integrations from '../../../../../static-data/Integrations.json';

const IntegrationCard = (props) =>
{
	const {name, icon, route} = props;

	const { Translate } = useTraductor();

	const navigate = useNavigate();

	return (
		<Panel class='desktop2 tablet3 mobile6 pointer'>
			<Card Click={() => navigate(route)}>
				<Header class='center no-border' style={{paddingTop: 35, paddingBottom: 10}}>
					<Icon {...icon} size={4}/>
				</Header>
				<Body class='center' style={{paddingTop: 0, paddingBottom: 35}}>
					<Text>{Translate(name)}</Text>
				</Body>
			</Card>
		</Panel>
	)
}

const IntegrationList = (props) =>
{
	return (
		<Section>
			<Panel>
				{
					integrations.map((integration, index) =>
					{
						return <IntegrationCard key={index} {...integration}/>
					})
				}
			</Panel>
		</Section>
	)
}

export { IntegrationList }