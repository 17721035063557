import React from 'react';
import {Generic} from 'crack-functions';

// Reducers
import {ModulesReducer} from '../../../reducers/collections/home/Modules';

// Contexts
import {useBusiness} from '../../layouts/Business';

const Modules = React.createContext({});

const ModulesProvider = (props) =>
{
	const {syssec} = props;

	const [state, dispatch] = React.useReducer(ModulesReducer, {modules: []});
	const [modulesPlans, setModulesPlans] = React.useState({});

	const {Fetch} = useBusiness();

	const LoadModules = React.useCallback((modules = []) =>
	{
		dispatch({type: 'LOAD_MODULES', modules});
	}, []);

	const LoadModulesPlans = React.useCallback((modulesPlans = []) =>
	{
		setModulesPlans(modulesPlans);
	}, []);

	const modulesByName = React.useMemo(() => Generic.ArrayToObjectByIdentifier(state.modules, 'route'), [state.modules]);

	const AddModule = async(module, plan) =>
	{
		const url = '/module/add';

		const data =
		{
			module,
			plan,
			syssec
		};

		return await Fetch(url, data);
	}

	const value =
	{
		modules: state.modules,
		modulesByName,
		modulesPlans,
		LoadModules,
		LoadModulesPlans,
		AddModule,
	}

	return <Modules.Provider value={value} {...props}/>
}

const useModules = () => React.useContext(Modules);

Modules.defaultProps =
{
	syssec: 'home'
}

export {ModulesProvider, useModules};