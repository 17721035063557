import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Components
import { ReportsList } from './ReportsList';

// Reports
import { Sales } from './Sales';
import { TopProducts } from './TopProducts';

const ReportsRouter = () =>
{
	return (
		<Routes>
			<Route path='/sales' exact={true} element={<Sales/>}/>
			<Route path='/top-products' exact={true} element={<TopProducts/>}/>
			<Route path='/' element={<ReportsList/>}/>
		</Routes>
	)
}

export { ReportsRouter as Reports };