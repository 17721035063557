import {Panel, Card, Header, Body, Text, Avatar, Footer, Icon} from 'crack-ux';
import {Utilities} from 'crack-functions';

// Contexts
import {useBusinessPage} from '../../../../../contexts/pages/public/BusinessPage';

const GetQualificationStars = (qualification) =>
{
	const stars = [];

	for (let i = 0; i < 5; i++)
	{
		const star = qualification - i;

		if (star >= 1)
		{
			stars.push(<Icon key={i} class='icon-star'/>);
		}
		else if (star > 0)
		{
			stars.push(<Icon key={i} class='icon-star-half-alt'/>);
		}
		else
		{
			stars.push(<Icon key={i} class='icon-star empty'/>);
		}
	}

	return stars;
}

const Review = (review) =>
{
	const {name, picture, description, qualification} = review;

	const {business} = useBusinessPage();

	const {page = {}} = business;
	const {reviews: reviewsConf = {}} = page;	

	return (
		<Panel class='review desktop4'>
			<Card
				style =
				{{
					background: reviewsConf.card?.background,
					color: Utilities.HexIsLight(reviewsConf.card?.background) ? 'black' : 'white',
				}}
			>
				<Header class='center no-border'>
					<Avatar class='circle' src={picture}/>
					<Text>{name}</Text>
				</Header>
				<Body class='center'>
					<Text type='p'>{description}</Text>
				</Body>
				<Footer class='qualification center no-border'>
					{GetQualificationStars(qualification)}
				</Footer>
			</Card>
		</Panel>
	);
}

export {Review};