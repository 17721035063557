import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

// Context
import { useSocket } from '../../general/Socket';

// Reducers
import { useBusiness } from '../../layouts/Business';
import { MembersReducer } from '../../../reducers/collections/staff/Members';

const Members = createContext({});

const MembersProvider = (props) =>
{
	const [state, dispatch] = useReducer(MembersReducer, {members: [], membersObj: {}});
	const [permissionsSchema, setPermissionsSchema] = useState({});
	const [currentMember, setCurrentMember] = useState({});

	const Socket = useSocket();
	const { business, BusinessFetch, SomeThingWentWrong } = useBusiness();

	const ReadMembers = useCallback(async () =>
	{
		try
		{
			const response = await BusinessFetch.Get('/staff/members');

			if(response.status === 200)
			{
				dispatch({type: 'LOAD_MEMBERS', members: response.data});
			}
		}
		catch(error)
		{
			SomeThingWentWrong('ReadMembers');
		}
	}, [BusinessFetch, SomeThingWentWrong]);

	const SearchUserByEmail = async (member) =>
	{
		try
		{
			const query =
			{
				email: member.email
			}

			const response = await BusinessFetch.Get('/staff/members/find-by-email', query);

			return response;
		}
		catch(error)
		{
			SomeThingWentWrong('SearchUserByEmail');
		}
	}

	const InviteUserToStaff = async (user) =>
	{
		try
		{
			const response = await BusinessFetch.Post('/staff/members/invite', user);
	
			return response;
		}
		catch(error)
		{
			SomeThingWentWrong('InviteUserToStaff');
		}
	}

	const ReadPermissionsSchema = useCallback(async () =>
	{
		try
		{
			const response = await BusinessFetch.Get('/staff/permissions/schema');

			if(response.status === 200)
			{
				setPermissionsSchema(response.data);
			}
		}
		catch(error)
		{
			SomeThingWentWrong('ReadPermissionsSchema');
		}
	}, [BusinessFetch, SomeThingWentWrong]);

	const ChangePermissions = async (memberId, permissions) =>
	{
		try
		{
			const memberData =
			{
				_id: memberId,
				permissions
			};

			const response = await BusinessFetch.Put(`/staff/members/permissions`, memberData);

			return response;
		}
		catch(error)
		{
			SomeThingWentWrong('ChangePermissions');
		}
	}

	useEffect(() =>
	{
		ReadMembers();
		ReadPermissionsSchema();
	}, [ReadMembers, ReadPermissionsSchema]);

	useEffect(() =>
	{
		const events =
		[
			{
				name: 'STAFF_MEMBER_CREATE',
				Function: (member) => dispatch({type: 'CREATE_MEMBER', member})
			},
			{
				name: 'STAFF_MEMBER_UPDATE',
				Function: (member) => dispatch({type: 'UPDATE_MEMBER', member})
			},
			{
				name: 'STAFF_MEMBER_DELETE',
				Function: (member) => dispatch({type: 'DELETE_MEMBER', member})
			}
		];

		Socket.ConnectEvents(business._id, events);
	}, [Socket, business._id]);

	const value =
	{
		code: state.code,
		members: state.members,
		membersObj: state.membersObj,
		permissionsSchema,
		currentMember,
		setCurrentMember,
		SearchUserByEmail,
		InviteUserToStaff,
		ChangePermissions,
		// availableMembers,
		// StatusMembersMember,
	}

	return <Members.Provider value={value} {...props}/>
}

const useMembers = () => useContext(Members);

export { MembersProvider, useMembers };