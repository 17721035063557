import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

// Context
import { useSocket } from '../../general/Socket';
import { useTraductor } from '../../general/Traductor';
import { useBusiness } from '../../layouts/Business';

// Reducers
import { ListsReducer } from '../../../reducers/collections/Lists';

const ListsContext = createContext({});

const ParseItem = (item, internalName) =>
{
	if(internalName === 'sales-stage')
	{
		item.data =
		{
			exclusionPoint: item.exclusionPoint
		}
	}

	delete item.colorComponent;
	delete item.orderButtons;

	return (item);
}

const ListsProvider = (props) =>
{
	const [state, dispatch] = useReducer(ListsReducer, {lists: [], listsByName: {}});
	const [currentList, setCurrentList] = useState({});
	const [sorting, setSorting] = useState(false);

	const Socket = useSocket();
	const { Translate } = useTraductor();
	const { BusinessFetch, business, SomethingWentWrong } = useBusiness();

	const ReadLists = useCallback(async () =>
	{
		try
		{
			if(business._id)
			{
				const response = await BusinessFetch.Get('/lists');

				if(response.status === 200)
				{
					dispatch({type: 'LOAD_LISTS', lists: response.data || []});
				}
			}
		}
		catch (error)
		{
			SomethingWentWrong('ReadLists');
		}
	}, [BusinessFetch, business._id, SomethingWentWrong]);

	const UpdateList = async (list) =>
	{
		const url = '/list/update';

		return await BusinessFetch(url, list);
	}

	const AddItem = async(internalName, item) =>
	{
		try
		{
			if(state.listsByName[internalName])
			{
				const data =
				{
					item: ParseItem(item, internalName),
					internalName,
				};

				const response = await BusinessFetch.Post('/list/item', data);

				if(response.status === 200)
				{
					window.CrackUX.Toasts.AddToast(
					{
						class: 'success',
						title: Translate(internalName),
						message: Translate('item-created'),
					});
				}
		
				return response;
			}
		}
		catch (error)
		{
			SomethingWentWrong('AddItem');
		}
	}
	
	const UpdateItem = async(internalName, item) =>
	{
		try
		{
			if(state.listsByName[internalName])
			{
				const data =
				{
					item: ParseItem(item, internalName),
					internalName,
				};

				const response = await BusinessFetch.Put('/list/item', data);

				if(response.status === 200)
				{
					window.CrackUX.Toasts.AddToast(
					{
						class: 'success',
						title: Translate(internalName),
						message: Translate('item-updated'),
					});
				}
		
				return response;
			}
		}
		catch (error)
		{
			SomethingWentWrong('UpdateItem');
		}
	}

	const DeleteItem = async(internalName, item) =>
	{
		try
		{
			if(state.listsByName[internalName])
			{
				const data =
				{
					item: ParseItem(item, internalName),
					internalName,
				};

				const result = await BusinessFetch.Delete('/list/item', data);

				if(result.status === 200)
				{
					window.CrackUX.Toasts.AddToast(
					{
						class: 'success',
						title: Translate(internalName),
						message: Translate('item-deleted'),
					});
				}

				return result;
			}
		}
		catch (error)
		{
			SomethingWentWrong('DeleteItem');
		}
	}

	const UpdateOrder = useCallback(async (internalName, order, direction = 'down') =>
	{
		try
		{
			if(state.listsByName[internalName])
			{
				const workingItems = state.listsByName[internalName].workingItems;
				const workingIndex = workingItems.findIndex(item => item.order === order);
	
				if(workingIndex !== -1)
				{
					const items = [];
	
					if(direction === 'up')
					{
						const secondItem = [...workingItems].reverse().find(item => item.order <= order - 1);
	
						if(secondItem?._id)
						{
							items.push({_id: workingItems[workingIndex]._id, order: secondItem.order});
							items.push({_id: secondItem._id, order});
						}
					}
					else if(direction === 'down')
					{
						const secondItem = workingItems.find(item => item.order >= order + 1);
	
						if(secondItem?._id)
						{
							items.push({_id: workingItems[workingIndex]._id, order: secondItem.order});
							items.push({_id: secondItem._id, order});
						}
					}
	
					if(items.length > 0)
					{
						setSorting(true);
	
						const data =
						{
							items,
							internalName,
						};
						
						await BusinessFetch.Post('/list/items/sort', data);
	
						setSorting(false);
					}
				}
			}
		}
		catch (error)
		{
			SomethingWentWrong('UpdateOrder');
		}
	}, [BusinessFetch, state.listsByName, SomethingWentWrong]);

	/**
	 * This function should help to store and assign the list options ids before save a document
	 * @param {Object} form A form result
	 * @param {array} internalNames An array of lists internal names
	 * @returns {Object} An object with list options ids
	 */
	const GetListFieldsValuesForForm = async(form, internalNames) =>
	{
		const fieldsValues = {};

		if(Array.isArray(internalNames))
		{
			for(let i = 0; i < internalNames.length; i++)
			{
				const fieldValue = form[internalNames[i]];

				if(fieldValue)
				{
					const items = state.listsByName[internalNames[i]].items || [];
					const foundItemValue = items.find(item => item._id === fieldValue || item.text === fieldValue)?._id || null;

					if(foundItemValue)
					{
						fieldsValues[internalNames[i]] = foundItemValue;
					}
					else
					{
						const response = await AddItem(internalNames[i], {text: fieldValue});

						if(response.status === 200)
						{
							fieldsValues[internalNames[i]] = response.data._id;
						}
					}
				}
				else
				{
					fieldsValues[internalNames[i]] = null;
				}
			}
		}

		return fieldsValues;
	}

	useEffect(() =>
	{
		ReadLists();
	}, [ReadLists]);

	useEffect(() =>
	{
		const events =
		[
			{
				name: `UPDATE_LIST`,
				Function: (list) =>
				{
					dispatch({type: 'UPDATE_LIST', list});

					if(list._id === currentList._id)
					{
						setCurrentList(list);
					}
				}
			}
		];

		Socket.ConnectEvents(business._id, events);

	}, [Socket, currentList, business._id]);

	const value =
	{
		code: state.code,
		lists: state.lists,
		listsByName: state.listsByName,
		currentList,
		sorting,
		setCurrentList,
		ReadLists,
		UpdateList,
		AddItem,
		UpdateItem,
		DeleteItem,
		UpdateOrder,
		GetListFieldsValuesForForm,
	}

	return <ListsContext.Provider value={value} {...props}/>
}

const useLists = () => useContext(ListsContext);

export {ListsProvider, useLists};