import {useNavigate} from 'react-router-dom';
import {Content, Panel, Header, Body, Footer, Text, Image, Button} from 'crack-ux';
import {Utilities} from 'crack-functions';

// Contexts
import {useBusinessPage} from '../../../../contexts/pages/public/BusinessPage';

const AboutUs = (props) =>
{
	const { data } = props;

	const navigate = useNavigate();
	const {business} = useBusinessPage();

	const {page = {}} = business;
	const {aboutUs} = data || page;

	if(!aboutUs?.active) return null;

	return (
		<Content class='about-us desktop'>
			<Panel class='message desktop6'>
				{/* <Animation animation='enter-right' page={pageRef}> */}
					<Header class='no-border'>
						<Text class='title'>{aboutUs.title}</Text>
						<Text class='caption'>{aboutUs.caption}</Text>
					</Header>
					<Body>
						<Text class='description'>{aboutUs.description}</Text>
					</Body>
					<Footer class='center no-border'>
						<Button
							class = 'action-button'
							text = {aboutUs.productsButtonText}
							Click = {() => navigate('./products')}
							style =
							{{
								background: aboutUs.productsButtonColor,
								color: Utilities.HexIsLight(aboutUs.productsButtonColor) ? 'black' : 'white',
							}}
						/>
						<Button
							class = 'action-button'
							text = {aboutUs.servicesButtonText}
							Click = {() => navigate('./services')}
							style =
							{{
								background: aboutUs.servicesButtonColor,
								color: Utilities.HexIsLight(aboutUs.servicesButtonColor) ? 'black' : 'white',
							}}
						/>
					</Footer>
				{/* </Animation> */}
			</Panel>
			<Panel class='image desktop6'>
				{/* <Animation animation='enter-left' page={pageRef}> */}
					<Image src='/public-page/about-us.png'/>
				{/* </Animation> */}
			</Panel>
		</Content>
	)
}

export {AboutUs}