import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";

// Contexts
import { useGeneral } from "./General";
import { useTraductor } from "./Traductor";

const Session = createContext();

const SessionProvider = (props) =>
{
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState({});
	const [businesses, setBusinesses] = useState([]);

	const { Fetch } = useGeneral();
	const { Translate } = useTraductor();
	
	const SessionFetch = useCallback(async (url, data = {}, method = 'GET') =>
	{
		const options =
		{
			credentials: 'include',
		};

		const response = await Fetch.Core(url, data, method, options);

		return response;
		
	}, [Fetch]);

	const SessionFetchObject = useMemo(() =>
	{
		return {
			Get: (url, data) => SessionFetch(url, data),
			Post: (url, data) => SessionFetch(url, data, 'POST'),
			Put: (url, data) => SessionFetch(url, data, 'PUT'),
			Delete: (url) => SessionFetch(url, {}, 'DELETE'),
		}
	}, [SessionFetch]);

	const Login = async (user, redirect = true) =>
	{
		const messages = {};

		if(!user.username) messages.username = Translate('field-required');
		if(!user.password) messages.password = Translate('field-required');

		if(Object.keys(messages).length > 0)
		{
			return {
				type: 'messages',
				messages,
			};
		}

		try
		{
			const response = await SessionFetch('/users/login', user, 'POST');

			if (response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('access-granted'),
				});

				UpdateSession();

				return {
					type: 'success',
					messages: Translate('access-granted')
				}
			}
			else
			{
				return {
					type: 'failed',
					messages: Translate('access-denied')
				}
			}
		}
		catch (error)
		{
			console.error('Login error:', error);
		}
	}

	const Logout = async () =>
	{
		try
		{
			const response = await SessionFetch('/users/logout', {}, 'POST');

			if (response.status === 200)
			{
				setUser({});
				setBusinesses([]);
				setLoading(true);

				return true;
			}
			else
			{
				return false;
			}
		}
		catch (error)
		{
			console.error('Logout error:', error);
		}
	}

	const UpdateSession = useCallback(async () =>
	{
		try
		{
			setLoading(true);

			const response = await SessionFetch('/users/session-data');

			if (response.status === 200)
			{
				setUser(response.data.user);
				setBusinesses(response.data.business);
			}
		}
		catch (error)
		{
			console.error('UpdateSession error:', error);
		}
		finally
		{
			setLoading(false);
		}
	}, [SessionFetch]);

	useEffect(() =>
	{
		UpdateSession();
	}, [UpdateSession]);

	const value =
	{
		loading,
		user,
		businesses,
		SessionFetch: SessionFetchObject,
		Login,
		Logout,
		UpdateSession,
	}

	return <Session.Provider value={value} {...props}/>;
}

const useSession = () => useContext(Session);

export {SessionProvider, useSession};