import { Table } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Context
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useMembers } from '../../../../contexts/collections/staff/Members';

// Components
import { MemberForm } from './MemberForm';

const MembersTable = () =>
{
	const { AddModal } = useModal();
	const { Translate } = useTraductor();
	const { members, setCurrentMember, StatusStaffMember } = useMembers();

	const navigate = useNavigate();
	const params = useParams();

	const { memberId } = params;

	return (
		<Table
			class = 'current'
			header =
			{[
				{
					name: 'nameText',
					text: Translate('name')
				},
				{
					name: 'lastNameText',
					text: Translate('last-name')
				},
				{
					name: 'emailText',
					text: Translate('email')
				},
				// {
				// 	name: 'phoneText',
				// 	text: Translate('phone')
				// },
				{
					name: 'statusText',
					text: Translate('status')
				},
				{
					name: 'invitationText',
					text: Translate('invitation')
				}
			]}
			body = {members.map(member =>
			{
				member.invitationText = Translate(member.invitation);

				return member;
			})}
			actions =
			{[
				{
					text: Translate('New Member'),
					class: 'bg-blue white',
					section: 'grouped',
					Click: () => AddModal(<MemberForm/>)
				},
				{
					text: 'Active / Inactive',
					section: 'context',
					class: 'red',
					icon:
					{
						class: 'icon-switch'
					},
					Click: (member) =>
					{
						StatusStaffMember(member)
					}
				}
			]}
			ClickRow = {(member) =>
			{
				setCurrentMember(member);
				navigate(`${memberId ? '../' : './'}${member.fullName}/${member._id}`);
			}}
			firstLoad =
			{{
				triggerClickRow: false
			}}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export')
				}
			}
			filters = 
			{{
				columns: {
					editable: true
				},
				Apply:{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
}

MembersTable.defaultProps =
{
	actions: []
}

export { MembersTable };