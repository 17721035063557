import { useEffect, useMemo, useState } from "react";
import { Card, Header, Body, Footer, Text, Form, Button } from "crack-ux";
import moment from "moment";

// Contexts
import { useModal } from "../../../../contexts/general/Modal";
import { useTraductor } from "../../../../contexts/general/Traductor";
import { useLists } from "../../../../contexts/collections/home/Lists";
import { useMovements } from "../../../../contexts/collections/commerce/Movements";

const PaymentForm = (props) =>
{
	const { movement } = props;

	const [paymentForm, setPaymentForm] = useState({});
	const [messages, setMessages] = useState({});
	
	const { CloseModal } = useModal();
	const { Translate } = useTraductor();
	const { listsByName } = useLists();
	const { AddPayment } = useMovements();

	const paymentMethods = useMemo(() => listsByName['payment-methods'] || [], [listsByName]);

	const SavePayment = async () =>
	{
		const messagesErrors = {};

		if(!paymentForm.date) messagesErrors.date = Translate('required-field');
		if(!paymentForm.method) messagesErrors.method = Translate('required-field');
		if(!paymentForm.amount) messagesErrors.amount = Translate('required-field');
		if(paymentForm.amount > (movement.total - movement.discount - movement.payments.reduce((acc, payment) => acc + payment.amount, 0))) messagesErrors.amount = Translate('amount-exceeds-remaining');
		if(paymentForm.amount < 0) messagesErrors.amount = Translate('amount-negative');
		if(paymentForm.amount === '0') messagesErrors.amount = Translate('amount-zero');
		
		setMessages(messagesErrors);

		if(Object.keys(messagesErrors).length === 0)
		{
			const payment =
			{
				...paymentForm,
				date: moment(`${paymentForm.date} ${paymentForm.time}`, 'YYYY-MM-DD HH:mm').toDate(),
				movementId: movement._id,
			};

			const response = await AddPayment(payment);

			if(response.status === 200)
			{
				CloseModal();
			}
		}
	}

	useEffect(() =>
	{
		if(Object.keys(paymentForm).length === 0 && paymentMethods.items?.length > 0)
		{
			const paymentMethod = paymentMethods.items.find(method => method.internalName === 'cash')?._id;

			setPaymentForm(
			{
				...paymentForm,
				method: paymentMethod || paymentMethods.items[0]._id,
				date: moment().format('YYYY-MM-DD'),
				time: moment().format('HH:mm'),
				amount: movement.total - movement.discount - movement.payments.reduce((acc, payment) => acc + payment.amount, 0),
			});
		}
	}, [paymentMethods, paymentForm, movement]);

	return (
		<Card>
			<Header>
				<Text class='title'>{Translate('payment')}</Text>
			</Header>
			<Body>
				<Form
					inputs =
					{[
						{
							name: 'date',
							text: Translate('date'),
							class: 'mobile6',
							type: 'date',
							value: paymentForm.date,
							message: messages.date,
						},
						{
							name: 'time',
							text: Translate('time'),
							class: 'mobile6',
							type: 'time',
							value: paymentForm.time,
						},
						{
							name: 'method',
							text: Translate('payment-method'),
							type: 'select',
							options: paymentMethods.selectOptions,
							value: paymentForm.method,
							message: messages.method,
						},
						{
							name: 'amount',
							text: Translate('amount'),
							type: 'number',
							value: paymentForm.amount,
							message: messages.amount,
						}
					]}
					form = {paymentForm}
					setForm = {setPaymentForm}
				/>
			</Body>
			<Footer class='right'>
				<Button class='bg-blue white' text={Translate('save')} Click={SavePayment}/>
				<Button text={Translate('cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
};

export { PaymentForm };