import { useCallback, useEffect, useMemo, useState } from 'react';
import { Section, Header, Body, Table, Box, Text, Icon, Confirm } from 'crack-ux';
import { Generic } from 'crack-functions';
import { useNavigate } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useSession } from '../../../../contexts/general/Session';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useModal } from '../../../../contexts/general/Modal';
import { useItems } from '../../../../contexts/collections/storage/Items';

// Components
import { ItemForm } from './ItemForm';
import { UploadFilesForm } from './UploadFiles';
import { FileViewer } from './FileViewer';
import { BackButton } from '../../../../components/BackButton';

const ItemsTable = ({module, draggableRows, from}) =>
{
	const [folders, setFolders] = useState([{_id: null, name: ''}]);
	const [showConfirm, setShowConfirm] = useState(false);

	const { screen } = useGeneral();
	const { user } = useSession();
	const { Translate } = useTraductor();
	const { AddModal } = useModal();
	const { items, currentItem, setCurrentItem, PublicPrivate, DeleteItem } = useItems();

	const navigate = useNavigate();

	useEffect(() =>
	{
		setFolders([{_id: null, name: Translate(module)}]);
	}, [module, Translate]);

	const GoToFolder = useCallback((key) =>
	{
		const foldersClone = [...folders];

		key ++;

		if(foldersClone.length > 1 && key < foldersClone.length)
		{
			foldersClone.splice(key, foldersClone.length - key);

			setFolders(foldersClone);
		}
	}, [folders]);

	const HandleDownload = useCallback((item) =>
	{
		Generic.DownloadURI(`${item.publicURL}?action=download`, item.name);
	}, []);

	const parent = useMemo(() =>
	(
		folders[folders.length - 1]
	), [folders]);

	const moduleDocuments = useMemo(() =>
	(
		items.filter(item => item.folder === module)
	), [items, module]);

	const body = useMemo(() =>
	{
		return moduleDocuments.filter(document => document.parent === parent._id).map(document =>
		{
			if(!document.file)
			{
				const items = moduleDocuments.filter(item => item.parent === document._id).length;

				document.items = items;
				document.sizeText = `${items} items`;
			}

			return document;
		})
	}, [moduleDocuments, parent]);

	const history = useMemo(() =>
	{
		const arrayFolders = folders.map((folder, key) =>
		{
			const color = key < folders.length - 1 ? 'blue pointer' : '';

			return <Box key={key} Click={() => GoToFolder(key)}><Text class={color}>{folder.name}</Text></Box>;
		});

		return arrayFolders.reduce((prev, curr, key) => [...prev, prev.length === 0 ? <Icon class='icon-folder-open' style={{marginRight: 8}} key={`${key}_icon`}/> : <Text style={{margin: '0px 5px'}} key={`${key}_sep`}>/</Text>, curr], []);
	}, [folders, GoToFolder]);

	const MainAction = useCallback((document) =>
	{
		if(!document.file)
		{
			setFolders(current => [...current, document]);
		}
		else
		{
			AddModal(<FileViewer document={document}/>);
		}
	}, [AddModal]);

	const actions = useMemo(() =>
	{
		const result = [];

		result.push
		({
			text: Translate('preview'),
			Condition: (item) => item.file,
			section: 'context',
			icon:
			{
				class: 'icon-eye'
			},
			Click: MainAction
		});

		result.push
		({
			text: Translate('open-folder'),
			Condition: (item) => !item.file,
			section: 'context',
			icon:
			{
				class: 'icon-forward'
			},
			Click: MainAction
		});

		if(!parent.readOnly)
		{
			result.push
			({
				class: 'bg-blue white',
				text: Translate('create-folder'),
				Click: () => AddModal(<ItemForm module={module} parent={parent}/>),
				section: 'grouped'
			});

			result.push
			({
				class: 'bg-blue white',
				text: Translate('upload-files'),
				Click: () => AddModal(<UploadFilesForm module={module} parent={parent}/>),
				section: 'grouped'
			});
		}

		result.push
		({
			text: Translate('download'),
			section: 'context',
			Condition: (item) => item.file,
			icon:
			{
				class: 'icon-download'
			},
			Click: (item) =>
			{
				if(item.file)
				{
					HandleDownload(item);
				}
			}
		});

		result.push
		({
			text: `${Translate('public')}/${Translate('private')}`,
			section: 'context',
			Condition: (item) => item.file,
			icon:
			{
				class: 'icon-exchange'
			},
			Click: (item) =>
			{
				if(item.file)
				{
					PublicPrivate(item);
				}
			}
		});

		result.push
		({
			text: Translate('Edit'),
			section: 'context',
			icon:
			{
				class: 'icon-pencil'
			},
			Click: (item) =>
			{
				if(!item.readOnly)
				{
					AddModal(<ItemForm module={module} parent={parent} item={item}/>);
				}
			}
		})

		result.push
		({
			class: 'red',
			text: Translate('Delete'),
			section: 'context',
			icon:
			{
				class: 'icon-trash'
			},
			Click: (item) =>
			{
				if(!item.readOnly)
				{
					setCurrentItem(item);
					setShowConfirm(true);
				}
			}
		});

		return result;
	}, [module, parent, Translate, HandleDownload, PublicPrivate, AddModal, setCurrentItem, MainAction]);

	return (
		<Section class='sice-documents full-height'>
			<Header>
				{screen.device === 'mobile' && <BackButton Click={() => navigate('../')}/>}
				<Box style={{marginTop: screen.device === 'mobile' ? 8 : 0}}>
					{history}
				</Box>
			</Header>
			<Body class='no-padding' style={{height: `calc(100% - ${screen.device === 'mobile' ? '61px' : 'var(--header)'})`}}>
				<Table
					header =
					{[
						{
							name: 'typeIcon',
							text: Translate('type')
						},
						{
							name: 'name',
							text: Translate('file-name')
						},
						{
							name: 'extension',
							text: Translate('extension')
						},
						{
							name: 'sizeText',
							text: Translate('size')
						},
						{
							name: 'dateCreatedText',
							text: Translate('date-created')
						},
						{
							name: 'publicURLIcon',
							text: Translate('public-url')
						}
					]}
					body = {body}
					actions = {actions}
					DoubleClickRow = {MainAction}
					draggable = {draggableRows ? {types: ['data'], Condition: (row) => row.file && row.isPublic} : false}
					language = {user.preferences?.language}
				/>
			</Body>
			<Confirm
				show={showConfirm}
				header={<Text class='bold'>{Translate(`delete-${currentItem.type}`)}</Text>}
				body=
				{
					<>
						<Text type='p'>{Translate(`confirm-delete-${currentItem.type}`)}</Text>
						<Text type='p'><span className='bold'>{Translate(currentItem.type)}: </span>{currentItem.name}</Text>
					</>
				}
				confirm=
				{{
					text: Translate('delete'),
					class: 'bg-red white',
					Click: () =>
					{
						DeleteItem(currentItem);
						setShowConfirm(false);
					}
				}}
				cancel=
				{{
					text: Translate('cancel'),
					Click: () => setShowConfirm(false)
				}}
			/>
		</Section>
	);
};

ItemsTable.defaultProps =
{
	draggableRows: false,
	view: 'both' // public, private, both
}

export { ItemsTable };