import React from 'react'
import {Header, Body, Footer, Form, Text, Button} from 'crack-ux';
import {useNavigate, useParams} from 'react-router-dom';
import {Generic, Text as TextF} from 'crack-functions';

const PasswordRecoverForm = (props) =>
{
	const [form, setForm] = React.useState({});
	const [showPassword, setShowPassword] = React.useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
	const [response, setResponse] = React.useState(null);

	const {token} = useParams();
	const navigate = useNavigate();

	const GeneratePassword = () =>
	{
		const firstPartPassword = TextF.RandomString(8);
		const secondPartPassword = TextF.RandomString(2, 
		{
			letters:'',
			numbers: '1234567890',
			characters: ''
		});
		const thirdPartPassword = TextF.RandomString(2, 
		{
			letters:'',
			numbers:'',
			characters: '!@#$%^&*()',
		});

		setForm(
		{
			...form,
			password: firstPartPassword + secondPartPassword + thirdPartPassword,
			confirmPassword: firstPartPassword + secondPartPassword + thirdPartPassword
		});
	}

	const HasAlphanumeric = (text) =>
	{ 
		return /[0-9a-zA-Z]/.test(text); 
	}

	const HasSpecialCharacter = (text) =>
	{
		return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(text);
	}

	const HasLowercase = (texto) =>
	{
		const letras="abcdefghyjklmnopqrstuvwxyz";

		for(let i = 0; i < texto.length; i++)
		{
      		if(letras.indexOf(texto.charAt(i), 0) !== -1)
			{
         		return true;
      		}
   		}
   		return false;
	}

	const HasUppercase = (texto) =>
	{
		const letras = "ABCDEFGHYJKLMNOPQRSTUVWXYZ";

		for(let i = 0; i < texto.length; i++)
		{
      		if(letras.indexOf(texto.charAt(i), 0) !== -1)
			{
         		return true;
      		}
   		}
   		return false;
	}

	const ChangePassword = () =>
	{
		const errors = [];
		const {password, confirmPassword} = form;

		if(!password || !confirmPassword)
		{
			setResponse(<Text class='red'>Fill all the fields to change the password</Text>);
		}
		else if (password !== confirmPassword)
		{
			setResponse(<Text class='red'>Passwords don't match</Text>);
		}
		else
		{
			if(password.length < 8)
			{
				errors.push(<Text class='red'>At least 8 characters</Text>);
			}
			
			if(!HasAlphanumeric(password))
			{
				errors.push(<Text class='red'>At least 1 letter and 1 number</Text>);
			}
			
			if(!HasSpecialCharacter(password))
			{
				errors.push(<Text class='red'>At least 1 Special Character</Text>);
			} 
			
			if(!HasUppercase(password))
			{
				errors.push(<Text class='red'>At least 1 uppercase letter</Text>);
			}
			
			if(!HasLowercase(password))
			{
				errors.push(<Text class='red'>At least 1 lowercase letter</Text>);
			}

			if(errors.length === 0)
			{
				const data =
				{
					token,
					password,
					confirmPassword
				}

				Generic.Fetch('/user/change-password-token', data).then(resp =>
				{
					if(resp.status === 200)
					{
						setResponse(<Text class='green center'>Password changed successfully</Text>);

						setTimeout(()=>
						{
							navigate('/');
						}, 2000);
					}
				})
			}
			else
			{
				errors.unshift(<Text>The password must have:</Text>)

				setResponse(errors.map((ErrorComponent, key) =>
				{
					const Error = ErrorComponent.type;
					return <Error {...ErrorComponent.props} key = {key}/>
				}));
			}
		}
	}

	return (
		<>
			<Header>
				<Text class='block center uppercase blue' size={1.5}>Change your password</Text>
			</Header>
			<Body>
				<Form
					inputs = 
					{[
						{
							type: 'content',
							content: <Button
								class = 'bg-blue white block full-width center'
								text = 'Password Generator'
								Click = {GeneratePassword}
							/>
						},
						{
							name: 'password',
							type: showPassword ? 'text' : 'password',
							text: 'New password',
							value: form.password,
							icon: 
							{
								class: showPassword ? 'icon-eye-off bg-blue white pointer' : 'icon-eye bg-blue white pointer',
								Click: () => setShowPassword(!showPassword)
							},
							required: true
						},
						{
							name: 'confirmPassword',
							type: showConfirmPassword ? 'text' : 'password',
							text: 'Confirm Password',
							value: form.confirmPassword,
							icon: 
							{
								class: showConfirmPassword ? 'icon-eye-off bg-blue white pointer' : 'icon-eye bg-blue white pointer',
								Click: () => setShowConfirmPassword(!showConfirmPassword)
							},
							required: true
						}
					]}
					response = {response}
					Change = {(response) => setForm(response)}
					Submit = {ChangePassword}
				/>
			</Body>
			<Footer>
				<Button class='full-width bg-blue white' text='Change Password' Click={ChangePassword}/>
			</Footer>
		</>
	)
}

export {PasswordRecoverForm}