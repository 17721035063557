import React from "react";

// Contexts
import {useModal} from "../../../general/Modal";
import {useMessages} from "../../../collections/crm/Messages";
import {useContactsNotes} from "../../../collections/crm/ContactsNotes";

// Forms
import {ContactNoteForm} from "../../../../components/forms/modules/crm/ContactNote/ContactNote";

// Warnings
import {DeleteNote} from "../../../../components/warnings/crm/DeleteContactNote/DeleteContactNote";

const Contacts = React.createContext({});

const ContactsProvider = (props) =>
{
	const [contactsIds, setContactsIds] = React.useState([]);

	const {AddModal} = useModal();

	const {LoadContactMessages} = useMessages();
	const {LoadContactNotes} = useContactsNotes();

	const AddContactTab = (contactId) =>
	{
		const foundContactId = contactsIds.find(item => item === contactId);

		if(!foundContactId)
		{
			setContactsIds(current => [...current, contactId]);

			LoadContactMessages(contactId);
			LoadContactNotes(contactId);
		}
	}

	const RemoveContactTab = (contactId) =>
	{
		const foundContactId = contactsIds.find(item => item === contactId);

		if(foundContactId)
		{
			setContactsIds(contactsIds.filter(item => item !== contactId));
		}
	}

	const ShowContactNoteForm = (note, params = {}) =>
	{
		const
		{
			contact = {}
		} = params;

		AddModal(<ContactNoteForm note = {note} contact = {contact}/>);
	}

	const ShowDeleteNoteWarning = (note = {}) =>
	{
		AddModal(<DeleteNote note = {note}/>);
	}

	const value =
	{
		contactsIds,
		AddContactTab,
		RemoveContactTab,
		ShowContactNoteForm,
		ShowDeleteNoteWarning,
	}

	return (
		<Contacts.Provider value={value}>
			{props.children}
		</Contacts.Provider>
	);
}

const useContacts = () => React.useContext(Contacts);

export {ContactsProvider, useContacts};