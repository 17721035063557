import {Content, Header, Body, Text} from 'crack-ux';

// Contexts
import {useBusinessPage} from '../../../../contexts/pages/public/BusinessPage';

// Components
import {Feature} from '../components/Feature';

const Features = () =>
{
	const {business} = useBusinessPage();

	const {page = {}} = business;
	const {features: featuresConf = {}} = page;
	const {features = []} = featuresConf;

	return (
		<Content class='features desktop'>
			<Header class='center no-border'>
				<Text class='title'>{featuresConf?.title}</Text>
				<Text class='caption'>{featuresConf?.caption}</Text>
			</Header>
			<Body class='no-padding'>
				{features.map(feature => <Feature key={feature._id} {...feature} />)}
			</Body>
		</Content>
	);
}

export {Features}