import { useState } from 'react';
import { Card, Header, Body, Footer, Form, Button, Text } from 'crack-ux';

// Context
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useProviders } from '../../../../contexts/collections/commerce/Providers';

// Components
import { PhonesForm } from '../../../../components/PhonesForm/PhonesForm';
import { EmailsForm } from '../../../../components/EmailsForm/EmailsForm';

const ProviderForm = (props) =>
{
	const { provider = {} } = props;

	const [providerForm, setProviderForm] = useState(provider);
	const [phones, setPhones] = useState(provider.phones || []);
	const [emails, setEmails] = useState(provider.emails || []);
	const [messages, setMessages] = useState({});

	const { CloseModal } = useModal();
	const { Translate } = useTraductor();
	const { CreateProvider , UpdateProvider} = useProviders();

	const HandleSubmit = async () =>
	{
		const messagesData = {};

		if(!providerForm.name) messagesData.name = Translate('field-required');

		setMessages(messagesData);

		if(Object.keys(messagesData).length === 0)
		{
			const data =
			{
				...provider,
				...providerForm,
				phones,
				emails
			};
	
			const result = provider._id ? await UpdateProvider(data) : await CreateProvider(data);
	
			if(result.status === 200)
			{
				CloseModal();
			}
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{provider._id ? Translate(`edit-provider`) : Translate(`new-provider`)}</Text>
			</Header>
			<Body>
				<Form
					inputs =
					{
						[
							{
								name: 'name',
								text: Translate('provider-name'),
								value: providerForm.name,
								message: messages.name
							},
							{
								name: 'tin',
								text: Translate('tin'),
								value: providerForm.tin,
								message: messages.tin
							},
							{
								text: Translate('phones'),
								type: 'content',
								message: messages.phones,
								content: <PhonesForm phones={phones} setPhones={setPhones} />
							},
							{
								text: Translate('emails'),
								type: 'content',
								content: <EmailsForm emails={emails} setEmails={setEmails} />
							},
						]
					}
					form = {providerForm}
					setForm = {setProviderForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('save')}
					class = 'bg-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {ProviderForm};