import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Context
import { GeneralProvider } from './contexts/general/General';
import { TraductorProvider } from './contexts/general/Traductor';
import { SessionProvider } from './contexts/general/Session';
import { UsersProvider } from './contexts/main/Users';
import { WorkerProvider } from './contexts/general/Worker';

// Layouts
import { Public } from './layouts/Public';
import { Personal } from './layouts/Personal';
import { Business } from './layouts/Business';

const AppRoutes = () =>
{
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/p/*' element={<Personal/>}/>
				<Route path='/b/:business/*' element={<Business/>}/>
				<Route path='*' element={<Public/>}/>
			</Routes>
		</BrowserRouter>
	)
}

const AppRoutesWithProviders = () =>
{
	return (
		<GeneralProvider>
			<TraductorProvider>
				<SessionProvider>
					<UsersProvider>
						<WorkerProvider>
							<AppRoutes/>
						</WorkerProvider>
					</UsersProvider>
				</SessionProvider>
			</TraductorProvider>
		</GeneralProvider>
	)
}

export default AppRoutesWithProviders;  