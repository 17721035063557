import React from 'react';
import {Content, Card, Header, Body, Footer, Box, Text, Form, Searcher, Icon, Button} from 'crack-ux';

// Contexts
import {useModal} from '../../../../contexts/general/Modal';
import {useBusinessPage} from '../../../../contexts/pages/public/BusinessPage';

// Components
import {Service} from '../components/Service';

// Styles
import './Services.css';

const Services = () =>
{
	const {AddModal, CloseModal} = useModal();	
	const {services, servicesCategories} = useBusinessPage();

	const ShowFilters = React.useCallback(() =>
	{
		AddModal(
			<Card>
				<Header>
					<Text>Filtros</Text>
					<Icon class='icon-cancel' Click={CloseModal}/>
				</Header>
				<Body>
					<Form
						inputs =
						{[
							{
								text: 'Categorias',
								type: 'select',
								options: servicesCategories
							}
						]}
					/>
				</Body>
				<Footer>
					<Button text='Aplicar'/>
					<Button text='Cancelar' Click={CloseModal}/>
				</Footer>
			</Card>
		);
	}, [AddModal, CloseModal, servicesCategories]);

	return (
		<Content class='services'>
			<Header>
				<Box>
					<Searcher/>
				</Box>
				<Button Click={ShowFilters}>
					<Icon class='icon-filter'/>
				</Button>
			</Header>
			<Body>
				{services.map(service => <Service key={service._id} {...service} />)}
			</Body>
		</Content>
	)
}

export {Services};