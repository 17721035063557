import { useState } from 'react';
import { Table, Confirm, Text, Header, Body } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useSession } from '../../../../contexts/general/Session';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useModal } from '../../../../contexts/general/Modal';
import { useClients } from '../../../../contexts/collections/commerce/Clients';

// Components
import { ClientForm } from './ClientForm';

const ClientsTable = () =>
{
	const [showConfirm, setShowConfirm] = useState(false);

	const { user } = useSession();
	const { Translate } = useTraductor();
	const { AddModal } = useModal();
	const { clients, currentClient, setCurrentClient, DeleteClient } = useClients();

	const navigate = useNavigate();
	const { clientId } = useParams();

	return (
		<>
			<Header>
				<Text class='title'>{Translate('clients')}</Text>
			</Header>
			<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
				<Table
					class = 'current hover pointer'
					header = 
					{[
						{
							name: 'codeText',
							text: Translate('code')
						},
						{
							name: 'name',
							text: Translate('client-name')
						},
						{
							name: 'tin',
							text: Translate('tin')
						},
						{
							name: 'phonesText',
							text: Translate('phones')
						},
						{
							name: 'emailsText',
							text: Translate('emails')
						},
						{
							name: 'discountText',
							text: Translate('discount')
						},
					]}
					body = {clients.map(client =>
					{
						if(client.code === 0)
						{
							client.name = Translate(client.name)
							client.discountText = '';
						}

						return client;
					})}
					ClickRow = {(client) => navigate(`${clientId ? '../' : './'}${client.name}/${client._id}`)}
					actions =
					{[
						{
							text: Translate('new-client'),
							class: 'bg-blue white',
							section: 'grouped',
							Click: () => AddModal(<ClientForm/>),
							Condition: (client) => client.code !== 0
						},
						{
							text: Translate('edit-client'),
							section: 'context',
							icon:
							{
								class: 'icon-pencil'
							},
							Click: (client) => AddModal(<ClientForm client={client}/>),
							Condition: (client) => client.code !== 0
						},
						{
							text: Translate('delete-client'),
							class: 'red',
							section: 'context',
							icon:
							{
								class: 'icon-trash'
							},
							Click: (client) =>
							{
								setCurrentClient(client);
								setShowConfirm(true);
							},
							Condition: (client) => client.code !== 0
						}
					]}
					exportable = {false}
					language = {user.preferences?.language || 'es'}
					filters = 
					{{
						columns:
						{
							editable: true
						},
						Apply:
						{
							text: Translate('Apply'),
							Click: () => {}
						},
						Reset:
						{
							text: Translate('Reset'),
							Click: () => {}
						}
					}}
					firstLoad = 
					{{
						triggerClickRow: false
					}}
				/>
			</Body>
			<Confirm
				show={showConfirm}
				header={<Text class='bold'>{Translate(`delete-client`)}</Text>}
				body=
				{
					<>
						<Text type='p'>{Translate(`confirm-delete-client`)}</Text>
						<Text type='p'><span className='bold'>{Translate('client-name')}: </span>{currentClient.name}</Text>
					</>
				}
				confirm=
				{{
					text: Translate('delete'),
					class: 'bg-red white',
					Click: () =>
					{
						DeleteClient(currentClient);
						setShowConfirm(false);
					}
				}}
				cancel=
				{{
					text: Translate('cancel'),
					Click: () => setShowConfirm(false)
				}}
			/>
		</>
	)
}

export { ClientsTable };