import {Text as TextF, Dates} from 'crack-functions';

// Functions
import Phones from '../../../functions/Phones';

const SetContact = (contact) =>
{
	contact.codeText = `CON-${contact.code}`;
	contact.fullName = `${contact.name || ''} ${contact.lastName || ''}`;
	contact.age = contact.dob && Dates.GetAge(contact.dob);
	contact.ageNumber = contact.dob ? Dates.GetAge(contact.dob) : 0;
	contact.dateForm = contact.dob && Dates.GetDateForForm(contact.dob);
	contact.phoneText = Phones.GetPhoneTexts(contact.phones).join(', ');
	contact.tagsText = Array.isArray(contact.tags) ? contact.tags.join(', ') : '';
	contact.emailText = Array.isArray(contact.emails) ? contact.emails.filter(email => email?.address).map(email => email.address).join(', ') : '';
	contact.initials = TextF.Initials(contact.fullName);

	return contact;
}

const ContactsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_CONTACTS':
			return {
				...state,
				contacts: action.contacts.map(contact => SetContact(contact))
			}

		case 'ADD_CONTACTS':
			return {
				...state,
				contacts: [...state.contacts.slice(0, action.from), ...action.contacts.map(contact => SetContact(contact))]
			}

		case 'CREATE_CONTACT':
			return {
				...state,
				contacts: [...state.contacts, SetContact(action.contact)],
				code: state.code + 1
			}

		case 'UPDATE_CONTACT':
			return {
				...state,
				contacts: state.contacts.map(contact => contact._id === action.contact._id ? SetContact(action.contact) : contact)
			}

		case 'DELETE_CONTACT':
		{
			return {
				...state,
				contacts: state.contacts.filter(contact => contact._id !== action.contact._id)
			}
		}

		case 'SET_LATEST_CONTACT_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {ContactsReducer, SetContact};