import { Content } from 'crack-ux';

// Components
import { WhatsappTable } from './WhatsappTable';

const Whatsapp = () =>
{
	return (
		<Content>
			<WhatsappTable/>
		</Content>
	)
}

export { Whatsapp };