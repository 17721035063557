import { useEffect, useMemo, useState } from 'react';
import {Card, Header, Body, Footer, Form, Button, Text} from 'crack-ux';

// Context
import {useModal} from '../../../../contexts/general/Modal';
import {useTraductor} from '../../../../contexts/general/Traductor';
import {useItems} from '../../../../contexts/collections/storage/Items';

const ItemForm = ({item = {}, module, parent}) =>
{
    const [itemForm, setItemForm] = useState(item);
	const [messages, setMessages] = useState({});

    const {CloseModal} = useModal();
    const {Translate} = useTraductor();

	const {CreateItem, UpdateItem} = useItems();
	
	const HandleSubmit = async () =>
	{
		const messagesData = {};

		if(!itemForm.name) messagesData.name = Translate('field-required');

		setMessages(messagesData);

		if(Object.keys(messagesData).length === 0)
		{
			const itemData =
			{
				...itemForm,
				parent: parent._id
			};

			const result = item._id ? await UpdateItem(itemData) : await CreateItem(itemData);
	
			if(result.status === 200)
			{
				CloseModal();
			}
		}
	}

	const inputs = useMemo(() =>
	{
		const inputs =
		[{
			name: 'name',
			text: Translate(`${itemForm.type}-name`),
			message: messages.name,
			value: itemForm.name
		}];

		if(item._id && item.file)
		{
			inputs.push
			({
				name: 'isPublic',
				text: Translate('public-file'),
				value: itemForm.isPublic,
				type: 'checkbox'
			});
		}

		return inputs;
	}, [item, itemForm, messages, Translate]);

	useEffect(() =>
	{
		if(Object.keys(itemForm).length === 0)
		{
			setItemForm({
				folder: module,
				type: 'folder'
			});
		}
	}, [itemForm, module]);

	return (
		<Card>
			<Header>
				<Text class='title'>{item._id ? Translate(`edit-${itemForm.type}`) : Translate(`new-${itemForm.type}`)}</Text>
			</Header>
			<Body class='vertical-scroll'>
				<Form
					inputs = {inputs}
					form = {itemForm}
					setForm = {setItemForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('save')}
					class = 'bg-blue white'
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {ItemForm};