import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Toasts } from 'crack-ux';
import { Generic } from 'crack-functions';
import config from '../../config';

const GeneralContext = createContext();

const AppendToFormData = (formData, data, parentKey = null) =>
{
	for (const key in data)
	{
		if (key === '_files')
		{
			continue;
		}

		const fullKey = parentKey ? `${parentKey}[${key}]` : key;

		if (Array.isArray(data[key]))
		{
			data[key].forEach((item, index) =>
			{
				if (typeof item === 'object' && item !== null)
				{
					AppendToFormData(formData, item, `${fullKey}[${index}]`);
				}
				else
				{
					formData.append(`${fullKey}[${index}]`, item);
				}
			});
		}
		else if (typeof data[key] === 'object' && data[key] !== null)
		{
			AppendToFormData(formData, data[key], fullKey);
		}
		else
		{
			formData.append(fullKey, data[key]);
		}
	}
}

const GeneralProvider = (props) =>
{
	const [screen, setScreen] = useState({});

	const Fetch = useCallback(async (url, data = {}, method = 'GET', options = {}) =>
	{	
		const isAbsolute = url.includes('http');
		let fullURL = isAbsolute ? url : `${config.server.fullDomain}${url}`;
	
		const hasFiles = data.hasOwnProperty('_files');
	
		if (hasFiles)
		{
			options =
			{
				...options,
				method,
				headers:
				{
					...options.headers // Do not set 'Content-Type' header for FormData
				}
			};
	
			const formData = new FormData();

			AppendToFormData(formData, data);
	
			// Append files
			const filesObj = data['_files'];
			for (const fieldName in filesObj)
			{
				const filesArray = filesObj[fieldName]; // Should be an array of files
	
				filesArray.forEach((file) =>
				{
					formData.append(fieldName, file);
				});
			}
	
			options.body = formData;
		}
		else
		{
			options =
			{
				...options,
				method,
				headers:
				{
					'Content-Type': 'application/json',
					...options.headers
				}
			};
	
			if (method === 'GET')
			{
				const BuildQuery = (data, parentKey = '') =>
				{
					let queryString = '';
			
					for (const key in data)
					{
						if (data.hasOwnProperty(key))
						{
							const newKey = parentKey ? `${parentKey}[${key}]` : key;
			
							if (typeof data[key] === 'object' && data[key] !== null)
							{
								queryString += BuildQuery(data[key], newKey);
							}
							else
							{
								queryString += `${encodeURIComponent(newKey)}=${encodeURIComponent(data[key])}&`;
							}
						}
					}
			
					return queryString;
				};
			
				const queryString = BuildQuery(data).slice(0, -1); // Elimina el último `&`
				fullURL += `?${queryString}`;
			}
			else
			{
				options.body = JSON.stringify(data);
			}
		}
	
		try
		{
			const response = await fetch(fullURL, options);
	
			if(response.ok)
			{
				const result = await response.json();
				return result;
			}
			else
			{
				console.error('Fetch error:', response);
				return null;
			}
		}
		catch (error)
		{
			throw error;
		}
	}, []);

	const FetchObject = useMemo(() =>
	{
		return {
			Core: Fetch,
			Get: async (url, data, options) => Fetch(url, data, 'GET', options), 
			Post: async (url, data, options) => Fetch(url, data, 'POST', options),
			Put: async (url, data, options) => Fetch(url, data, 'PUT', options),
			Delete: async (url, data, options) => Fetch(url, data, 'DELETE', options)
		};
	}, [Fetch]);

	const HandleResize = () =>
	{
		const device = Generic.CurrentScreen();
		
		setScreen(
		{
			width: window.innerWidth,
			height: window.innerHeight,
			device,
		});
	};

	useEffect(() =>
	{
		HandleResize();

		window.addEventListener('resize', HandleResize);

		return () => window.removeEventListener('resize', HandleResize);
	}, []);

	const value =
	{
		screen,
		Fetch: FetchObject
	};

	return (
		<GeneralContext.Provider value={value}>
			{props.children}
			<Toasts/>
		</GeneralContext.Provider>
	);
}

const useGeneral = () => useContext(GeneralContext);

export {GeneralProvider, useGeneral};