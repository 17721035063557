import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

// Module Section
import { SalesPoint } from './SalesPoint';
import { Acquisitions } from './Acquisitions';
import { Movements } from './Movements';
import { Clients } from './Clients';
import { Providers } from './Providers';
import { Products } from './Products';
import { CashRegisters } from './CashRegisters';
import { Reports } from './Reports';

// Contexts
import { ModalProvider } from '../../../contexts/general/Modal';
import { ProductsProvider, useProducts } from '../../../contexts/collections/commerce/Products';
import { ClientsProvider, useClients } from '../../../contexts/collections/commerce/Clients';
import { ProvidersProvider, useProviders } from '../../../contexts/collections/commerce/Providers';
import { CRShiftsProvider, useCRShifts } from '../../../contexts/collections/commerce/CRShifts';
import { MovementsProvider, useMovements } from '../../../contexts/collections/commerce/Movements';
import { useItems } from '../../../contexts/collections/storage/Items';

const Commerce = () =>
{
	const { ReadClients, loading: loadingClients } = useClients();
	const { ReadProviders, loading: loadingProviders } = useProviders();
	const { ReadProducts } = useProducts();
	const { ReadShifts } = useCRShifts();
	const { ReadMovements } = useMovements();
	const { ReadItems } = useItems();

	useEffect(() =>
	{
		ReadClients();
		ReadProviders();
	}, [ReadClients, ReadProviders]);
	
	useEffect(() =>
	{
		if(loadingClients && loadingProviders)
		{
			ReadProducts();
			ReadShifts();
			ReadMovements();
			ReadItems('commerce');
		}
	}, [loadingClients, loadingProviders, ReadProducts, ReadShifts, ReadMovements, ReadItems]);

	return (
		<Routes>
			<Route path='/sales-point/*' element={<SalesPoint/>}/>
			<Route path='/acquisitions/*' element={<Acquisitions/>}/>
			<Route path='/movements/*' element={<Movements/>}/>
			<Route path='/clients/*' element={<Clients/>}/>
			<Route path='/providers/*' element={<Providers/>}/>
			<Route path='/products/*' element={<Products/>}/>
			<Route path='/cash-registers/*' element={<CashRegisters/>}/>
			<Route path='/reports/*' element={<Reports/>}/>
		</Routes>
	)
}

const CommerceWithProviders = () =>
{
	return (
		<ClientsProvider>
			<ProvidersProvider>
				<ProductsProvider>
					<CRShiftsProvider>
						<MovementsProvider>
							<ModalProvider>
								<Commerce/>
							</ModalProvider>
						</MovementsProvider>
					</CRShiftsProvider>
				</ProductsProvider>
			</ProvidersProvider>
		</ClientsProvider>
	)
}

export {CommerceWithProviders as Commerce};