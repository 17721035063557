import { useMemo, useState } from 'react';
import { Table, Confirm, Text } from 'crack-ux';
import { Generic } from 'crack-functions';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useCompanies } from '../../../../contexts/collections/crm/Companies';
import { useLists } from '../../../../contexts/collections/home/Lists';

// Components
import { CompanyForm } from './CompanyForm';

// Static Data
import CompanyType from '../../../../static-data/CompanyType.json';

const CompaniesTable = (props) =>
{
	const [company, setCompany] = useState({});
	const [showConfirm, setShowConfirm] = useState(false);

	const { screen } = useGeneral();
	const { AddModal } = useModal();
	const { Translate } = useTraductor();
	const { listsByName } = useLists();
	const { companies, DeleteCompany } = useCompanies();

	const params = useParams();
	const navigate = useNavigate();

	const { companyName } = params;

	const economicActivitiesItemsById = useMemo(() => listsByName['economic-activity']?.itemsById || {}, [listsByName]);

	const body = useMemo(() =>
	{
		const businessTypeObj = Generic.ArrayToObjectByIdentifier(CompanyType, 'value');

		return companies.map(company =>
		{
			const economicActivitiesIds = Array.isArray(company?.economicActivities) ? company?.economicActivities : company?.economicActivities ? [company?.economicActivities] : [];

			company.typeText = businessTypeObj?.[company?.type]?.text || '';
			company.economicActivitiesText = economicActivitiesIds.map(economicActivityId => economicActivitiesItemsById[economicActivityId]?.text || null).filter(economicActivityText => economicActivityText).join(', ');

			return company;
		});
	}, [companies, economicActivitiesItemsById]);

	return (
		<>
			<Table
				class = 'current'
				header = 
				{[
					{
						name: 'codeText',
						text: Translate('code')
					},
					{
						name: 'name',
						text: Translate('company-name')
					},
					{
						name: 'economicActivitiesText',
						text: Translate('economic-activities'),
						maxWidth: 240,
						title: 'locationText'
					},
					{
						name: 'locationText',
						text: Translate('location'),
						maxWidth: 240,
						title: 'locationText'
					},
					{
						name: 'description',
						text: Translate('Description'),
						maxWidth: 240,
						title: 'description'
					},
				]}
				body = {body}
				actions =
				{[
					{
						text: Translate('new-company'),
						class: 'bg-blue white',
						section: 'grouped',
						Click: () => AddModal(<CompanyForm/>)
					},
					{
						text: Translate('edit-company'),
						section: 'context',
						icon:
						{
							class: 'icon-pencil'
						},
						Click: (company) => AddModal(<CompanyForm company={company}/>)
					},
					{
						text: Translate('delete-company'),
						class: 'red',
						section: 'context',
						icon:
						{
							class: 'icon-trash'
						},
						Click: (company) =>
						{
							setCompany(company);
							setShowConfirm(true);
						}
					}
				]}
				ClickRow = {(company) => navigate(`${companyName ? '../' : './'}${company.name}/${company._id}`)}
				firstLoad = {
				{
					triggerClickRow: screen.device === 'desktop'
				}}
				exportable = {false}
				searcher =
				{
					{text: Translate('Search')}
				}
				texts =
				{
					{
						noRecords:Translate('There Are Not Records To Show'),
						records:Translate('Records'),
						fields: Translate('Fields'),
						settings: Translate('Settings')
					}
				}
				filters = 
				{{
					columns:
					{
						editable: true
					},
					Apply:
					{
						text: Translate('Apply'),
						Click: () => {}
					},
					Reset:
					{
						text: Translate('Reset'),
						Click: () => {}
					}
				}}
			/>
			<Confirm
				show={showConfirm}
				header={<Text class='bold'>{Translate(`delete-company`)}</Text>}
				body=
				{
					<>
						<Text type='p'>{Translate(`confirm-delete-company`)}</Text>
						<Text type='p'><span className='bold'>{Translate('company')}: </span>{company.name}</Text>
					</>
				}
				confirm=
				{{
					text: Translate('delete'),
					class: 'bg-red white',
					Click: () =>
					{
						DeleteCompany(company);
						setShowConfirm(false);
					}
				}}
				cancel=
				{{
					text: Translate('cancel'),
					Click: () => setShowConfirm(false)
				}}
			/>
		</>
	)
}

export {CompaniesTable};