import React from 'react';
import {Routes, Route} from 'react-router-dom';

// Contexts
import { ModalProvider } from '../../../contexts/general/Modal';
import { useSession } from '../../../contexts/general/Session';
import { useBusiness } from '../../../contexts/layouts/Business';
import { SchedulerProviderModule } from '../../../contexts/modules/Scheduler';
import { useEventsConfig } from '../../../contexts/collections/scheduler/EventsConfig';
import { useEvents } from '../../../contexts/collections/scheduler/Events';

// Module Section
import {CalendarPage} from './Calendar';

const Scheduler = () =>
{
	const { user } = useSession();
	const { Fetch } = useBusiness();
	const { LoadEventsConfig } = useEventsConfig();
	const { LoadEvents } = useEvents();

	React.useEffect(() =>
	{
		if(user?._id)
		{
			const requests =
			[
				Fetch(`/events-config/read`, {_where: {staff: {$in: [user?._id]}}}),
				Fetch(`/events/read`, {_where: {staff: {$in: [user?._id]}}}),
			];
	
			Promise.all(requests).then(responses =>
			{
				if(responses.every(response => response.status === 200))
				{
					LoadEventsConfig(responses[0].data || []);
					LoadEvents(responses[1].data || []);
				}
			});
		}
	}, [Fetch, user, LoadEventsConfig, LoadEvents]);

	return (
		<Routes>
			<Route path='/calendar/*' element={<CalendarPage/>}/>
		</Routes>
	)
}

const SchedulerWithProviders = () =>
{
	return (
		<ModalProvider>
			<SchedulerProviderModule>
				<Scheduler/>
			</SchedulerProviderModule>
		</ModalProvider>
	)
}

export {SchedulerWithProviders as Scheduler};