const SetContact = (contact) =>
{
	contact.codeText = `CLIE-${contact.code}`;
	contact.phonesText = contact.phones.map(phone => `(+${phone.country}) ${phone.number}`).join(', ');
	contact.emailsText = contact.emails.map(email => email.address).join(', ');

	return contact;
}

const ContactsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_CONTACTS':
			return {
				...state,
				contacts: action.contacts.map(contact => SetContact(contact))
			}

		case 'CREATE_CONTACT':
			return {
				...state,
				contacts: [...state.contacts, SetContact(action.contact)],
				code: state.code + 1
			}

		case 'UPDATE_CONTACT':
			return {
				...state,
				contacts: state.contacts.map(contact => contact._id === action.contact._id ? SetContact(action.contact) : contact)
			}

		case 'DELETE_CONTACT':
		{
			return {
				...state,
				contacts: state.contacts.filter(contact => contact._id !== action.contact._id)
			}
		}

		case 'SET_LATEST_CONTACT_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export { ContactsReducer };