import { useEffect } from 'react';
import { Content, Panel, Card } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';

// Components
import { ProductsTable } from './ProductsTable';
import { Categories } from './Categories';

const Products = () =>
{
	const { screen } = useGeneral();

	const { categoryId } = useParams();
	const navigate = useNavigate();

	useEffect(() =>
	{
		if (!categoryId && screen.device !== 'mobile')
		{
			navigate('../all-categories/all-categories');
		}
	}, [categoryId, navigate, screen.device]);

	return (
		<Content>
			{(screen.device !== 'mobile' || !categoryId) &&
			<Panel class='tablet4 desktop2'>
				<Card>
					<Categories/>
				</Card>
			</Panel>}
			{(screen.device !== 'mobile' || categoryId) &&
			<Panel class='tablet8 desktop10'>
				<Card class='relative'>
					{categoryId && <ProductsTable/>}
				</Card>
			</Panel>}
		</Content>
	)
}

export { Products };