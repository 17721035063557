import {Text, Box} from 'crack-ux';

const defaultListColor = '#1889d4';
const listsWithColor =
[
	'sales-stage',
	'event-type',
];

const listsWithOrder =
[
	'sales-stage',
];

const listWithType =
[
	'stock-movement-types'
];

const SetList = (list) =>
{
	const customItemsLists =
	{
		selectOptions: [],
		itemsById: {},
		workingItems: []
	};

	if(Array.isArray(list.items))
	{
		customItemsLists.workingItems = list.items.filter(item => !item._deleted).sort((a, b) => a.order - b.order);

		customItemsLists.selectOptions = customItemsLists.workingItems.map(item =>
		{
			const text = item.color
				?
				<>
					<Box style={{width: 15, height: 15, backgroundColor: item.color, marginRight: 10}}></Box>
					<Text>{item.text}</Text>
				</>
				: item.text;

			return {
				text,
				value: item._id
			}
		}); // TODO: Add data=item if it is necessary

		customItemsLists.itemsById = customItemsLists.workingItems.reduce((items, item) =>
		{
			items[item._id] = item;
			return items;
		}, {});
	}

	return {
		...list,
		...customItemsLists
	};
}

const ListsReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_LISTS':
			return {
				...state,
				lists: action.lists.map(list => SetList(list)),
				listsByName: action.lists.reduce((lists, list) =>
				{
					lists[list.internalName] = SetList(list);
					return lists;
				}, {})
			}

		case 'UPDATE_LIST':
			return {
				...state,
				lists: state.lists.map(list => list._id === action.list._id ? SetList(action.list) : list),
				listsByName:
				{
					...state.listsByName,
					[action.list.internalName]: SetList(action.list)
				}
			}

		default:
			return state;
	}
}

export {ListsReducer, SetList, defaultListColor, listsWithColor, listsWithOrder, listWithType};