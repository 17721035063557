import React from 'react';
import { Content, Panel, Card, Text } from 'crack-ux';

// Tables
import { MembersTable } from './MembersTable';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useTraductor } from '../../../../contexts/general/Traductor';

// Components
import { MemberTabs } from './MemberTabs';
import { useParams } from 'react-router-dom';

const Members = () =>
{
	const { screen } = useGeneral();
	const { Translate } = useTraductor();

	const params = useParams();

	const { memberId } = params;

	return (
		<Content>
			{(screen.device !== 'mobile' || !memberId) && 
			<Panel class='tablet6 full-height'>
				<Card>
					<MembersTable/>
				</Card>
			</Panel>}
			{(screen.device !== 'mobile' || memberId) &&
			<Panel class='tablet6 full-height'>
				{memberId ?
				<MemberTabs/> :
				<Card class='relative'>
					<Text class='full-center'>{Translate('select-member')}</Text>
				</Card>}
			</Panel>}
		</Content>
	)
}

export { Members };