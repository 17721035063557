import React, { useMemo } from 'react';
import { Table, Header, Body, Text, Box } from 'crack-ux';
import { useNavigate } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useSession } from '../../../../contexts/general/Session';
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useMovements } from '../../../../contexts/collections/commerce/Movements';
import { MovementsDetailsTable } from './MovementsDetailsTable';
import { useLists } from '../../../../contexts/collections/home/Lists';

// Components
import { PaymentForm } from './PaymentForm';

const MovementsTable = ({movements, from}) =>
{
	const { screen } = useGeneral();
	const { user } = useSession();
	const { AddModal } = useModal();
	const { Translate } = useTraductor();
	const { AnnulledMovement } = useMovements();
	const { listsByName } = useLists();

	const navigate = useNavigate();

	const movementTypes = useMemo(() => listsByName['stock-movement-types'] || [], [listsByName]);

	return (
		<>
			<Header>
				<Text class='title'>{Translate('movements')}</Text>
			</Header>
			<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
				<Table
					class = 'hover pointer'
					header = 
					{[
						{
							name: 'status',
							text: Translate('status'),
							class: 'center',
							width: 80
						},
						{
							name: 'codeText',
							text: Translate('Code')
						},
						{
							name: 'name',
							text: Translate('name')
						},
						{
							name: 'typeText',
							text: Translate('type')
						},
						{
							name: 'totalText',
							text: Translate('total')
						},
						{
							name: 'discountText',
							text: Translate('discount')
						},
						{
							name: 'paymentsTotalText',
							text: Translate('payments')
						},
						{
							name: 'balanceTextColor',
							text: Translate('balance')
						},
						{
							name: 'dateText',
							text: Translate('date')
						},
						{
							name: 'timeText',
							text: Translate('time')
						},
					]}
					body = {movements.map(movement =>
					{
						const statusColor = movement.annulled ? 'bg-red' : 'bg-green';
						const statusText = movement.annulled ? 'annulled' : 'active';
					
						movement.status = <Box class={`round ${statusColor}`} style={{width: 15, height: 15}} title={Translate(statusText)}></Box>;
						movement.typeText = movementTypes.itemsById?.[movement.type]?.text || '';
						movement.balanceTextColor = <Text class={movement.balance === 0 ? '' : movement.balance === movement.total ? 'red' : 'yellow'}>{movement.balanceText}</Text>;

						return movement;
					})}
					actions =
					{[
						{
							text: Translate('view-detail'),
							section: 'context',
							icon:
							{
								class: 'icon-clipboard'
							},
							Click: (movement) => AddModal(<MovementsDetailsTable movement={movement}/>)
						},
						{
							text: Translate('add-payment'),
							section: 'context',
							icon:
							{
								class: 'icon-plus'
							},
							Click: (movement) => AddModal(<PaymentForm movement={movement}/>),
							Condition: (movement) => !movement.annulled && movement.balance > 0
						},
						{
							text: Translate('cancel'),
							section: 'context',
							class: 'red',
							icon:
							{
								class: 'icon-dot-circled'
							},
							Click: (movement) => AnnulledMovement(movement)
						},
					]}
					DoubleClickRow = {(movement) => AddModal(<MovementsDetailsTable movement={movement}/>)}
					exportable = {false}
					filters = 
					{{
						columns:
						{
							editable: true
						},
						Apply:
						{
							text: Translate('Apply'),
							Click: () => {}
						},
						Reset:
						{
							text: Translate('Reset'),
							Click: () => {}
						}
					}}
					BackButton = {(screen.device === 'mobile' && from) ?
					{
						Click: () => navigate('../')
					}
					: null}
					language = {user.preferences?.language || 'es'}
				/>
			</Body>
		</>
	)
}

export { MovementsTable };