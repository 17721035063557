import React from 'react';
import { Content, Card } from 'crack-ux';

// Contexts
import { ContactsProvider as ContactsProviderPage } from '../../../../contexts/pages/business/crm/Contacts';
import { useContacts } from '../../../../contexts/collections/crm/Contacts';

// Components
import { ContactsList } from './ContactsList';

// Styles
import './Contacts.css';

const Contacts = () =>
{
	const { contacts } = useContacts();

	return (
		<Content class='crm-contacts'>
			<Card>
				<ContactsList contacts={contacts} from='contacts'/>
			</Card>
		</Content>
	)
}

const ContactsWithProviders = () =>
{
	return (
		<ContactsProviderPage>
			<Contacts/>
		</ContactsProviderPage>
	)
};

export {ContactsWithProviders as Contacts};