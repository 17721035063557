import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Section } from 'crack-ux';

// Module Section
import { Dashboard } from './Dashboard/Dashboard';
import { BusinessAndPreferences } from './BusinessAndPreferences';
import { WebPage } from './WebPage';
import { SystemVariables } from './SystemVariables';
import { Integrations } from './Integrations';
import { Lists } from './Lists';
import { Credits } from './Credits';
import { Invoices } from './Billings';
import { Modules } from './Modules';

// Contexts
import { ModulesProvider } from '../../../contexts/collections/home/Modules';
import { ModalProvider } from '../../../contexts/general/Modal';

const Home = () =>
{
	return (
		<Section class='home-module full-height'>
			<Routes>
				<Route path='/dashboard/*' element={<Dashboard/>}/>
				<Route path='/business-and-preferences/*' element={<BusinessAndPreferences/>}/>
				<Route path='/web-page/*' element={<WebPage/>}/>
				<Route path='/system-variables/*' element={<SystemVariables/>}/>
				<Route path='/integrations/*' element={<Integrations/>}/>
				<Route path='/lists/*' element={<Lists/>}/>
				<Route path='/credits/*' element={<Credits/>}/>
				<Route path='/billings/*' element={<Invoices/>}/>
				<Route path='/modules/*' element={<Modules/>}/>
			</Routes>
		</Section>
	)
}

const HomeWithProviders = () =>
{
	return (
		<ModulesProvider>
			<ModalProvider>
				<Home/>
			</ModalProvider>
		</ModulesProvider>
	)
}

export {HomeWithProviders as Home};