import { Card, Header, Body, Footer, Text, Button, Box } from 'crack-ux';
import { QRCodeSVG } from 'qrcode.react';

// Contexts
import { useTraductor } from '../../../../../contexts/general/Traductor';
import { useModal } from '../../../../../contexts/general/Modal';
import { Loading } from '../../../../../components/views/Loading';
import { useWhatsappIntegration } from '../../../../../contexts/integrations/Whatsapp';

const ConnectWhatsapp = (props) =>
{
	const { whatsapp } = props;

	const { Translate } = useTraductor();
	const { CloseModal } = useModal();
	const { whatsappsIntegration } = useWhatsappIntegration();

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('connect-whatsapp')}</Text>
			</Header>
			<Body class='center relative' style={{height: 310}}>
				{whatsappsIntegration[whatsapp._id].status === 'connected'
				?
					<Box class='full-center full-width'>
						<Text class='green' type='p' size={1.5}>{Translate('connected-successfully')}</Text>
					</Box>
				: whatsappsIntegration[whatsapp._id].qr ? <QRCodeSVG value={whatsappsIntegration[whatsapp._id].qr} size={280}/> : <Loading/>}
			</Body>
			<Footer class='right'>
				<Button text={Translate('cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export { ConnectWhatsapp };