import { Content, Panel, Card, Header, Body, Text, List } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useLists } from '../../../../contexts/collections/home/Lists';

// Components
import { ItemsTable } from './ItemsTable';

const Lists = () =>
{
	const { screen } = useGeneral();
	const { Translate } = useTraductor();

	const { internalName } = useParams();
	const navigate = useNavigate();

	const {lists} = useLists();

	return (
		<Content>
			{(!internalName || screen.device !== 'mobile') &&
			<Panel class='desktop3 tablet4'>
				<Card>
					<Header>
						<Text class='title'>{Translate('lists')}</Text>
					</Header>
					<Body class='no-padding vertical-scroll' style={{height: 'calc(100% - var(--header))'}}>
						<List
							class = 'hover'
							currentClass = 'bg-blue white'
							current = {internalName}
							options = {lists.map(list =>
							{
								return {
									identifier: list.internalName,
									text: Translate(list.title),
									Click: () => navigate(`../${list.internalName}`),
								}
							})}
							autoSelect = {false}
						/>
					</Body>
				</Card>
			</Panel>}
			{(internalName || screen.device !== 'mobile') &&
			<Panel class='desktop9 tablet8'>
				<Card class='relative'>
					{internalName ? <ItemsTable/> : <Text class='full-center'>{Translate('select-list')}</Text>}
				</Card>
			</Panel>}
		</Content>
	);
}

const ListsWithProviders = (props) =>
{
	return (
		<Lists {...props}/>
	);
}

export {ListsWithProviders as Lists};