import { Route, Routes } from 'react-router-dom';
import { CashRegisters } from './CashRegisters';

const CashRegistersRouter = () =>
{
	return (
		<Routes>
			<Route path='/:cashRegisterName/:cashRegisterId' element={<CashRegisters/>}/>
			<Route path='/' element={<CashRegisters/>}/>
		</Routes>
	)
}

export { CashRegistersRouter as CashRegisters };