import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

// Contexts
import { ModalProvider } from '../../../contexts/general/Modal';
import { useItems } from '../../../contexts/collections/storage/Items';

// Pages
import { FilesRouter } from './Files/FilesRouter';

const Storage = () =>
{
	const { ReadItems } = useItems();

	useEffect(() =>
	{
		ReadItems();
	}, [ReadItems]);

	return (
		<Routes>
			<Route path='/files/*' element={<FilesRouter/>}/>
		</Routes>
	)
}

const StorageWithProviders = () =>
{
	return (
		<ModalProvider>
			<Storage/>
		</ModalProvider>
	)
}

export {StorageWithProviders as Storage};