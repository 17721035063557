import { Section, Text } from 'crack-ux';

// Styles
import './Label.css';

const Label = ({ label, text}) =>
{
	return (
		<Section class='component-label'>
			<Text class='bold' style={{marginRight: 5}}>{label}:</Text>
			<Text>{text}</Text>
		</Section>
	)
};

export { Label };