import { useNavigate } from 'react-router-dom';
import { Content, Section, Box, Header, Body, Footer, Text, Form, Button} from 'crack-ux';
import config from '../../../config.json';

// Contexts
import { useTraductor } from '../../../contexts/general/Traductor';
import { useSession } from '../../../contexts/general/Session';
import { useUsers } from '../../../contexts/main/Users';

// Styles
import './Login.css';
import { useCallback, useEffect, useState } from 'react';

const Login = () =>
{
	const [messages, setMessages] = useState({});
	const [response, setResponse] = useState(null);
	
	const navigate = useNavigate();

	const { Translate } = useTraductor();
	const { Login, user } = useSession();
	const { userForm, setUserForm } = useUsers();

	useEffect(() =>
	{
		if(user?._id)
		{
			window.location.href = '/p';
		}
	}, [user?._id]);

	const HandleLogin = useCallback(async () =>
	{
		const response = await Login(userForm);
		
		if(response.type === 'messages')
		{

			setMessages(response.messages);
			setResponse(null);
		}
		else
		{
			setMessages({});
			setResponse(<Text class={response.type === 'success' ? 'green' : 'red'}>{response.messages}</Text>);

			if(response.type === 'success')
			{
				navigate('/p');
			}
		}
	}, [Login, userForm, navigate]);

	useEffect(() =>
	{
		const HandleEnter = (e) =>
		{
			if(e.key === 'Enter')
			{
				HandleLogin();
			}
		}

		document.addEventListener('keydown', HandleEnter);

		return () =>
		{
			document.removeEventListener('keydown', HandleEnter);
		}
	}, [HandleLogin]);


	return (
		<Content class='login tablet relative'>
			<Box class='full-center'>
				<Header class='center' style={{padding: '50px 25px 35px'}}>
					<Text class='logo block' size={5} style={{marginBottom: 15}}>Sice</Text>
					<Text class='block' size={2}>{Translate('Wellcome back')}</Text>
				</Header>
				<Body>
					<Form
						inputs =
						{[
							{
								name: 'username',
								text: Translate('username-email'),
							},
							{
								name: 'password',
								text: Translate('password'),
								type: 'password',
							},
						]}
						form = {userForm}
						setForm = {setUserForm}
						messages = {messages}
						response = {response}
					/>
				</Body>
				<Footer>
					<Section class='right' style={{marginBottom: 5}}>
						<Button
							class='blue no-padding'
							text={Translate('forgot-password')}
							Click= {() => navigate('./recover-password')}
						/>
					</Section>
					<Button
						class='full-width bg-blue white'
						text={Translate('login')}
						Click={HandleLogin}
					/>
					{/* <Section class='center' style={{marginTop: 15}}>
						<Text>{Translate('do-not-have-account')}</Text>
						<Text class='blue pointer' Click={() => navigate('/create-account')} style={{marginLeft: 5}}>{Translate('register-here')}</Text>
					</Section> */}
					<Section class='center' style={{marginTop: 50}}>
						<Text class='version'>{config.app.version}</Text>
					</Section>					
				</Footer>
			</Box>
		</Content>
	);
}

export { Login }