import { useState } from 'react';
import { ToggleContent, Form } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';

// Components
import { FeaturesForm as FeaturesFormComp } from '../../../../components/FeaturesForm';

const FeaturesForm = (props) =>
{
	const { featuresForm, setFeaturesForm, messages } = props;

	const [features, setFeatures] = useState([]);

	const { Translate } = useTraductor();

	return (
		<ToggleContent text={Translate('features')}>
			<Form
				inputs =
				{[
					{
						name: 'title',
						text: Translate('title'),
						value: featuresForm.title,
						message: messages.title,
					},
					{
						name: 'caption',
						text: Translate('caption'),
						value: featuresForm.caption,
						message: messages.caption,
					},
					{
						name: 'cardBackground',
						text: Translate('card-background'),
						type: 'color',
						value: featuresForm.cardBackground,
						message: messages.cardBackground,
					},
					{
						text: Translate('features'),
						type: 'content',
						content: <FeaturesFormComp features={features} setFeatures={setFeatures}/>
					},
				]}
				form = {featuresForm}
				setForm = {setFeaturesForm}
			/>
		</ToggleContent>
	)
}

export { FeaturesForm };