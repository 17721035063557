import {Section, Header, Body, Footer, Box, Text, Icon, Input, Image, Button} from 'crack-ux';
import {Amount} from 'crack-functions'; 

// Contexts
import {useBusinessPage} from '../../../../../contexts/pages/public/BusinessPage';

const Product = (product) =>
{
	const {product: productInfo, quantity} = product;

	const {SetProductInShoppingCart, AddProductToShoppingCart, RemoveProductFromShoppingCart} = useBusinessPage();

	if(quantity === 0) return <></>;

	return (
		<Section class='product'>
			<Section class='info'>
				<Text>{productInfo.name}</Text>
			</Section>
			<Section>
				<Box class='picture'>
					<Image src='/assets/box.png'/>
				</Box>
				<Box class='quantity'>
					<Input text='Cantidad' type='number' value={quantity} Change={value => SetProductInShoppingCart(productInfo, value)} placeholder='Cantidad'/>
					<Box class='arrows'>
						<Button>
							<Icon class='icon-up-dir' Click={() => AddProductToShoppingCart(productInfo)}/>
						</Button>
						<Button>
							<Icon class='icon-down-dir' Click={() => RemoveProductFromShoppingCart(productInfo)}/>
						</Button>
					</Box>
				</Box>
				<Box class='unit-price float-right'>
					<Text class='label block'>Precio Unitario</Text>
					<Text class='price block'>{Amount.CurrencyFormat(productInfo.price.sale)}</Text>
				</Box>
			</Section>
			<Section class='total'>
				<Text>Subtotal</Text>
				<Text class='float-right'>{Amount.CurrencyFormat(productInfo.price.sale * quantity)}</Text>
			</Section>
		</Section>
	);
}

const Products = () =>
{
	const {shoppingCart} = useBusinessPage();

	const {products} = shoppingCart;

	const productsArray = Object.values(products);

	const totalQuantity = productsArray.reduce((total, product) => total + product.quantity, 0);

	if(totalQuantity === 0) return <></>;
	
	return (
		<Section>
			<Header class='center no-border'>
				<Text class='subtitle'>Productos</Text>
			</Header>
			<Body class='no-padding'>
				{productsArray.map(product => <Product key={product.product._id} {...product}/>)}
			</Body>
		</Section>
	);
}

const Service = (service) =>
{
	const {service: serviceInfo, quantity} = service;

	const {RemoveServiceFromShoppingCart} = useBusinessPage();

	if(quantity === 0) return <></>;

	return (
		<Section class='service'>
			<Section class='info'>
				<Text>{serviceInfo.name}</Text>
			</Section>
			<Section>
				<Box class='picture'>
					<Image src='/assets/box.png'/>
				</Box>
				<Box class='remove-service'>
					<Button text='Remover Servicio' Click={() => RemoveServiceFromShoppingCart(serviceInfo)}/>
				</Box>
				<Box class='unit-price float-right'>
					<Text class='label block'>Precio</Text>
					<Text class='price block'>{Amount.CurrencyFormat(serviceInfo.price.sale)}</Text>
				</Box>
			</Section>
		</Section>
	);
}

const Services = () =>
{
	const {shoppingCart} = useBusinessPage();

	const {services} = shoppingCart;

	const servicesArray = Object.values(services);

	const totalQuantity = servicesArray.reduce((total, service) => total + service.quantity, 0);

	if(totalQuantity === 0) return <></>;
	
	return (
		<Section>
			<Header class='center no-border'>
				<Text class='subtitle'>Servicios</Text>
			</Header>
			<Body class='no-padding'>
				{servicesArray.map(service => <Service key={service.service._id} {...service}/>)}
			</Body>
		</Section>
	);
}

const ShoppingCart = () =>
{
	const {shoppingCart, setShowShoppingCart} = useBusinessPage();

	const {products, services} = shoppingCart;

	const productsArray = Object.values(products);
	const servicesArray = Object.values(services);

	const totalProductsPrice = productsArray.reduce((total, product) => total + (product.product.price.sale * product.quantity), 0);
	const totalServicesPrice = servicesArray.reduce((total, service) => total + (service.service.price.sale * service.quantity), 0);

	const totalPrice = totalProductsPrice + totalServicesPrice;

	return (
		<Section class='shopping-cart'>
			<Header>
				<Text>Carrito de Compras</Text>
				<Icon class='icon-cancel float-right pointer' Click={() => setShowShoppingCart(false)}/>
			</Header>
			<Body class='no-padding vertical-scroll'>
				<Products/>
				<Services/>
			</Body>
			<Footer>
				<Section class='total'>
					<Text>Total</Text>
					<Text class='float-right'>{Amount.CurrencyFormat(totalPrice)}</Text>
				</Section>
				<Section class='center'>
					<Button class='bg-blue white' text='Continuar con la Comprar'/>
				</Section>
			</Footer>
		</Section>
	)
}

export {ShoppingCart};