import { Section, Header, Body, Text, Table } from 'crack-ux';
import { Amount } from 'crack-functions';

// Contexts
import { useSession } from '../../../../../contexts/general/Session';
import { useTraductor } from '../../../../../contexts/general/Traductor';

const ProductsSold = (props) =>
{
	const { products } = props;

	const { user } = useSession();
	const { Translate } = useTraductor();

	return (
		<Section class='report-section'>
			<Header>
				<Text class='bold'>{Translate('products-sold')}</Text>
			</Header>
			<Body class='no-padding'>
				<Table
					header = 
					{[
						{
							name: 'codeText',
							text: Translate('code')
						},
						{
							name: 'name',
							text: Translate('product-name')
						},
						{
							name: 'quantity',
							text: Translate('quantity')
						},
						{
							name: 'minPriceText',
							text: Translate('min-price')
						},
						{
							name: 'maxPriceText',
							text: Translate('max-rice')
						},
						{
							name: 'averageText',
							text: Translate('average-price')
						},
						{
							name: 'totalText',
							text: Translate('total')
						},
					]}
					body = {products.map(product =>
					{
						product.minPriceText = Amount.CurrencyFormat(product.minPrice);	
						product.maxPriceText = Amount.CurrencyFormat(product.maxPrice);	
						product.averageText = Amount.CurrencyFormat(product.total / product.quantity);
						product.totalText = Amount.CurrencyFormat(product.total);

						return product;
					})}
					searcher = {false}
					pager =
					{{
						rows: 1000
					}}
					language = {user.preferences?.language || 'es'}
				/>
			</Body>
		</Section>
	)

}

export {ProductsSold};