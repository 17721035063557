import { useMemo } from 'react';
import { Content, Panel, Card, Text } from 'crack-ux';
import { useParams } from 'react-router-dom';

// Contexts
import { useMovements } from '../../../../contexts/collections/commerce/Movements';
import { useGeneral } from '../../../../contexts/general/General';
import { useTraductor } from '../../../../contexts/general/Traductor';

// Components
import { ProvidersTable } from './ProvidersTable';
import { MovementsTable } from '../Movements/MovementsTable';

const Providers = () =>
{
	const { screen } = useGeneral();
	const { movements } = useMovements();
	const { Translate } = useTraductor();

	const { providerId } = useParams();

	const providerMovements = useMemo(() => movements.filter(movement => movement.contact === providerId), [movements, providerId]);

	return (
		<Content>
			{(screen.device !== 'mobile' || !providerId) && 
			<Panel class='desktop6'>
				<Card>
					<ProvidersTable/>
				</Card>
			</Panel>}
			{(screen.device !== 'mobile' || providerId) &&
			<Panel class='desktop6'>
				<Card class='relative'>
					{providerId ? <MovementsTable movements={providerMovements} from='providers'/> : <Text class='full-center'>{Translate('select-provider')}</Text>}
				</Card>
			</Panel>}
		</Content>
	)
}

export {Providers};