import { useMemo } from 'react';
import {Table, Button} from 'crack-ux';
import {Generic} from 'crack-functions';

// Contexts
import {useTraductor} from '../../../../contexts/general/Traductor';

const SystemVariablesTable = (props) =>
{
	const {variables, actions} = props;

	const {Translate} = useTraductor();

	const body = useMemo(() =>
	{
		return variables.filter(item => item.type !== 2).map(item =>
		{
			item.action = <Button
								text = {Translate('Copy')}
								Click = {() => Generic.CopyToClipboard(item.variableText)}
							/>

			return item;
		});
	}, [variables, Translate]);

	return (
		<Table
			class = 'hover'
			header =
			{[
				{
					name: 'name',
					text: Translate('Name')
				},
				{
					name: 'variableText',
					text: Translate('Variable')
				},
				{
					name: 'description',
					text: Translate('Description')
				},
				{
					name: 'action',
					text: Translate('Action')
				}
			]}
			body = {body}
			actions = {actions}
			searcher =
			{
				{text: Translate('Search')}
			}
			texts =
			{
				{
					noRecords:Translate('There Are Not Records To Show'),
					records:Translate('Records'),
					fields: Translate('Fields'),
					settings: Translate('Settings'),
					export: Translate('Export')
				}
			}
			filters = 
			{{
				columns:
				{
					editable: true
				},
				Apply:
				{
					text: Translate('Apply'),
					Click: () => {}
				},
				Reset:
				{
					text: Translate('Reset'),
					Click: () => {}
				}
			}}
		/>
	)
}

SystemVariablesTable.defaultProps =
{
	actions: [],
	variables: []
}

export {SystemVariablesTable};