import React from 'react';
import { Card, Header, Body, Footer, Button, Text, FileList } from 'crack-ux';

// Context
import { useSession } from '../../../../contexts/general/Session';
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useItems } from '../../../../contexts/collections/storage/Items';

const UploadFilesForm = ({module, parent}) =>
{
    const [files, setFiles] = React.useState({files: [], filesNames: []});
	const [messages, setMessages] = React.useState({});
	const [uploading, setUploading] = React.useState(false);

	const { user } = useSession();
	const { CloseModal } = useModal();
    const { Translate } = useTraductor();

	const { UploadDocuments } = useItems();

	const HandleSubmit = async () =>
	{
		const interlMessages = {};

		if(files.files.length === 0) interlMessages.message = Translate('no-files-selected');

		setMessages(interlMessages);

		if(Object.keys(interlMessages).length === 0)
		{
			setUploading(true);

			const data =
			{
				folder: module,
				filesNames: files.filesNames,
				_files:
				{
					[module]: files.files
				},
			};
	
			if(parent._id) data.parent = parent._id;
	
			const result = await UploadDocuments(data);
	
			setUploading(false);

			if(result.status === 200)
			{
				CloseModal();
			}
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('upload-files')}</Text>
			</Header>
			<Body class='vertical-scroll' style={{height: 'calc(100% - var(--header) - var(--footer))'}}>
				<FileList
					UpdateFiles = {(files, filesNames) => setFiles({files, filesNames})}
					language = {user.preferences?.language || 'es'}
				/>
				{messages.message && <Text class='red'>{messages.message}</Text>}
			</Body>
			<Footer class='right'>
				<Button
					text = {uploading ? Translate('saving') : Translate('save')}
					class = 'bg-blue white'
					Click = {HandleSubmit}
					loading = {uploading}
				/>
				<Button
					text = {Translate('cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {UploadFilesForm};