import { useState } from 'react';
import { Card, Table, Confirm, Text, Section, Box } from 'crack-ux';

// Contexts
import { useGeneral } from '../../../../../contexts/general/General';
import { useTraductor } from '../../../../../contexts/general/Traductor';
import { useModal } from '../../../../../contexts/general/Modal';
import { useBusiness } from '../../../../../contexts/main/Business';
import { useWhatsappIntegration } from '../../../../../contexts/integrations/Whatsapp';

// Components
import { WhatsappForm } from './WhatsappForm';
import { ConnectWhatsapp } from './ConnectWhatsapp';

const WhatsappTable = () =>
{
	const [showConfirm, setShowConfirm] = useState(false);
	const [whatsapp, setWhatsapp] = useState({});

	const { screen } = useGeneral();
	const { Translate } = useTraductor();
	const { AddModal } = useModal();
	const { currentBusiness, DeleteWhatsappNumber } = useBusiness();
	const { whatsappsIntegration } = useWhatsappIntegration();

	return (
		<Card class='full-height'>
			<Table
				header=
				{[
					{
						name: 'status',
						text: Translate('status'),
						width: 75
					},
					{
						name: 'label',
						text: Translate('label'),
					},
					{
						name: 'phoneText',
						text: Translate('whatsapp'),
					},
					{
						name: 'chatbot',
						text: Translate('chatbot'),
					},
				]}
				body={currentBusiness.whatsapps.map(whatsapp =>
				{
					let statusColor = 'bg-light-gray';

					switch(whatsappsIntegration[whatsapp._id]?.status)
					{
						case 'connected':
							statusColor = 'bg-green';
						break;

						case 'disconnected':
							statusColor = 'bg-red';
						break;

						default:
							statusColor = 'bg-light-gray';
						break;
					}

					whatsapp.status = <Section class='center'>
						<Box
							class={`round ${statusColor}`}
							style={{width: 15, height: 15}}
							title={Translate(whatsapp.status)}/>
					</Section>

					return whatsapp;
				})}
				actions=
				{[
					{
						text: Translate('add-whatsapp'),
						class: 'bg-blue white',
						section: 'grouped',
						Click: () => AddModal(<WhatsappForm/>),
					},
					// {
					// 	text: Translate('check-connection'),
					// 	section: 'context',
					// 	Click: (record) => CheckStatus(record),
					// 	icon:
					// 	{
					// 		class: 'icon-check'
					// 	}
					// },
					{
						text: Translate('connect-whatsapp'),
						section: 'context',
						Click: (record) => AddModal(<ConnectWhatsapp whatsapp={record}/>),
						icon:
						{
							class: 'icon-link'
						}
					},
					{
						text: Translate('edit-whatsapp'),
						section: 'context',
						Click: (record) => AddModal(<WhatsappForm whatsapp={record}/>),
						icon:
						{
							class: 'icon-pencil'
						}
					},
					{
						text: Translate('delete-whatsapp'),
						section: 'context',
						class: 'red',
						Click: (record) =>
						{
							setWhatsapp(record);
							setShowConfirm(true);	
						},
						icon:
						{
							class: 'icon-trash'
						}
					}
				]}
				screen={screen.device}
			/>
			<Confirm
				show={showConfirm}
				header={<Text class='bold'>{Translate('delete-whatsapp')}</Text>}
				body=
				{
					<>
						<Text type='p'>{Translate('confirm-delete-whatsapp')}</Text>
						<Text type='p'><span className='bold'>{`${Translate('whatsapp')}: `}</span>{whatsapp.label}</Text>
					</>
				}
				confirm=
				{{
					text: Translate('delete'),
					class: 'bg-red white',
					Click: () =>
					{
						DeleteWhatsappNumber(whatsapp);
						setShowConfirm(false);
					}
				}}
				cancel=
				{{
					text: Translate('cancel'),
					Click: () => setShowConfirm(false)
				}}
			/>
		</Card>
	)
}

export { WhatsappTable };