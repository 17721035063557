import { Section, Content, Panel, Text, Box, Icon } from 'crack-ux';

const End = () =>
{
	return (
		<Section class='end'>
			<Content>
				<Panel class='tablet6 desktop3'>
					<Text class='logo'>SICE</Text>
					<Text class='tagline'>Un software hecho a tu medida</Text>
				</Panel>
				<Panel class='tablet6 desktop3'>
					<Text class='column-title'>Enlaces</Text>
					<Text class='column-link'>Inicio</Text>
					<Text class='column-link'>Módulos</Text>
					<Text class='column-link'>Testimonios</Text>
				</Panel>
				<Panel class='tablet6 desktop3'>
					<Text class='column-title'>Contacto</Text>
					<Text class='column-link'>📱 +591 77790990</Text>
					<Text class='column-link'>✉️ contact@cracksrl.com</Text>
				</Panel>
				<Panel class='tablet6 desktop3'>
					<Text class='column-title'>Política</Text>
					<Text class='column-link'>Política de privacidad</Text>
					<Text class='column-link'>Términos y condiciones</Text>
					<Box class='social'>
						<Icon class='icon-facebook'/>
						<Icon class='icon-instagram'/>
						<Icon class='icon-twitter'/>
						<Icon class='icon-linkedin'/>
						<Icon class='icon-youtube'/>
						<Icon class='icon-whatsapp'/>
					</Box>
				</Panel>
			</Content>
			<Content class='desktop copyright'>
				<Text>© 2025 SICE by CRACK SRL. Todos los derechos reservados.</Text>
			</Content>
		</Section>
	)
}

export { End };
