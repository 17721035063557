import {useLocation, useNavigate} from 'react-router-dom';
import {Section, Card, Header, Body, Text, List, Button, Icon} from 'crack-ux';

// Contexts
import { useGeneral } from '../../contexts/general/General';
import { useTraductor } from '../../contexts/general/Traductor';
import { useSession } from '../../contexts/general/Session';

const PersonalMenu = () =>
{
	const navigate = useNavigate();
	const {pathname} = useLocation();

	const { screen } = useGeneral();
	const { Logout } = useSession();
	const { Translate } = useTraductor();

	const { device } = screen;

	const page = pathname.split('/').pop();

	const options =
	[
		{
			identifier: 'business',
			text: Translate('businesses'),
			Click: () => navigate('./business'),
			icon:
			{
				class: 'icon-building'
			},
		},
		{
			identifier: 'profile',
			text: Translate('profile'),
			Click: () => navigate('./profile'),
			icon:
			{
				class: 'icon-user-circle-o'
			},
		},
		{
			identifier: 'preferences',
			text: Translate('preferences'),
			Click: () => navigate('./preferences'),
			icon:
			{
				class: 'icon-cog-alt'
			},
		},
		{
			identifier: 'logout',
			text: Translate('logout'),
			Click: () => Logout().then(() => navigate('/')),
			icon:
			{
				class: 'icon-logout'
			}
		},
	];

	if(device === 'mobile')
	{
		return (
			<Section class='menu'>
				{options.map((option, index) =>
				{
					const active = page === option.identifier ? 'bg-blue white' : 'default';

					return (
						<Button
							key = {index}
							class = {`options no-margin ${active}`}
							Click = {option.Click}
						>
							<Icon {...option.icon} size={1.25}/>
						</Button>
					)
				})}
			</Section>
		)
	}
	else
	{
		return (
			<Card class='menu'>
				<Header>
					<Text class='bold'>{Translate('profile-settings')}</Text>
				</Header>
				<Body class='no-padding' style={{height: 'calc(100% - var(--header))'}}>
					<List
						currentClass = 'bg-blue white'
						style={{padding: '0px 15px'}}
						options = {options}
						current = {page}
					/>
				</Body>
			</Card>
		)
	}
}

export {PersonalMenu};