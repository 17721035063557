import { Route, Routes, useParams, Navigate, useNavigate } from 'react-router-dom';
import { Content, Panel, Card, Header, Body, Text, List } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';

// Components
import { BusinessProfile } from './BusinessProfile';
import { CommercePreferences } from './CommercePreferences';

const BusinessAndPreferences = () =>
{
	const { Translate } = useTraductor();

	const navigate = useNavigate();
	const params = useParams();

	const path = params['*'];

	return (
		<Content class='desktop'>
			<Panel class='mobile4'>
				<Panel class='height-auto no-padding' style={{ marginBottom: 10 }}>
					<Card>
						<Header>
							<Text class='title'>{Translate('business-and-preferences')}</Text>
						</Header>
					</Card>
				</Panel>
				<Panel class='height-auto no-padding' style={{ marginBottom: 10 }}>
					<Card>
						<Header>
							<Text class='title'>{Translate('general')}</Text>
						</Header>
						<Body class='no-padding'>
							<List
								currentClass = 'bg-blue white'
								current = {path}
								options =
								{[
									{
										identifier: 'business-profile',
										text: Translate('business-profile'),
										Click: () => navigate('./business-profile')
									}
								]}
								autoSelect = {false}
							/>
						</Body>
					</Card>
				</Panel>
				<Panel class='no-padding' style={{height: 'calc(100% - 46px - 46px - 47px - 20px)'}}>
					<Card>
						<Header>
							<Text class='title'>{Translate('modules')}</Text>
						</Header>
						<Body class='no-padding'>
							<List
								currentClass = 'bg-blue white'
								current = {path}
								options =
								{[
									{
										identifier: 'commerce-preferences',
										text: Translate('commerce-preferences'),
										Click: () => navigate('./commerce-preferences')
									}
								]}
								autoSelect = {false}
							/>
						</Body>
					</Card>
				</Panel>
			</Panel>
			<Panel class='desktop8'>
				<Routes>
					<Route path='/business-profile' element={<BusinessProfile/>}/>
					<Route path='/commerce-preferences' element={<CommercePreferences/>}/>
					<Route path='/' element={<Navigate to='./business-profile'/>}/>
				</Routes>
			</Panel>
		</Content>
	)
}

export { BusinessAndPreferences };