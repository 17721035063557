import { useMemo, useState } from 'react';
import { Table, Section, Box, Icon, Confirm, Text } from 'crack-ux';
import { useNavigate, useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useModal } from '../../../../contexts/general/Modal';
import { useLists } from '../../../../contexts/collections/home/Lists';

// Reducers
import { listsWithColor, listsWithOrder, listWithType } from '../../../../reducers/collections/Lists';

// Components
import { ItemForm } from './ItemForm';

const ItemsTable = () =>
{
	const [showConfirm, setShowConfirm] = useState(false);
	const [item, setItem] = useState({});

	const { screen } = useGeneral();
	const { Translate } = useTraductor();
	const { AddModal } = useModal();
	const { listsByName, UpdateOrder, sorting, DeleteItem } = useLists();

	const params = useParams();
	const navigate = useNavigate();

	const { internalName } = params;

	const workingItems = useMemo(() =>
	{
		if(Array.isArray(listsByName?.[internalName]?.workingItems))
		{
			return listsByName[internalName].workingItems.map(item =>
			{
				const marginLeft = item.order === 1 ? 34 : 7;

				const upButton = item.order > 1 && <Icon class={`icon-angle-up black ${sorting ? 'disabled' : ''}`} style={{padding: 5, backgroundColor: 'var(--light-gray)'}} Click={() => UpdateOrder(internalName, item.order, 'up')}/>;
				const downButton = item.order < listsByName[internalName].workingItems.length && <Icon class={`icon-angle-down black ${sorting ? 'disabled' : ''}`} style={{padding: 5, marginLeft, backgroundColor: 'var(--light-gray)'}} Click={() => UpdateOrder(internalName, item.order, 'down')}/>;

				const specificFields = {};

				switch(internalName)
				{
					case 'sales-stage':
						specificFields.exclusionPointText = Translate(item.data?.exclusionPoint ? 'yes' : 'no');
					break;

					default:
					break;
				}

				return {
					...item,
					...specificFields,
					type: Translate(item.type),
					editableText: Translate(item.editable ? 'yes' : 'no'),
					colorComponent: (
						<Section class='center'>
							<Box style={{width: 15, height: 15, backgroundColor: item.color}}></Box>
						</Section>
					),
					orderButtons: (
						<Box class='right'>
							{upButton}
							{downButton}
						</Box>
					)
				};
			});
		}

		return [];
	}, [internalName, listsByName, UpdateOrder, sorting, Translate]);

	const header = useMemo(() =>
	{
		const result =
		[
			{
				name: 'order',
				text: Translate('position')
			},
			{
				name: 'text',
				text: Translate('item-name')
			},
			...(listsWithColor.includes(internalName)) ?
			[{
				name: 'colorComponent',
				text: Translate('color')
			}] : [],
			...(listWithType.includes(internalName)) ?
			[{
				name: 'type',
				text: Translate('type')
			}] : [],
			...(internalName === 'sales-stage') ?
			[{
				name: 'exclusionPointText',
				text: Translate('exclusion-point')
			}] : [],
			{
				name: 'editableText',
				text: Translate('editable')
			},
			...(listsWithOrder.includes(internalName)) ?
			[{
				name: 'orderButtons',
				text: Translate('sort')
			}] : [],
		];

		return result;
	}, [internalName, Translate]);

	return (
		<>
			<Table
				class = 'hover'
				header = {header}
				body = {workingItems}
				actions =
				{[
					{
						text: Translate(`new-${internalName}`),
						class: 'bg-blue white',
						Click: () => AddModal(<ItemForm internalName = {internalName}/>),
						section: 'grouped'
					},
					{
						text: Translate(`edit-${internalName}`),
						section: 'context',
						icon:
						{
							class: 'icon-pencil'
						},
						Click: (item) => AddModal(<ItemForm internalName = {internalName} item={item}/>),
						Condition: (item) => item.editable
					},
					{
						text: Translate(`delete-${internalName}`),
						class: 'red',
						section: 'context',
						icon:
						{
							class: 'icon-trash'
						},
						Click: (item) =>
						{
							setItem(item);
							setShowConfirm(true);
						},
						Condition: (item) => item.editable
					}
				]}
				DoubleClickRow = {(item) => AddModal(<ItemForm internalName = {internalName} item={item}/>)}
				exportable = {false}
				searcher =
				{
					{text: Translate('Search')}
				}
				texts =
				{
					{
						noRecords:Translate('There Are Not Records To Show'),
						records:Translate('Records'),
						fields: Translate('Fields'),
						settings: Translate('Settings'),
						export: Translate('Export')
					}
				}
				filters = 
				{{
					columns:
					{
						editable: true
					},
					Apply:
					{
						text: Translate('Apply'),
						Click: () => {}
					},
					Reset:
					{
						text: Translate('Reset'),
						Click: () => {}
					}
				}}
				BackButton = {screen.device === 'mobile' &&
				{
					Click: () => navigate('../')
				}}
			/>
			<Confirm
				show={showConfirm}
				header={<Text class='bold'>{Translate(`delete-${internalName}`)}</Text>}
				body=
				{
					<>
						<Text type='p'>{Translate(`confirm-delete-${internalName}`)}</Text>
						<Text type='p'><span className='bold'>{Translate(internalName)}: </span>{item.text}</Text>
					</>
				}
				confirm=
				{{
					text: Translate('delete'),
					class: 'bg-red white',
					Click: () =>
					{
						DeleteItem(internalName, item);
						setShowConfirm(false);
					}
				}}
				cancel=
				{{
					text: Translate('cancel'),
					Click: () => setShowConfirm(false)
				}}
			/>
		</>
	)
}

export {ItemsTable};