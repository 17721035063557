import React from 'react';
import {Card, Header, Body, Footer, Form, Button, Text} from 'crack-ux';
import {Text as TextF, Dates} from 'crack-functions';

// Functions
import Validations from '../../../../../utilities/Validate';

// Components
import {Recurrence} from '../../../Recurrence/Recurrence';

// Contexts
import {useTraductor} from '../../../../../contexts/general/Traductor';
import {useModal} from '../../../../../contexts/general/Modal';
import {useLists} from '../../../../../contexts/collections/home/Lists';
import {useMembers} from '../../../../../contexts/collections/staff/Members';
import {useEventsConfig} from '../../../../../contexts/collections/scheduler/EventsConfig';

// Styles
import './EventConfig.css'

const EventConfigForm = (props) =>
{
	const {eventConfig} = props;

	const [form, setForm] = React.useState({...eventConfig, staff: eventConfig?.staff || []});
	const [messages, setMessages] = React.useState({});

	const {Translate} = useTraductor();
	const {CloseModal} = useModal();

	const {listsByName} = useLists();
	const {availableStaff} = useMembers();
	const {CreateEventConfig, UpdateEventConfig} = useEventsConfig();

	const HandleSubmit = async () =>
	{
		const startDateStr = Dates.GetDateForForm(form.startDate);

		const eventConfigData =
		{
			...form,
			startTime: Dates.GetDateFromForm(startDateStr, form.startTimeValue),
			endTime: form.endTimeValue ? Dates.GetDateFromForm(startDateStr, form.endTimeValue) : null,
		};

		const response = eventConfig._id ? await UpdateEventConfig({...eventConfigData, _id: eventConfig._id}) : await CreateEventConfig(eventConfigData);

		setMessages(Validations.ParseByField(response.validations));

		if(response.status === 200)
		{
			CloseModal();
		}
	}

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate(`${eventConfig._id ? 'Edit Event Recurrence' : 'New Event Recurrence'}`)}</Text>
			</Header>
			<Body class='vertical-scroll' style={{width: 500, maxWidth: 'calc(100vw - 40px)', maxHeight: 'calc(100vh - var(--header) - 40px)'}}>
				<Form
					inputs =
					{[
						{
							name: 'title',
							text: Translate('Title'),
							value: form.title,
							Change: (value) => setForm({...form, title: value}),
							message: messages.title
						},
						{
							name: 'description',
							text: Translate('Description'),
							value: form.description,
							type: 'textarea',
							Change: (value) => setForm({...form, description: value}),
							message: messages.description
						},
						{
							name: 'staff',
							text: Translate('Staff'),
							value: form.staff,
							type: 'autocomplete',
							multiple: true,
							options: availableStaff.map(item =>
							{
								return {
									text: TextF.FullName(item.user.name, item.user.lastName),
									value: item._id
								}
							}),
							Change: (response) => setForm(current => ({...current, staff: response})),
							message: messages.staff
						},
						{
							name: 'eventType',
							text: Translate('Event Type'),
							value: form.eventType,
							message: messages.eventType,
							type: 'select',
							options: listsByName.eventTypes?.selectOptions || [],
							Change: (response) => setForm(current => ({...current, eventType: response.value})),
						},
						{
							type: 'content',
							content: <Recurrence
										recurrence = {form?.recurrence}
										Change = {(recurrence) =>
										{
											const eventConfigData =
											{
												startDate: recurrence.form.startDate,
												endDate: recurrence.form.UNTIL,
												startTimeValue: recurrence.form.startTime,
												endTimeValue: recurrence.form.endTime,
												isRecurring: recurrence.form.FREQ !== 'NEVER',
												recurrence
											}

											setForm({...form, ...eventConfigData});
										}}
									/>
						}
					]}
				/>
			</Body>
			<Footer class='right'>
				<Button
					class = 'bg-blue white'
					text = {Translate('Save')}
					Click = {HandleSubmit}
				/>
				<Button
					text = {Translate('Cancel')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export {EventConfigForm};