import { Card, Header, Body, Box, Text, Searcher, List, Button } from 'crack-ux';

// Contexts
import { useGeneral } from '../../../../../contexts/general/General';
import { useTraductor } from '../../../../../contexts/general/Traductor';

const ProductFilter = (props) =>
{
	const { categories, filter, setFilter, ResetFilter } = props;

	const { screen } = useGeneral();
	const { Translate } = useTraductor();

	return (
		<Card>
			<Header>
				<Text>{Translate('products-filter')}</Text>
				<Box class='float-right' style={{marginTop: -7}}>
					<Button text={Translate('reset')} Click={ResetFilter}/>
				</Box>
			</Header>
			<Header>
				<Searcher search={filter.search} Change={(search) => setFilter({...filter, search})}/>
			</Header>
			<Body class='no-padding vertical-scroll' style={{height: `calc(100% - var(--header) - ${screen.device === 'mobile' ? 0 : 67}px)`}}>
				<List
					currentClass = 'bg-blue white'
					current = {filter.categories}
					multiple = {true}
					options = {categories.map(category => 
					{
						return {
							identifier: category._id,
							text: category.text,
							value: category._id
						}
					})}
					Change = {(options) => setFilter({...filter, categories: options})}
				/>
			</Body>
		</Card>
	)
}

export { ProductFilter };