import { useEffect, useMemo, useState, useCallback } from 'react';
import { Panel, Box, Section, Body, Footer, Switch, Text, Button } from 'crack-ux';
import cloneDeep from 'lodash/cloneDeep';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useBusiness } from '../../../../contexts/layouts/Business';
import { useMembers } from '../../../../contexts/collections/staff/Members';

// Styles
import './Permissions.css';

const Owner = () =>
{
	const { Translate } = useTraductor();

	return (
		<Section class='relative full-height'>
			<Box class='full-center'>
				<Text>{Translate('business-owner-full-access')}</Text>
			</Box>
		</Section>
	);
};

const Permissions = () =>
{
	const [enabled, setEnabled] = useState(false);
	const [localPermissions, setLocalPermissions] = useState({});

	const { Translate } = useTraductor();
	const { Can } = useBusiness();
	const { currentMember, permissionsSchema, ChangePermissions } = useMembers();

	const canUpdatePermissions = useMemo(() => Can('update-permissions'), [Can]);

	useEffect(() =>
	{
		setLocalPermissions(cloneDeep(currentMember.permissions || {}));
	}, [currentMember.permissions]);

	const GetPermissionValueAtPath = useCallback((memberPermissions, path, defaultValue) =>
	{
		let current = memberPermissions;
		for (let i = 0; i < path.length; i++)
		{
			const key = path[i];
			if (current[key] !== undefined)
			{
				current = current[key];
			}
			else
			{
				return defaultValue;
			}

			if (i === path.length -1)
			{
				if (typeof current.value === 'boolean')
				{
					return current.value;
				}
				else
				{
					return defaultValue;
				}
			}
			else
			{
				if (current.permissions)
				{
					current = current.permissions;
				}
				else
				{
					return defaultValue;
				}
			}
		}
		return defaultValue;
	}, []);

	const UpdatePermissionAtPath = useCallback((path, value) =>
	{
		setLocalPermissions((current) =>
		{
			const newPermissions = { ...current };
			let currentLevel = newPermissions;

			for (let i = 0; i < path.length; i++)
			{
				const key = path[i];

				if (i === path.length - 1)
				{
					if (!currentLevel[key])
					{
						currentLevel[key] = {};
					}
					currentLevel[key] = {
						...currentLevel[key],
						value: value
					};
				}
				else
				{
					if (!currentLevel[key])
					{
						currentLevel[key] = {};
					}
					currentLevel = currentLevel[key];

					if (!currentLevel.permissions)
					{
						currentLevel.permissions = {};
					}
					currentLevel = currentLevel.permissions;
				}
			}

			return newPermissions;
		});
	}, []);

	const RenderPermissions = useCallback((permissionsData, memberPermissions, path = [], parentActive = true, level = 0) =>
	{
		const elements = [];

		for (let key in permissionsData)
		{
			const item = permissionsData[key];
			const itemPath = [...path, key];

			const hasChildren = item.permissions && Object.keys(item.permissions).length > 0;

			const isActive = GetPermissionValueAtPath(memberPermissions, itemPath, item.default);

			if (hasChildren)
			{
				elements.push(
					<Section key={itemPath.join('_')} class={`permission-item level-${level}`}>
						<Panel class='right title'>
							<Text class='float-left'>
								{Translate(item.text)}
							</Text>
							<Switch
								class='inline-block'
								type='toggle'
								text=''
								Change={(value) => UpdatePermissionAtPath(itemPath, value)}
								checked={isActive}
								disabled={item.readOnly || !enabled || !parentActive}
							/>
						</Panel>
						{isActive && 
						<Section class='permissions-children'>
							{RenderPermissions(
								item.permissions,
								memberPermissions,
								itemPath,
								parentActive && isActive,
								level + 1
							)}
						</Section>}
					</Section>
				);
			}
			else
			{
				elements.push(
					<Panel key={`${currentMember._id}_${itemPath.join('_')}`} class={`permission-item level-${level}`}>
						<Switch
							type='toggle'
							Change={(value) => UpdatePermissionAtPath(itemPath, value)}
							text={Translate(item.text)}
							checked={isActive}
							disabled={item.readOnly || !enabled || !parentActive}
						/>
					</Panel>
				);
			}
		}

		return elements;
	}, [Translate, GetPermissionValueAtPath, UpdatePermissionAtPath, enabled, currentMember._id]);

	const modulesPermissions = useMemo(() =>
	{
		return (
			<Section class='full-height'>
				<Body class='vertical-scroll' style={{height: canUpdatePermissions ? 'calc(100% - 67px)' : '100%'}}>
					{RenderPermissions(permissionsSchema, localPermissions)}
				</Body>
				{canUpdatePermissions && 
				<Footer class='right'>
					<Button
						text={Translate(enabled ? 'save' : 'edit')}
						class='bg-blue white'
						Click={() =>
						{
							if (!enabled)
							{
								setEnabled(true);
							}
							else
							{
								ChangePermissions(currentMember._id, localPermissions).then((response) =>
								{
									if (response.status === 200)
									{
										setEnabled(false);
									}
								});
							}
						}}
					/>
					{enabled && (
						<Button
							text={Translate('cancel')}
							Click={() =>
							{
								setEnabled(false);
								setLocalPermissions(cloneDeep(currentMember.permissions));
							}}
						/>
					)}
				</Footer>}
			</Section>
		);
	}, [permissionsSchema, currentMember._id, localPermissions, enabled, Translate, ChangePermissions, RenderPermissions, canUpdatePermissions, currentMember.permissions]);

	return (
		<Section class='full-height permissions'>
			{currentMember.isBusinessOwner ? <Owner /> : modulesPermissions}
		</Section>
	);
};

export { Permissions };