import React, { useMemo } from 'react';
import { Content, Panel, Card } from 'crack-ux';
import { useParams } from 'react-router-dom';

// Contexts
import { useGeneral } from '../../../../contexts/general/General';
import { useContacts } from '../../../../contexts/collections/crm/Contacts';

// Components
import { CompaniesTable } from './CompaniesTable';
import { ContactsList } from '../Contacts/ContactsList';

const Companies = () =>
{
	const { screen } = useGeneral();
	const { contacts } = useContacts();

	const params = useParams();

	const { companyName, companyId } = params;

	const companyContacts = useMemo(() => contacts.filter(contact => contact.companies?.includes(companyId)), [contacts, companyId]);
	
	return (
		<Content>
			{(screen.device === 'desktop' || !companyName) && <Panel class='desktop6 full-height'>
				<Card>
					<CompaniesTable/>
				</Card>
			</Panel>}
			{(screen.device === 'desktop' || companyName) && <Panel class='desktop6 full-height'>
				<Card class='relative'>
					<ContactsList contacts={companyContacts} from='companies'/>
				</Card>
			</Panel>}
		</Content>
	)
}

export {Companies};