import {useParams} from 'react-router-dom';
import {Section} from 'crack-ux';

// Contexts
import {useBusinessPage} from '../../../../contexts/pages/public/BusinessPage';

// Components
import {ProductDetail} from '../components/ProductDetail';

const Product = () =>
{
	const params = useParams();
	const {products} = useBusinessPage();

	const {_id} = params;

	const product = products.find(product => product._id === _id);

	return (
		<Section>
			<ProductDetail {...product}/>
		</Section>
	)
}

export {Product}