import React, { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

// Context
import { useSocket } from '../../general/Socket';
import { useTraductor } from '../../general/Traductor';
import { useBusiness } from '../../layouts/Business';

// Reducers
import { ProvidersReducer } from '../../../reducers/collections/commerce/Providers';

const Providers = createContext({});

const ProvidersProvider = (props) =>
{
	const [state, dispatch] = useReducer(ProvidersReducer, {providers: [], code: 0});
	const [currentProvider, setCurrentProvider] = useState({});
	const [loading, setLoading] = useState(true);

	const Socket = useSocket();
	const { Translate } = useTraductor();
	const { BusinessFetch, business, SomethingWentWrong } = useBusiness();

	const ReadProviders = useCallback(async () =>
	{
		try
		{
			setLoading(true);

			const response = await BusinessFetch.Get('/commerce/providers');

			if(response.status === 200)
			{
				dispatch({type: 'LOAD_PROVIDERS', providers: response.data});
			}
		}
		catch(error)
		{
			SomethingWentWrong('ReadProviders');
		}
		finally
		{
			setLoading(false);
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const CreateProvider = async (provider) =>
	{
		try
		{
			provider.code = state.code + 1;

			const response = await BusinessFetch.Post('/commerce/provider', provider);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('provider-created')
				})
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('CreateProvider');
		}
	}

	const UpdateProvider = async (provider) =>
	{
		try
		{
			const response = await BusinessFetch.Put('/commerce/provider', provider);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('provider-updated')
				})
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('UpdateProvider');
		}
	}

	const DeleteProvider = async (provider) =>
	{
		try
		{
			const data =
			{
				_id: provider._id,
			}
			
			const response = await BusinessFetch.Delete('/commerce/provider', data);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('provider-deleted')
				})
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('DeleteProvider');
		}
	}

	useEffect(() =>
	{
		BusinessFetch.Get('/commerce/providers/code').then(response =>
		{
			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_PROVIDER_CODE', code: response.data});
			}
		});
	}, [BusinessFetch]);

	useEffect(() =>
	{
		const events =
		[
			{
				name: 'COMMERCE_PROVIDER_CREATE',
				Function: (provider) => dispatch({type: 'CREATE_PROVIDER', provider})
			},
			{
				name: 'COMMERCE_PROVIDER_UPDATE',
				Function: (provider) => dispatch({type: 'UPDATE_PROVIDER', provider})
			},
			{
				name: 'COMMERCE_PROVIDER_DELETE',
				Function: (provider) => dispatch({type: 'DELETE_PROVIDER', provider})
			}
		];

		Socket.ConnectEvents(business._id, events);
	}, [Socket, business._id]);

	const value =
	{
		loading,
		code: state.code,
		providers: state.providers,
		currentProvider,
		setCurrentProvider,
		ReadProviders,
		CreateProvider,
		UpdateProvider,
		DeleteProvider
	}

	return (
		<Providers.Provider value={value}>
			{props.children}
		</Providers.Provider>
	)
}

const useProviders = () => useContext(Providers);

export {ProvidersProvider, useProviders};