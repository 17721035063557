import { useState } from 'react';
import { Card, Form, Header, Body, Footer, Button, Text } from 'crack-ux';

// Contexts
import { useModal } from '../../../../../contexts/general/Modal';
import { useTraductor } from '../../../../../contexts/general/Traductor';
import { useBusiness } from '../../../../../contexts/main/Business';

// Static Data
import PhoneCodes from '../../../../../static-data/PhoneCodes.json';

const WhatsappForm = (props) =>
{
	const { whatsapp = {country: 591} } = props;

	const [phoneForm, setPhoneForm] = useState(whatsapp);
	const [messages, setMessages] = useState({});

	const { CloseModal } = useModal();
	const { Translate } = useTraductor();
	const { CreateWhatsappNumber, UpdateWhatsappNumber } = useBusiness();

	return (
		<Card class='form'>
			<Header>
				<Text class='title'>{Translate('add-whatsapp')}</Text>
			</Header>
			<Body class='form-body'>
				<Form
					inputs=
					{[
						{
							name: 'label',
							text: Translate('label'),
							value: whatsapp.label,
							message: messages.label && <Text class='red'>{Translate(messages.label)}</Text>,
						},
						{
							name: 'country',
							text: Translate('code'),
							class: 'mobile4',
							value: whatsapp.country,
							type: 'select',
							options: PhoneCodes,
						},
						{
							name: 'number',
							text: Translate('phone'),
							class: 'mobile8',
							value: whatsapp.number,
							type: 'number',
							message: messages.number && <Text class='red'>{Translate(messages.number)}</Text>,
						},
						{
							name: 'chatbot',
							text: Translate('chatbot'),
							value: whatsapp.chatbot || null,
							type: 'select',
							options:
							[
								{
									text: Translate('no-chatbot'),
									value: null
								}
							]
						},
					]}
					form={phoneForm}
					setForm={setPhoneForm}
				/>
			</Body>
			<Footer class='right'>
				<Button
					class='bg-blue white'
					text={Translate('save')}
					Click={async () =>
					{
						const response = phoneForm._id ? await UpdateWhatsappNumber(phoneForm) : await CreateWhatsappNumber(phoneForm);

						setMessages(response.messages);

						if(response.status === 200)
						{
							CloseModal();
						}
					}}
				/>
				<Button text={Translate('cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export { WhatsappForm };