import { Card, Header, Body, Footer, Text, Button } from "crack-ux";
import { QRCodeSVG } from 'qrcode.react';

// Contexts
import { useTraductor } from "../../contexts/general/Traductor";
import { useModal } from "../../contexts/general/Modal";

const QRGenerator = (props) => 
{
	const {
		title,
		value,
		level = 'H',
		size = 256,
		minVersion = 10,
		imageSettings = null // { src, height, width, excavate, x, y, opacity, crossOrigin }
	} = props;

	const { CloseModal } = useModal();
	const { Translate } = useTraductor();

	return (
		<Card>
			<Header>
				<Text class='title'>{title}</Text>
			</Header>
			<Body class='center'>
				<QRCodeSVG
					value = {value}
					size = {size}
					level = {level}
					minVersion = {minVersion}
					imageSettings = {imageSettings}
				/>
			</Body>
			<Footer class='right'>
				<Button text={Translate('close')} Click={CloseModal}/>
			</Footer>
		</Card>
	);
};

export { QRGenerator };