import { Section, Content, Text, Button, Box } from 'crack-ux';

const Hero = () =>
{
	return (
		<Section class='hero'>
			<Content class='desktop full-height-v relative'>
				<Box class='content'>
					<Text class='main-phrase'>Optimiza tu negocio con un software hecho a tu medida.</Text>
					<Text class='subtext'>SICE se adapta a ti. Gestiona ventas, cobros, pagos y más, desde donde estés.</Text>
					<Section class='actions'>
						<Button class='cta1' text='Agenda una demo'/>
						<Button class='cta2' text='Contactanos'/>
					</Section>
				</Box>
			</Content>
		</Section>
	)
}

export { Hero };