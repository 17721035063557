import {Panel, Card, Header, Body, Text, Icon} from 'crack-ux';
import {Utilities} from 'crack-functions';

// Contexts
import {useBusinessPage} from '../../../../../contexts/pages/public/BusinessPage';

const Feature = (feature) =>
{
	const {title, description, icon} = feature;

	const {business} = useBusinessPage();
	
	const {page = {}} = business;
	const {features: featuresConf} = page;

	return (
		<Panel class='feature desktop4'>
			<Card
				style =
				{{
					background: featuresConf?.card?.background,
					color: Utilities.HexIsLight(featuresConf?.card?.background) ? 'black' : 'white'
				}}
			>
				<Header class='no-border'>
					<Icon class={icon}/>
					<Text>{title}</Text>
				</Header>
				<Body>
					<Text>{description}</Text>
				</Body>
			</Card>
		</Panel>
	);
}

export {Feature};