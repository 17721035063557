import {convertFromRaw} from "draft-js";

const SetTemplate = (template) =>
{
	template.codeText = `TEMP-${template.code}`;
	template.finishChatText = template.finishChat ? 'Yes' : 'No';

	if(Array.isArray(template.files))
	{
		template.filesAsObj = template.files.map(file =>
		({
			_id: file
		}));
	}

	template.contentText = template.content ? convertFromRaw(JSON.parse(template.content)).getPlainText() : '';

	return template;
}

const TemplatesReducer = (state = {}, action) =>
{
	switch (action.type)
	{
		case 'LOAD_TEMPLATES':
			return {
				...state,
				templates: action.templates.map(template => SetTemplate(template))
			}

		case 'CREATE_TEMPLATE':
			return {
				...state,
				templates: [...state.templates, SetTemplate(action.template)],
				code: state.code + 1
			}

		case 'UPDATE_TEMPLATE':
			return {
				...state,
				templates: state.templates.map(template => template._id === action.template._id ? SetTemplate(action.template) : template)
			}

		case 'DELETE_TEMPLATE':
		{
			return {
				...state,
				templates: state.templates.filter(template => template._id !== action.template._id)
			}
		}

		case 'SET_LATEST_TEMPLATE_CODE':
		{
			return {
				...state,
				code: action.code
			}	
		}

		default:
			return state;
	}
}

export {TemplatesReducer};