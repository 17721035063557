import { useEffect, useMemo, useRef, useState } from 'react';
import { Content, Section, Panel, Card, Header, Body, Footer, Text, Button, Box, Icon } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';
import { BusinessPageProvider, useBusinessPage } from '../../../../contexts/pages/public/BusinessPage';

// Components
import { GeneralForm } from './GeneralForm';
import { InitForm } from './InitForm';
import { AboutUsForm } from './AboutUsForm';
import { FeaturesForm } from './FeaturesForm';
import { ProductsForm } from './ProductsForm';
import { ServicesForm } from './ServicesForm';
import { ReviewsForm } from './ReviewsForm';
import { FaqsForm } from './FaqsForm';
import { ContactUsForm } from './ContactUsForm';

// Web Page Sections
import { BusinessPage } from '../../../public/BusinessPage';

// Styles
import './WebPage.css';

// Importar isEqual de lodash para comparación profunda
import isEqual from 'lodash/isEqual';

const WebPage = () =>
{
	const [generalForm, setGeneralForm] = useState({});
	const [initForm, setInitForm] = useState({});
	const [aboutUsForm, setAboutUsForm] = useState({});
	const [featuresForm, setFeaturesForm] = useState({});
	const [productsForm, setProductsForm] = useState({});
	const [servicesForm, setServicesForm] = useState({});
	const [reviewsForm, setReviewsForm] = useState({});
	const [faqsForm, setFaqsForm] = useState({});
	const [contactUsForm, setContactUsForm] = useState({});
	const [messages, setMessages] = useState({});
	const [disabled, setDisabled] = useState(true);
	const [device, setDevice] = useState('desktop');

	const { Translate } = useTraductor();
	const { business } = useBusinessPage();

	const containerRef = useRef();

	const data = useMemo(() =>
	{
		return {
			general: generalForm,
			init: initForm,
			aboutUs: aboutUsForm,
			features: featuresForm,
			products: productsForm,
			services: servicesForm,
			reviews: reviewsForm,
			faqs: faqsForm,
			contactUs: contactUsForm,
		}
	}, [generalForm, initForm, aboutUsForm, featuresForm, productsForm, servicesForm, reviewsForm, faqsForm, contactUsForm]);

	const HandleSave = async () =>
	{
		try
		{
			setDisabled(true); // Remove this once save function is implemented, the disabled state will change on the useEffect once business.page is updated 
			setMessages({});
		}
		catch (error)
		{
			// Manejo de errores
			setMessages({ error: 'Error al guardar los cambios' });
		}
	}

	const HandleRevertChanges = () =>
	{
		setGeneralForm(business.page?.general || {});
		setInitForm(business.page?.init || {});
		setAboutUsForm(business.page?.aboutUs || {});
		setFeaturesForm(business.page?.features || {});
		setProductsForm(business.page?.products || {});
		setServicesForm(business.page?.services || {});
		setReviewsForm(business.page?.reviews || {});
		setFaqsForm(business.page?.faqs || {});
		setContactUsForm(business.page?.contactUs || {});
		setMessages({});
	}

	useEffect(() =>
	{
		setGeneralForm(business.page?.general || {});
		setInitForm(business.page?.init || {});
		setAboutUsForm(business.page?.aboutUs || {});
		setFeaturesForm(business.page?.features || {});
		setProductsForm(business.page?.products || {});
		setServicesForm(business.page?.services || {});
		setReviewsForm(business.page?.reviews || {});
		setFaqsForm(business.page?.faqs || {});
		setContactUsForm(business.page?.contactUs || {});
	}, [business.page]);

	useEffect(() =>
	{
		const isDataEqual = isEqual(data, business.page || {});

		setDisabled(isDataEqual);
	}, [data, business.page]);

	return (
		<Content class='home-web-page'>
			<Panel class='preview desktop9 full-height'>
				<Card>
					<Header>
						<Text class='title'>{Translate('web-page-preview')}</Text>
						<Box class='float-right'>
							<Icon class={`icon-desktop ${device === 'desktop' && 'active'}`} Click={() => setDevice('desktop')}/>
							<Icon class={`icon-laptop ${device === 'laptop' && 'active'}`} Click={() => setDevice('laptop')}/>
							<Icon class={`icon-tablet ${device === 'tablet' && 'active'}`} Click={() => setDevice('tablet')}/>
							<Icon class={`icon-mobile ${device === 'mobile' && 'active'}`} Click={() => setDevice('mobile')}/>
						</Box>
					</Header>
					<Body class='vertical-scroll no-padding' style={{height: 'calc(100% - var(--header))'}}>
						<Section
							reference = {containerRef}
							style =
							{{
								margin: 'auto',
								width: device === 'desktop' ? '100%' : device === 'laptop' ? 1024 : device === 'tablet' ? 768 : 360
							}}
						>
							<BusinessPage containerRef={containerRef} data={data}/>
						</Section>
					</Body>
				</Card>
			</Panel>
			<Panel class='desktop3 full-height'>
				<Card>
					<Header>
						<Text class='title'>{Translate('web-page')}</Text>
					</Header>
					<Body class='vertical-scroll' style={{height: 'calc(100% - var(--header) - var(--footer))'}}>
						<GeneralForm generalForm={generalForm} setGeneralForm={setGeneralForm} messages={messages} />
						<InitForm initForm={initForm} setInitForm={setInitForm} messages={messages} />
						<AboutUsForm aboutUsForm={aboutUsForm} setAboutUsForm={setAboutUsForm} messages={messages} />
						<FeaturesForm featuresForm={featuresForm} setFeaturesForm={setFeaturesForm} messages={messages} />
						<ProductsForm productsForm={productsForm} setProductsForm={setProductsForm} messages={messages} />
						<ServicesForm servicesForm={servicesForm} setServicesForm={setServicesForm} messages={messages} />
						<ReviewsForm reviewsForm={reviewsForm} setReviewsForm={setReviewsForm} messages={messages} />
						<FaqsForm faqsForm={faqsForm} setFaqsForm={setFaqsForm} messages={messages} />
						<ContactUsForm contactUsForm={contactUsForm} setContactUsForm={setContactUsForm} messages={messages} />
					</Body>
					<Footer class='right'>
						<Button
							text = {Translate('save-changes')}
							class = 'bg-blue white'
							Click = {HandleSave}
							disabled = {disabled}
						/>
						<Button
							text = {Translate('revert-changes')}
							class = 'bg-red white'
							Click = {HandleRevertChanges}
							disabled = {disabled}
						/>
					</Footer>
				</Card>
			</Panel>
		</Content>
	)
}

const WebPageWithProviders = () =>
{
	return (
		<BusinessPageProvider>
			<WebPage />
		</BusinessPageProvider>
	)
}

export { WebPageWithProviders as WebPage };