import { useMemo } from 'react';
import { Section, Panel, Card, Header, Body, Footer, Button, Text } from 'crack-ux';
import { Amount } from 'crack-functions';
import moment from 'moment';

// Context
import { useModal } from '../../../../contexts/general/Modal';
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useLists } from '../../../../contexts/collections/home/Lists';

const CloseCashRegisterView = (props) =>
{
	const { shift } = props;

	const { CloseModal } = useModal();
	const { Translate } = useTraductor();
	const { listsByName } = useLists();

	const paymentMethods = useMemo(() => listsByName['payment-methods']?.workingItems || [], [listsByName]);

	console.log(shift);

	const totalInserted = shift.closingSummary.reduce((total, payment) => total + payment.declaredAmount, 0);
	const totalSystem = shift.closingSummary.reduce((total, payment) => total + payment.expectedAmount, 0);
	const totalDifference = totalSystem - totalInserted;

	return (
		<Card>
			<Header>
				<Text class='bold'>{Translate('shift-detail')}</Text>
			</Header>
			<Body>
				<Section>
					<Panel class='mobile4'>
						<Text class='bold'>{Translate('cash-register')}:</Text>
					</Panel>
					<Panel class='mobile8'>
						<Text>{shift.cashRegisterName}</Text>
					</Panel>
					<Panel class='mobile4'>
						<Text class='bold'>{Translate('employee')}:</Text>
					</Panel>
					<Panel class='mobile8'>
						<Text>{shift.memberFullName}</Text>
					</Panel>
					<Panel class='mobile4'>
						<Text class='bold'>{Translate('open-date')}:</Text>
					</Panel>
					<Panel class='mobile8'>
						<Text>{moment(shift.openDate).format('DD/MM/YYYY HH:mm')}</Text>
					</Panel>
					<Panel class='mobile4'>
						<Text class='bold'>{Translate('opening-cash')}:</Text>
					</Panel>
					<Panel class='mobile8'>
						<Text>{Amount.CurrencyFormat(shift.initialCash)}</Text>
					</Panel>
					<Panel class='mobile4'>
						<Text class='bold'>{Translate('closed-date')}:</Text>
					</Panel>
					<Panel class='mobile8'>
						<Text>{moment(shift.closedDate).format('DD/MM/YYYY HH:mm')}</Text>
					</Panel>
				</Section>
				<Section style={{marginTop: 20}}>
					<Panel>
						<Text class='bold'>{Translate('close-balance')}:</Text>
					</Panel>
					<Panel class='mobile3'>
						<Text class='bold'>{Translate('payment-method')}</Text>
					</Panel>
					<Panel class='mobile3'>
						<Text class='bold'>{Translate('declared-amount')}</Text>
					</Panel>
					<Panel class='mobile3'>
						<Text class='bold'>{Translate('expected-amount')}</Text>
					</Panel>
					<Panel class='mobile3'>
						<Text class='bold'>{Translate('difference')}</Text>
					</Panel>
					{shift.closingSummary.map(record =>
					{
						const paymentMethod = paymentMethods[record.method] || {};

						const insertedColor = record.expectedAmount !== record.declaredAmount ? 'red' : '';

						return (
							<Section key={record.method}>
								<Panel class='mobile3'>
									<Text class='bold'>{paymentMethod.text}</Text>
								</Panel>
								<Panel class='mobile3'>
									<Text>{Amount.CurrencyFormat(record.declaredAmount)}</Text>
								</Panel>
								<Panel class='mobile3'>
									<Text>{Amount.CurrencyFormat(record.expectedAmount)}</Text>
								</Panel>
								<Panel class='mobile3'>
									<Text class={insertedColor}>{Amount.CurrencyFormat(record.expectedAmount - record.declaredAmount)}</Text>
								</Panel>
							</Section>
						)
					})}
				</Section>
				<Section style={{marginTop: 20}}>
					<Panel class='mobile4'>
						<Text class='bold'>{Translate('declared-total')}:</Text>
					</Panel>
					<Panel class='mobile8'>
						<Text>{Amount.CurrencyFormat(totalInserted)}</Text>
					</Panel>
					<Panel class='mobile4'>
						<Text class='bold'>{Translate('expected-total')}:</Text>
					</Panel>
					<Panel class='mobile8'>
						<Text>{Amount.CurrencyFormat(totalSystem)}</Text>
					</Panel>
					<Panel class='mobile4'>
						<Text class='bold'>{Translate('total-difference')}:</Text>
					</Panel>
					<Panel class='mobile8'>
						<Text class={totalDifference && 'red'}>{Amount.CurrencyFormat(totalDifference)}</Text>
					</Panel>
				</Section>
				{shift.note &&
				<Section style={{marginTop: 20}}>
					<Panel class='mobile3'>
						<Text class='bold'>{Translate('note')}:</Text>
					</Panel>
					<Panel class='mobile9'>
						<Text>{shift.note}</Text>
					</Panel>
				</Section>}
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('close')}
					Click = {CloseModal}
				/>
			</Footer>
		</Card>
	)
}

export { CloseCashRegisterView };