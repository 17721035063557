import { useCallback, useMemo } from 'react';
import { Card, Panel, Table, Input, Icon, Avatar } from 'crack-ux';
import { Generic, Amount } from 'crack-functions';

// Contexts
import { useSession } from '../../../../../contexts/general/Session';
import { useTraductor } from '../../../../../contexts/general/Traductor';
import { useBusiness } from '../../../../../contexts/layouts/Business';
import { useProducts } from '../../../../../contexts/collections/commerce/Products';

// Components
import { BarcodeQRScanner } from '../../../../../components/BarcodeQRScanner';

const ProductsDetail = (props) =>
{
	const { from, productsSelected, AddProduct, ClearProduct, UpdatePrice, UpdateAmount } = props;

	const { user } = useSession();
	const { Translate } = useTraductor();
	const { Can } = useBusiness();
	const { products: allProducts } = useProducts();

	const products = useMemo(() => Generic.ObjectToArray(productsSelected), [productsSelected]);

	const availableProducts = useMemo(() => allProducts.filter(product => product.available), [allProducts]);

	const canEditPrice = useMemo(() => from === 'sales' ? Can('can-edit-sales-price') : Can('can-edit-acquisitions-price'), [Can, from]);

	const ScannProduct = useCallback((barcode) =>
	{
		if(barcode)
		{
			const product = availableProducts.find(product => product.barcode === barcode);

			if(product)
			{
				AddProduct(product);
			}
		}
	}, [availableProducts, AddProduct]);

	return (
		<>
			<Panel>
				<BarcodeQRScanner scope='barcode' ScanSuccess={(barcode) => ScannProduct(barcode)} card={false} style={{height: 200}}/>
			</Panel>
			<Panel class='no-padding-horizontal' style={{height: `calc(100% - 205px)`}}>
				<Card>
					<Table
						header =
						{[
							{
								name: 'pictureMiniature',
								text: Translate('picture')
							},
							{
								name: 'name',
								text: Translate('product'),
								widht: 200
							},
							{
								name: 'amountInput',
								text: Translate('amount'),
							},
							...(from === 'sales') ?
							[{
								name: 'salesPriceInput',
								text: Translate('price')
							}] : [],
							...(from === 'acquisitions') ?
							[{
								name: 'purchasePriceInput',
								text: Translate('price')
							}] : [],
							{
								name: 'totalText',
								text: Translate('total')
							},
							{
								name: 'clearProduct',
								text: ''
							},
						]}
						body = {products.map(product =>
						{
							product.amountInput =
							<Input
								type = 'number'
								value = {(from === 'sales' && product.amount > product.quantity.current) ? product.quantity.current : product.amount}
								style = {{width: 65}}
								min = {1}
								max = {product.quantity.current}
								Change = {(amount) => UpdateAmount(product, amount)}
							/>;
							product.clearProduct = <Icon class='icon-cancel red pointer' style={{padding: 10}} Click={() => ClearProduct(product)}/>; 
							product.pictureMiniature = <Avatar class='bg-white' src={product.pictureURL} error='/assets/box.png'/>
							
							if(from === 'sales')
							{
								product.salesPriceInput = <Input type='number' value={product.salePrice} style={{width: 85}} Change={(price) => UpdatePrice(product, price)} disabled={!canEditPrice}/>;
								product.total = Amount.CalculateTotal({quantity: product.amount, price: product.salePrice, tax: 0});
							}
							else
							{
								product.purchasePriceInput = <Input type='number' value={product.purchasePrice} style={{width: 85}} Change={(price) => UpdatePrice(product, price)} disabled={!canEditPrice}/>;
								product.total = Amount.CalculateTotal({quantity: product.amount, price: product.purchasePrice, tax: 0});
							}

							product.totalText = Amount.CurrencyFormat(product.total);

							return product;	
						})}
						actions = {false}
						searcher = {false}
						footer = {false}
						filters = {false}
						pager = 
						{{
							rows: 1000
						}}
						language = {user.preferences?.language || 'es'}
					/>
				</Card>
			</Panel>
		</>
	)
}

export { ProductsDetail }