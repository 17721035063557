import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Content, Box, Section, Text, Button} from 'crack-ux';

// Styles
import './PageNotFound.css';

const PageNotFound = () =>
{
	const navigate = useNavigate();

	return (
		<Content class='relative'>
			<Box class='full-center center'>
				<Section>
					<Text size={6}>Page Not Found</Text>
				</Section>
				<Section class='center' style={{marginTop: 30}}>
					<Button class='bg-blue white' text='Go Home' Click={() => navigate('..')}/>
				</Section>
			</Box>
		</Content>
	)
}

export {PageNotFound};