import { Route, Routes } from "react-router-dom";

// Pages
import { Companies } from "./Companies";

const CompaniesRouter = () =>
{
	return (
		<Routes>
			<Route path="/:companyName/:companyId" element={<Companies/>} />
			<Route path="/" element={<Companies/>} />
		</Routes>
	);
}

export { CompaniesRouter as Companies };