import { useMemo } from 'react';
import { Card, Header, Body, Footer, Button, Section, Text, Avatar, Icon } from 'crack-ux';
import { useNavigate } from 'react-router-dom';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';
import { useModal } from '../../../../contexts/general/Modal';
import { useCompanies } from '../../../../contexts/collections/crm/Companies';
import { useLists } from '../../../../contexts/collections/home/Lists';

// Components
import { ContactForm } from './ContactForm';
import { BackButton } from '../../../../components/BackButton';

// Styles
import './ContactInfo.css';

const Info = ({ label, value }) =>
{
	return (
		<Section class='info'>
			<Text class='label' style={{marginRight: 5}}>{label}:</Text>
			{value && <Text class='value'>{value}</Text>}
		</Section>
	);
}

const ContactInfo = ({ contact = {} }) =>
{
	const { Translate } = useTraductor();
	const { AddModal } = useModal();
	const { companies } = useCompanies();
	const { listsByName } = useLists();

	const contactCompaniesData = useMemo(() => companies.filter(company => (contact.companies ?? []).includes(company._id)), [contact.companies, companies]);

	const navigate = useNavigate();

	const companiesSection = useMemo(() =>
	{
		return contactCompaniesData.map(company =>
		{
			return (
				<Section key={company._id} class='company'>
					<Button class='float-right blue' text={Translate('Edit Company')} Click={() => {}} style={{marginTop: -5}}/>
					<Info label={Translate('company')} value={company.name}/>
					<Info label={Translate('employees-amount')} value={company.employees}/>
					{(company.economicActivities && company.economicActivities.length > 0) && <Info label={Translate('economic-activities')} value={company.economicActivities.map(item => listsByName['economic-activity']?.itemsById?.[item]?.text || '').join(', ')}/>}
					{company.location?.address && <Info label={Translate('location')} value={company.location?.address}/>}
				</Section>
			);
		});
	}, [contactCompaniesData, Translate, listsByName]);

	return (
		<Card class='contact-info'>
			<Header>
				<BackButton Click={() => navigate(`../${contact.name}/${contact._id}/chat`)}/>
				<Text class='title'>{Translate('contact-info')}</Text>
			</Header>
			<Body class='no-padding'>
				<Section>
					<Avatar class='round bg-blue white' src={contact.initials}/>
					<Section class='actions center'>
						<Icon class='icon-mobile bg-gray white'/>
						<Icon class='icon-sticky-note bg-gray white'/>
						<Icon class='icon-calendar bg-gray white'/>
					</Section>
				</Section>
				<Section>
					<Info label={Translate('code')} value={contact.codeText}/>
					<Info label={Translate('full-name')} value={contact.fullName}/>
					{contact.genderText && <Info label={Translate('gender')} value={contact.genderText}/>}
					{contact.age && <Info label={Translate('age')} value={`${contact.age} ${Translate('years')}`}/>}
					{contact.phoneText && <Info label={Translate('phones')} value={contact.phoneText}/>}
					{contact.emailText && <Info label={Translate('emails')} value={contact.emailText}/>}
					{contact.tagsText && <Info label={Translate('tags')} value={contact.tagsText}/>}
				</Section>
				<Section>
					<Text class='title'>{Translate('companies')}</Text>
				</Section>
				<Section>
					{companiesSection}
				</Section>
			</Body>
			<Footer class='right'>
				<Button
					text = {Translate('edit-contact')}
					class = 'bg-blue white'
					Click = {() => AddModal(<ContactForm contact={contact}/>)}
				/>
			</Footer>
		</Card>
	)
}

export { ContactInfo };