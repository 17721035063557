import { Routes, Route } from 'react-router-dom';
import { Page } from 'crack-ux';

// Pages
import { Home } from '../../pages/public/Home';
import { Home2 } from '../../pages/public/Home2';
import { CreateAccount } from '../../pages/public/CreateAccount';
import { Login } from '../../pages/public/Login';
import { PasswordRecover } from '../../pages/public/PasswordRecover';
import { BusinessPage } from '../../pages/public/BusinessPage';
import { Explorer } from '../../pages/public/Explorer';
import { AcceptedInvitation } from '../../pages/public/AcceptInvitation/AcceptInvitation';

// Contexts
import { PublicProvider } from '../../contexts/layouts/Public';

// Styles
import './Public.css';

const Public = () =>
{
	return (
		<Page class='public-layout'>
			<Routes>
				<Route path='/create-account/:encryptedData' exact={true} element={<CreateAccount/>}/>
				<Route path='/create-account' exact={true} element={<CreateAccount/>}/>
				<Route path='/login' exact={true} element={<Login/>}/>
				<Route path="/accept-invitation/:userId/:encryptedData" exact={true} element={<AcceptedInvitation/>}/>
				<Route path="/recover-password" exact={true} element={<PasswordRecover/>}/>
				<Route path="/recover-password/:token" exact={true} element={<PasswordRecover/>}/>
				<Route path='/explorer' element={<Explorer/>}/>
				<Route path='/home2' element={<Home2/>}/>
				<Route path='/:business/*' element={<BusinessPage/>}/>
				<Route path='/' element={<Home/>}/>
			</Routes>
		</Page>
	)
}

const PublicWithProviders = () =>
{
	return (
		<PublicProvider>
			<Public/>
		</PublicProvider>
	)	
}

export {PublicWithProviders as Public};