import { ToggleContent, Form } from 'crack-ux';

// Contexts
import { useTraductor } from '../../../../contexts/general/Traductor';

const AboutUsForm = (props) =>
{
	const { aboutUsForm, setAboutUsForm, messages } = props;

	const { Translate } = useTraductor();

	return (
		<ToggleContent text={Translate('about-us')}>
			<Form
				inputs =
				{[
					{
						name: 'active',
						text: Translate('about-us-visibility'),
						type: 'switch',
						value: aboutUsForm.active,
						message: messages.active,
					},
					{
						name: 'title',
						text: Translate('title'),
						type: 'textarea',
						rows: 2,
						value: aboutUsForm.title,
						message: messages.title,
					},
					{
						name: 'caption',
						text: Translate('caption'),
						type: 'textarea',
						rows: 2,
						value: aboutUsForm.caption,
						message: messages.caption,
					},
					{
						name: 'description',
						text: Translate('description'),
						type: 'textarea',
						value: aboutUsForm.description,
						message: messages.description,
					},
					{
						text: Translate('products-button'),
						type: 'label',
					},
					{
						name: 'productsButtonText',
						text: Translate('text'),
						class: 'desktop9',
						value: aboutUsForm.productsButtonText,
						message: messages.productsButtonText,
					},
					{
						name: 'productsButtonColor',
						text: Translate('color'),
						class: 'desktop3',
						type: 'color',
						value: aboutUsForm.productsButtonColor,
						message: messages.productsButtonColor,
					},
					{
						text: Translate('services-button'),
						type: 'label',
					},
					{
						name: 'servicesButtonText',
						text: Translate('text'),
						class: 'desktop9',
						value: aboutUsForm.servicesButtonText,
						message: messages.servicesButtonText,
					},
					{
						name: 'servicesButtonColor',
						text: Translate('color'),
						class: 'desktop3',
						type: 'color',
						value: aboutUsForm.servicesButtonColor,
						message: messages.servicesButtonColor,
					},
				]}
				form = {aboutUsForm}
				setForm = {setAboutUsForm}
			/>
		</ToggleContent>
	)
}

export { AboutUsForm };