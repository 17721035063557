import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

// Context
import { useSocket } from '../../general/Socket';
import { useTraductor } from '../../general/Traductor';
import { useBusiness } from '../../layouts/Business';

// Reducers
import { CompaniesReducer } from '../../../reducers/collections/crm/Companies';

const Companies = createContext({});

const CompaniesProvider = (props) =>
{
	const [state, dispatch] = useReducer(CompaniesReducer, {companies: [], code: 0});
	const [currentCompany, setCurrentCompany] = useState({});
	const [loading, setLoading] = useState(true);

	const Socket = useSocket();
	const { Translate } = useTraductor();
	const { BusinessFetch, business, SomethingWentWrong } = useBusiness();

	const GetMaxCompaniesCode = useCallback(async () =>
	{
		try
		{
			const response = await BusinessFetch.Get('/crm/companies/code');

			if(response.status === 200)
			{
				dispatch({type: 'SET_LATEST_COMPANY_CODE', code: response.data});
			}
		}
		catch (error)
		{
			console.error('GetMaxCompaniesCode error:', error);
		}
	}, [BusinessFetch]);

	const ReadCompanies = useCallback(async () =>
	{
		try
		{
			setLoading(true);

			const response = await BusinessFetch.Get('/crm/companies');

			if(response.status === 200)
			{
				dispatch({type: 'LOAD_COMPANIES', companies: response.data});
			}
		}
		catch(error)
		{
			SomethingWentWrong('ReadCompanies');
		}
		finally
		{
			setLoading(false);
		}
	}, [BusinessFetch, SomethingWentWrong]);

	const CreateCompany = async (company) =>
	{
		try
		{
			company.code = state.code + 1;
	
			const response = await BusinessFetch.Post('/crm/company', company);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('company-created'),
				});
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('CreateCompany');
		}
	}

	const UpdateCompany = async (company) =>
	{
		try
		{
			const response = await BusinessFetch.Put('/crm/company', company);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('company-updated'),
				});
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('UpdateCompany');
		}
	}

	const DeleteCompany = async (company) =>
	{
		try
		{
			const response = await BusinessFetch.Delete(`/crm/company`, company);

			if(response.status === 200)
			{
				window.CrackUX.Toasts.AddToast(
				{
					class: 'success',
					message: Translate('company-deleted'),
				});
			}

			return response;
		}
		catch(error)
		{
			SomethingWentWrong('DeleteCompany');
		}
	}

	useEffect(() =>
	{
		GetMaxCompaniesCode();
	}, [GetMaxCompaniesCode]);

	useEffect(() =>
	{
		const events =
		[
			{
				name: `CRM_COMPANY_CREATE`,
				Function: (company) => dispatch({type: 'CREATE_COMPANY', company})
			},
			{
				name: `CRM_COMPANY_UPDATE`,
				Function: (company) => dispatch({type: 'UPDATE_COMPANY', company})
			},
			{
				name: `CRM_COMPANY_DELETE`,
				Function: (company) => dispatch({type: 'DELETE_COMPANY', company})
			}
		];

		Socket.ConnectEvents(business._id, events);
	}, [Socket, business]);

	const value =
	{
		loading,
		code: state.code,
		companies: state.companies,
		currentCompany,
		setCurrentCompany,
		ReadCompanies,
		CreateCompany,
		UpdateCompany,
		DeleteCompany
	}

	return (
		<Companies.Provider value={value}>
			{props.children}
		</Companies.Provider>
	)
}

const useCompanies = () => useContext(Companies);

export { CompaniesProvider, useCompanies };