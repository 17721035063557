import React from 'react';
import {Section, Panel, Text, Input, Select, Radio, CheckBox, Button, Modal, Box, Card, Table, Header, Body, Footer} from 'crack-ux';
import {Dates} from 'crack-functions';
import moment from 'moment';
import {RRule} from 'rrule';

// Functions
// import Validations from '../../../utilities/Validate';
import Times from '../../../functions/Times';

// Contexts
import {useTraductor} from '../../../contexts/general/Traductor';

// Styles
import './Recurrence.css';

const byMonthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']; // to use with +1
const bySetPosMonthList = {1: 'First', 2: 'Second', 3: 'Third', 4: 'Fourth', '-1': 'Last'}; // bySetPos of month
const byDayList = {'MO': 'Monday', 'TU': 'Tuesday', 'WE': 'Wednesday', 'TH': 'Thursday', 'FR': 'Friday', 'SA': 'Saturday', 'SU': 'Sunday'};
const byDaysList = {...byDayList, 'MO,TU,WE,TH,FR,SA,SU': 'Day', 'MO,TU,WE,TH,FR': 'Weekday', 'SA,SU': 'Weekend day'}; // 
const byMonthDayList = Array.from({length: 31}, (v, i) => i + 1);
const freqList = ['NEVER', 'DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY']; // HOURLY, MINUTELY, SECONDLY

const defaultForm =
{
	startDate: new Date((new Date()).setHours(0, 0, 0, 0)),
	duration: '00:00',
	startTime: '',
	endTime: '',
	FREQ: 'NEVER',
	endType: 1, // 0.- Never, 1.- After # occurrences (COUNT), 2.- On Date (UNTIL)
	COUNT: 1
};

const GetFormFreqSection = (freq, onType, form = {}) =>
{
	let newForm = {};

	for(let i in defaultForm)
	{
		newForm[i] = form[i] ? form[i] : defaultForm[i];
	}

	if(freq)
	{
		newForm.FREQ = freq;
	}
	else
	{
		freq = newForm.FREQ;
	}

	if(newForm.endType === 1 && form.COUNT !== undefined) // COUNT
	{
		newForm.COUNT = form.COUNT;
	}
	else if(newForm.endType === 2 && form.UNTIL !== undefined) // UNTIL
	{
		newForm.UNTIL = form.UNTIL;
	}

	// remove endType, COUNT, UNTIL for NEVER freq
	if(freq === 'NEVER')
	{
		delete newForm.UNTIL;
		delete newForm.COUNT;
		delete newForm.endType;
	}

	switch(freq)
	{
		case 'YEARLY':
		case 'MONTHLY':
			onType = onType ? onType : defaultFreqForm[freq].defaults.onType;
			newForm = {...newForm, ...defaultFreqForm[freq].defaults, onType, ...defaultFreqForm[freq].onType[onType]};
		break;

		case 'WEEKLY':
		case 'DAILY':
			newForm = {...newForm, ...defaultFreqForm[freq].defaults};
		break;

		default: break;
	}

	return newForm;
}

const defaultFreqForm =
{
	YEARLY:
	{
		defaults:
		{
			onType: 0 // 0.- On, 1.- On The
		},
		onType:
		{
			0:
			{
				BYMONTH: 1,
				BYMONTHDAY: 1
			},
			1:
			{
				BYSETPOS: '1',
				BYDAY: 'MO',
				BYMONTH: 1
			}
		},
		stringConfig:
		{
			0: ['FREQ', 'BYMONTH', 'BYMONTHDAY'],
			1: ['FREQ', 'BYSETPOS', 'BYDAY', 'BYMONTH']
		}
	},
	MONTHLY:
	{
		defaults:
		{
			INTERVAL: 1,
			onType: 0 // 0.- On Day, 1.- On The
		},
		onType:
		{
			0:
			{
				BYMONTHDAY: 1
			},
			1:
			{
				BYSETPOS: '1',
				BYDAY: 'MO'
			}
		},
		stringConfig:
		{
			0: ['FREQ', 'INTERVAL', 'BYMONTHDAY'],
			1: ['FREQ', 'INTERVAL', 'BYSETPOS', 'BYDAY']
		}
	},
	WEEKLY:
	{
		defaults:
		{
			INTERVAL: 1,
			BYDAY: ''
		},
		stringConfig: ['FREQ', 'INTERVAL', 'BYDAY']
	},
	DAILY:
	{
		defaults:
		{
			INTERVAL: 1
		},
		stringConfig: ['FREQ', 'INTERVAL']
	},
	NEVER:
	{
		stringConfig: []
	}
}

const GetRuleArray = (form) =>
{
	const result = [];

	const dtStart = new Date(form.startDate);
	const freqConfig = defaultFreqForm[form.FREQ];
	const stringConfig = form.onType !== undefined ? freqConfig.stringConfig[form.onType] : freqConfig.stringConfig;

	stringConfig.forEach(rulePart =>
	{
		if(form[rulePart] !== '')
		{
			result.push(`${rulePart}=${form[rulePart]}`);
		}
	});

	const stupidISO8601Function = (date) =>
	{
		function pad(i)
		{
			return i < 10 ? `0${i}` : `${i}`;
		}

		const year = date.getUTCFullYear();
		const month = pad(date.getUTCMonth() + 1);
		const day = pad(date.getUTCDate());
		const hour = pad(date.getUTCHours());
		const minute = pad(date.getUTCMinutes());
		const second = pad(date.getUTCSeconds());

		return `${year}${month}${day}T${hour}${minute}${second}Z`;
	}

	if(form.endType === 1) // COUNT
	{
		result.push(`COUNT=${form.COUNT}`);
	}
	else if(form.endType === 2) // UNTIL
	{
		result.push(`UNTIL=${stupidISO8601Function(new Date(form.UNTIL))}`);
	}

	return [`DTSTART:${stupidISO8601Function(dtStart)}`, `RRULE:${result.join(';')}`];
}

const YEARLYSection = (props) =>
{
	const {form, setForm} = props;
	const [onTypeFields, setOnTypeFields] = React.useState(<></>);

	const {Translate} = useTraductor();

	React.useEffect(() =>
	{
		const GetOnTypeFields = () =>
		{
			if(form.onType === 0) // On
			{
				return <>
							<Panel class = 'desktop8 no-padding-left'>
								<Select
									// text = {Translate('Month')}
									selected = {form.BYMONTH}
									options = {byMonthList.map((monthItem, monthIndex) => ({text: monthItem, value: monthIndex + 1}))}
									Change = {(item) => setForm({...form, BYMONTH: item.value})}
								/>
							</Panel>
							<Panel class = 'desktop4 no-padding-right'>
								<Select
									// text = {Translate('Day')}
									selected = {form.BYMONTHDAY}
									options = {byMonthDayList.map((monthDayItem, monthDayIndex) => ({text: monthDayItem, value: monthDayIndex + 1}))}
									Change = {(item) => setForm({...form, BYMONTHDAY: item.value})}
								/>
							</Panel>
						</>
			}
			else // On The
			{
				return <>
							<Panel class = 'desktop4 no-padding-left'>
								<Select
									// text = {Translate('Month')}
									selected = {form.BYSETPOS}
									options = {Object.keys(bySetPosMonthList).map((weekPosKey) => ({text: bySetPosMonthList[weekPosKey], value: weekPosKey}))}
									Change = {(item) => setForm({...form, BYSETPOS: item.value})}
								/>
							</Panel>
							<Panel class = 'desktop4'>
								<Select
									// text = {Translate('Month')}
									selected = {form.BYDAY}
									options = {Object.keys(byDaysList).map((byDaysKey) => ({text: byDaysList[byDaysKey], value: byDaysKey}))}
									Change = {(item) => setForm({...form, BYDAY: item.value})}
								/>
							</Panel>
							<Panel class = 'desktop1' style = {{verticalAlign: 'bottom', marginBottom: 'var(--spaceXS)'}}>
								<Text>{Translate('of')}</Text>
							</Panel>
							<Panel class = 'desktop3 no-padding-right'>
								<Select
									// text = {Translate('Month')}
									selected = {form.BYMONTH}
									options = {byMonthList.map((monthItem, monthIndex) => ({text: monthItem, value: monthIndex + 1}))}
									Change = {(item) => setForm({...form, BYMONTH: item.value})}
								/>
							</Panel>
						</>
			}
		}

		setOnTypeFields(GetOnTypeFields());
	}, [form, form.onType, setForm, Translate]);

	return <>
				<Panel style = {{verticalAlign: 'bottom'}}>
					<Radio
						class = 'on_type'
						selected = {form.onType}
						options =
						{[
							{value: 0, text: 'On'},
							{value: 1, text: 'On The'}
						]}
						Change = {(item) => setForm(GetFormFreqSection(form.FREQ, item.value, form))}
					/>
					{onTypeFields}
				</Panel>
			</>
}

const MONTHLYSection = (props) =>
{
	const {form, setForm} = props;
	const [onTypeFields, setOnTypeFields] = React.useState(<></>);

	const {Translate} = useTraductor();

	React.useEffect(() =>
	{
		const GetOnTypeFields = () =>
		{
			if(form.onType === 0) // On Day
			{
				return <>
							<Panel class = 'desktop4'>
								<Select
									// text = {Translate('Day')}
									selected = {form.BYMONTHDAY}
									options = {byMonthDayList.map((monthDayItem, monthDayIndex) => ({text: monthDayItem, value: monthDayIndex + 1}))}
									Change = {(item) => setForm({...form, BYMONTHDAY: item.value})}
								/>
							</Panel>
						</>
			}
			else // On The
			{
				return <>
							<Panel class = 'desktop4'>
								<Select
									// text = {Translate('Month')}
									selected = {form.BYSETPOS}
									options = {Object.keys(bySetPosMonthList).map((weekPosKey) => ({text: bySetPosMonthList[weekPosKey], value: weekPosKey}))}
									Change = {(item) => setForm({...form, BYSETPOS: item.value})}
								/>
							</Panel>
							<Panel class = 'desktop4'>
								<Select
									// text = {Translate('Month')}
									selected = {form.BYDAY}
									options = {Object.keys(byDaysList).map((byDaysKey) => ({text: byDaysList[byDaysKey], value: byDaysKey}))}
									Change = {(item) => setForm({...form, BYDAY: item.value})}
								/>
							</Panel>
						</>
			}
		}

		setOnTypeFields(GetOnTypeFields());
	}, [form, form.onType, setForm]);

	return <>
				<Panel class = 'desktop2' style = {{verticalAlign: 'bottom', marginBottom: 'var(--spaceXS)'}}>
					<Text>{Translate('Every')}</Text>
				</Panel>
				<Panel class = 'desktop2'>
					<Input
						// text = {Translate('Day of Month')}
						type = 'number'
						value = {form.INTERVAL}
						Change = {(value) =>
						{
							if(value !== '')
							{
								value = parseInt(value)
	
								if(!(value >= 0))
								{
									value = 0;
								}
							}

							setForm({...form, INTERVAL: String(value)})
						}}
						Blur = {(value) =>
						{
							value = parseInt(value)

							if(!(value >= 0))
							{
								value = 0;
							}

							setForm({...form, INTERVAL: String(value)})							
						}}
					/>
				</Panel>
				<Panel class = 'desktop3' style = {{verticalAlign: 'bottom', marginBottom: 'var(--spaceXS)'}}>
					<Text>{Translate('Month(s)')}</Text>
				</Panel>
				<Panel style = {{verticalAlign: 'bottom'}}>
					<Radio
						class = 'on_type'
						selected = {form.onType}
						options =
						{[
							{value: 0, text: 'On Day'},
							{value: 1, text: 'On The'}
						]}
						Change = {(item) => setForm(GetFormFreqSection(form.FREQ, item.value, form))}
					/>
					{onTypeFields}
				</Panel>
			</>
}

const WEEKLYSection = (props) =>
{
	const {form, setForm} = props;

	const {Translate} = useTraductor();

	return <>
				<Panel class = 'desktop2' style = {{verticalAlign: 'bottom', marginBottom: 'var(--spaceXS)'}}>
					<Text>{Translate('Every')}</Text>
				</Panel>
				<Panel class = 'desktop2'>
					<Input
						// text = {Translate('Day of Month')}
						type = 'number'
						value = {form.INTERVAL}
						Change = {(value) =>
						{
							if(value !== '')
							{
								value = parseInt(value)
	
								if(!(value >= 0))
								{
									value = 0;
								}
							}

							setForm({...form, INTERVAL: String(value)})
						}}
						Blur = {(value) =>
						{
							value = parseInt(value)

							if(!(value >= 0))
							{
								value = 0;
							}

							setForm({...form, INTERVAL: String(value)})							
						}}
					/>
				</Panel>
				<Panel class = 'desktop3' style = {{verticalAlign: 'bottom', marginBottom: 'var(--spaceXS)'}}>
					<Text>{Translate('Week(s)')}</Text>
				</Panel>
				<Panel class = 'day_selector_container'>
					<DaysSelector byDayString = {form.BYDAY} Change = {(result) => {setForm({...form, BYDAY: result})}}/>
				</Panel>
			</>
}

const DAILYSection = (props) =>
{
	const {form, setForm} = props;

	const {Translate} = useTraductor();

	return <>
				<Panel class = 'desktop2' style = {{verticalAlign: 'bottom', marginBottom: 'var(--spaceXS)'}}>
					<Text>{Translate('Every')}</Text>
				</Panel>
				<Panel class = 'desktop2'>
					<Input
						// text = {Translate('Day of Month')}
						type = 'number'
						value = {form.INTERVAL}
						Change = {(value) =>
						{
							if(value !== '')
							{
								value = parseInt(value)
	
								if(!(value >= 0))
								{
									value = 0;
								}
							}

							setForm({...form, INTERVAL: String(value)})
						}}
						Blur = {(value) =>
						{
							value = parseInt(value)

							if(!(value >= 0))
							{
								value = 0;
							}

							setForm({...form, INTERVAL: String(value)})							
						}}
					/>
				</Panel>
				<Panel class = 'desktop3' style = {{verticalAlign: 'bottom', marginBottom: 'var(--spaceXS)'}}>
					<Text>{Translate('Day(s)')}</Text>
				</Panel>
			</>
}

const DaysSelector = (props) =>
{
	const {byDayString, Change} = props;
	const byDayArray = byDayString.split(',');

	const checkboxes = Object.keys(byDayList).map(byDayKey =>
	{
		return <CheckBox 
					key = {byDayKey}
					text = {byDayList[byDayKey]}
					checked = {byDayArray.indexOf(byDayKey) !== -1}
					Change = {(checked) =>
					{
						let changedByDayArray = [...byDayArray];
						const dayIndex = byDayArray.indexOf(byDayKey);
						const foundDay = dayIndex !== -1;

						if(checked && !foundDay)
						{
							changedByDayArray = [...changedByDayArray, byDayKey];
						}
						else if(!checked && foundDay)
						{
							changedByDayArray.splice(dayIndex, 1);
						}

						const newByDayArray = Object.keys(byDayList).filter(dayKey => changedByDayArray.indexOf(dayKey) !== -1);

						Change(newByDayArray.join(','));
					}}
				/>
	});

	return checkboxes;
}

const GetFreqSection = (form, setForm) =>
{
	switch(form.FREQ)
	{
		case 'YEARLY':
			return <YEARLYSection form = {form} setForm = {setForm}/>;

		case 'MONTHLY':
			return <MONTHLYSection form = {form} setForm = {setForm}/>;

		case 'WEEKLY':
			return <WEEKLYSection form = {form} setForm = {setForm}/>;

		case 'DAILY':
			return <DAILYSection form = {form} setForm = {setForm}/>;

		default: break;
	}
}

const GetEndSection = (form, setForm, GenerateRecurrence, Translate) =>
{
	const result = [];

	result.push
	(<Panel class = 'desktop4' key = 'endType_select'>
		<Select
			selected = {form.endType}
			// options = {['Never', 'After', 'On Date'].map((endTypeItem, endTypeKey) => ({text: endTypeItem, value: endTypeKey}))}
			options = {['After', 'On Date'].map((endTypeItem, endTypeKey) => ({text: endTypeItem, value: endTypeKey + 1}))}
			Change = {(item) =>
			{
				const newForm = {...form, endType: item.value};

				// remove COUNT and UNTIL
				delete newForm.COUNT;
				delete newForm.UNTIL;

				if(item.value === 1)
				{
					newForm.COUNT = 1;
				}
				else if(item.value === 2)
				{
					newForm.UNTIL = new Date();
				}

				setForm(newForm)
			}}
		/>
	</Panel>);

	if(form.endType === 1)
	{
		result.push
		(<Panel key = 'COUNT_input' class = 'desktop2'>
			<Input
				// text = {Translate('Day of Month')}
				type = 'number'
				value = {form.COUNT}
				Change = {(value) =>
				{
					if(value !== '')
					{
						value = parseInt(value)

						if(!(value >= 0))
						{
							value = 0;
						}
					}

					setForm({...form, COUNT: String(value)})
				}}
				Blur = {(value) =>
				{
					value = parseInt(value)

					if(!(value >= 0))
					{
						value = 0;
					}

					setForm({...form, COUNT: String(value)})							
				}}
			/>
		</Panel>,
		<Panel key = 'recurrence_title' class = 'desktop4' style = {{verticalAlign: 'bottom', marginBottom: 'var(--spaceXS)'}}>
			<Text>{Translate('Recurrence(s)')}</Text>
		</Panel>);
	}
	else if(form.endType === 2)
	{
		result.push
		(<Panel key = 'UNTIL_input' class = 'desktop6'>
			<Input
				type = 'date'
				value = {Dates.GetDateForForm(form.UNTIL)}
				Change = {(value) => setForm({...form, UNTIL: Dates.GetDateFromForm(value)})}
			/>
		</Panel>)
	}

	result.push
	(<Panel key = 'test_section' class = 'desktop2'>
		<Button 
			class = 'blue'
			icon = {{class: 'icon-eye'}}
			text = ''
			Click = {() => GenerateRecurrence()}/>
	</Panel>)

	return result;
}

const Recurrence = (props) =>
{
	const {recurrence, Change} = props;

	const [form, setForm] = React.useState({});
	const [showModal, setShowModal] = React.useState(false);
	const [recurrencesList, setRecurrencesList] = React.useState([]);
	// const [messages, setMessages] = React.useState({});

	const {Translate} = useTraductor();

	React.useEffect(() =>
	{
		if(!recurrence)
		{
			Change({form: GetFormFreqSection('NEVER'), rrule: ''})
		}
		else
		{
			setForm(recurrence?.form ? recurrence.form : GetFormFreqSection(recurrence?.form?.FREQ, recurrence?.form?.onType, recurrence?.form));
		}
	}, [recurrence, Change]);

	const setFormAndCallback = (newForm) =>
	{
		setForm(newForm);
		Change({form: newForm, rrule: GetRuleArray(newForm)});
	}

	const GenerateRecurrence = () =>
	{
		const rruleText = GetRuleArray(form).join('\n');
		const rule = RRule.fromString(rruleText);
		const dates = rule.all((date, i) => i < 100);

		const newRecurrencesList = dates.map(dateItem =>
		{
			const recurrenceItem =
			{
				date: moment(dateItem).format('DD/MM/YYYY'),
				startTime: form.startTime,
				endTime: form.endTime
			};

			return recurrenceItem;
		});

		setRecurrencesList(newRecurrencesList);

		setShowModal(true);
	}

	const duration = React.useMemo(() => Times.GetDuration(form.startTime, form.endTime), [form.startTime, form.endTime]);

	return <Section class = 'recurrence_form'>
				<Panel class = 'no-padding'>
					<Text class = 'semi-bold block recurrence_section'>{Translate('Start')}</Text>
				</Panel>
				<Panel class = 'desktop6'>
					<Input
						text = {Translate('Start Time')}
						type = 'time'
						value = {form.startTime}
						Change = {(value) => setFormAndCallback({...form, startTime: value})}
					/>
				</Panel>
				<Panel class = 'desktop6'>
					<Input
						text = {Translate('End Time')}
						type = 'time'
						value = {form.endTime}
						Change = {(value) => setFormAndCallback({...form, endTime: value})}
					/>
				</Panel>
				<Panel class = 'desktop6'>
					<Input
						text = {Translate('Date')}
						type = 'date'
						value = {Dates.GetDateForForm(form.startDate)}
						Change = {(value) => setFormAndCallback({...form, startDate: Dates.GetDateFromForm(value)})}
					/>
				</Panel>
				<Panel class = 'desktop6'>
					<Input
						text = {Translate('Duration')}
						value = {duration}
						disabled = {true}
					/>
				</Panel>
				<Panel class = 'no-padding'>
					<Text class = 'semi-bold block recurrence_section'>{Translate('Repeat')}</Text>
				</Panel>
				<Panel>
					<Select
						selected = {form.FREQ}
						options = {freqList.map(freqItem => ({text: freqItem, value: freqItem}))}
						Change = {(item) =>
						{
							const newForm = GetFormFreqSection(item.value, null, form);

							setFormAndCallback(newForm)
						}}
					/>
				</Panel>
				{GetFreqSection(form, setFormAndCallback)}
				{
					form.FREQ !== 'NEVER' ?
					<Panel class = 'no-padding'>
						<Text class = 'semi-bold block recurrence_section'>{Translate('End')}</Text>
					</Panel> :
					<></>
				}
				{form.FREQ !== 'NEVER' ? GetEndSection(form, setFormAndCallback, GenerateRecurrence, Translate) : <></>}
				<Modal show={showModal} PressESC={() => setShowModal(false)}>
					<Box class='full-center' style={{width: 600}}>
						<Card>
							<Header>
								<Text class = 'bold'>{Translate('Recurrences')}</Text>
							</Header>
							<Body class='no-padding' style={{height: 500, maxHeight: 'calc(100vh - 2 * var(--header) - 64px)'}}>
								<Table
									header =
									{[
										{
											name: 'counter',
											text: Translate('Rec.')
										},
										{
											name: 'date',
											text: Translate('Date')
										},
										{
											name: 'startTime',
											text: Translate('Start Time')
										},
										{
											name: 'endTime',
											text: Translate('End Time')
										}
									]}
									body = {recurrencesList.map((recurrenceItem, index) =>
									{
										recurrenceItem.counter = index + 1;

										return recurrenceItem
									})}
									searcher = {false}
								/>
							</Body>
							<Footer class = 'right'>
								<Button 
									text = {Translate('Close')}
									Click = {() => setShowModal(false)}
								/>
							</Footer>
						</Card>
					</Box>
				</Modal>
			</Section>
}

export {Recurrence};