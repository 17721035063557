import { Card, Form, Button, Section, Text, Icon } from 'crack-ux';

// Contexts
import { useTraductor } from '../../contexts/general/Traductor';
import { useState } from 'react';

const FeaturesForm = (props) =>
{
	const { features, setFeatures } = props;

	const [feature, setFeature] = useState({});

	const { Translate } = useTraductor();

	const AddFeature = () =>
	{
		const clonePhones = [...features];

		clonePhones.push(feature);

		setFeatures(clonePhones);
		setFeature({});
	}

	const RemoveFeature = (index) =>
	{
		const clonePhones = [...features];

		clonePhones.splice(index, 1);

		setFeatures(clonePhones);
	}

	return (
		<Card>
			<Form
				inputs =
				{[
					{
						name: 'name',
						text: Translate('name'),
						class: 'desktop5 mobile12',
						value: feature.name,
					},
					{
						name: 'description',
						text: Translate('description'),
						class: 'desktop5 mobile12',
						value: feature.description,
					},
					{
						class: 'desktop2 mobile12',
						type: 'content',
						content: <Button class='bg-blue white full-width' Click={AddFeature}><Icon class='icon-plus' style={{margin: 0}}/></Button>
					},
					...features.length > 0 ?
					[
						{
							text: Translate('features'),
							type: 'content',
							content:
							<Card class='no-shadow bg-light-gray'>
								{features.map((feature, index) =>
								{
									return (
									<Section key={index} style={{padding: 5}}>
										<Text>{feature.name}: {feature.description}</Text>
										<Icon
											class='icon-minus red pointer float-right'
											Click={() => RemoveFeature(index)}
										/>
									</Section>)
								})}
							</Card>
						}
					] : []
				]}
				form = {feature}
				setForm = {setFeature}
			/>
		</Card>
	)	
}

export { FeaturesForm }