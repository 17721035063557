import {useCallback} from 'react';
import {Panel, Card, Header, Body, Footer, Box, Text, Image, Button} from 'crack-ux';
import {Utilities, Amount} from 'crack-functions';

// Contexts
import {useModal} from '../../../../../contexts/general/Modal';
import {useBusinessPage} from '../../../../../contexts/pages/public/BusinessPage';

// Components
import {ProductDetail} from '../ProductDetail';

const Product = (product = {}) =>
{
	const {_id, name, price} = product;

	const {AddModal} = useModal();
	const {business, shoppingCart, AddProductToShoppingCart, RemoveProductFromShoppingCart} = useBusinessPage();

	const {page = {}} = business;
	const {products: productsConf = {}} = page;

	const ShowProductDetail = useCallback(() =>
	{
		AddModal(
			<Card class='product-detail-modal vertical-scroll'>
				<ProductDetail {...product}/>
			</Card>
		);
	}, [AddModal, product]);

	const shoppingCartProduct = shoppingCart.products[_id];

	return (
		<Panel class='product desktop3'>
			<Card>
				<Header class='no-border'>
					{(shoppingCartProduct && shoppingCart.products[_id] && shoppingCart.products[_id]?.quantity > 0) && 
					<Box Click={() => RemoveProductFromShoppingCart(product)}>
						<Text>{shoppingCart.products[_id]?.quantity || ""}</Text>
					</Box>}
					<Image src='/assets/box.png' Click={ShowProductDetail}/>
				</Header>
				<Body
					class = 'center'
					style =
					{{
						background: productsConf.card?.background,
						color: Utilities.HexIsLight(productsConf.card?.background) ? 'black' : 'white'
					}}
				>
					<Text class='name block'>{name}</Text>
					<Text
						class = 'price block'
						style =
						{{
							color: productsConf.price?.color
						}}
					>
						{Amount.CurrencyFormat(price?.sale)}
					</Text>
				</Body>
				<Footer
					class = 'center no-border'
					style =
					{{
						background: productsConf.card?.background,
						color: Utilities.HexIsLight(productsConf.card?.background) ? 'black' : 'white'
					}}
				>
					<Button
						class = 'action-button'
						text = {productsConf.detailButton?.text}
						Click = {ShowProductDetail}
						style =
						{{
							background: productsConf.detailButton?.background,
							color: Utilities.HexIsLight(productsConf.detailButton?.background) ? 'black' : 'white'
						}}
					/>
					<Button
						class = 'action-button'
						text = {productsConf.addProductButton?.text}
						Click = {() => AddProductToShoppingCart(product)}
						style =
						{{
							background: productsConf.addProductButton?.background,
							color: Utilities.HexIsLight(productsConf.addProductButton?.background) ? 'black' : 'white'
						}}
					/>
				</Footer>
			</Card>
		</Panel>
	);
}

export {Product};