import { useCallback, useEffect, useMemo, useState } from "react";
import { Content, Panel, Header, Body, Footer, Card, Text, Button, Icon, Box, ButtonGroup } from 'crack-ux';
import { Generic, Amount } from 'crack-functions';
import moment from 'moment';

// Contexts
import { useGeneral } from "../../../../contexts/general/General";
import { useModal } from "../../../../contexts/general/Modal";
import { useTraductor } from "../../../../contexts/general/Traductor";
import { useLists } from "../../../../contexts/collections/home/Lists";
import { useMovements } from "../../../../contexts/collections/commerce/Movements";

// Components
import { ProductFilter } from "../components/ProductsFilter";
import { ProductsGrid } from "../components/ProductsGrid";
import { ProductsDetail } from "../components/ProductsDetail";
import { VoucherPreview } from "../components/VoucherPreview";

// Styles
import "./Acquisitions.css";

const buttonOptions =
[
	{ icon: 'icon-sliders', view: 'filter' },
	{ icon: 'icon-list-alt', view: 'detail' },
	{ icon: 'icon-table', view: 'products' },
];

const defaultFilter =
{
	categories: [],
	search: ''
}

const defaultAcquisition =
{
	date: moment().format('YYYY-MM-DD'),
}

const Acquisitions = () =>
{
	const [provider, setProvider] = useState({});
	const [productsSelected, setProductsSelected] = useState({});
	const [filter, setFilter] = useState(defaultFilter);
	const [view, setView] = useState('products');

	const { screen } = useGeneral();
	const { AddModal } = useModal();
	const { Translate } = useTraductor();
	const { listsByName } = useLists();
	const { code, movementForm, setMovementForm } = useMovements();

	const categories = useMemo(() => listsByName['product-categories']?.items.filter(item => !item._deleted) || [], [listsByName]);
	const products = useMemo(() => Generic.ObjectToArray(productsSelected), [productsSelected]);

	const paymentMethods = useMemo(() => listsByName['payment-methods'] || {}, [listsByName]);
	const movementTypes = useMemo(() => listsByName['stock-movement-types'] || {}, [listsByName]);

	const total = useMemo(() =>
	{
		let total = 0

		if(products.length > 0)
		{
			products.forEach(product =>
			{
				total = total + Amount.CalculateTotal({quantity: product.amount, price: product.purchasePrice, tax: 0});
			});
		}
		
		return total;
	}, [products]);

	const GetConditionalPrice = useCallback((product, quantity) =>
	{
		let specialPrice = product.price.purchase;
	
		if(product.price?.conditional?.length && movementForm.type)
		{
			const validConditions = product.price.conditional.filter(condition => condition.type === movementForm.type && quantity >= condition.quantity );
	
			const bestCondition = validConditions.reduce((best, current) =>
			{
				return (!best || current.quantity > best.quantity) ? current : best;
			}, null);
	
			if(bestCondition)
			{
				specialPrice = bestCondition.price;
			}
		}
	
		return specialPrice;
	}, [movementForm.type]);

	const AddProduct = useCallback((product) =>
	{
		const cloneProductsSelected = {...productsSelected};
	
		if(cloneProductsSelected[product._id])
		{
			cloneProductsSelected[product._id].amount++;
		}
		else
		{
			cloneProductsSelected[product._id] =
			{
				...product,
				amount: 1,
				purchasePrice: product.price.purchase
			}
		}
	
		const newAmount = cloneProductsSelected[product._id].amount;
		cloneProductsSelected[product._id].purchasePrice = GetConditionalPrice(product, newAmount);
	
		setProductsSelected(cloneProductsSelected);
	}, [productsSelected, GetConditionalPrice]);

	const RemoveProduct = useCallback((product) =>
	{
		if(productsSelected[product._id]?.amount)
		{
			const cloneProductsSelected = {...productsSelected};
	
			if(cloneProductsSelected[product._id].amount > 1)
			{
				cloneProductsSelected[product._id].amount--;
	
				const newAmount = cloneProductsSelected[product._id].amount;
				cloneProductsSelected[product._id].purchasePrice = GetConditionalPrice(product, newAmount);
			}
			else
			{
				delete cloneProductsSelected[product._id];
			}
	
			setProductsSelected(cloneProductsSelected);
		}
	}, [productsSelected, GetConditionalPrice]);

	const ClearProduct = useCallback((product) =>
	{
		const cloneProductsSelected = {...productsSelected};
		delete cloneProductsSelected[product._id];

		setProductsSelected(cloneProductsSelected);
	}, [productsSelected]);

	const UpdatePrice = (product, price) =>
	{
		if(productsSelected[product._id])
		{
			const cloneProductsSelected = {...productsSelected};
			cloneProductsSelected[product._id].purchasePrice = price;
	
			setProductsSelected(cloneProductsSelected);
		}
	}

	const UpdateAmount = (product, amount) =>
	{
		if(productsSelected[product._id])
		{
			const cloneProductsSelected = {...productsSelected};
			cloneProductsSelected[product._id].amount = amount;
	
			cloneProductsSelected[product._id].purchasePrice = GetConditionalPrice(product, amount);
	
			setProductsSelected(cloneProductsSelected);
		}
	}

	const ClearAll = () =>
	{
		setProvider({});
		setProductsSelected({});
		setMovementForm(defaultAcquisition);
		setView('products');
	}

	const ResetFilter = () =>
	{
		setFilter(defaultFilter);
	};

	const HandleSubmit = () =>
	{
		setMovementForm({...movementForm, paymentAmount: total});

		AddModal(<VoucherPreview
			from = 'acquisitions'
			products = {products}
			total = {total}
			ClearAll = {ClearAll}
		/>);
	}

	useEffect(() =>
	{
		const newMovementForm = {...movementForm, ...defaultAcquisition};
		let updatedType = false;
		let updatedPaymentMethod = false;

		if(!movementForm.type && movementTypes.items?.length > 0)
		{
			newMovementForm.type = movementTypes.items.find(type => type.internalName === 'purchase')?._id;
			updatedType = true;
		}

		if(!movementForm.paymentMethodId && paymentMethods.items?.length > 0)
		{
			newMovementForm.paymentMethodId = paymentMethods.items.find(method => method.internalName === 'cash')?._id;
			updatedPaymentMethod = true;
		}

		if(updatedType || updatedPaymentMethod)
		{
			setMovementForm(newMovementForm);
		}
	}, [paymentMethods, movementForm, movementTypes, setMovementForm]);

	return (
			<Content class='commerce-purchases-point'>
				<Panel class='auto-height'>
					<Card>
						<Header>
							<Text class='bold'>{Translate('acquisitions')}: {`MOVE-${code + 1}`}</Text>
							<Box class='float-right'>
								{screen.device === 'mobile' && buttonOptions.map(button =>
								{
									return (
										<Button key={button.view} class={`float-right ${view === button.view ? 'bg-blue white' : 'default'}`} style={{ marginTop: -7, marginLeft: 5 }}>
											<Icon class={`${button.icon} no-margin`} Click={() => setView(button.view)} />
										</Button>
									)
								})}
							</Box>
						</Header>
					</Card>
				</Panel>
				<Body class='no-padding' style={{height: `calc(100% - var(--header) - var(--footer) - 10px)`}}>
					{(view === 'filter' || screen.device !== 'mobile') &&
					<Panel class='desktop2 full-height'>
						<ProductFilter
							categories = {categories}
							filter = {filter}
							setFilter = {setFilter}
							ResetFilter = {ResetFilter}
						/>
					</Panel>}
					{(view === 'products' || screen.device !== 'mobile') &&
					<Panel class='desktop6 full-height'>
						<ProductsGrid
							productsSelected = {productsSelected}
							AddProduct = {AddProduct}
							RemoveProduct = {RemoveProduct}
							ClearProduct = {ClearProduct}
							type = 'acquisitions'
							filter = {filter}
						/>
					</Panel>}
					{(view === 'detail' || screen.device !== 'mobile') &&
					<Panel class='desktop4 full-height'>
						<ProductsDetail
							productsSelected = {productsSelected}
							person = {provider}
							setPerson = {setProvider}
							UpdatePrice = {UpdatePrice}
							UpdateAmount = {UpdateAmount}
							AddProduct = {AddProduct}
							ClearProduct = {ClearProduct}
							ClearAll = {ClearAll}
							setView = {setView}
							from = 'acquisitions'
						/>
					</Panel>}
				</Body>
				{view !== 'filter' &&
				<Panel class='auto-height'>
					<Card>
						<Footer>
							<Text class='bold' size={1.5}>Total:</Text>
							<Text size={1.5} style={{marginLeft: 5}}>{Amount.CurrencyFormat(total)}</Text>
							<Box class='float-right' style={{marginTop: -5}}>
								{(view === 'products' && screen.device === 'mobile') &&
									<Button text={Translate('next')} Click={() => setView('detail')}/>
								}
								{view === 'detail' &&
								<ButtonGroup
									actions =
									{[
										{
											text: Translate('save'),
											class: 'bg-blue white',
											Click: HandleSubmit
										},
										{
											text: Translate('previous'),
											Click: () => setView('products')
										},
										{
											text: Translate('clear-all'),
											Click: () => ClearAll()
										}
									]}
								/>}
								{screen.device !== 'mobile' &&
								<>
									<Button class='bg-blue white' text={Translate('save')} Click={HandleSubmit}/>
									<Button text={Translate('clear-all')} Click={() => ClearAll()}/>
								</>}
							</Box>
						</Footer>
					</Card>
				</Panel>}
			</Content>
		)
}

export { Acquisitions };