import { useEffect, useMemo, useState } from 'react';
import { Card, Panel, Section, Header, Body, Footer, Text, Button, Form, Box, CheckBox } from 'crack-ux';
import { Amount } from 'crack-functions';
import moment from 'moment';

// Context
import { useTraductor } from '../../../../../contexts/general/Traductor';
import { useModal } from '../../../../../contexts/general/Modal';
import { useBusiness } from '../../../../../contexts/layouts/Business';
import { useLists } from '../../../../../contexts/collections/home/Lists';
import { useClients } from '../../../../../contexts/collections/commerce/Clients';
import { useProviders } from '../../../../../contexts/collections/commerce/Providers';
import { useMovements } from '../../../../../contexts/collections/commerce/Movements';

// Components
import { ClientForm } from '../../Clients/ClientForm';
import { ProviderForm } from '../../Providers/ProviderForm';

const TextItem = (product) =>
{
	const { name, amount, price, total } = product;

	return (
		<Section class={product.class}>
			<Panel class='mobile6'>
				<Text>{name}</Text>
			</Panel>
			<Panel class='mobile2'>
				<Text>{amount}</Text>
			</Panel>
			<Panel class='mobile2'>
				<Text>{price}</Text>
			</Panel>
			<Panel class='mobile2'>
				<Text>{total}</Text>
			</Panel>
		</Section>
	)
}

const VoucherPreview = (props) =>
{
	const { from, products, total, ClearAll, currentShift } = props;
	
	const [person, setPerson] = useState({});
	const [invoiced, setInvoiced] = useState(false);
	const [messages, setMessages] = useState({});

	const { Translate } = useTraductor();
	const { AddModal, CloseModal } = useModal();
	const { business, Can } = useBusiness();
	const { listsByName } = useLists();
	const { clients } = useClients();
	const { providers } = useProviders();
	const { movementForm, setMovementForm, CreateMovement } = useMovements();

	const people = useMemo(() => from === 'sales' ? clients : providers, [from, clients, providers]);
	const paymentMethodsObj = useMemo(() => listsByName['payment-methods'] || {}, [listsByName]);

	const discountConfig = business?.preferences?.commerce?.discountRounding || {};
	const {
		method = 'half-up',
		decimals = 2,
		halfSteps = false
	} = discountConfig;

	const rawDiscount = parseFloat(movementForm.discountPercent || 0);
	const discountRate = rawDiscount / 100;
	const discountAmount = Amount.ApplyRounding(total * discountRate, { method, decimals, halfSteps });
	const discountedTotal = Amount.ApplyRounding(total - discountAmount, { method, decimals, halfSteps });

	const HandleChangeDiscount = (value = 0) =>
	{
		const rate = value / 100;
		const amount = Amount.ApplyRounding(total * rate, { method, decimals, halfSteps });

		setMovementForm(prev =>
		{
			return {
				...prev,
				discountPercent: value,
				discount: amount,
				paymentAmount: total - amount
			}
		});
	}

	const HandleChangeDiscountAmount = (value) =>
	{
		const rate = value / total * 100;
		const fixedRate = Amount.ApplyRounding(rate, { method, decimals: 2, halfSteps: false });

		setMovementForm(prev =>
		{
			return {
				...prev,
				discountPercent: fixedRate,
				discount: value,
				paymentAmount: total - value
			}
		});
	}

	const canEditDate = useMemo(() => from === 'sales' ? Can('can-edit-sales-date') : Can('can-edit-acquisitions-date'), [Can, from]);
	
	const SaveMovement = () =>
	{
		const messagesData = {};

		const difference = discountedTotal - movementForm.paymentAmount;

		if(difference && !person._id) messagesData.person = Translate('field-required');
		if(difference && person.code === 0) messagesData.person = Translate('select-valid-person');

		setMessages(messagesData);

		if(Object.keys(messagesData).length > 0) return;

		const details = products.map(product =>
		{
			return {
				product: product._id,
				quantity: product.amount,
				price: from === 'sales' ? product.salePrice : product.purchasePrice,
				total: product.total
			}
		});

		const movement =
		{
			name: person.name || Translate('no-name'),
			tin: person.tin || '',
			contact: person._id || null,
			type: movementForm.type,
			details,
			total: total,
			discount: discountAmount,
			date: moment(movementForm.date).set(
			{
				hour: moment().hour(),
				minute: moment().minute(),
				second: moment().second()
			}),
			annulled: false,
			payments:
			[
				{
					method: movementForm.paymentMethodId,
					amount: movementForm.paymentAmount,
					date: moment()
				}
			],
			invoiced,
		}

		if(from === 'sales' && currentShift._id)
		{
			movement.crShiftId = currentShift._id
		}
		
		CreateMovement(movement).then(response =>
		{
			if(response.status === 200)
			{
				ClearAll();
				CloseModal();
			}	
		});
	}

	const PrintVoucher = () =>
	{
		const printWindow = window.open('', '_blank');
	
		printWindow.document.write(`
			<html>
				<head>
					<title>Voucher</title>
					<style>
						@media print {
							@page {
								margin: 20mm;
							}
							body {
								font-family: Arial, sans-serif;
								padding: 0;
								margin: 0;
							}
							.header {
								height: 100px;
								text-align: center;
								border-bottom: 1px solid #ccc;
								margin-bottom: 20px;
							}
							.content {
								padding: 20px;
							}
						}
					</style>
				</head>
				<body>
					<div class="header">
						<!-- Aquí puedes poner el logo en el futuro -->
					</div>
					<div class="content">
						<!-- Aquí agregas tu contenido dinámico luego -->
						<h1 class="title">Voucher</h1>
						<p>Contenido de prueba del voucher...</p>
					</div>
				</body>
			</html>
		`);
	
		printWindow.document.close();
		printWindow.focus();
		printWindow.print();
		printWindow.close();
	}

	useEffect(() =>
	{
		setMovementForm(prevState =>
		{
			const clientDiscount = parseFloat(person.discount || 0);
			const discount = Amount.ApplyRounding(total * (clientDiscount / 100), { method, decimals, halfSteps });

			return {
				...prevState,
				discountPercent: clientDiscount,
				discount: parseFloat(discount),
				paymentAmount: total - discount,
			}
		});
	}, [person, setMovementForm, total, method, decimals, halfSteps]);

	return (
		<Card style={{width: 600}}>
			<Header>
				<Text class='title'>{Translate('voucher')}</Text>
			</Header>
			<Body>
				<Form
					inputs =
					{[
						{
							name: 'person',
							text: Translate(from === 'sales' ? 'client' : 'provider'),
							class: 'mobile6',
							type: 'autocomplete',
							value: person.displayName || '',
							options: people.map(person =>
							{
								return {
									text: `${person.name === 'no-name' ? Translate('no-name') : person.name}${person.tin ? ` (${person.tin})` : ''}`,
									value: person._id,
									data: person
								}
							}),
							Change: (response) =>
							{
								if(response.data)
								{
									setPerson(
									{
										...response.data,
										displayName: response.text
									});
								}
								else
								{
									setPerson(
									{
										_id: null,
										displayName: response
									});
								}
							},
							message: messages.person
						},
						{
							type: 'content',
							content: <Button class='bg-blue white full-width' text={Translate(from === 'sales' ? 'new-client' : 'new-provider')} Click={() => AddModal(from === 'sales' ? <ClientForm/> : <ProviderForm/>)}/> ,
							class: 'mobile3'
						},
						{
							name: 'date',
							text: Translate('date'),
							class: 'mobile3',
							type: 'date',
							value: movementForm.date,
							disabled: !canEditDate,
						},
						{
							name: 'discountPercent',
							text: `${Translate('discount-percent')} (0-100)%`,
							class: 'mobile6',
							type: 'number',
							value: movementForm.discountPercent,
							Change: (value) => HandleChangeDiscount(value)
						},
						{
							name: 'discountAmount',
							text: Translate('discount-amount'),
							class: 'mobile6',
							type: 'number',
							value: movementForm.discount,
							Change: (value) => HandleChangeDiscountAmount(value)
						},
						{
							name: 'paymentMethodId',
							text: Translate('payment-method'),
							class: 'mobile6',
							type: 'select',
							options: paymentMethodsObj.selectOptions,
							value: movementForm.paymentMethodId,
						},
						{
							name: 'paymentAmount',
							text: Translate('to-pay'),
							class: 'mobile6',
							type: 'number',
							value: movementForm.paymentAmount
						}
					]}
					form = {movementForm}
					setForm = {setMovementForm}
				/>
				<Section style={{minHeight: 200}}>
					<TextItem name={Translate('product')} amount={Translate('amount')} price={Translate('price')} total={Translate('total')} class='bold'/>
					{products.map(product => <TextItem
								key = {product._id}
								name = {product.name}
								amount = {product.amount}
								price = {Amount.CurrencyFormat(product.salePrice)}
								total = {Amount.CurrencyFormat(product.total)}
							/>
					)}
				</Section>
				<Section style={{marginTop: 20, marginBottom: 10}}>
					<Panel class='mobile10'>
						<Text class='bold'>{Translate('total')}</Text>
					</Panel>
					<Panel class='mobile2'>
						<Text class='bold'>{Amount.CurrencyFormat(total)}</Text>
					</Panel>
					<Panel class='mobile10'>
						<Text class='bold'>{Translate('discount')}</Text>
					</Panel>
					<Panel class='mobile2'>
						<Text class='bold'>{Amount.CurrencyFormat(discountAmount)}</Text>
					</Panel>
					<Panel class='mobile10'>
						<Text class='bold'>{Translate('to-pay')}</Text>
					</Panel>
					<Panel class='mobile2'>
						<Text class='bold'>{Amount.CurrencyFormat(discountedTotal)}</Text>
					</Panel>
					<Panel class='mobile10'>
						<Text class='bold'>{Translate('balance')}</Text>
					</Panel>
					<Panel class='mobile2'>
						<Text class='bold'>{Amount.CurrencyFormat(discountedTotal - movementForm.paymentAmount)}</Text>
					</Panel>
				</Section>
			</Body>
			<Footer class='right'>
				<Box class='float-left'>
					<CheckBox text={Translate('to-invoice')} checked={invoiced} Change={setInvoiced} disabled={true}/>
				</Box>
				<Button class='bg-blue white' text={Translate('save')} Click={SaveMovement}/>
				<Button text={Translate('print')} Click={PrintVoucher}/>
				<Button text={Translate('cancel')} Click={CloseModal}/>
			</Footer>
		</Card>
	)
}

export { VoucherPreview };