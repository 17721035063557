import { useMemo } from "react";
import { Card, Section, Header, Body, Footer, Text, Button } from "crack-ux";
import QrReader from "react-qr-barcode-scanner";

// Contexts
import { useGeneral } from "../../contexts/general/General";
import { useTraductor } from "../../contexts/general/Traductor";
import { useModal } from "../../contexts/general/Modal";

const BarcodeQRScanner = (props) =>
{
	const {
		ScanSuccess,
		scope = 'both',
		closeModal = true,
		sound = true,
		card = true,
		style = {}
	} = props;

	const { screen } = useGeneral();
	const { Translate } = useTraductor();
	const { CloseModal } = useModal();
	
	const acceptedFormats = useMemo(() => 
	{
		if (scope === "barcode") 
		{
			return ["code_128", "code_39", "ean_13", "ean_8", "upc_a", "upc_e"];
		} 
		else if (scope === "qr") 
		{
			return ["qr_code"];
		} 
		else
		{
			return ["qr_code", "code_128", "code_39", "ean_13", "ean_8", "upc_a", "upc_e"];
		}
	}, [scope]);

	const PlayScanSound = () => 
	{
		const audio = new Audio("/assets/sounds/scan.mp3");
		audio.play().catch((error) => console.error("Scan sound error:", error));
	};

	const HandleScan = (data) =>
	{
		if (data)
		{
			if (sound) PlayScanSound();

			if (ScanSuccess) ScanSuccess(data.text);

			if (closeModal) CloseModal();
		}
	};

	const HandleError = (error) =>
	{
		// console.error("Scan Error: ", error);
	};

	const QR = () =>
	{
		return (
			<Section style={style}>
				<QrReader
					onUpdate = {(error, result) =>
					{
						if (result) HandleScan(result);
						if (error) HandleError(error);
					}}
					acceptedFormats = {acceptedFormats}
					constraints = {{ facingMode: "environment" }}
				/>
			</Section>
		);
	}

	if (card)
	{
		return (
			<Card>
				<Header>
					<Text>{scope === 'barcode' ? Translate('barcode-scanner') : scope === 'qr' ? Translate('qr-scanner') : Translate('barcode-qr-scanner')}</Text>
				</Header>
				<Body style={screen.device === 'mobile' ? {height: 'calc(100% - var(--header) - var(--footer))'} : {}}>
					<QR/>
				</Body>
				{closeModal &&
				<Footer class='right'>
					<Button text={Translate('close')} Click={CloseModal}/>
				</Footer>}
			</Card>
		);
	}
	else
	{
		return <QR/>;
	}
};

export { BarcodeQRScanner };
