import { Route, Routes } from 'react-router-dom';
import { Clients } from './Clients';

const ClientsRouter = () =>
{
	return (
		<Routes>
			<Route path='/:clientName/:clientId' element={<Clients/>}/>
			<Route path='/' element={<Clients/>}/>
		</Routes>
	)
}

export { ClientsRouter as Clients };